import {Nav, Tabs, Tab, Row, Col} from "react-bootstrap";
import React, {useEffect} from "react";
import GeneralTab from "./LinguistGeneralTab";
import LogOfNewBatchesTab from "./LinguistLogOfNewBatchesTab";
import LanguageTab from "./LinguistLanguageTab";

export default function StyleGuideTabs(props) {
    useEffect(() => {
        props.triggerOpenInModalAddedImage()
    }, []);

    return(
        <div className="main-block-div-dark">
            <Tab.Container defaultActiveKey={"general"}>
                <Row>
                    <Col sm={12}>
                        <Nav variant="pills" className="nav-custom-grey">
                            <Nav.Item>
                                <Nav.Link eventKey={"general"}>
                                    General
                                </Nav.Link>
                            </Nav.Item>
                            {
                                props.logOfNewBatches &&
                                <Nav.Item>
                                    <Nav.Link eventKey={"logOfNewBatches"}>
                                        Log Of New Batches
                                    </Nav.Link>
                                </Nav.Item>
                            }
                            {
                                props.languages.map(language => {
                                    return(
                                        <Nav.Item key={language.code} >
                                            <Nav.Link eventKey={language.code}>
                                                {language.title}
                                            </Nav.Link>
                                        </Nav.Item>
                                    )
                                })
                            }
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey={"general"}>
                                <div className="tab-item-wrapper">
                                    <br/>
                                    <GeneralTab rows={props.rows} columns={props.columns}/>
                                </div>
                            </Tab.Pane>
                            {
                                props.logOfNewBatches &&
                                <Tab.Pane eventKey={"logOfNewBatches"}>
                                    <div className="tab-item-wrapper">
                                        <br/>
                                        <LogOfNewBatchesTab
                                            logOfNewBatchesHeaders={props.logOfNewBatchesHeaders}
                                            logOfNewBatchesRowsCount={props.logOfNewBatchesRowsCount}
                                            logOfNewBatchesRowsData={props.logOfNewBatchesRowsData}/>
                                    </div>
                                </Tab.Pane>
                            }
                            {
                                props.languages.map(language => {
                                    return(
                                        <Tab.Pane key={language.code} eventKey={language.code}>
                                            <div className="tab-item-wrapper">
                                                <br/>
                                                <LanguageTab
                                                    // submit={props.submit}
                                                    linguistCode={props.linguistCode}
                                                    language={language}
                                                    acceptedData={props.acceptedData}
                                                    bufferedData={props.bufferedData}
                                                    addRowToStyleGuideLanguageBlock={props.addRowToStyleGuideLanguageBlock}
                                                    characterCommunications={props.characterCommunications}
                                                    communicationBetweenCharacters={props.communicationBetweenCharacters}
                                                    oninputLinguistCharacterCommunicationCell={props.oninputLinguistCharacterCommunicationCell}
                                                    setBufferedLanguagePreferencesUpdatedCellData={props.setBufferedLanguagePreferencesUpdatedCellData}
                                                    modalAddLinksAndImagesActive={props.modalAddLinksAndImagesActive}
                                                    modalOpenAddedImageActive={props.modalOpenAddedImageActive}
                                                    triggerOpenInModalAddedImage={props.triggerOpenInModalAddedImage}
                                                    openModalToAddLinksAndImages={props.openModalToAddLinksAndImages}
                                                    languageTabSavePreferencesCellValue={props.languageTabSavePreferencesCellValue}
                                                />
                                            </div>
                                        </Tab.Pane>
                                    )
                                })
                            }
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    )
}