import {Table} from "react-bootstrap";
import React from "react";

const styles = {
    blockHeader: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    editableCell: {
        backgroundColor: '#fff',
        width: '50%'
    },

    noEditableCell: {
        backgroundColor: '#f6f6f6',
        width: '50%'
    },

    clientNotAcceptedCell: {
        backgroundColor: '#fff8e1',
        width: '50%'
    },

    clientDidNotSeeChanges: {
        backgroundColor: '#c5deff',
        width: '50%'
    }
}

export default function LinguistLanguageTabPreferences(props) {
    return(
        <div key={props.language.code} className="row">
            <div className="col-sm-12 table-blue-thead-not-fixed">
                <Table responsive bordered>
                    <thead>
                    <tr style={styles.blockHeader}>
                        <th className="center" colSpan={2}
                            style={styles.blockHeader}>
                            {props.language.data.base_info.title}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.language.data.base_info.data.map(data => {
                            return (
                                <tr key={data.code}>
                                    {
                                        data.row.map((rowData, rowIndex) => {
                                            if (props.language.data.base_info.clientEditable && rowIndex !== 0) {
                                                let style = styles.editableCell
                                                let linguistData = {
                                                    isLinguistRow: false,
                                                    linguistId: ""
                                                }
                                                let changeAcceptedByClient = true

                                                if ("changedBy" in rowData) {
                                                    changeAcceptedByClient = "changeAcceptedByClient" in rowData.changedBy ?
                                                        rowData.changedBy.changeAcceptedByClient : false
                                                }

                                                if ("isAccepted" in rowData && !rowData.isAccepted) {
                                                    style = styles.clientNotAcceptedCell
                                                } else if (!changeAcceptedByClient && props.showAcceptChangesBtn) {
                                                    style = styles.clientDidNotSeeChanges
                                                }

                                                if ("linguistExtraRow" in data && data.linguistExtraRow) {
                                                    linguistData.isLinguistRow = true
                                                    linguistData.linguistId = data.linguist
                                                }

                                                return(
                                                    <td key={rowData.code + "-" + rowIndex} className="whiteSpacePreLine"
                                                        colSpan={data.colspan ? 2 : 1}
                                                        contentEditable={true}
                                                        suppressContentEditableWarning={true}
                                                        style={style}
                                                        dangerouslySetInnerHTML={{__html: rowData.text}}
                                                        onBlur={(e) =>
                                                            props.sgTabSaveCellValue(linguistData, props.language.code,
                                                                "base_info", data.code, rowData.code, e.target.innerText)}>
                                                    </td>
                                                )
                                            } else {
                                                return(
                                                    <td key={rowData.code + "-" + rowIndex} className="whiteSpacePreLine"
                                                        colSpan={data.colspan ? 2 : 1}
                                                        contentEditable={false}
                                                        style={styles.noEditableCell}
                                                        dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                    </td>
                                                )
                                            }
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
                <br />
                <Table responsive bordered>
                    <thead>
                    <tr style={styles.blockHeader}>
                        <th className="center" colSpan={2} style={styles.blockHeader}>
                            {props.language.data.punctuation_marks.title}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.language.data.punctuation_marks.data.map(data => {
                            return (
                                <tr key={data.code}>
                                {
                                        data.row.map((rowData, rowIndex) => {
                                            if (props.language.data.punctuation_marks.clientEditable && rowIndex !== 0) {
                                                let style = styles.editableCell
                                                let linguistData = {
                                                    isLinguistRow: false,
                                                    linguistId: ""
                                                }
                                                let changeAcceptedByClient = true

                                                if ("changedBy" in rowData) {
                                                    changeAcceptedByClient = "changeAcceptedByClient" in rowData.changedBy ?
                                                        rowData.changedBy.changeAcceptedByClient : false
                                                }

                                                if ("isAccepted" in rowData && !rowData.isAccepted) {
                                                    style = styles.clientNotAcceptedCell
                                                } else if (!changeAcceptedByClient && props.showAcceptChangesBtn) {
                                                    style = styles.clientDidNotSeeChanges
                                                }

                                                if ("linguistExtraRow" in data && data.linguistExtraRow) {
                                                    linguistData.isLinguistRow = true
                                                    linguistData.linguistId = data.linguist
                                                }

                                                return (
                                                    <td key={rowData.code + "-" + rowIndex}
                                                        className="whiteSpacePreLine"
                                                        colSpan={data.colspan ? 2 : 1}
                                                        contentEditable={true}
                                                        suppressContentEditableWarning={true}
                                                        style={style}
                                                        dangerouslySetInnerHTML={{__html: rowData.text}}
                                                        onBlur={(e) =>
                                                            props.sgTabSaveCellValue(linguistData, props.language.code,
                                                                "punctuation_marks", data.code, rowData.code, e.target.innerText)}>
                                                    </td>
                                                )
                                            } else {
                                                return (
                                                    <td key={rowData.code + "-" + rowIndex}
                                                        className="whiteSpacePreLine"
                                                        colSpan={data.colspan ? 2 : 1}
                                                        contentEditable={false}
                                                        style={styles.noEditableCell}
                                                        dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                    </td>
                                                )
                                            }
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
                <br />
                <Table responsive bordered>
                    <thead>
                    <tr style={styles.blockHeader}>
                        <th className="center" colSpan={2} style={styles.blockHeader}>
                            {props.language.data.capital_letters.title}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.language.data.capital_letters.data.map(data => {
                            return (
                                <tr key={data.code}>
                                {
                                        data.row.map((rowData, rowIndex) => {
                                            if (props.language.data.capital_letters.clientEditable && rowIndex !== 0) {
                                                let style = styles.editableCell
                                                let linguistData = {
                                                    isLinguistRow: false,
                                                    linguistId: ""
                                                }
                                                let changeAcceptedByClient = true

                                                if ("changedBy" in rowData) {
                                                    changeAcceptedByClient = "changeAcceptedByClient" in rowData.changedBy ?
                                                        rowData.changedBy.changeAcceptedByClient : false
                                                }

                                                if ("isAccepted" in rowData && !rowData.isAccepted) {
                                                    style = styles.clientNotAcceptedCell
                                                } else if (!changeAcceptedByClient && props.showAcceptChangesBtn) {
                                                    style = styles.clientDidNotSeeChanges
                                                }

                                                if ("linguistExtraRow" in data && data.linguistExtraRow) {
                                                    linguistData.isLinguistRow = true
                                                    linguistData.linguistId = data.linguist
                                                }

                                                return (
                                                    <td key={rowData.code + "-" + rowIndex}
                                                        className="whiteSpacePreLine"
                                                        colSpan={data.colspan ? 2 : 1}
                                                        contentEditable={true}
                                                        suppressContentEditableWarning={true}
                                                        style={style}
                                                        dangerouslySetInnerHTML={{__html: rowData.text}}
                                                        onBlur={(e) =>
                                                            props.sgTabSaveCellValue(linguistData, props.language.code,
                                                                "capital_letters", data.code, rowData.code, e.target.innerText)}>
                                                    </td>
                                                )
                                            } else {
                                                return (
                                                    <td key={rowData.code + "-" + rowIndex}
                                                        className="whiteSpacePreLine"
                                                        colSpan={data.colspan ? 2 : 1}
                                                        contentEditable={false}
                                                        style={styles.noEditableCell}
                                                        dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                    </td>
                                                )
                                            }
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
                <br />
                <Table responsive bordered>
                    <thead>
                    <tr style={styles.blockHeader}>
                        <th className="center" colSpan={2} style={styles.blockHeader}>
                            {props.language.data.target_language_features.title}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.language.data.target_language_features.data.map(data => {
                            return (
                                <tr key={data.code}>
                                {
                                        data.row.map((rowData, rowIndex) => {
                                            if (props.language.data.target_language_features.clientEditable && rowIndex !== 0) {
                                                let style = styles.editableCell
                                                let linguistData = {
                                                    isLinguistRow: false,
                                                    linguistId: ""
                                                }
                                                let changeAcceptedByClient = true

                                                if ("changedBy" in rowData) {
                                                    changeAcceptedByClient = "changeAcceptedByClient" in rowData.changedBy ?
                                                        rowData.changedBy.changeAcceptedByClient : false
                                                }

                                                if ("isAccepted" in rowData && !rowData.isAccepted) {
                                                    style = styles.clientNotAcceptedCell
                                                } else if (!changeAcceptedByClient && props.showAcceptChangesBtn) {
                                                    style = styles.clientDidNotSeeChanges
                                                }

                                                if ("linguistExtraRow" in data && data.linguistExtraRow) {
                                                    linguistData.isLinguistRow = true
                                                    linguistData.linguistId = data.linguist
                                                }

                                                return (
                                                    <td key={rowData.code + "-" + rowIndex}
                                                        className="whiteSpacePreLine"
                                                        colSpan={data.colspan ? 2 : 1}
                                                        contentEditable={true}
                                                        suppressContentEditableWarning={true}
                                                        style={style}
                                                        dangerouslySetInnerHTML={{__html: rowData.text}}
                                                        onBlur={(e) =>
                                                            props.sgTabSaveCellValue(linguistData, props.language.code,
                                                                "target_language_features", data.code, rowData.code, e.target.innerText)}>
                                                    </td>
                                                )
                                            } else {
                                                return (
                                                    <td key={rowData.code + "-" + rowIndex}
                                                        className="whiteSpacePreLine"
                                                        colSpan={data.colspan ? 2 : 1}
                                                        contentEditable={false}
                                                        style={styles.noEditableCell}
                                                        dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                    </td>
                                                )
                                            }
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
                <br />
                <Table responsive bordered>
                    <thead>
                    <tr style={styles.blockHeader}>
                        <th className="center" colSpan={2} style={styles.blockHeader}>
                            {props.language.data.inclusive_language_guidelines.title}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.language.data.inclusive_language_guidelines.data.map(data => {
                            return (
                                <tr key={data.code}>
                                {
                                        data.row.map((rowData, rowIndex) => {
                                            if (props.language.data.inclusive_language_guidelines.clientEditable && rowIndex !== 0) {
                                                let style = styles.editableCell
                                                let linguistData = {
                                                    isLinguistRow: false,
                                                    linguistId: ""
                                                }
                                                let changeAcceptedByClient = true

                                                if ("changedBy" in rowData) {
                                                    changeAcceptedByClient = "changeAcceptedByClient" in rowData.changedBy ?
                                                        rowData.changedBy.changeAcceptedByClient : false
                                                }

                                                if ("isAccepted" in rowData && !rowData.isAccepted) {
                                                    style = styles.clientNotAcceptedCell
                                                } else if (!changeAcceptedByClient && props.showAcceptChangesBtn) {
                                                    style = styles.clientDidNotSeeChanges
                                                }

                                                if ("linguistExtraRow" in data && data.linguistExtraRow) {
                                                    linguistData.isLinguistRow = true
                                                    linguistData.linguistId = data.linguist
                                                }

                                                return (
                                                    <td key={rowData.code + "-" + rowIndex}
                                                        className="whiteSpacePreLine"
                                                        colSpan={data.colspan ? 2 : 1}
                                                        contentEditable={true}
                                                        suppressContentEditableWarning={true}
                                                        style={style}
                                                        dangerouslySetInnerHTML={{__html: rowData.text}}
                                                        onBlur={(e) =>
                                                            props.sgTabSaveCellValue(linguistData, props.language.code,
                                                                "inclusive_language_guidelines", data.code, rowData.code, e.target.innerText)}>
                                                    </td>
                                                )
                                            } else {
                                                return (
                                                    <td key={rowData.code + "-" + rowIndex}
                                                        className="whiteSpacePreLine"
                                                        colSpan={data.colspan ? 2 : 1}
                                                        contentEditable={false}
                                                        style={styles.noEditableCell}
                                                        dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                    </td>
                                                )
                                            }
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}