import React, {useEffect} from "react";
import Form from "./games_store_parser/Form";
import ReviewsInfo from "./games_store_parser/ReviewsInfo";
import Login from "./auth/Login";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GameStoreParser from "./games_store_parser/GameStoreParser";
import ManagedQualityOnProjectView from "./managed_quality_on_project/view/ManagedQualityOnProjectView";
import ManagedQualityOnProjectCreate from "./managed_quality_on_project/create/ManagedQualityOnProjectCreate";
import ProjectBibleTemplateHeader from "./managed_quality_on_project/project_bible/template_header/ProjectBibleTemplateHeader";
import ProjectBibleTemplateBody from "./managed_quality_on_project/project_bible/template_body/ProjectBibleTemplateBody";
import ProjectBibleTemplateTags from "./managed_quality_on_project/project_bible/template_tags/ProjectBibleTemplateTags";
import ProjectBibleView from "./managed_quality_on_project/project_bible/view/ProjectBibleView";
import ProjectBibleClientView from "./managed_quality_on_project/project_bible/client_view/ProjectBibleClientView";
import ProjectBibleStyleGuide from "./managed_quality_on_project/project_bible/style_guide/StyleGuide";
import StyleGuideLinguist from "./managed_quality_on_project/project_bible/style_guide_linguist/StyleGuideLinguist";
import Test from "./test/Test";
// import Test2 from "./test/Test2";
import logo from "./img/logo1.png";
import logoNew from "./img/-1.png";
import ProjectStagesView from "./managed_quality_on_project/project_stages/view/ProjectStagesView";
import ProjectStagesTemplateView from "./managed_quality_on_project/project_stages/template/View";
import ProjectBibleReport from "./managed_quality_on_project/project_bible/report/ProjectBibleReport";
// import VmSmartSheet from "./vm/list/VmSmartSheet";
// import VmSmartSheetTable from "./vm/view/VmSmartSheetTable";
// import VmScript from "./vm/script/VmScript";
// import Onboarding from "./onboarding/Onboarding";
import LocForm from "./folk/LocForm"
// import ShowCV from "./folk/ShowCV"
import ProjectBibleClientViewNew from "./managed_quality_on_project/project_bible/client_view_new/View";

const styles = {
  companyLogoStyle: {
    paddingLeft: '3rem',
    // margin: '.5rem'
  },

  navbar: {
    backgroundColor: '#222',
  }
}

function App() {
  function authLogIn() {

  }

  return (
      <div id="mainDocumentBlock">
        <style type="text/css">
          {`
            .btn-yellow, .btn-yellow:hover {
              background-color: rgb(255, 221, 99);
              border-color: rgb(255, 221, 99);
              color: rgb(25, 25, 25);
            }
            
            .btn-blue, .btn-blue:hover {
              background-color: #4A8EFF;
              border-color: #4A8EFF;
              color: white;
            }
            
            .nav-link, .nav-link:hover {
              color: white;
            }
          `}
        </style>
        <nav className="navbar navbar-expand-lg" style={styles.navbar}>
          <div className="col-12">
            <div style={styles.companyLogoStyle}>
              <a className="navbar-brand" href="#">
                <img src={logoNew} alt="logo" width="165" height="27"/>
              </a>
            </div>
          </div>
        </nav>
        <BrowserRouter>
          <Routes>
            <Route path="/"/>
            <Route path="/autorization" element={<Login authLogIn={authLogIn}/>}/>
            <Route path="/game_store_parser" element={<GameStoreParser/>}/>
            <Route path="/managed_quality_on_project/project_bible/template_header"
                   element={<ProjectBibleTemplateHeader/>}/>
            <Route path="/managed_quality_on_project/project_bible/template_body"
                   element={<ProjectBibleTemplateBody/>}/>
            <Route path="/managed_quality_on_project/project_bible/template_tags"
                   element={<ProjectBibleTemplateTags/>}/>
            <Route path="/managed_quality_on_project/project_bible/view" element={<ProjectBibleView/>}/>
            <Route path="/managed_quality_on_project/project_bible/client_view" element={<ProjectBibleClientView/>}/>
            <Route path="/managed_quality_on_project/project_bible/client_view_new"
                   element={<ProjectBibleClientViewNew/>}/>
            <Route path="/managed_quality_on_project/project_bible/styleguide" element={<ProjectBibleStyleGuide/>}/>
            <Route path="/managed_quality_on_project/project_bible/styleguidelinguist" element={<StyleGuideLinguist/>}/>
            <Route path="/managed_quality_on_project/project_stages" element={<ProjectStagesView/>}/>
            <Route path="/workflow/template" element={<ProjectStagesTemplateView/>}/>
            <Route path="/managed_quality_on_project/pb_report" element={<ProjectBibleReport/>}/>
            {/*<Route path="/vm" element={<VmSmartSheet /> } />*/}
            {/*<Route path="/vm/table" element={<VmSmartSheetTable /> } />*/}
            {/*<Route path="/vm/script" element={<VmScript /> } />*/}
            {/*<Route path="/onboarding" element={<Onboarding /> } />*/}
            <Route path="/locform" element={<LocForm/>}/>
            {/*<Route path="/locform/showcv" element={<ShowCV /> } />*/}
            <Route path="/test" element={<Test/>}/>
            {/*<Route path="/test2" element={<Test2 /> } />*/}


            {/*<Route path="/managed_quality_on_project_create" element={<ManagedQualityOnProjectCreate /> } />*/}
            {/*<Route path="/managed_quality_on_project_view" element={<ManagedQualityOnProjectView /> } />*/}
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;
