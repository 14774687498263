import "../css/modal.css"
import {Col, FormCheck, FormLabel, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";

const styles = {
    modalWH: {
        backgroundColor: "#222",
        width: '40%',
        height: '70%'
    }
}

export default function ResponsibleModal(props) {
    let selectedResponsible = props.responsibleModalData[0].selectedResponsible

    function setRowResponsible(responsibleCode, e) {
        if (e.target.checked) {
            if (!selectedResponsible.includes(responsibleCode)) {
                selectedResponsible.push(responsibleCode)
            }
        } else {
            if (selectedResponsible.includes(responsibleCode)) {
                let index = selectedResponsible.indexOf(responsibleCode)

                selectedResponsible.splice(index, 1)
            }
        }
    }

    let getAllResponsible = responsibles => {
        let content = []

        for (let i = 0; i < responsibles.length; i++) {
            let responsibleCode = responsibles[i].code
            let responsibleName = responsibles[i].responsibleName
            let isCheck = props.responsibleModalData[0].selectedResponsible.includes(responsibleCode)

            content.push(
                <FormCheck defaultChecked={isCheck} label={responsibleName}
                           onChange={(e) =>
                               setRowResponsible(responsibleCode, e)} key={responsibleCode} />
            )
        }

        return content
    };

    function setResponsible(techProcess, block, row, data) {
        selectedResponsible = {}

        props.saveResponsibleCell(techProcess, block, row, data)
    }

    if (props.responsibleModalActive) {
        return(
            <div className={props.responsibleModalActive ? "modal active" : "modal"} onClick={() => props.setResponsibleModalActive(false)} >
                <div className={props.responsibleModalActive ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}
                     style={styles.modalWH} >
                    <div className="row">
                        <div className="col-sm-10">
                            <h4>Here you can choose responsibles</h4>
                        </div>
                        <div className="col-sm-2 center">
                            <button className="btn btn-blue"
                                    onClick={(e) =>
                                        setResponsible(props.responsibleModalData[0].techProcess, props.responsibleModalData[0].block, props.responsibleModalData[0].row, selectedResponsible)}>
                                Save
                            </button>
                        </div>
                    </div>
                    <hr />
                    <br />
                    <div className="row">
                        <div className="col-sm-12">
                            { getAllResponsible(props.responsibleModalData[0].allResponsible) }
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <div className={props.responsibleModalActive ? "modal active" : "modal"} onClick={() => props.setResponsibleModalActive(false)} >
                <div className={props.responsibleModalActive ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}
                     style={styles.modalWH} >
                </div>
            </div>
        )
    }
}