import React, {useState} from "react";
import GeneralTableCell from "./GeneralTableCell";

const styles = {
    noEditableCell: {
        backgroundColor: '#fafafa',
        whiteSpace: 'pre-line',
    },
}

export default function TableRow(props) {
    return(
        <tr>
            <td className="center" style={styles.noEditableCell}>{props.rowIndex + 1}</td>
            {
                props.columns.map((column, columnIndex) => {
                    if (column.show) {
                        return(
                            <GeneralTableCell
                                viewCode={props.viewCode}
                                key={props.row.code + "_" + column.code}
                                column={column}
                                rowCode={props.row.code}
                                cellData={props.row.data[column.code]}
                                columnIndex={columnIndex}
                                generalTabSaveCellValue={props.generalTabSaveCellValue}
                                showAcceptChangesBtn={props.showAcceptChangesBtn}
                            />
                        )
                    }
                })
            }
        </tr>
    )
}