import React, {useState} from "react";
import {Form} from "react-bootstrap";
import queryString from "query-string";

export default function AdditionalComment(props) {
    async function oninputAdditionalComment(value) {
        await fetch('/proxy/project_bible_template/setClientViewAdditionalComment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "code": props.viewCode,
                "value": value
            })
        })
            .then(res => res.json())
            .then(
                async (resultUpdate) => {

                },
                (error) => {
                    alert("An error occurred while saving cell data.")
                }
            )
    }

    return(
        <div className="row">
            <div className="col-sm-12">
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Additional comment</Form.Label>
                        <Form.Control as="textarea" rows={3} defaultValue={props.additionalComment}
                                      onBlur={(e) =>
                                          oninputAdditionalComment(e.target.value)}/>
                    </Form.Group>
                </Form>
            </div>
        </div>
    )
}