import {Table} from "react-bootstrap";
import React from "react";

const styles = {
    blockHeader: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    noEditableCell: {
        backgroundColor: '#f6f6f6',
        width: '30%'
    },

    clientNotAcceptedCell: {
        backgroundColor: '#fff8e1',
        width: '30%'
    }
}

export default function LinguistLanguageTabPreferences(props) {
    let getBlocks = (blocks) => {
        let content = []

        for (let block in blocks) {
            let blockData = blocks[block]

            if (blockData.clientEditable) {
                content.push(
                    <>
                        <Table responsive bordered>
                            <thead>
                            <tr style={styles.blockHeader}>
                                <th className="center" colSpan={3}
                                    style={styles.blockHeader}>
                                    {blockData.title}
                                </th>
                                <th className="center"
                                    style={styles.blockHeader}>
                                    (Client changes)
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                blockData.data.map(data => {
                                    console.log("block data", data)

                                    if ("show" in data && data.show) {
                                        return (
                                            <tr key={data.code}>
                                                {
                                                    data.row.map((rowData, rowIndex) => {
                                                        if (blockData.clientEditable && rowIndex !== 0) {
                                                            return (
                                                                <>
                                                                    <td key={rowData.code + "-" + rowIndex}
                                                                        className="whiteSpacePreLine"
                                                                        colSpan={data.colspan ? 2 : 1}
                                                                        contentEditable={false}
                                                                        style={styles.noEditableCell}
                                                                        dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                    </td>
                                                                    <td className="center" style={styles.noEditableCell}>
                                                                        <input
                                                                            type="checkbox" onChange={(e) =>
                                                                            props.setAcceptedSGLanguageRows(props.language.code, block, data.code, rowData.code, e.target.checked)}
                                                                            defaultChecked={false}
                                                                        />
                                                                    </td>
                                                                    <td key={rowData.code + "-" + rowIndex + "-client"}
                                                                        className="whiteSpacePreLine"
                                                                        colSpan={data.colspan ? 2 : 1}
                                                                        contentEditable={false}
                                                                        style={styles.clientNotAcceptedCell}
                                                                        dangerouslySetInnerHTML={{__html: rowData.clientText}}>
                                                                    </td>
                                                                </>
                                                            )
                                                        } else {
                                                            return (
                                                                <td key={rowData.code + "-" + rowIndex}
                                                                    className="whiteSpacePreLine"
                                                                    colSpan={data.colspan ? 2 : 1}
                                                                    contentEditable={false}
                                                                    style={styles.noEditableCell}
                                                                    dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                </td>
                                                            )
                                                        }
                                                    })
                                                }
                                            </tr>
                                        )
                                    }
                                })
                            }
                            </tbody>
                        </Table>
                        <br />
                    </>
                )
            }
        }

        return content
    }

    return(
        <div key={props.language.code} className="row">
            <div className="col-sm-12">
                { getBlocks(props.language.data) }
            </div>
        </div>
    )
}