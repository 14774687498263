import "../project_bible/css/modal.css"
import {Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import ModalCellDataHistoryList from "./ModalCellDataHistoryList"

const styles = {
    modalWH: {
        width: '75%',
        height: '85%'
    }
}

export default function ModalCellDataHistory(props) {
    const [isNewValueChecked, setIsNewValueChecked] = useState(false)
    const [newValueIndex, setNewValueIndex] = useState(0)
    const [newValue, setNewValue] = useState("")

    // console.log("ModalCellDataHistory", props)

    function selectNewValue(key, isСhecked, value) {
        console.log("selectNewValue", key, isСhecked, value)

        if (isСhecked) {
            setIsNewValueChecked(true)
            setNewValueIndex(key)
            setNewValue(value)

            props.editModalHistoryDataCheckedValue(key, isСhecked)
        } else {
            setIsNewValueChecked(false)

            props.editModalHistoryDataCheckedValue(key, isСhecked)
        }
    }

    return (
        <div className={props.modalOpenCellDataHistoryActive ? "modal active" : "modal"}
             onClick={() => props.setModalOpenCellDataHistoryActive(false)}>
            <div className={props.modalOpenCellDataHistoryActive ? "modal__content active" : "modal__content"}
                 onClick={e => e.stopPropagation()}
                 style={styles.modalWH}>
                <div className="row">
                    <div className="col-sm-12">
                        <h4>{props.modalOpenCellDataHistoryData[0].modalTitle}</h4>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="table-responsive cell-data-history-container">
                            <table className="table table-bordered table-blue-thead-not-fixed">
                                <thead>
                                <tr>
                                    <th className="center align-middle"></th>
                                    <th className="center align-middle">Changed by</th>
                                    <th className="center align-middle">Value</th>
                                    <th className="center align-middle">Timestamp</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ModalCellDataHistoryList
                                    data={props.modalOpenCellDataHistoryData[0].data}
                                    isNewValueChecked={isNewValueChecked}
                                    newValueIndex={newValueIndex}
                                    selectNewValue={selectNewValue}
                                    setIsNewValueChecked={setIsNewValueChecked}
                                    setNewValueIndex={setNewValueIndex}
                                    setNewValue={setNewValue}
                                />
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <br/>
                {
                    isNewValueChecked &&
                    <div className="row">
                        <div className="col-sm-12 center">
                            <Button variant="primary" className="btn btn-blue"
                                    onClick={(e) =>
                                        props.replaceToOldValueFromDataHistory(
                                            props.modalOpenCellDataHistoryData[0].saveMethod,
                                            props.modalOpenCellDataHistoryData[0].identityData,
                                            props.modalOpenCellDataHistoryData[0].tableName,
                                            newValue
                                        )}>
                                Replace to old value
                            </Button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}