import "../css/modal.css"
import {Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";

const styles = {
    modalWH: {
        width: '30%',/*,
        height: '40%'*/
        background: '#222'
    },

    btnOk: {
        width: '100px'
    },

    listDot: {
        listStyleType: 'disc'
    }
}

export default function ClientHintModal(props) {
    return (
        <div className={props.modalHintActive ? "modal active" : "modal"}
             onClick={() => props.setModalHintActive(false)}>
            <div className={props.modalHintActive ? "modal__content active" : "modal__content"}
                 onClick={e => e.stopPropagation()}
                 style={styles.modalWH}>
                <div className="row">
                    <div className="col-sm-12">
                        <strong>Project Overview</strong>
                        <br/><br/>
                        <ul style={styles.listDot}>
                            <li><strong>Project Tab:</strong> View general project specifications and questions.</li>
                            <li><strong>Language Tabs:</strong> View localization specifications for each language.</li>
                        </ul>
                        <br/>
                        <strong>Editing Info Column</strong>
                        <br/><br/>
                        <ul style={styles.listDot}>
                            <li><strong>White Cells:</strong> No changes made.</li>
                            <li><strong>Blue Cells:</strong> Changes made by us, pending your approval. You can review
                                and modify these if needed.</li>
                            <li><strong>Yellow Cells:</strong> Changes made by you, pending our review.</li>
                        </ul>
                        To approve all changes marked in blue, click the <strong>Accept Changes</strong> button at the
                        top right corner of the screen. If you don’t agree with any of our changes, simply edit the
                        blue lines before clicking the button.
                        <br/><br/>
                        <strong>Additional Comments:</strong> If you have any additional comments, please fill out
                        the <strong>Additional Comments</strong> section.
                        <br/><br/>
                        <strong>Autosave Feature:</strong> The system automatically saves your changes when you move to
                        another cell or row. For example, if you type something in a cell and then click anywhere else
                        on the screen, it will be saved automatically.
                    </div>
                </div>
                <br/>
                <br/>
                <div className="row">
                    <div className="col-sm-12 center">
                        <Button variant="primary" className="btn btn-blue" style={styles.btnOk}
                                onClick={() => props.setModalHintActive(false)}>
                            Ok
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}