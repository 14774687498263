import "../css/modal.css"
import {Col, Form, FormCheck, FormControl, FormLabel, ProgressBar, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";

const styles = {
    modalWH: {
        width: '60%',
        height: '70%',
        backgroundColor: '#222' /* dark-theme */
    }
}

export default function StyleGuideModal(props) {
    let languages = props.styleGuideLanguages
    let logOfNewBatches = false
    let characterCommunication = false

    function setLanguage(title, e) {
        languages.forEach(function(language) {
            if (language.title === title) {
                language.value = !language.value
            }
        })

        console.log("setLanguage", languages)
    }

    let getLanguages = languages => {
        let content = []

        languages.map(value => {
            content.push(
                <div className="row" key={value.title}>
                    <div className="col=sm-12">
                        <FormCheck defaultChecked={value.value} label={value.title} onChange={(e) =>
                            setLanguage(value.title, e)} />
                    </div>
                </div>
            )

            return value
        })

        return content
    };

    return(
        <div className={props.createStyleGuideModalActive ? "modal active" : "modal"} onClick={() => props.setCreateStyleGuideModalActive(false)} >
            <div className={props.createStyleGuideModalActive ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}
                 style={styles.modalWH} >
                <div className="row">
                    <div className="col-sm-12">
                        <h4>Поставьте необходимые галочки</h4>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-6">
                        <div className="row">
                            <h5>Вкладки языков</h5>
                        </div>
                        { getLanguages(props.styleGuideLanguages) }
                    </div>
                    <div className="col-sm-6">
                        <div className="row">
                            <h5>Остальное</h5>
                        </div>
                        <FormCheck defaultChecked={logOfNewBatches} label="Log Of New Batches"
                                   onChange={(e) => {logOfNewBatches = !logOfNewBatches}} />
                        <FormCheck defaultChecked={characterCommunication} label="Communication between characters"
                                   onChange={(e) => {characterCommunication = !characterCommunication}} />
                    </div>
                </div>
                <br />
                <br />
                <div className="row">
                    <div className="col-sm-12 center">
                        <button className="btn btn-blue"
                                onClick={(e) => props.createStyleGuide(languages, logOfNewBatches, characterCommunication)}>Create a styleguide</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
