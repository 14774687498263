import {Button, Table} from "react-bootstrap";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faMagic, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";

export default function CommunicationBetweenCharacters(props) {
    return(
        <Table>
            <tbody>

            </tbody>
        </Table>
    )
}