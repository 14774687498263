import React, {useState} from "react";
import TableCell from "../client_view/TableCell";
import {Form} from "react-bootstrap";
import queryString from "query-string";

export default function AdditionalComment(props) {
    const queryStringParams = queryString.parse(window.location.search)
    const code = queryStringParams.code

    async function oninputAdditionalComment(type, e, oldValue) {
        let queryLinkExist = '/proxy/project_bible_template/'
        let queryUpdateCell = '/proxy/project_bible_template/'
        let queryInsertCell = '/proxy/project_bible_template/'
        let value

        if (type === "input") {
            queryLinkExist += 'clientViewGetAdditionalComment'
            queryUpdateCell += 'clientViewAdditionalCommentOninputUpdate'
            queryInsertCell += 'clientViewAdditionalCommentOninputInsert'
            value = e.target.value
        }

        // if (value !== oldValue) {
            await fetch(queryLinkExist, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "code": code
                })
            })
                .then(res => res.json())
                .then(
                    async (resultEditable) => {
                        if (resultEditable.length) {
                            await fetch(queryUpdateCell, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "code": code,
                                    "value": value
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (resultUpdate) => {
                                        if (props.submit) {
                                            props.setFormUpdateValue()
                                        }
                                    },
                                    (error) => {
                                        alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                            "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                            "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                            "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                            "верное значение")
                                    }
                                )
                        } else {
                            await fetch(queryInsertCell, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "code": code,
                                    "value": value
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (resultInsert) => {
                                        if (props.submit) {
                                            props.setFormUpdateValue()
                                        }
                                    },
                                    (error) => {
                                        alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                            "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                            "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                            "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                            "верное значение")
                                    }
                                )
                        }
                    },
                    (error) => {
                        alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                            "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                            "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                            "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                            "верное значение")
                    }
                )
        // }
    }

    /*if (props.submit) {
        return(
            <div className="row">
                <div className="col-sm-12">
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicAdditionalComment">
                            <Form.Label>Additional comment</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder={props.additionalComment} disabled/>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        )
    } else {*/
        return(
            <div className="row">
                <div className="col-sm-12">
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicAdditionalComment">
                            <Form.Label>Additional comment</Form.Label>
                            <Form.Control as="textarea" rows={3} defaultValue={props.additionalComment}
                                          onBlur={(e) =>
                                              oninputAdditionalComment("input", e, props.additionalComment)}/>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        )
    /*}*/
}