import React from "react";
import LanguageTabPreferences from "./ClientViewLanguageTabPreferences";

export default function ClientViewLanguageTab(props) {
    return (
        <div className=" table-blue-thead-not-fixed">
            <LanguageTabPreferences
                language={props.language}
                setAcceptedSGLanguageRows={props.setAcceptedSGLanguageRows}
            />
        </div>
    )
}