import "../css/modal.css"
import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";

const styles = {
    modalWH: {
        width: '50%',
        height: '80%',
        color: '#000'
    },

    imgDivStyle: {
        width: '100%',
        height: '100%',
        alignItems: 'center'
    },

    imgStyle: {
        maxWidth: '35%',
        maxHeight: '35%',
        objectFit: 'contain'
    },

    justify: {
        textAlign: 'justify'
    }
}

export default function AlertModal(props) {
    let alertCases = [
        {
            title: "No languages available",
            description: "It appears that we can’t detect language pairs in your account. Please try logging out and back in to your A-platform portal account. If that does not resolve the issue, please get in touch with support for assistance.",
            actions: [
                "Log out of your portal account.",
                "Log back in to your portal account.",
                "If the issue persists, contact support for assistance."
            ],
            isRedirect: true
        },
        {
            title: "Languages do not match style guide",
            description: "It appears that the languages in your account do not match the languages that are specified in the style guide. This means that you cannot view any content. Please get in touch with support for assistance.",
            actions: [
                "Contact support for assistance."
            ],
            isRedirect: true
        }
    ]

    console.log("Alert Modal", props)

    return(
        <div className={props.modalAlertActive ? "modal active" : "modal"} onClick={() => props.setModalAlertActive(false)} >
            <div className={props.modalAlertActive ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}
                 style={styles.modalWH} >
                <div className="row">
                    <div className="col-sm-12 center" style={styles.imgDivStyle}>
                        <img src={require(`../img/Boo.png`).default} style={styles.imgStyle} />
                    </div>
                </div>
                <br />
                <br />
                <div className="row">
                    <div className="col-sm-12 center">
                        <strong>{alertCases[props.alertCase].title}</strong>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-12">
                        <span style={styles.justify}>
                            <strong>Description: </strong>{alertCases[props.alertCase].description}
                        </span>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-12">
                        <strong>Action: </strong>
                        <br/>
                        {
                            alertCases[props.alertCase].actions.map((action, index) => {
                                return (
                                    <span key={index}>
                                        <strong>&nbsp;&nbsp;&nbsp;&nbsp;{index + 1}. </strong>{action}
                                        <br />
                                    </span>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    alertCases[props.alertCase].isRedirect &&
                    <>
                        <br />
                        <br />
                        <div className="row">
                            <div className="col-sm-12 center">
                                <Button variant="primary" className="btn btn-blue" onClick={(e) => props.setRedirect(false)}>
                                    Redirect to A-platform portal
                                </Button>
                            </div>
                        </div>
                    </>
                }
                <br />
                <br />
            </div>
        </div>
    )
}