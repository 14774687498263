const styles = {
    blockCenter: {
        textAlign: 'center'
    },

    blockView: {
        border: '1px solid #ccc',
        padding: '3rem 2rem',
        borderRadius: '5px',
        marginTop: '5rem',
        /*marginLeft: '15rem',
        marginRight: '15rem',
        marginBottom: '5rem',*/
    },

    disabledBlockCenter: {
        textAlign: 'center',
        color: 'red'
    },
}

export default function Login(props) {
    return(
        <div className="row">
            <div className="col-sm-4">

            </div>
            <div className="col-sm-4">
                <div style={styles.blockView}>
                    <h2 style={styles.blockCenter}>Please Log In</h2>
                    <h6 style={styles.disabledBlockCenter}>*this form is disabled</h6>
                    <br />
                    <form onSubmit={props.authLogIn}>
                        <div className="row">
                            <div className="col-sm-12">
                                <label htmlFor="inputAuthLogin" className="form-label"><strong>Enter Login:</strong></label>
                                <input className="form-control" id="inputAuthLogin" name="inputAuthLogin" disabled={true} />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12">
                                <label htmlFor="inputAuthPassword" className="form-label"><strong>Enter Password:</strong></label>
                                <input className="form-control" id="inputAuthPassword" type="password" name="inputAuthPassword" disabled={true} />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12" style={styles.blockCenter}>
                                <button className="btn btn-primary" disabled={true}>Log In</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="col-sm-4">

            </div>
        </div>
    )
}