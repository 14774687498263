import "../css/modal.css"
import {Col, FormCheck, FormLabel, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";

const styles = {
    modalWH: {
        backgroundColor: "#222",
        width: '75%',
        height: '70%'
    }
}

export default function TagModal(props) {
    let selectedTags = props.tagModalData[0].selectedTags

    function setRowTags(group, tag, e) {
        if (e.target.checked) {
            if (!selectedTags.includes(tag)) {
                selectedTags.push(tag)
            }

            /*if (group in selectedTags) {
                let oldValues = selectedTags[group]

                if (!oldValues.includes(tag)) {
                    oldValues.push(tag)
                }

                selectedTags[group] = oldValues
            } else {
                selectedTags[group] = [tag]
            }*/
        } else {
            if (selectedTags.includes(tag)) {
                let index = selectedTags.indexOf(tag)

                selectedTags.splice(index, 1)
            }

            /*if (group in selectedTags) {
                let oldValues = selectedTags[group]

                if (oldValues.includes(tag)) {
                    let newValues = []

                    for (let i = 0; i < oldValues.length; i++) {
                        if (oldValues[i] !== tag) {
                            newValues.push(oldValues[i])
                        }
                    }

                    if (newValues.length > 0) {
                        selectedTags[group] = newValues
                    } else {
                        removeGroup(group)
                    }
                }
            }*/
        }

        console.log("selectedTags", selectedTags)
    }

    /*function removeGroup(groupToRemove) {
        let newValues = {}

        for (let group in selectedTags) {
            if (group !== groupToRemove) {
                newValues[group] = selectedTags[group]
            }
        }

        selectedTags = newValues
    }*/

    let getAllTags = groups => {
        let content = []

        groups.forEach((tags, group) => {
            let contentTags = []

            for (let i = 0; i < tags.length; i++) {
                let tagTitle = tags[i].tag
                let tagId = tags[i].id
                let tagService = tags[i].service

                if (tagService.includes(props.techProcess[props.tagModalData[0].techProcess].techProcessName1C)) {
                    // let isCheck = props.tagModalData[0].selectedTags.includes(tagTitle)
                    let isCheck = props.tagModalData[0].selectedTags.includes(tagId)

                    contentTags.push(
                        <FormCheck defaultChecked={isCheck} label={tagTitle}
                                   onChange={(e) =>
                                       setRowTags(group, tagId, /*tagTitle,*/ e)} key={/*tagTitle*/tagId} />
                    )
                }
            }

            if (contentTags.length) {
                content.push(
                    <div className="col-sm-4" key={group}>
                        <FormLabel><h5>{group}</h5></FormLabel>
                        { contentTags }
                        <br />
                    </div>
                )
            }
        });

        return content
    };

    let getTagsByGroup = (group, tags) => {
        let content = []

        for (let i = 0; i < tags.length; i++) {
            let tagTitle = tags[i].tag
            let tagService = tags[i].service

            console.log("tagService", tagTitle, tagService, props.tagModalData[0].techProcess, props.techProcess, props.techProcessData)

            if (tagService.includes(props.techProcess[props.tagModalData[0].techProcess].techProcessName1C)) {
                let isCheck = props.tagModalData[0].selectedTags.includes(tagTitle)

                content.push(
                    <FormCheck defaultChecked={isCheck} label={tagTitle}
                               onChange={(e) =>
                                   setRowTags(group, tagTitle, e)} key={tagTitle} />
                )
            }
        }

        return content
    };

    function setTags(techProcess, block, row, data) {
        selectedTags = {}

        props.saveTagCell(techProcess, block, row, data)
    }

    if (props.tagModalActive) {
        return(
            <div className={props.tagModalActive ? "modal active" : "modal"} onClick={() => props.setTagModalActive(false)} >
                <div className={props.tagModalActive ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}
                     style={styles.modalWH} >
                    <div className="row">
                        <div className="col-sm-8">
                            <h4>Here you can choose tags</h4>
                        </div>
                        <div className="col-sm-4 center">
                            <button className="btn btn-blue"
                                    onClick={(e) =>
                                        setTags(props.tagModalData[0].techProcess, props.tagModalData[0].block, props.tagModalData[0].row, selectedTags)}>
                                Save
                            </button>
                        </div>
                    </div>
                    <hr />
                    <br />
                    <div className="row">
                        { getAllTags(props.tagModalData[0].allTags) }
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <div className={props.tagModalActive ? "modal active" : "modal"} onClick={() => props.setTagModalActive(false)} >
                <div className={props.tagModalActive ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}
                     style={styles.modalWH} >
                </div>
            </div>
        )
    }
}