import React, {useEffect, useState} from "react";
import {Button, Form, ProgressBar, Tabs} from "react-bootstrap";
import queryString from 'query-string';
import "../css/tableFixHeader.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faLink, faMagic} from "@fortawesome/free-solid-svg-icons";
import StyleGuideSettingsModal from "./StyleGuideSettingsModal";
import StyleGuideTabs from "./StyleGuideTabs";
import AddLinksAndImagesModal from "./AddLinksAndImagesModal";
import OpenInModalAddedImage from "./OpenInModalAddedImage";
import { Tooltip } from 'primereact/tooltip';
import OpenInTooltipAddedImage from "./OpenInTooltipAddedImage";
import TemplateTextSelectionModal from "./TemplateTextSelectionModal";

const styles = {
    blockView: {
        border: '1px solid #ccc',
        padding: '1rem',
        borderRadius: '5px',
        margin: '0px'
    },

    progressBar: {
        margin: '10rem 10rem'
    },
}

export default function StyleGuide(props) {
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isLanguageLoaded, setIsLanguageLoaded] = useState(false)
    const [isLogOfNewBatches, setIsLogOfNewBatches] = useState(false)
    const [isCharacterCommunicationLoaded, setIsCharacterCommunicationLoaded] = useState(false)
    const [styleGuideSettingsModalActive, setStyleGuideSettingsModalActive] = useState(false)
    const [progressPercent, setProgressPercent] = useState(0)
    const [logOfNewBatches, setLogOfNewBatches] = useState(false)
    const [imgFolderSrc, setImgFolderSrc] = useState('')
    const [communicationBetweenCharacters, setCommunicationBetweenCharacters] = useState(false)
    const [projectManagementInfo, setProjectManagementInfo] = useState(false)
    const [languages, setLanguages] = useState([{
        data: []
    }])
    const [columns, setColumns] = useState([{
        data: []
    }])
    const [rows, setRows] = useState([{
        data: []
    }])
    const [characterCommunications, setCharacterCommunications] = useState([{
        characters: [],
        templateData: [],
        languagesData: [],

    }])
    const [logOfNewBatchesData, setLogOfNewBatchesData] = useState([{
        headers: [],
        rowsCount: 0,
        rowsData: []
    }])
    const [linguistLinks, setLinguistLinks] = useState([{
        data: []
    }])
    const [isLinguistDataLoaded, setIsLinguistDataLoaded] = useState(false)
    const [modalAddLinksAndImagesActive, setModalAddLinksAndImagesActive] = useState(false)
    const [modalAddLinksAndImagesData, setModalAddLinksAndImagesData] = useState([{
        links: [],
        images: [],
        bufferedImages: [],
        value: '',
        saveMethod: '',
        identificationData: []
    }])
    const [modalOpenAddedImageActive, setModalOpenAddedImageActive] = useState(false)
    const [modalOpenAddedImageSrc, setModalOpenAddedImageSrc] = useState('')
    // const [tooltipImage, setTooltipImage] = useState('')
    const [tooltipImageData, setTooltipImageData] = useState([{
        src: '',
        title: '',
        element: null,
        justUploaded: false
    }])
    const [modalTemplateTextSelectionActive, setModalTemplateTextSelectionActive] = useState(false)
    const [modalTemplateTextSelectionData, setModalTemplateTextSelectionData] = useState([{
        data: [],
        selectedValue: 0
    }])
    const [modalOpenCellDataHistoryActive, setModalOpenCellDataHistoryActive] = useState(false);
    const [modalOpenCellDataHistoryData, setModalOpenCellDataHistoryData] = useState([{
        modalTitle: "",
        tableName: "",
        saveMethod: "",
        identityData: [],
        data: []
    }]);
    const queryStringParams = queryString.parse(window.location.search)
    const clientName = queryStringParams.client_name
    const projectName = queryStringParams.project_name
    const projectCode = queryStringParams.project_code

    let cellAllCount = 0
    let cellOnCount = 0
    let languageCount = 0
    let logOfNewBatchesCount = 0
    let charactersDataCount = 0
    let linguistDataCount = 0
    let linguistLanguagesDataCount = []

    const authorizationCookieName = "b24_authorization_log"
    const authorizationCookieMatches = document.cookie.match(new RegExp(
        "(?:^|; )" + authorizationCookieName.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    const employeeId = authorizationCookieMatches ?
        +JSON.parse(window.atob(decodeURIComponent(authorizationCookieMatches[1]))).userId :
        window.location.href.split("/")[2] === "localhost:3000" ? 191 : 0
    const employeeName = authorizationCookieMatches ?
        JSON.parse(window.atob(decodeURIComponent(authorizationCookieMatches[1]))).name :
        window.location.href.split("/")[2] === "localhost:3000" ? "Daria Arisova" : ""

    useEffect(async () => {
        document.title = projectName + " (SG)";

        if (/*await lookIp()*/checkCookie() && checkQueryStringParams()) {
            deleteRedirectCookie()
            await getImgFolderSrc()

            await fetch("/proxy/project_bible_template/projectBibleStyleGuide", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "clientName": clientName,
                    "projectName": projectName,
                    "projectCode": projectCode
                }),
            })
                .then(res => res.json())
                .then(
                    async (styleGuideresult) => {
                        await fetch("/proxy/project_bible_template/projectBibleInfo", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode
                            }),
                        })
                            .then(res => res.json())
                            .then(
                                async (result) => {
                                    await fetch("/proxy/project_bible_template/projectBibleTemplateRowsColumns", {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        }
                                    })
                                        .then(res => res.json())
                                        .then(
                                            async (resultTemplate) => {
                                                await fetch("/proxy/project_bible_template/styleGuideGetCharacters", {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({
                                                        "clientName": clientName,
                                                        "projectName": projectName,
                                                        "projectCode": projectCode
                                                    }),
                                                })
                                                    .then(res => res.json())
                                                    .then(
                                                        async (resultCharacters) => {
                                                            await fetch("/proxy/project_bible_template/styleGuideGetLanguagesList", {
                                                                method: 'GET',
                                                                headers: {
                                                                    'Content-Type': 'application/json'
                                                                },
                                                            })
                                                                .then(res => res.json())
                                                                .then(
                                                                    async (resultStyleGuideLanguages) => {
                                                                        await fetch("/proxy/project_bible_template/styleGuideLinguistAll", {
                                                                            method: 'POST',
                                                                            headers: {
                                                                                'Content-Type': 'application/json'
                                                                            },
                                                                            body: JSON.stringify({
                                                                                "clientName": clientName,
                                                                                "projectName": projectName,
                                                                                "projectCode": projectCode
                                                                            }),
                                                                        })
                                                                            .then(res => res.json())
                                                                            .then(
                                                                                async (resultStyleGuideForLinguists) => {
                                                                                    await fetch("/proxy/project_bible_template/getStyleGuideLinguistAcceptChanges", {
                                                                                        method: 'POST',
                                                                                        headers: {
                                                                                            'Content-Type': 'application/json'
                                                                                        },
                                                                                        body: JSON.stringify({
                                                                                            "clientName": clientName,
                                                                                            "projectName": projectName,
                                                                                            "projectCode": projectCode
                                                                                        }),
                                                                                    })
                                                                                        .then(res => res.json())
                                                                                        .then(
                                                                                            async (resultStyleGuideForLinguistsAcceptedChangesData) => {
                                                                                                let rowsMap = new Map()
                                                                                                let templateRowsMap = new Map()

                                                                                                setLogOfNewBatches(styleGuideresult[0]["log_of_new_batches"])
                                                                                                setCommunicationBetweenCharacters(styleGuideresult[0]["communication_between_characters"])
                                                                                                setProjectManagementInfo(styleGuideresult[0]["project_management_info"])

                                                                                                setLanguages(
                                                                                                    languages.map(value => {
                                                                                                        for (let i = 0; i < resultStyleGuideLanguages.languages.length; i++) {
                                                                                                            value.data.push({
                                                                                                                title: resultStyleGuideLanguages.languages[i].language,
                                                                                                                code: resultStyleGuideLanguages.languages[i].language_code,
                                                                                                                value: styleGuideresult[0].languages.includes(resultStyleGuideLanguages.languages[i].language_code),
                                                                                                                data: [],
                                                                                                                accept: []
                                                                                                                /*accept: {
                                                                                                                    linguistId: "",
                                                                                                                    value: []/!*false,
                                                                                                                    data: []*!/
                                                                                                                }*/
                                                                                                            })

                                                                                                            if (styleGuideresult[0].languages.includes(resultStyleGuideLanguages.languages[i].language_code)) {
                                                                                                                setCharacterCommunications(
                                                                                                                    characterCommunications.map(info => {
                                                                                                                        info.languagesData[resultStyleGuideLanguages.languages[i].language_code] = {
                                                                                                                            // title: value.title,
                                                                                                                            data: [],
                                                                                                                            linguistAcceptedData: []
                                                                                                                            /*,
                                                                                                                            accept: {
                                                                                                                                linguistId: "",
                                                                                                                                value: false/!*,
                                                                                                                    data: []*!/
                                                                                                                            }*/
                                                                                                                        }

                                                                                                                        return info
                                                                                                                    })
                                                                                                                )
                                                                                                            }
                                                                                                        }

                                                                                                        return value
                                                                                                    })
                                                                                                )

                                                                                                for (let i = 0; i < resultCharacters.length; i++) {
                                                                                                    let characterId = +resultCharacters[i].id
                                                                                                    let characterNum = resultCharacters[i].num
                                                                                                    let characterName = resultCharacters[i].name

                                                                                                    setCharacterCommunications(
                                                                                                        characterCommunications.map(info => {
                                                                                                            info.characters[characterId] = {
                                                                                                                "id": characterId,
                                                                                                                "num": characterNum,
                                                                                                                "name": characterName
                                                                                                            }

                                                                                                            info.templateData[characterNum - 1] = {
                                                                                                                "id": characterId,
                                                                                                                "name": characterName,
                                                                                                                "data": []
                                                                                                            }

                                                                                                            return info
                                                                                                        })
                                                                                                    )
                                                                                                }

                                                                                                resultStyleGuideForLinguists.map(value => {
                                                                                                    setLinguistLinks(
                                                                                                        linguistLinks.map(info => {
                                                                                                            info.data[value.linguist_id] = {
                                                                                                                code: value["linguist_id"],
                                                                                                                linguistName: value.linguist,
                                                                                                                languages: value.languages,
                                                                                                                languagesNames: [],
                                                                                                                // submit: value.submit,
                                                                                                                data: {
                                                                                                                    languagePreferences: [],
                                                                                                                    characterCommunications: {
                                                                                                                        value: styleGuideresult[0]["communication_between_characters"],
                                                                                                                        characters: [],
                                                                                                                        baseStyleGuideData: [],
                                                                                                                        templateData: [],
                                                                                                                        languagesData: []
                                                                                                                    }
                                                                                                                },
                                                                                                                acceptData: [],
                                                                                                                bufferedData: []
                                                                                                            }

                                                                                                            return info
                                                                                                        })
                                                                                                    )

                                                                                                    return value
                                                                                                })

                                                                                                console.log("resultStyleGuideForLinguistsAcceptedChangesData", resultStyleGuideForLinguistsAcceptedChangesData)

                                                                                                resultStyleGuideForLinguistsAcceptedChangesData.map(value => {
                                                                                                    setLinguistLinks(
                                                                                                        linguistLinks.map(info => {
                                                                                                            let linguistId = value.linguist_id
                                                                                                            let languageCode = value.language_code
                                                                                                            let acceptLanguagePreferences = value.accept_language_preferences
                                                                                                            // let acceptCommunicationBetweenCharacters = value.accept_communication_between_characters
                                                                                                            let bufferedChangedLanguagePreferences = []
                                                                                                            // let bufferedChangedCommunicationBetweenCharacters = value.buffered_changed_communication_between_characters

                                                                                                            for (let block in acceptLanguagePreferences) {
                                                                                                                if ("otherLinguistExtra" in acceptLanguagePreferences[block]) {
                                                                                                                    let acceptOtherLinguistLanguagePreferences = []

                                                                                                                    for (let i = 0; i < acceptLanguagePreferences[block].otherLinguistExtra.length; i++) {
                                                                                                                        let otherLinguistData = acceptLanguagePreferences[block].otherLinguistExtra[i]

                                                                                                                        acceptOtherLinguistLanguagePreferences[otherLinguistData.linguist] = []

                                                                                                                        for (let j = 0; j < otherLinguistData.rows.length; j++) {
                                                                                                                            acceptOtherLinguistLanguagePreferences[otherLinguistData.linguist][otherLinguistData.rows[j]] = []
                                                                                                                        }
                                                                                                                    }

                                                                                                                    acceptLanguagePreferences[block].otherLinguistExtra = acceptOtherLinguistLanguagePreferences
                                                                                                                }
                                                                                                            }

                                                                                                            for (let block in value.buffered_changed_language_preferences) {
                                                                                                                let blockValueBufferedLanguagePreferences = value.buffered_changed_language_preferences[block]

                                                                                                                bufferedChangedLanguagePreferences[block] = {
                                                                                                                    "default": [],
                                                                                                                    "extra": []
                                                                                                                }

                                                                                                                for (let i = 0; i < blockValueBufferedLanguagePreferences.default.length; i++) {
                                                                                                                    bufferedChangedLanguagePreferences[block].default[blockValueBufferedLanguagePreferences.default[i]] = []
                                                                                                                }

                                                                                                                for (let i = 0; i < blockValueBufferedLanguagePreferences.extra.length; i++) {
                                                                                                                    bufferedChangedLanguagePreferences[block].extra[blockValueBufferedLanguagePreferences.extra[i]] = []
                                                                                                                }

                                                                                                                if ("otherLinguistExtra" in blockValueBufferedLanguagePreferences) {
                                                                                                                    let bufferedOtherLinguistLanguagePreferences = []

                                                                                                                    for (let i = 0; i < blockValueBufferedLanguagePreferences.otherLinguistExtra.length; i++) {
                                                                                                                        let otherLinguistData = blockValueBufferedLanguagePreferences.otherLinguistExtra[i]

                                                                                                                        bufferedOtherLinguistLanguagePreferences[otherLinguistData.linguist] = []

                                                                                                                        for (let j = 0; j < otherLinguistData.rows.length; j++) {
                                                                                                                            bufferedOtherLinguistLanguagePreferences[otherLinguistData.linguist][otherLinguistData.rows[j]] = []
                                                                                                                        }
                                                                                                                    }

                                                                                                                    bufferedChangedLanguagePreferences[block]["otherLinguistExtra"] = []
                                                                                                                    bufferedChangedLanguagePreferences[block]["otherLinguistExtra"] = bufferedOtherLinguistLanguagePreferences
                                                                                                                }
                                                                                                            }

                                                                                                            info.data[linguistId].acceptData[languageCode] = {
                                                                                                                languagePreferences: {
                                                                                                                    value: acceptLanguagePreferences
                                                                                                                },
                                                                                                                communicationBetweenCharacters: {
                                                                                                                    value: /*acceptCommunicationBetweenCharacters*/[]
                                                                                                                }
                                                                                                            }

                                                                                                            info.data[linguistId].bufferedData[languageCode] = {
                                                                                                                languagePreferences: {
                                                                                                                    value: bufferedChangedLanguagePreferences
                                                                                                                },
                                                                                                                communicationBetweenCharacters: {
                                                                                                                    value: /*bufferedChangedCommunicationBetweenCharacters*/[]
                                                                                                                }
                                                                                                            }

                                                                                                            /*if (info.data[linguistId].submit) {*/
                                                                                                                if (acceptLanguagePreferences) {
                                                                                                                    setLanguagePreferencesAcceptData(linguistId, languageCode, acceptLanguagePreferences)
                                                                                                                }

                                                                                                                /*if (acceptCommunicationBetweenCharacters) {
                                                                                                                    setCharacterCommunicationsAcceptData(linguistId, languageCode, acceptCommunicationBetweenCharacters)
                                                                                                                }*/
                                                                                                            /*}*/

                                                                                                            return info
                                                                                                        })
                                                                                                    )

                                                                                                    return value
                                                                                                })

                                                                                                result[0].columns.map(value => {
                                                                                                    if (value["col_styleguide"] || value["col_styleguide_filter"]) {
                                                                                                        setColumns(
                                                                                                            columns.map(info => {
                                                                                                                info.data.push({
                                                                                                                    "code": value.code,
                                                                                                                    "name": value.name,
                                                                                                                    "type": value.type,
                                                                                                                    "editable": value.editable,
                                                                                                                    "template": value.template,
                                                                                                                    "col_styleguide": value["col_styleguide"],
                                                                                                                    "col_styleguide_filter": value["col_styleguide_filter"],
                                                                                                                    "filter": {
                                                                                                                        "show": true,
                                                                                                                        "showAll": {
                                                                                                                            "value": true,
                                                                                                                            "showFilledCells": true,
                                                                                                                            "showEmptyCells": true,
                                                                                                                            "data": new Map(),
                                                                                                                            "selectedData": []
                                                                                                                        }
                                                                                                                    }
                                                                                                                });

                                                                                                                return info
                                                                                                            })
                                                                                                        )
                                                                                                    }

                                                                                                    return value
                                                                                                })

                                                                                                resultTemplate.rows.map(value => {
                                                                                                    templateRowsMap.set(value.code, value.code)
                                                                                                })

                                                                                                result[0].rows.map(value => {
                                                                                                    rowsMap.set(value.code, value.code)
                                                                                                })

                                                                                                templateRowsMap.forEach((value, key) => {
                                                                                                    if (!rowsMap.has(key)) {
                                                                                                        result[0].rows.push({
                                                                                                            code: key
                                                                                                        })
                                                                                                    }
                                                                                                });

                                                                                                result[0].rows.map(value => {
                                                                                                    setRows(
                                                                                                        rows.map(info => {
                                                                                                            info.data.push({
                                                                                                                "code": value.code,
                                                                                                                "show": true,
                                                                                                                "data": [],
                                                                                                                "template": []
                                                                                                            });

                                                                                                            return info
                                                                                                        })
                                                                                                    )

                                                                                                    return value
                                                                                                })

                                                                                                cellAllCount = columns[0].data.length * rows[0].data.length

                                                                                                fillDataForLinguistStyleGuideAcceptModal(styleGuideresult, resultCharacters, resultStyleGuideLanguages)
                                                                                                fillDataForHeaders()

                                                                                                if (styleGuideresult[0]["log_of_new_batches"]) {
                                                                                                    fillLogOfNewBatches()
                                                                                                }

                                                                                                if (styleGuideresult[0]["communication_between_characters"]) {
                                                                                                    fillCharacterCommunication()
                                                                                                }
                                                                                            },
                                                                                            (error) => {
                                                                                                setIsLoaded(true);
                                                                                                setError({
                                                                                                    message: "Не удалось получить данные о языках стайлгайда. Пожалуйста перезагрузите страницу, чтобы запустить процесс снова"
                                                                                                });
                                                                                            }
                                                                                        )
                                                                                },
                                                                                (error) => {
                                                                                    setIsLoaded(true);
                                                                                    setError({
                                                                                        message: "Не удалось получить данные о языках стайлгайда. Пожалуйста перезагрузите страницу, чтобы запустить процесс снова"
                                                                                    });
                                                                                }
                                                                            )
                                                                    },
                                                                    (error) => {
                                                                        setIsLoaded(true);
                                                                        setError({
                                                                            message: "Не удалось получить данные о языках стайлгайда. Пожалуйста перезагрузите страницу, чтобы запустить процесс снова"
                                                                        });
                                                                    }
                                                                )
                                                        },
                                                        (error) => {
                                                            setIsLoaded(true);
                                                            setError(error);
                                                        }
                                                    )
                                            },
                                            (error) => {
                                                setIsLoaded(true);
                                                setError(error);
                                            }
                                        )
                                },
                                (error) => {
                                    setIsLoaded(true);
                                    setError(error);
                                }
                            )
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                )
        } else {
            // let link = window.location.href.split("/")
            // window.location.replace('http://' + link[2] + '/autorization')

            setRedirectCookie()
            window.location.replace('https://b24.allcorrectgames.com');
        }
    }, [])

    async function getImgFolderSrc() {
        const response = await fetch('/proxy/get_img_folder_src', {
            method: 'GET'
        });
        let result = await response.json();

        setImgFolderSrc(result.src)
    }

    function checkCookie() {
        let name = "testCookie"

        document.cookie = "testCookie=test; max-age=3600";

        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));

        if (!matches) {
            alert("Allow cookies in your browser settings for correct work.")

            return false
        } else {
            return employeeId > 0
        }
    }

    function setRedirectCookie() {
        let cookieName =  'apps_allcorrect_redirect'
        let cookieValue = window.btoa(window.location.href)
        let date = new Date(Date.now() + 1800e3);
        let options = {
            path: '/',
            domain: 'allcorrectgames.com',
            expires: date.toUTCString(),
            samesite: 'lax'
        };
        let updatedCookie = cookieName + "=" + cookieValue;

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;

            let optionValue = options[optionKey];

            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    function deleteRedirectCookie() {
        let cookieName =  'apps_allcorrect_redirect'
        let cookieValue = 'val'
        let date = new Date();

        date.setMonth(date.getMonth() - 1);

        let options = {
            path: '/',
            domain: 'allcorrectgames.com',
            expires: date.toUTCString(),
            samesite: 'lax'
        };
        let updatedCookie = cookieName + "=" + cookieValue;

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;

            let optionValue = options[optionKey];

            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    function checkQueryStringParams() {
        if (!clientName || !projectName || !projectCode) {
            alert("Missed URL parameters")

            return false
        }

        return true
    }

    function setLanguagePreferencesAcceptData(linguistId, languageCode, value) {
        setLanguages(
            languages.map(info => {
                info.data.map(language => {
                    if (language.code === languageCode) {
                        // language.accept.linguistId = linguistId
                        // language.accept.value = value

                        language.accept[linguistId] = {
                            linguistId: linguistId,
                            value: value
                        }
                    }

                    return language
                })

                return info
            })
        )
    }

    /*function setCharacterCommunicationsAcceptData(linguistId, languageCode, value) {
        setCharacterCommunications(
            characterCommunications.map(info => {
                if (languageCode in info.languagesData) {
                    info.languagesData[languageCode].accept.linguistId = linguistId
                    info.languagesData[languageCode].accept.value = value
                }

                return info
            })
        )
    }*/

    function fillDataForHeaders() {
        setRows(rows.map(rowsData => {
            rowsData.data.map(async row => {
                columns.map(columnsData => {
                    columnsData.data.map(async column => {
                        let queryLinkTemplate = '/proxy/project_bible_template/'
                        let queryLinkEditable = '/proxy/project_bible_template/'

                        if (column.type === "input") {
                            // queryLinkTemplate += 'projectBibleTemplateTextByName'
                            queryLinkTemplate += 'projectBibleTemplateTextByNameIfExist'
                            queryLinkEditable += 'projectBibleFilledCellTextByName'
                        } else if (column.type === "checkbox") {
                            // queryLinkTemplate += 'projectBibleTemplateBoolByName'
                            queryLinkTemplate += 'projectBibleTemplateBoolByNameIfExist'
                            queryLinkEditable += 'projectBibleFilledCellBoolByName'
                        } else if (column.type === "tags_list") {
                            queryLinkTemplate += 'projectBibleTemplateTagJsonByNameIfExist'
                            queryLinkEditable += 'projectBibleFilledCellBoolByName'
                        }

                        if (column.template && column.editable) {
                            await fetch(queryLinkTemplate, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "rowCode": row.code,
                                    "colCode": column.code,
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (resultTemplate) => {
                                        await fetch(queryLinkEditable, {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                                "clientName": clientName,
                                                "projectName": projectName,
                                                "projectCode": projectCode,
                                                "rowCode": row.code,
                                                "colCode": column.code,
                                            })
                                        })
                                            .then(res => res.json())
                                            .then(
                                                async (resultEditable) => {
                                                    let value

                                                    if (resultEditable.length) {
                                                        value = resultEditable[0].value
                                                    } else {
                                                        if (resultTemplate.length) {
                                                            value = resultTemplate[0].value
                                                        } else if (column.type === "input") {
                                                            value = ""
                                                        } else if (column.type === "checkbox") {
                                                            value = false
                                                        } else if (column.type === "tags_list") {
                                                            value = []
                                                        }
                                                    }

                                                    if (column["col_styleguide_filter"]) {
                                                        if (column.type === "input") {
                                                            if (value === "") {
                                                                row.show = false
                                                            }
                                                        } else if (column.type === "checkbox") {
                                                            if (value === false) {
                                                                row.show = false
                                                            }
                                                        }
                                                    }

                                                    if (column["col_styleguide"]) {
                                                        row.data[column.code] = value
                                                    }

                                                    cellOnCount++
                                                    console.log("cellOnCount, cellAllCount", cellOnCount, cellAllCount)
                                                    setProgressPercent(Math.round(cellOnCount / cellAllCount * 100))

                                                    if (cellOnCount === cellAllCount && !error) {
                                                        setIsLoaded(true);
                                                    }
                                                },
                                                (error) => {
                                                    setIsLoaded(true);
                                                    setError(error);
                                                }
                                            )
                                    },
                                    (error) => {
                                        setIsLoaded(true);
                                        setError(error);
                                    }
                                )
                        } else if (column.template) {
                            await fetch(queryLinkTemplate, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "rowCode": row.code,
                                    "colCode": column.code,
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (result) => {
                                        let value

                                        if (result.length) {
                                            value = result[0].value
                                        } else if (column.type === "input") {
                                            value = ""
                                        } else if (column.type === "checkbox") {
                                            value = false
                                        } else if (column.type === "tags_list") {
                                            value = []
                                        }

                                        if (column["col_styleguide_filter"]) {
                                            if (column.type === "input") {
                                                if (value === "") {
                                                    row.show = false
                                                }
                                            } else if (column.type === "checkbox") {
                                                if (value === false) {
                                                    row.show = false
                                                }
                                            }
                                        }

                                        if (column["col_styleguide"]) {
                                            row.data[column.code] = value
                                        }

                                        cellOnCount++
                                        console.log("cellOnCount, cellAllCount", cellOnCount, cellAllCount)
                                        setProgressPercent(Math.round(cellOnCount / cellAllCount * 100))

                                        if (cellOnCount === cellAllCount && !error) {
                                            setIsLoaded(true);
                                        }
                                    },
                                    (error) => {
                                        setIsLoaded(true);
                                        setError(error);
                                    }
                                )
                        } else if (column.editable) {
                            await fetch(queryLinkEditable, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "clientName": clientName,
                                    "projectName": projectName,
                                    "projectCode": projectCode,
                                    "rowCode": row.code,
                                    "colCode": column.code,
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (result) => {
                                        let value

                                        if (result.length) {
                                            value = result[0].value
                                        } else if (column.type === "input") {
                                            value = ""
                                        } else if (column.type === "checkbox") {
                                            value = false
                                        } else if (column.type === "tags_list") {
                                            value = []
                                        }

                                        if (column["col_styleguide_filter"]) {
                                            if (column.type === "input") {
                                                if (value === "") {
                                                    row.show = false
                                                }
                                            } else if (column.type === "checkbox") {
                                                if (value === false) {
                                                    row.show = false
                                                }
                                            }
                                        }

                                        if (column["col_styleguide"]) {
                                            row.data[column.code] = value
                                        }

                                        cellOnCount++
                                        console.log("cellOnCount, cellAllCount", cellOnCount, cellAllCount)
                                        setProgressPercent(Math.round(cellOnCount / cellAllCount * 100))

                                        if (cellOnCount === cellAllCount && !error) {
                                            setIsLoaded(true);
                                        }
                                    },
                                    (error) => {
                                        setIsLoaded(true);
                                        setError(error);
                                    }
                                )
                        }
                    })

                    return columnsData
                })
            })

            return rowsData
        }))
    }

    async function fillLogOfNewBatches() {
        await fetch("/proxy/project_bible_template/styleGuideLogOfNewBatchesHeaders", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(
                async (resultLogOfNewBatchesHeaders) => {
                    await fetch("/proxy/project_bible_template/styleGuideLogOfNewBatchesRowsText", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "clientName": clientName,
                            "projectName": projectName,
                            "projectCode": projectCode
                        })
                    })
                        .then(res => res.json())
                        .then(
                            async (resultLogOfNewBatchesRows) => {
                                resultLogOfNewBatchesHeaders.headers.map(value => {
                                    setLogOfNewBatchesData(
                                        logOfNewBatchesData.map(info => {
                                            info.headers.push(value)

                                            return info
                                        })
                                    )

                                    return value
                                })

                                setLogOfNewBatchesData(
                                    logOfNewBatchesData.map(info => {
                                        let rowsCount = resultLogOfNewBatchesRows.count.rowCount !== 0 ? resultLogOfNewBatchesRows.count.rows[0].num : 0

                                        info.rowsCount = rowsCount

                                        for (let i = 0; i < rowsCount; i++) {
                                            info.rowsData[i] = []

                                            resultLogOfNewBatchesHeaders.headers.map(value => {
                                                info.rowsData[i][value.code] = ""
                                            })
                                        }

                                        resultLogOfNewBatchesRows.rows.map(value => {
                                            info.rowsData[value.num - 1][value.col_code] = value.value

                                            return value
                                        })

                                        return info
                                    })
                                )

                                setIsLogOfNewBatches(true)
                            },
                            (error) => {
                                setIsLogOfNewBatches(true);
                                setError(error);
                            }
                        )
                },
                (error) => {
                    setIsLogOfNewBatches(true);
                    setError(error);
                }
            )
    }

    async function fillDataForLanguages() {
        console.log("fillDataForLanguages", languages[0].data.length)

        setLanguages(
            languages.map(info => {
                info.data.map(async language => {
                    if (language.value) {
                        await fetch("/proxy/project_bible_template/styleGuideGetLanguagesListByCode", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "code": language.code,
                                "projectManagementInfo": true
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultLanguageTemplate) => {
                                    await fetch("/proxy/project_bible_template/styleGuideGetLanguagesExtraRows", {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            "clientName": clientName,
                                            "projectName": projectName,
                                            "projectCode": projectCode,
                                            "languageCode": language.code
                                        })
                                    })
                                        .then(res => res.json())
                                        .then(
                                            async (resultLanguageExtraRows) => {
                                                await fetch("/proxy/project_bible_template/styleGuideGetLanguagesFilledData", {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({
                                                        "clientName": clientName,
                                                        "projectName": projectName,
                                                        "projectCode": projectCode,
                                                        "languageCode": language.code
                                                    })
                                                })
                                                    .then(res => res.json())
                                                    .then(
                                                        async (resultLanguageFilledData) => {
                                                            language.data = fillResultLanguageTemplateWithOtherData(language.code, resultLanguageTemplate, resultLanguageExtraRows, resultLanguageFilledData)

                                                            languageCount++

                                                            console.log("languages[0].data.length TRUE", languages[0].data.length, languageCount)

                                                            if (languageCount === languages[0].data.length) {
                                                                setIsLanguageLoaded(true);
                                                            }
                                                        },
                                                        (error) => {
                                                            setIsLanguageLoaded(true);
                                                            setError(error);
                                                        }
                                                    )
                                            },
                                            (error) => {
                                                setIsLanguageLoaded(true);
                                                setError(error);
                                            }
                                        )
                                },
                                (error) => {
                                    setIsLanguageLoaded(true);
                                    setError(error);
                                }
                            )
                    } else {
                        languageCount++

                        console.log("languages[0].data.length FALSE", languages[0].data.length, languageCount)

                        if (languageCount === languages[0].data.length) {
                            setIsLanguageLoaded(true);
                        }
                    }

                    return language
                })

                return info
            })
        )
    }

    function fillResultLanguageTemplateWithOtherData(languageCode, data, extraRowsData, filledData) {
        for (let i = 0; i < extraRowsData.length; i++) {
            let rowToPush = []

            for (let j = 0; j < extraRowsData[i].columns.length; j++) {
                rowToPush.push({
                    "code": extraRowsData[i].columns[j],
                    "text": "",
                    "styleGuideEditable": true
                })
            }

            data[0][extraRowsData[i].block].data.push({
                "row": rowToPush,
                "code": extraRowsData[i]["row_code"],
                "colspan": extraRowsData[i].colspan,
                "extraRow": true
            })
        }

        for (let blockKey in data[0]) {
            let blockData = data[0][blockKey].data

            for (let i = 0; i < blockData.length; i++) {
                let rowData = blockData[i].row

                for (let j = 0; j < rowData.length; j++) {
                    let text = rowData[j].text

                    if (Array.isArray(text)) {
                        rowData[j].templateTextSelection = rowData[j].text
                        rowData[j].text = ""
                    }
                }
            }
        }

        for (let i = 0; i < filledData.length; i++) {
            let elData = data[0][filledData[i].block].data

            for (let j = 0; j < elData.length; j++) {
                if (elData[j].code === filledData[i].row_code) {
                    for (let k = 0; k < elData[j].row.length; k++) {
                        if (elData[j].row[k].code === filledData[i].col_code) {
                            elData[j].row[k].text = filledData[i].value
                        }
                    }
                }
            }

            data[0][filledData[i].block].data = elData
        }

        return data
    }

    async function fillCharacterCommunication() {
        await fetch("/proxy/project_bible_template/styleGuideGetCharactersData", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    if (result.length) {
                        console.log("fillCharacterCommunication", characterCommunications, result)

                        for (let i = 0; i < result.length; i++) {
                            let languageCode = result[i].language_code
                            let firstCharacter = result[i].character_first
                            let secondCharacter = result[i].character_second
                            let firstCharacterSequence = getCharacterSequence(firstCharacter) - 1
                            let secondCharacterSequence = getCharacterSequence(secondCharacter) - 1

                            setCharacterCommunications(
                                characterCommunications.map(info => {
                                    if (languageCode === 0) {
                                        info.templateData[firstCharacterSequence].data[secondCharacterSequence] = result[i].value

                                        /*if (result[i].character_first - 1 in info.templateData.data) {
                                            if (result[i].character_second - 1 in info.templateData.data[result[i].character_first - 1]) {
                                                info.templateData.data[result[i].character_first - 1][result[i].character_second - 1] = result[i].value
                                            } else {
                                                info.templateData.data[result[i].character_first - 1][result[i].character_second - 1] = []
                                            }
                                        } else {
                                            info.templateData.data[result[i].character_first - 1] = []
                                        }*/
                                    } else {
                                        if (languageCode in info.languagesData) {
                                            // if (!info.languagesData[languageCode].accept.value) {
                                                if (!(firstCharacterSequence in info.languagesData[languageCode].data)) {
                                                    info.languagesData[languageCode].data[firstCharacterSequence] = []
                                                }

                                                info.languagesData[languageCode].data[firstCharacterSequence][secondCharacterSequence] = result[i].value
                                            // }

                                            /*if (info.languagesData[languageCodeValue].accept.value) {
                                                let linguistId = info.languagesData[languageCodeValue].accept.linguistId

                                                info.languagesData[languageCodeValue].data = linguistLinks[0].data[linguistId].data.characterCommunications.languagesData[languageCodeValue].data
                                            } else {
                                                if (!(result[i].character_first - 1 in info.languagesData[languageCodeValue].data)) {
                                                    info.languagesData[languageCodeValue].data[result[i].character_first - 1] = []
                                                }

                                                info.languagesData[languageCodeValue].data[result[i].character_first - 1][result[i].character_second - 1] = result[i].value
                                            }*/
                                        }
                                    }

                                    return info
                                })
                            )

                            charactersDataCount++

                            if (charactersDataCount === result.length) {
                                setIsCharacterCommunicationLoaded(true);
                            }
                        }

                        /*setCharacterCommunications(
                            characterCommunications.map(info => {
                                for (let key in info.languagesData) {
                                    let value = info.languagesData[key]

                                    if (value.accept.value) {
                                        let linguistId = value.accept.linguistId

                                        value.data = linguistLinks[0].data[linguistId].data.characterCommunications.languagesData[key].data
                                    }
                                }

                                return info
                            })
                        )*/
                    } else {
                        setIsCharacterCommunicationLoaded(true);
                    }
                },
                (error) => {
                    setIsCharacterCommunicationLoaded(true);
                    setError(error);
                }
            )
    }

    function getCharacterSequence(id) {
        let num = 0

        characterCommunications.map(info => {
            console.log("getCharacterSequence", id, info)

            num = info.characters[id].num

            return info
        })

        return num
    }

    function getCharacterId(num) {
        let id = 0

        characterCommunications.map(info => {
            info.characters.map(character => {
                if (character.num === num) {
                    id = character.id
                }

                return character
            })

            return info
        })

        return id
    }

    function fillDataForLinguistStyleGuideAcceptModal(styleGuideresult, resultCharacters, allStyleGuideLanguages) {
        let styleGuideLanguages = styleGuideresult[0]["languages"]

        console.log("fillDataForLinguistStyleGuideAcceptModal", linguistLinks)

        setLinguistLinks(
            linguistLinks.map(info => {
                let length = Object.keys(info.data).length

                if (length > 0) {
                    for (let key in info.data) {
                        let value = info.data[key]
                        let linguistId = value.code

                        /*if (value.submit) {*/
                            let linguistLanguages = value.languages
                            let languagesAllCount = countCorrectLinguistLanguages(linguistLanguages, styleGuideLanguages, allStyleGuideLanguages)
                            let isLinguistLanguagesExistInSG = false

                            linguistLanguagesDataCount[linguistId] = {
                                languagePreferences: {
                                    load: false,
                                    onCountLanguage: 0,
                                    languagesAllCount: languagesAllCount
                                },
                                characterCommunications: {
                                    load: false,
                                    loadCharacterCommunication: false,
                                    loadLinguistCharacterCommunication: false,
                                    charactersDataCount: 0,
                                    linguistCharactersDataCount: 0
                                }
                            }

                            for (let i = 0; i < resultCharacters.length; i++) {
                                value.data.characterCommunications.characters[i] = {
                                    "name": resultCharacters[i].name
                                }
                            }

                            for (let i = 0; i < allStyleGuideLanguages.languages.length; i++) {
                                let languageCode = allStyleGuideLanguages.languages[i].language_code
                                let languageName = allStyleGuideLanguages.languages[i].language

                                if (styleGuideLanguages.includes(languageCode) &&
                                    linguistLanguages.includes(languageCode)) {
                                    isLinguistLanguagesExistInSG = true

                                    value.languagesNames.push({
                                        code: languageCode,
                                        name: languageName
                                    })

                                    value.data.languagePreferences.push({
                                        title: allStyleGuideLanguages.languages[i].language,
                                        code: languageCode,
                                        data: []
                                    })

                                    value.data.characterCommunications.templateData[languageCode] = {
                                        data: []
                                    }

                                    value.data.characterCommunications.languagesData[languageCode] = {
                                        data: [],
                                        isBuffered: false
                                    }

                                    fillLanguageDataForLinguistStyleGuide(linguistId, languageCode, length)

                                    if (styleGuideresult[0]["communication_between_characters"]) {
                                        fillCharacterCommunicationDataForLinguistStyleGuide(linguistId, length)
                                    } else {
                                        linguistLanguagesDataCount[linguistId].characterCommunications.load = true

                                        if (linguistLanguagesDataCount[linguistId].languagePreferences.load) {
                                            linguistDataCount++
                                            console.log("linguistDataCount submit - no communication", linguistDataCount, length, linguistId)

                                            //todo
                                            if (linguistDataCount === length) {
                                                setIsLinguistDataLoaded(true);

                                                fillDataForLanguages()
                                            }
                                        }
                                    }
                                }

                                // console.log("linguist checkLanguage", linguistId, languageCode)
                            }
                        /*} else {
                            linguistDataCount++
                            console.log("linguistDataCount not submit", linguistDataCount, length, linguistId)
                        }*/

                        if (!isLinguistLanguagesExistInSG) {
                            // console.log("linguist checkLanguage no match", linguistId)
                            linguistDataCount++
                        }

                        if (linguistDataCount === length) {
                            setIsLinguistDataLoaded(true)

                            fillDataForLanguages()
                        }
                    }
                } else {
                    setIsLinguistDataLoaded(true)

                    fillDataForLanguages()
                }

                return info
            })
        )
    }

    function countCorrectLinguistLanguages(linguistLanguages, styleGuideLanguages, allStyleGuideLanguages) {
        let count = 0

        for (let i = 0; i < allStyleGuideLanguages.languages.length; i++) {
            let languageCode = allStyleGuideLanguages.languages[i].language_code

            if (styleGuideLanguages.includes(languageCode) &&
                linguistLanguages.includes(languageCode)) {
                count++
            }
        }

        return count
    }

    async function fillLanguageDataForLinguistStyleGuide(linguistId, languageCode, length) {
        await fetch("/proxy/project_bible_template/styleGuideGetLanguagesListByCode", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "code": languageCode,
                "projectManagementInfo": true
            })
        })
            .then(res => res.json())
            .then(
                async (resultLanguageTemplate) => {
                    await fetch("/proxy/project_bible_template/styleGuideGetLanguagesExtraRows", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "clientName": clientName,
                            "projectName": projectName,
                            "projectCode": projectCode,
                            "languageCode": languageCode
                        })
                    })
                        .then(res => res.json())
                        .then(
                            async (resultLanguageExtraRows) => {
                                await fetch("/proxy/project_bible_template/styleGuideLinguistGetLanguagesExtraRows", {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        "clientName": clientName,
                                        "projectName": projectName,
                                        "projectCode": projectCode,
                                        "id": linguistId,
                                        "languageCode": languageCode
                                    })
                                })
                                    .then(res => res.json())
                                    .then(
                                        async (resultLinguistLanguageExtraRows) => {
                                            await fetch("/proxy/project_bible_template/styleGuideGetLanguagesFilledData", {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    "clientName": clientName,
                                                    "projectName": projectName,
                                                    "projectCode": projectCode,
                                                    "languageCode": languageCode
                                                })
                                            })
                                                .then(res => res.json())
                                                .then(
                                                    async (resultLanguageFilledData) => {
                                                        await fetch("/proxy/project_bible_template/styleGuideLinguistGetLanguagesFilledData", {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-Type': 'application/json'
                                                            },
                                                            body: JSON.stringify({
                                                                "clientName": clientName,
                                                                "projectName": projectName,
                                                                "projectCode": projectCode,
                                                                "id": linguistId,
                                                                "languageCode": languageCode
                                                            })
                                                        })
                                                            .then(res => res.json())
                                                            .then(
                                                                async (resultLinguistLanguageFilledData) => {
                                                                    setLinguistLinks(
                                                                        linguistLinks.map(info => {
                                                                            for (let key in info.data) {
                                                                                let value = info.data[key]

                                                                                if (value.code === linguistId) {
                                                                                    for (let i = 0; i < resultLanguageExtraRows.length; i++) {
                                                                                        let rowToPush = []

                                                                                        for (let j = 0; j < resultLanguageExtraRows[i].columns.length; j++) {
                                                                                            rowToPush.push({
                                                                                                "code": resultLanguageExtraRows[i].columns[j],
                                                                                                "text": "",
                                                                                                "styleGuideEditable": true,
                                                                                                "linguistEditable": resultLanguageExtraRows[i].linguist_editable
                                                                                            })
                                                                                        }

                                                                                        resultLanguageTemplate[0][resultLanguageExtraRows[i].block].data.push({
                                                                                            "row": rowToPush,
                                                                                            "code": resultLanguageExtraRows[i]["row_code"],
                                                                                            "colspan": resultLanguageExtraRows[i].colspan
                                                                                        })
                                                                                    }

                                                                                    for (let i = 0; i < resultLinguistLanguageExtraRows.length; i++) {
                                                                                        let rowToPush = []

                                                                                        for (let j = 0; j < resultLinguistLanguageExtraRows[i].columns.length; j++) {
                                                                                            rowToPush.push({
                                                                                                "code": resultLinguistLanguageExtraRows[i].columns[j],
                                                                                                "text": "",
                                                                                                "styleGuideEditable": true,
                                                                                                "linguistEditable": resultLinguistLanguageExtraRows[i].linguist_editable
                                                                                            })
                                                                                        }

                                                                                        resultLanguageTemplate[0][resultLinguistLanguageExtraRows[i].block].data.push({
                                                                                            "row": rowToPush,
                                                                                            "code": resultLinguistLanguageExtraRows[i]["row_code"],
                                                                                            "colspan": resultLinguistLanguageExtraRows[i].colspan,
                                                                                            "linguistExtraRow": true
                                                                                        })
                                                                                    }

                                                                                    for (let i = 0; i < resultLanguageFilledData.length; i++) {
                                                                                        let elData = resultLanguageTemplate[0][resultLanguageFilledData[i].block].data

                                                                                        for (let j = 0; j < elData.length; j++) {
                                                                                            if (elData[j].code === resultLanguageFilledData[i].row_code) {
                                                                                                for (let k = 0; k < elData[j].row.length; k++) {
                                                                                                    if (elData[j].row[k].code === resultLanguageFilledData[i].col_code) {
                                                                                                        elData[j].row[k].text = resultLanguageFilledData[i].value
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        resultLanguageTemplate[0][resultLanguageFilledData[i].block].data = elData
                                                                                    }

                                                                                    for (let i = 0; i < resultLinguistLanguageFilledData.length; i++) {
                                                                                        let linguistFilledData = resultLinguistLanguageFilledData[i]
                                                                                        let filledDataBlock = linguistFilledData.block
                                                                                        let filledDataRowCode = linguistFilledData["row_code"]
                                                                                        let filledDataColCode = linguistFilledData["col_code"]
                                                                                        let filledDataColValue = linguistFilledData.value
                                                                                        let elData = resultLanguageTemplate[0][filledDataBlock].data

                                                                                        if (languageCode in linguistLinks[0].data[linguistId].acceptData) {
                                                                                            let currentLinguistAcceptData = linguistLinks[0].data[linguistId].acceptData[languageCode].languagePreferences.value

                                                                                            if (filledDataBlock in currentLinguistAcceptData &&
                                                                                                "otherLinguistExtra" in currentLinguistAcceptData[filledDataBlock]) {
                                                                                                for (let otherLinguistExtra in currentLinguistAcceptData[filledDataBlock].otherLinguistExtra) {
                                                                                                    let otherLinguistExtraRows = currentLinguistAcceptData[filledDataBlock].otherLinguistExtra[otherLinguistExtra]

                                                                                                    if (filledDataRowCode in otherLinguistExtraRows) {
                                                                                                        otherLinguistExtraRows[filledDataRowCode][filledDataColCode] = filledDataColValue
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        if (languageCode in linguistLinks[0].data[linguistId].bufferedData) {
                                                                                            let currentLinguistBufferedData = linguistLinks[0].data[linguistId].bufferedData[languageCode].languagePreferences.value

                                                                                            if (filledDataBlock in currentLinguistBufferedData) {
                                                                                                if (filledDataRowCode in currentLinguistBufferedData[filledDataBlock].default) {
                                                                                                    currentLinguistBufferedData[filledDataBlock].default[filledDataRowCode][filledDataColCode] = filledDataColValue
                                                                                                }

                                                                                                if (filledDataRowCode in currentLinguistBufferedData[filledDataBlock].default) {
                                                                                                    currentLinguistBufferedData[filledDataBlock].default[filledDataRowCode][filledDataColCode] = filledDataColValue
                                                                                                }

                                                                                                if ("otherLinguistExtra" in currentLinguistBufferedData[filledDataBlock]) {
                                                                                                    for (let otherLinguistExtra in currentLinguistBufferedData[filledDataBlock].otherLinguistExtra) {
                                                                                                        let otherLinguistExtraRows = currentLinguistBufferedData[filledDataBlock].otherLinguistExtra[otherLinguistExtra]

                                                                                                        if (filledDataRowCode in otherLinguistExtraRows) {
                                                                                                            otherLinguistExtraRows[filledDataRowCode][filledDataColCode] = filledDataColValue
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        for (let j = 0; j < elData.length; j++) {
                                                                                            if (elData[j].code === resultLinguistLanguageFilledData[i].row_code) {
                                                                                                for (let k = 0; k < elData[j].row.length; k++) {
                                                                                                    if (elData[j].row[k].code === resultLinguistLanguageFilledData[i].col_code) {
                                                                                                        elData[j].row[k].text = resultLinguistLanguageFilledData[i].value
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        resultLanguageTemplate[0][resultLinguistLanguageFilledData[i].block].data = elData
                                                                                    }

                                                                                    value.data.languagePreferences.map(languagePreferencesLanguage => {
                                                                                        if (languagePreferencesLanguage.code === languageCode) {
                                                                                            languagePreferencesLanguage.data = resultLanguageTemplate
                                                                                        }

                                                                                        return languagePreferencesLanguage
                                                                                    })

                                                                                    linguistLanguagesDataCount[linguistId].languagePreferences.onCountLanguage = linguistLanguagesDataCount[linguistId].languagePreferences.onCountLanguage + 1

                                                                                    if (linguistLanguagesDataCount[linguistId].languagePreferences.onCountLanguage === linguistLanguagesDataCount[linguistId].languagePreferences.languagesAllCount) {
                                                                                        linguistLanguagesDataCount[linguistId].languagePreferences.load = true

                                                                                        if (linguistLanguagesDataCount[linguistId].characterCommunications.load) {
                                                                                            linguistDataCount++
                                                                                            console.log("linguistDataCount submit - fill language data && communication load", linguistDataCount, length, linguistId)

                                                                                            //todo
                                                                                            if (linguistDataCount === length) {
                                                                                                setIsLinguistDataLoaded(true);

                                                                                                fillDataForLanguages()
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            return info
                                                                        })
                                                                    )
                                                                },
                                                                (error) => {
                                                                    setIsLinguistDataLoaded(true);
                                                                    setError(error);
                                                                }
                                                            )
                                                    },
                                                    (error) => {
                                                        setIsLinguistDataLoaded(true);
                                                        setError(error);
                                                    }
                                                )
                                        },
                                        (error) => {
                                            setIsLinguistDataLoaded(true);
                                            setError(error);
                                        }
                                    )
                            },
                            (error) => {
                                setIsLinguistDataLoaded(true);
                                setError(error);
                            }
                        )
                },
                (error) => {
                    setIsLinguistDataLoaded(true);
                    setError(error);
                }
            )
    }

    async function fillCharacterCommunicationDataForLinguistStyleGuide(linguistId, length) {
        await fetch("/proxy/project_bible_template/styleGuideGetCharactersData", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    await fetch("/proxy/project_bible_template/styleGuideLinguistGetCharactersData", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "clientName": clientName,
                            "projectName": projectName,
                            "projectCode": projectCode,
                            "linguistId": linguistId
                        })
                    })
                        .then(res => res.json())
                        .then(
                            async (resultLinguist) => {
                                setLinguistLinks(
                                    linguistLinks.map(info => {
                                        for (let key in info.data) {
                                            let value = info.data[key]

                                            if (value.code === linguistId) {
                                                if (result.length) {
                                                    for (let i = 0; i < result.length; i++) {
                                                        let firstCharacter = result[i].character_first
                                                        let secondCharacter = result[i].character_second
                                                        let firstCharacterSequence = getCharacterSequence(firstCharacter) - 1
                                                        let secondCharacterSequence = getCharacterSequence(secondCharacter) - 1

                                                        if (result[i].language_code !== 0) {
                                                            if (result[i].language_code in value.data.characterCommunications.templateData) {
                                                                if (!(firstCharacterSequence in value.data.characterCommunications.templateData[result[i].language_code].data)) {
                                                                    value.data.characterCommunications.templateData[result[i].language_code].data[firstCharacterSequence] = []
                                                                }

                                                                value.data.characterCommunications.templateData[result[i].language_code].data[firstCharacterSequence][secondCharacterSequence] = result[i].value
                                                            }
                                                        } else {
                                                            if (!(firstCharacterSequence in value.data.characterCommunications.baseStyleGuideData)) {
                                                                value.data.characterCommunications.baseStyleGuideData[firstCharacterSequence] = []
                                                            }

                                                            value.data.characterCommunications.baseStyleGuideData[firstCharacterSequence][secondCharacterSequence] = result[i].value
                                                        }

                                                        linguistLanguagesDataCount[linguistId].characterCommunications.charactersDataCount = linguistLanguagesDataCount[linguistId].characterCommunications.charactersDataCount + 1

                                                        if (linguistLanguagesDataCount[linguistId].characterCommunications.charactersDataCount === result.length) {
                                                            linguistLanguagesDataCount[linguistId].characterCommunications.loadCharacterCommunication = true

                                                            if (linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication) {
                                                                linguistLanguagesDataCount[linguistId].characterCommunications.load = true

                                                                if (linguistLanguagesDataCount[linguistId].languagePreferences.load) {
                                                                    linguistDataCount++

                                                                    //todo
                                                                    if (linguistDataCount === length) {
                                                                        setIsLinguistDataLoaded(true);

                                                                        fillDataForLanguages()
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    linguistLanguagesDataCount[linguistId].characterCommunications.loadCharacterCommunication = true

                                                    if (linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication) {
                                                        linguistLanguagesDataCount[linguistId].characterCommunications.load = true

                                                        if (linguistLanguagesDataCount[linguistId].languagePreferences.load) {
                                                            linguistDataCount++

                                                            //todo
                                                            if (linguistDataCount === length) {
                                                                setIsLinguistDataLoaded(true);

                                                                fillDataForLanguages()
                                                            }
                                                        }
                                                    }
                                                }

                                                console.log("resultLinguist", resultLinguist)
                                                if (resultLinguist.length) {
                                                    for (let i = 0; i < resultLinguist.length; i++) {
                                                        let isBuffered = resultLinguist[i]["is_buffered"]
                                                        let isAccepted = resultLinguist[i]["is_accepted"]

                                                        if (isBuffered || isAccepted) {
                                                            let firstCharacter = resultLinguist[i].character_first
                                                            let secondCharacter = resultLinguist[i].character_second
                                                            let firstCharacterSequence = getCharacterSequence(firstCharacter) - 1
                                                            let secondCharacterSequence = getCharacterSequence(secondCharacter) - 1

                                                            if (resultLinguist[i].language_code !== 0) {
                                                                if (resultLinguist[i].language_code in value.data.characterCommunications.languagesData) {
                                                                    let valueInfo = {
                                                                        value: resultLinguist[i].value
                                                                    }

                                                                    if (!(firstCharacterSequence in value.data.characterCommunications.languagesData[resultLinguist[i].language_code].data)) {
                                                                        value.data.characterCommunications.languagesData[resultLinguist[i].language_code].data[firstCharacterSequence] = []
                                                                    }

                                                                    if (isBuffered) {
                                                                        valueInfo["isBuffered"] = true
                                                                        value.data.characterCommunications.languagesData[resultLinguist[i].language_code].isBuffered = true
                                                                    }

                                                                    if (isAccepted) {
                                                                        let acceptedValue = resultLinguist[i]["accepted_value"]

                                                                        valueInfo["acceptedValue"] = acceptedValue
                                                                        setAcceptedCommunicationBetweenCharacters(resultLinguist[i].language_code, linguistId, firstCharacterSequence, secondCharacterSequence, acceptedValue)
                                                                    }

                                                                    value.data.characterCommunications.languagesData[resultLinguist[i].language_code].data[firstCharacterSequence][secondCharacterSequence] = valueInfo
                                                                }
                                                            }

                                                            linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication =
                                                                linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication + 1

                                                            if (linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication === resultLinguist.length) {
                                                                linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication = true

                                                                if (linguistLanguagesDataCount[linguistId].characterCommunications.loadCharacterCommunication) {
                                                                    linguistLanguagesDataCount[linguistId].characterCommunications.load = true

                                                                    if (linguistLanguagesDataCount[linguistId].languagePreferences.load) {
                                                                        linguistDataCount++

                                                                        //todo
                                                                        if (linguistDataCount === length) {
                                                                            setIsLinguistDataLoaded(true);

                                                                            fillDataForLanguages()
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        } else {
                                                            linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication =
                                                                linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication + 1

                                                            if (linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication === resultLinguist.length) {
                                                                linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication = true

                                                                if (linguistLanguagesDataCount[linguistId].characterCommunications.loadCharacterCommunication) {
                                                                    linguistLanguagesDataCount[linguistId].characterCommunications.load = true

                                                                    if (linguistLanguagesDataCount[linguistId].languagePreferences.load) {
                                                                        linguistDataCount++

                                                                        //todo
                                                                        if (linguistDataCount === length) {
                                                                            setIsLinguistDataLoaded(true);

                                                                            fillDataForLanguages()
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication = true

                                                    if (linguistLanguagesDataCount[linguistId].characterCommunications.loadCharacterCommunication) {
                                                        linguistLanguagesDataCount[linguistId].characterCommunications.load = true

                                                        if (linguistLanguagesDataCount[linguistId].languagePreferences.load) {
                                                            linguistDataCount++

                                                            //todo
                                                            if (linguistDataCount === length) {
                                                                setIsLinguistDataLoaded(true);

                                                                fillDataForLanguages()
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        return info
                                    })
                                )
                            },
                            (error) => {
                                setIsLinguistDataLoaded(true)
                                setError(error);
                            }
                        )
                },
                (error) => {
                    setIsLinguistDataLoaded(true)
                    setError(error);
                }
            )
    }

    function setAcceptedCommunicationBetweenCharacters(languageCode, linguistId, characterFirst, characterSecond, acceptedValue) {
        setCharacterCommunications(
            characterCommunications.map(info => {
                if (languageCode in info.languagesData) {
                    if (!(characterFirst in info.languagesData[languageCode].linguistAcceptedData)) {
                        info.languagesData[languageCode].linguistAcceptedData[characterFirst] = []
                    }

                    info.languagesData[languageCode].linguistAcceptedData[characterFirst][characterSecond] = {
                        linguist: linguistId,
                        value: acceptedValue
                    }
                }

                return info
            })
        )
    }

    function openStyleGuideSettingsModalWindow() {
        setStyleGuideSettingsModalActive(true)
    }

    async function changeStyleGuideSettings(languages, logOfNewBatches, communicationBetweenCharacters, projectManagementInfo) {
        let updateLanguages = []

        for (let i = 0; i < languages.length; i++) {
            if (languages[i].value) {
                updateLanguages.push(languages[i].code)
            }

            /*updateLanguages.push({
                "code": languages[i].code,
                "title": languages[i].title,
                "value": languages[i].value
            })*/
        }

        await fetch("/proxy/project_bible_template/projectBibleStyleGuideUpdate", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "logOfNewBatches": logOfNewBatches,
                "communicationBetweenCharacters": communicationBetweenCharacters,
                "projectManagementInfo": projectManagementInfo,
                "languages": updateLanguages
            }),
        })
            .then(res => res.json())
            .then(
                (resultAdd) => {
                    window.location.reload()

                    setStyleGuideSettingsModalActive(false)
                },
                (error) => {
                    //todo придумать какой-то текст ошибки
                }
            )
    }

    async function addRowToStyleGuideLanguageBlock(languageCode, block, clientEditable, linguistEditable, cellCount) {
        await fetch("/proxy/project_bible_template/styleGuideGenerateLanguagesExtraRowsCode", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "languageCode": languageCode,
                "block": block
            }),
        })
            .then(res => res.json())
            .then(
                async (resultGenerate) => {
                    if (!resultGenerate.exist.length) {
                        let columnsCodes = generateCodes(cellCount, [])

                        await fetch("/proxy/project_bible_template/styleGuideAddLanguagesExtraRows", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "languageCode": languageCode,
                                "block": block,
                                "rowCode": resultGenerate.individualCode,
                                "columns": columnsCodes,
                                "clientEditable": clientEditable,
                                "linguistEditable": linguistEditable,
                                "colspan": cellCount === 2 ? false : true
                            })
                        })
                            .then(res => res.json())
                            .then(
                                (resultAdd) => {
                                    setLanguages(
                                        languages.map(info => {
                                            info.data.map(async language => {
                                                if (language.code === languageCode) {
                                                    let rowData = []

                                                    for (let i = 0; i < columnsCodes.length; i++) {
                                                        rowData.push({
                                                            "code": columnsCodes[i],
                                                            "text": "",
                                                            "styleGuideEditable": true
                                                        })
                                                    }

                                                    language.data[0][block].data.push({
                                                        "row": rowData,
                                                        "code": resultGenerate.individualCode,
                                                        "colspan": cellCount === 2 ? false : true,
                                                        "extraRow": true
                                                    })
                                                }

                                                return language
                                            })

                                            return info
                                        })
                                    )
                                },
                                (error) => {
                                    //todo придумать какой-то текст ошибки
                                }
                            )
                    } else {
                        addRowToStyleGuideLanguageBlock(languageCode, block, clientEditable, linguistEditable, cellCount)
                    }
                },
                (error) => {
                    //todo придумать какой-то текст ошибки
                }
            )
    }

    async function addRowToAllStyleGuideLanguageBlock(block, clientEditable, linguistEditable, values, cellCount) {
        let activeLanguagesCount = countActiveLanguages(languages[0].data)
        let iterationLanguageNum = 0

        setLanguages(
            languages.map(info => {
                info.data.map(async language => {
                    if (language.value) {
                        await fetch("/proxy/project_bible_template/styleGuideGenerateLanguagesExtraRowsCode", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "languageCode": language.code,
                                "block": block
                            }),
                        })
                            .then(res => res.json())
                            .then(
                                async (resultGenerate) => {
                                    if (!resultGenerate.exist.length) {
                                        let columnsCodes = generateCodes(cellCount, [])

                                        await fetch("/proxy/project_bible_template/styleGuideAddLanguagesExtraRows", {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                                "clientName": clientName,
                                                "projectName": projectName,
                                                "projectCode": projectCode,
                                                "languageCode": language.code,
                                                "block": block,
                                                "rowCode": resultGenerate.individualCode,
                                                "columns": columnsCodes,
                                                "clientEditable": clientEditable,
                                                "linguistEditable": linguistEditable,
                                                "colspan": cellCount === 2 ? false : true
                                            })
                                        })
                                            .then(res => res.json())
                                            .then(
                                                async (resultAdd) => {
                                                    let rowData = []
                                                    let cellsOnCount = 0

                                                    iterationLanguageNum++

                                                    for (let i = 0; i < columnsCodes.length; i++) {
                                                        rowData.push({
                                                            "code": columnsCodes[i],
                                                            "text": values[i],
                                                            "styleGuideEditable": true
                                                        })
                                                    }

                                                    language.data[0][block].data.push({
                                                        "row": rowData,
                                                        "code": resultGenerate.individualCode,
                                                        "colspan": cellCount === 2 ? false : true,
                                                        "extraRow": true
                                                    })

                                                    for (let i = 0; i < columnsCodes.length; i++) {
                                                        await fetch('/proxy/project_bible_template/styleGuideLanguagesOninputInsertTextCell', {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-Type': 'application/json'
                                                            },
                                                            body: JSON.stringify({
                                                                "clientName": clientName,
                                                                "projectName": projectName,
                                                                "projectCode": projectCode,
                                                                "languageCode": language.code,
                                                                "block": block,
                                                                "colCode": columnsCodes[i],
                                                                "rowCode": resultGenerate.individualCode,
                                                                "value": values[i],
                                                                "changedBy": {
                                                                    id: employeeId,
                                                                    name: employeeName
                                                                }
                                                            })
                                                        })
                                                            .then(res => res.json())
                                                            .then(
                                                                async (resultInsert) => {
                                                                    cellsOnCount++

                                                                    if (iterationLanguageNum === activeLanguagesCount && cellsOnCount === columnsCodes.length) {
                                                                        setStyleGuideSettingsModalActive(false)
                                                                    }
                                                                },
                                                                (error) => {
                                                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                                                        "верное значение")
                                                                }
                                                            )
                                                    }

                                                    /*let rowData = []
                                                    iterationLanguageNum++

                                                    for (let i = 0; i < columnsCodes.length; i++) {
                                                        rowData.push({
                                                            "code": columnsCodes[i],
                                                            "text": values[i],
                                                            "styleGuideEditable": true
                                                        })
                                                    }

                                                    language.data[0][block].data.push({
                                                        "row": rowData,
                                                        "code": resultGenerate.individualCode,
                                                        "colspan": cellCount === 2 ? false : true,
                                                        "extraRow": true
                                                    })

                                                    if (iterationLanguageNum === activeLanguagesCount) {
                                                        setStyleGuideSettingsModalActive(false)
                                                    }*/
                                                },
                                                (error) => {
                                                    //todo придумать какой-то текст ошибки
                                                }
                                            )
                                    } else {
                                        addRowToAllStyleGuideLanguageBlock(block, clientEditable, linguistEditable, cellCount)
                                    }
                                },
                                (error) => {
                                    //todo придумать какой-то текст ошибки
                                }
                            )
                    }

                    return language
                })

                return info
            })
        )
    }

    function countActiveLanguages(languages) {
        let length = 0

        for (let i = 0; i < languages.length; i++) {
            if (languages[i].value) {
                length++
            }
        }

        return length
    }

    function generateCodes(count, codes) {
        let data = codes
        let codesLeft = count - data.length
        let abc = "abcdefghijklmnopqrstuvwxyz1234567890-";

        // if (data.length !== count) {
        for (let i = 0; i < codesLeft; i++) {
            let individualCode = "";

            while (individualCode.length < 37) {
                individualCode += abc[Math.floor(Math.random() * abc.length)];
            }

            if (!data.includes(individualCode)) {
                if (data.length !== count) {
                    data.push(individualCode)
                }
            } else {
                generateCodes(count, data)
            }
        }
        // }

        return data
    }

    async function addCharacter() {
        await fetch("/proxy/project_bible_template/styleGuideAddCharacter", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "num": Object.keys(characterCommunications[0].templateData).length + 1
            })
        })
            .then(res => res.json())
            .then(
                (resultAdd) => {
                    let addedCharacterId = resultAdd.id
                    let newCharacterLength = Object.keys(characterCommunications[0].templateData).length
                    let addedCharacterNum = newCharacterLength + 1

                    setCharacterCommunications(
                        characterCommunications.map(value => {
                            value.characters[addedCharacterId] = {
                                "id": addedCharacterId,
                                "num": addedCharacterNum,
                                "name": "Character " + addedCharacterNum
                            }
                            value.templateData[newCharacterLength] = {
                                "id": addedCharacterId,
                                "name": "Character " + addedCharacterNum,
                                "data": []
                            }

                            return value
                        })
                    )
                },
                (error) => {
                    //todo придумать какой-то текст ошибки
                }
            )
    }

    async function deleteCharacter(id, index) {
        console.log("deleteCharacter", id, index)
        alert("Персонаж в процессе удаления, страница будет автоматически перезагружена. Закройте это окно и не вносите до перезагрузки страницы никакие изменения")

        await fetch("/proxy/project_bible_template/styleGuideDeleteCharacter", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "id": id
            })
        })
            .then(res => res.json())
            .then(
                async (resultDeleteCharacter) => {
                    await fetch("/proxy/project_bible_template/styleGuideDecDeleteCharacter", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "clientName": clientName,
                            "projectName": projectName,
                            "projectCode": projectCode,
                            "num": index
                        })
                    })
                        .then(res => res.json())
                        .then(
                            async (resultDecDeleteCharacter) => {
                                await fetch("/proxy/project_bible_template/styleGuideDeleteCharactersData", {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        "clientName": clientName,
                                        "projectName": projectName,
                                        "projectCode": projectCode,
                                        "id": id
                                    })
                                })
                                    .then(res => res.json())
                                    .then(
                                        async (resultDeleteCharacterData) => {
                                            window.location.reload()

                                            /*await fetch("/proxy/project_bible_template/styleGuideDecDeleteCharactersData", {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    "clientName": clientName,
                                                    "projectName": projectName,
                                                    "projectCode": projectCode,
                                                    "num": index
                                                })
                                            })
                                                .then(res => res.json())
                                                .then(
                                                    (resultDecDeleteCharacterData) => {
                                                        window.location.reload()
                                                    },
                                                    (error) => {
                                                        //todo придумать какой-то текст ошибки
                                                    }
                                                )*/
                                        },
                                        (error) => {
                                            //todo придумать какой-то текст ошибки
                                        }
                                    )
                            },
                            (error) => {
                                //todo придумать какой-то текст ошибки
                            }
                        )
                },
                (error) => {
                    //todo придумать какой-то текст ошибки
                }
            )
    }

    async function updateCharacterName(index, id, type, e) {
        let queryUpdateCell = '/proxy/project_bible_template/'
        let value

        if (type === "input") {
            queryUpdateCell += 'styleGuideUpdateCharacterName'
            value = e.target.innerText
        }

        await fetch(queryUpdateCell, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "id": id,
                "name": value
            })
        })
            .then(res => res.json())
            .then(
                async (resultUpdate) => {
                    setCharacterCommunications(
                        characterCommunications.map(info => {
                            info.templateData[index - 1].name = value
                            info.characters[id].name = value

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    async function oninputCharacterCommunicationCell(language, firstCharacterId, firstCharacterNum, secondCharacterNum, type, e) {
        let queryLinkExist = '/proxy/project_bible_template/'
        let queryUpdateCell = '/proxy/project_bible_template/'
        let queryInsertCell = '/proxy/project_bible_template/'
        let value
        let secondCharacterId = getCharacterId(secondCharacterNum)

        console.log("oninputCharacterCommunicationCell", language, firstCharacterId, firstCharacterNum, secondCharacterNum, secondCharacterId, characterCommunications)

        if (type === "input") {
            queryLinkExist += 'styleGuideGetCharactersDataByCharacters'
            queryUpdateCell += 'styleGuideUpdateCharactersData'
            queryInsertCell += 'styleGuideAddCharactersData'
            value = e.target.innerText
        }

        // if (value !== oldValue) {
        await fetch(queryLinkExist, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "languageCode": language,
                "firstCharacter": firstCharacterId,
                "secondCharacter": secondCharacterId
            })
        })
            .then(res => res.json())
            .then(
                async (resultEditable) => {
                    if (resultEditable.length) {
                        await fetch(queryUpdateCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "languageCode": language,
                                "firstCharacter": firstCharacterId,
                                "secondCharacter": secondCharacterId,
                                "value": value
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultUpdate) => {
                                    if (language !== 0) {
                                        await resetAcceptedFromLinguistCharactersValues(language, firstCharacterId, secondCharacterId)
                                    }

                                    setCharacterCommunications(
                                        characterCommunications.map(info => {
                                            if (language === 0) {
                                                info.templateData[firstCharacterNum - 1].data[secondCharacterNum - 1] = value
                                            } else {
                                                if (!("data" in info.languagesData[language])) {
                                                    info.languagesData[language] = {
                                                        data: []
                                                    }
                                                }

                                                if (!(firstCharacterNum - 1 in info.languagesData[language].data)) {
                                                    info.languagesData[language].data[firstCharacterNum - 1] = []
                                                }

                                                info.languagesData[language].data[firstCharacterNum - 1][secondCharacterNum - 1] = value
                                            }

                                            return info
                                        })
                                    )
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    } else {
                        await fetch(queryInsertCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "languageCode": language,
                                "firstCharacter": firstCharacterId,
                                "secondCharacter": secondCharacterId,
                                "value": value
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultInsert) => {
                                    if (language !== 0) {
                                        await resetAcceptedFromLinguistCharactersValues(language, firstCharacterId, secondCharacterId)
                                    }

                                    setCharacterCommunications(
                                        characterCommunications.map(info => {
                                            if (language === 0) {
                                                info.templateData[firstCharacterNum - 1].data[secondCharacterNum - 1] = value
                                            } else {
                                                if (!("data" in info.languagesData[language])) {
                                                    info.languagesData[language] = {
                                                        data: []
                                                    }
                                                }

                                                if (!(firstCharacterNum - 1 in info.languagesData[language].data)) {
                                                    info.languagesData[language].data[firstCharacterNum - 1] = []
                                                }

                                                info.languagesData[language].data[firstCharacterNum - 1][secondCharacterNum - 1] = value
                                            }

                                            return info
                                        })
                                    )
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    }
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
        // }
    }

    async function resetAcceptedFromLinguistCharactersValues(language, firstCharacterId, secondCharacterId) {
        await fetch('/proxy/project_bible_template/styleGuideLinguistResetAcceptedCharactersCellValues', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "languageCode": language,
                "firstCharacter": firstCharacterId,
                "secondCharacter": secondCharacterId
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {

                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    async function resetAcceptedFromOtherLinguistCharactersValues(language, linguistId, firstCharacterNum, secondCharacterNum) {
        let firstCharacterId = getCharacterId(firstCharacterNum)
        let secondCharacterId = getCharacterId(secondCharacterNum)

        console.log("resetAcceptedFromOtherLinguistCharactersValues", language, linguistId,
            firstCharacterNum, secondCharacterNum, firstCharacterId, secondCharacterId)

        await fetch('/proxy/project_bible_template/styleGuideOtherLinguistResetAcceptedCharactersCellValues', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "languageCode": language,
                "linguist": linguistId,
                "firstCharacter": firstCharacterId,
                "secondCharacter": secondCharacterId
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {

                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    async function setLinguistCharactersValues(language, linguistId, firstCharacterNum, secondCharacterNum, isBuffered, isAccepted, acceptedValue) {
        let firstCharacterId = getCharacterId(firstCharacterNum)
        let secondCharacterId = getCharacterId(secondCharacterNum)

        console.log("setLinguistCharactersValues", language, linguistId, firstCharacterNum, secondCharacterNum,
            firstCharacterId, secondCharacterId, isBuffered, isAccepted, acceptedValue)

        await fetch('/proxy/project_bible_template/styleGuideLinguistSetCharactersCellValues', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "languageCode": language,
                "linguist": linguistId,
                "firstCharacter": firstCharacterId,
                "secondCharacter": secondCharacterId,
                "isBuffered": isBuffered,
                "isAccepted": isAccepted,
                "acceptedValue": acceptedValue
            })
        })
            .then(res => res.json())
            .then(
                async (resultUpdate) => {

                },
                (error) => {
                    alert("Ошибка при сохранении")
                }
            )
    }

    async function deleteLogOfNewBatchesRow(rowNum) {
        await fetch("/proxy/project_bible_template/styleGuideLogOfNewBatchesRowsTextDeleteRow", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "rowNum": +rowNum + 1
            })
        })
            .then(res => res.json())
            .then(
                async (resultDelete) => {
                    await fetch("/proxy/project_bible_template/styleGuideLogOfNewBatchesRowsTextDecNumDeleteRow", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "clientName": clientName,
                            "projectName": projectName,
                            "projectCode": projectCode,
                            "rowNum": +rowNum + 1
                        })
                    })
                        .then(res => res.json())
                        .then(
                            (resultDec) => {
                                setLogOfNewBatchesData(
                                    logOfNewBatchesData.map(info => {
                                        let newRowsData = []

                                        for (let key in info.rowsData) {
                                            if (+key < rowNum) {
                                                newRowsData[key] = info.rowsData[key]
                                            } else if (+key > rowNum) {
                                                newRowsData[+key - 1] = info.rowsData[key]
                                            }
                                        }

                                        info.rowsCount = info.rowsCount - 1
                                        info.rowsData = newRowsData

                                        return info
                                    })
                                )
                            },
                            (error) => {
                                //todo придумать какой-то текст ошибки
                            }
                        )
                },
                (error) => {
                    //todo придумать какой-то текст ошибки
                }
            )
    }

    async function addLogOfNewBatchesRowToTheBeginning() {
        await fetch("/proxy/project_bible_template/styleGuideLogOfNewBatchesRowsTextIncNumAddRow", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode
            })
        })
            .then(res => res.json())
            .then(
                (resultAdd) => {
                    setLogOfNewBatchesData(
                        logOfNewBatchesData.map(info => {
                            let newRowsData = []

                            newRowsData[0] = []

                            info.headers.map(header => {
                                newRowsData[0][header.code] = ""
                            })

                            for (let key in info.rowsData) {
                                // let newIndex = key + 1

                                newRowsData[+key + 1] = info.rowsData[key]
                            }

                            info.rowsCount = info.rowsCount + 1
                            info.rowsData = newRowsData

                            return info
                        })
                    )
                },
                (error) => {
                    //todo придумать какой-то текст ошибки
                }
            )
    }

    async function oninputLogOfNewBatchesCell(row, column, type, value, refillCell) {
        let queryLinkExist = '/proxy/project_bible_template/'
        let queryUpdateCell = '/proxy/project_bible_template/'
        let queryInsertCell = '/proxy/project_bible_template/'

        if (type === "input") {
            queryLinkExist += 'styleGuideLogOfNewBatchesRowsTextIfExistValue'
            queryUpdateCell += 'styleGuideLogOfNewBatchesRowsTextOninputUpdateTextCell'
            queryInsertCell += 'styleGuideLogOfNewBatchesRowsTextOninputInsertTextCell'
        }

        await fetch(queryLinkExist, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "rowNum": row,
                "colCode": column
            })
        })
            .then(res => res.json())
            .then(
                async (resultEditable) => {
                    if (resultEditable.length) {
                        await fetch(queryUpdateCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "rowNum": row,
                                "colCode": column,
                                "value": removeDataJustUploadedParam(value),
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultUpdate) => {
                                    if (refillCell) {
                                        setLogOfNewBatchesData(
                                            logOfNewBatchesData.map(info => {
                                                info.rowsData[row - 1][column] = value

                                                return info
                                            })
                                        )
                                    }

                                    triggerOpenInModalAddedImage()

                                    setModalAddLinksAndImagesData(
                                        modalAddLinksAndImagesData.map(info => {
                                            info.identificationData = []
                                            info.links = []
                                            info.images = []
                                            info.bufferedImages = []

                                            return info
                                        })
                                    )

                                    setModalAddLinksAndImagesActive(false)
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    } else {
                        await fetch(queryInsertCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "rowNum": row,
                                "colCode": column,
                                "value": removeDataJustUploadedParam(value),
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultInsert) => {
                                    if (refillCell) {
                                        setLogOfNewBatchesData(
                                            logOfNewBatchesData.map(info => {
                                                info.rowsData[row - 1][column] = value

                                                return info
                                            })
                                        )
                                    }

                                    triggerOpenInModalAddedImage()

                                    setModalAddLinksAndImagesData(
                                        modalAddLinksAndImagesData.map(info => {
                                            info.identificationData = []
                                            info.links = []
                                            info.images = []
                                            info.bufferedImages = []

                                            return info
                                        })
                                    )

                                    setModalAddLinksAndImagesActive(false)
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    }
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    /*async function returnLinguistLinkToWork(linguist) {
        await fetch("/proxy/project_bible_template/styleGuideLinguistSetSubmit", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "linguistId": linguist,
                "value": false
            }),
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setLinguistLinks(
                        linguistLinks.map(info => {
                            info.data[linguist].submit = false

                            return info
                        })
                    )
                },
                (error) => {

                }
            )
    }*/

    async function deleteRowFromStyleGuideLanguageBlock(language, block, row, index) {
        await fetch("/proxy/project_bible_template/styleGuideDeleteLanguagesExtraRows", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "languageCode": language,
                "block": block,
                "rowCode": row
            })
        })
            .then(res => res.json())
            .then(
                (resultDelete) => {
                    setLanguages(
                        languages.map(info => {
                            info.data.map(async languageValue => {
                                if (languageValue.code === language) {
                                    languageValue.data[0][block].data.splice(index, 1)
                                }

                                return languageValue
                            })

                            return info
                        })
                    )
                },
                (error) => {
                    //todo придумать какой-то текст ошибки
                }
            )
    }

    async function openModalToAddLinksAndImages(value, method, modalHistoryTitle, tableDBName, identificationData) {
        let queryDB = "/proxy/project_bible_template/"

        console.log("openModalToAddLinksAndImages", value, method, modalHistoryTitle, tableDBName, identificationData)

        clearModalOpenCellDataHistoryData()

        if (tableDBName === "project_bible_text") {
            queryDB += "getHistoryOfChangesInProjectBibleTextData"

            if (typeof value !== "string") {
                return
            }
        } else if (tableDBName === "style_guide_log_of_new_batches_rows_text") {
            queryDB += "getHistoryOfChangesInProjectBibleLogOfNewBatchesData"

            if (typeof value !== "string") {
                return
            }
        } else if (tableDBName === "style_guide_language_filled_data") {
            queryDB += "getHistoryOfChangesInProjectBibleLanguagePreferencesData"

            if (typeof value !== "string") {
                return
            }
        }

        await fetch(queryDB, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "tableName": tableDBName,
                "identityData": identificationData
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setModalOpenCellDataHistoryData(
                        modalOpenCellDataHistoryData.map(info => {
                            for (let i = 0; i < result.main.length; i++) {
                                result.main[i]["isOtherValueChecked"] = false
                            }

                            let resultData = result.main

                            if ("client" in result) {
                                for (let i = 0; i < result.client.length; i++) {
                                    result.client[i]["notAcceptedValuesFromClient"] = true
                                }

                                resultData = result.main.concat(result.client)

                                /*resultData = */resultData.sort(function (a, b) {
                                    let dateA = new Date(a.tstamp)
                                    let dateB = new Date(b.tstamp)

                                    console.log("sort", dateA.getTime(), dateB.getTime())

                                    if (dateA.getTime() < dateB.getTime()) {
                                        return 1;
                                    }
                                    if (dateA.getTime() > dateB.getTime()) {
                                        return -1;
                                    }

                                    return 0;
                                })
                            }

                            info.modalTitle = modalHistoryTitle
                            info.tableName = tableDBName
                            info.saveMethod = method
                            info.data = resultData
                            info.identityData = identificationData/*{
                                column: identificationData.column,
                                row: identificationData.row,
                                type: identificationData.type,
                                refillCell: true
                            }*/

                            return info
                        })
                    )

                    setModalAddLinksAndImagesActive(true)

                    setModalAddLinksAndImagesData(
                        modalAddLinksAndImagesData.map(info => {
                            let regexLink = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/g;
                            let regexTagLink = /(<a[^>]*?href=\"((https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}))\">(.*?)<\/a>)/g;
                            let regexLinkTitle = /<a[^>]*?href=\"(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})\">(.*?)<\/a>/;
                            let linkArray = value.match(regexLink) || []
                            let tagLinkArray = value.match(regexTagLink) || []
                            let associativeLinks = []
                            let regexImg = /<button[^>].*?class=\".*?open-in-modal-added-image.*?\".*?data-src=\".*?\">.*?<\/button>/g;
                            let regexSrcTitle = /<button[^>].*?class=\".*?open-in-modal-added-image.*?\".*?data-src=\"(.*?)\">(.*?)<\/button>/
                            let imgArray = value.match(regexImg) || []
                            let associativeImages = []

                            for (let i = 0; i < tagLinkArray.length; i++) {
                                let link = tagLinkArray[i].match(regexLinkTitle)[1]

                                for (let j = 0; j < linkArray.length; j++) {
                                    if (linkArray[j].includes(link)) {
                                        linkArray[j] = link
                                    }
                                }
                            }

                            for (let i = 0; i < linkArray.length; i++) {
                                associativeLinks[linkArray[i]] = {
                                    "link": linkArray[i],
                                    "title": "",
                                    "tagExist": ""
                                }
                            }

                            for (let i = 0; i < imgArray.length; i++) {
                                let srcValue = imgArray[i].match(regexSrcTitle)[1]
                                let titleValue = imgArray[i].match(regexSrcTitle)[2]

                                associativeImages[i] = {
                                    "src": srcValue,
                                    "title": titleValue
                                }
                            }

                            for (let i = 0; i < tagLinkArray.length; i++) {
                                let link = tagLinkArray[i].match(regexLinkTitle)[1]
                                let linkTitle = tagLinkArray[i].match(regexLinkTitle)[2]

                                associativeLinks[link].tagExist = tagLinkArray[i]
                                associativeLinks[link].title = linkTitle
                            }

                            console.log("associativeImages", associativeImages)

                            info.value = value
                            info.saveMethod = method
                            info.identificationData = identificationData
                            info.links = associativeLinks
                            info.images = associativeImages
                            info.bufferedImages = associativeImages

                            return info
                        })
                    )
                },
                (error) => {

                }
            )
    }

    async function generalTabSaveCellValue(column, row, type, value, refillCell) {
        let queryLinkExist = '/proxy/project_bible_template/'
        let queryUpdateCell = '/proxy/project_bible_template/'
        let queryInsertCell = '/proxy/project_bible_template/'

        console.log("generalTabSaveCellValue", column, row, type, value)

        if (type === "input") {
            queryLinkExist += 'projectBibleFilledCellTextByName'
            queryUpdateCell += 'projectBibleOninputUpdateTextCell'
            queryInsertCell += 'projectBibleOninputInsertTextCell'
        } else if (type === "checkbox") {
            queryLinkExist += 'projectBibleFilledCellBoolByName'
            queryUpdateCell += 'projectBibleOninputUpdateBoolCell'
            queryInsertCell += 'projectBibleOninputInsertBoolCell'
        }

        await fetch(queryLinkExist, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "colCode": column,
                "rowCode": row
            })
        })
            .then(res => res.json())
            .then(
                async (resultEditable) => {
                    if (resultEditable.length) {
                        await fetch(queryUpdateCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "colCode": column,
                                "rowCode": row,
                                "value": removeDataJustUploadedParam(value),
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultUpdate) => {
                                    if (refillCell) {
                                        setRows(
                                            rows.map(rowsData => {
                                                rowsData.data.map(rowValue => {
                                                    if (rowValue.code === row) {
                                                        rowValue.data[column] = value

                                                        triggerOpenInModalAddedImage()
                                                    }

                                                    return rowValue
                                                })

                                                return rowsData
                                            })
                                        )
                                    }

                                    triggerOpenInModalAddedImage()

                                    setModalAddLinksAndImagesData(
                                        modalAddLinksAndImagesData.map(info => {
                                            info.identificationData = []
                                            info.links = []
                                            info.images = []
                                            info.bufferedImages = []

                                            return info
                                        })
                                    )

                                    setModalAddLinksAndImagesActive(false)
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    } else {
                        await fetch(queryInsertCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "colCode": column,
                                "rowCode": row,
                                "value": removeDataJustUploadedParam(value),
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultInsert) => {
                                    if (refillCell) {
                                        setRows(
                                            rows.map(rowsData => {
                                                rowsData.data.map(rowValue => {
                                                    if (rowValue.code === row) {
                                                        rowValue.data[column] = value

                                                        triggerOpenInModalAddedImage()
                                                    }

                                                    return rowValue
                                                })

                                                return rowsData
                                            })
                                        )
                                    }

                                    triggerOpenInModalAddedImage()

                                    setModalAddLinksAndImagesData(
                                        modalAddLinksAndImagesData.map(info => {
                                            info.identificationData = []
                                            info.links = []
                                            info.images = []
                                            info.bufferedImages = []

                                            return info
                                        })
                                    )

                                    setModalAddLinksAndImagesActive(false)
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    }
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    function openInModalAddedImage(path) {
        console.log("CLICK openInModalAddedImage")

        setModalOpenAddedImageSrc(path)
        setModalOpenAddedImageActive(true)
    }

    function triggerOpenInModalAddedImage() {
        const openInModalAddedImageArray = document.getElementsByClassName("open-in-modal-added-image")

        for (const trigger of openInModalAddedImageArray) {
            /*trigger.addEventListener('click', (e) => {
                if (e.target.dataset.src) {
                    openInModalAddedImage(e.target.dataset.src)
                }
            });*/

            trigger.addEventListener('mouseover', (e) => {
                console.log("TRIGGER text", trigger)
                console.log("TRIGGER text trigger.innerHTML", trigger.innerHTML)
                console.log("TRIGGER text trigger.innerText", trigger.innerText)
                console.log("TRIGGER text e.target.dataset.src", e.target.dataset.src)

                setTooltipImageData(
                    tooltipImageData.map(data => {
                        data.src = e.target.dataset.src
                        data.title = trigger.innerText
                        data.element = e
                        data.justUploaded = "justUploaded" in e.target.dataset && e.target.dataset.justUploaded == "1";

                        return data
                    })
                )
            });

            /*trigger.addEventListener('mouseout', (e) => {
                console.log("TOOLTIP MOUSEOUT")

                /!*setTooltipImageData(
                    tooltipImageData.map(data => {
                        data.src = ''
                        data.title = ''

                        return data
                    })
                )*!/
            });*/
        }
    }

    /*function triggerOpenInTooltipAddedImage() {
        const openInTooltipAddedImageArray = document.getElementsByClassName("open-in-modal-added-image")

        for (const trigger of openInTooltipAddedImageArray) {
            trigger.addEventListener('mouseover', (e) => {
                // console.log("trigger.getAttribute data-src", trigger.getAttribute('data-src'))
                // console.log("trigger.dataset['src']", trigger.dataset.src)
                console.log("TRIGGER text", trigger, )
                console.log("TRIGGER text", trigger.innerHTML)
                console.log("TRIGGER text", trigger.innerText)
                console.log("TRIGGER text", e)

                setTooltipImageData(
                    tooltipImageData.map(data => {
                        data.src = trigger.dataset.src
                        data.title = trigger.innerText

                        return data
                    })
                )
                // document.getElementById("tooltipImage").setAttribute('target', '.open-in-modal-added-image')
            });

            trigger.addEventListener('mouseout', (e) => {
                setTooltipImageData(
                    tooltipImageData.map(data => {
                        data.src = ''
                        data.title = ''

                        return data
                    })
                )
            });
        }
    }*/

    function setImagesBufferedData(bufferedData) {
        setModalAddLinksAndImagesData(
            modalAddLinksAndImagesData.map(info => {
                info.bufferedImages = bufferedData

                return info
            })
        )
    }

    function saveImgTooltipChanges(title, src) {
        console.log("SAVE IMAGE TOOLTIP", title, src)

        tooltipImageData.map(data => {
            data.element.target.innerText = title
            data.element.target.dataset.src = src

            return data
        })
    }

    function deleteImgTooltip() {
        tooltipImageData.map(data => {
            data.element.target.innerText = ""
            data.element.target.dataset.src = ""

            return data
        })
    }

    async function languageTabSavePreferencesCellValue(languageCode, block, column, row, type, value, refillCell) {
        let queryLinkExist = '/proxy/project_bible_template/'
        let queryUpdateCell = '/proxy/project_bible_template/'
        let queryInsertCell = '/proxy/project_bible_template/'

        console.log("languageTabSavePreferencesCellValue", languageCode, block, column, row, type, value, refillCell)

        if (type === "input") {
            queryLinkExist += 'styleGuideLanguagesFilledCellTextByCode'
            queryUpdateCell += 'styleGuideLanguagesOninputUpdateTextCell'
            queryInsertCell += 'styleGuideLanguagesOninputInsertTextCell'
        }

        await fetch(queryLinkExist, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "languageCode": languageCode,
                "block": block,
                "colCode": column,
                "rowCode": row
            })
        })
            .then(res => res.json())
            .then(
                async (resultEditable) => {
                    if (resultEditable.length) {
                        await fetch(queryUpdateCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "languageCode": languageCode,
                                "block": block,
                                "colCode": column,
                                "rowCode": row,
                                "value": removeDataJustUploadedParam(value),
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultUpdate) => {
                                    if (refillCell) {
                                        setLanguages(
                                            languages.map(info => {
                                                info.data.map(languageValue => {
                                                    if (languageValue.code === languageCode) {
                                                        languageValue.data[0][block].data.map(rowData => {
                                                            if (rowData.code === row) {
                                                                rowData.row.map(columnData => {
                                                                    if (columnData.code === column) {
                                                                        console.log("THERE THERE THERE 1", columnData, columnData.text, value)

                                                                        columnData.text = value

                                                                        console.log("THERE THERE THERE 2", columnData, columnData.text, value)

                                                                        triggerOpenInModalAddedImage()
                                                                    }

                                                                    return columnData
                                                                })
                                                            }

                                                            return rowData
                                                        })
                                                    }

                                                    return languageValue
                                                })

                                                return info
                                            })
                                        )
                                    }

                                    triggerOpenInModalAddedImage()

                                    setModalAddLinksAndImagesData(
                                        modalAddLinksAndImagesData.map(info => {
                                            info.identificationData = []
                                            info.links = []
                                            info.images = []
                                            info.bufferedImages = []

                                            return info
                                        })
                                    )

                                    setModalTemplateTextSelectionData(
                                        modalTemplateTextSelectionData.map(info => {
                                            info.data = []
                                            info.selectedValue = 0

                                            return info
                                        })
                                    )

                                    setModalAddLinksAndImagesActive(false)
                                    setModalTemplateTextSelectionActive(false)
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    } else {
                        await fetch(queryInsertCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "languageCode": languageCode,
                                "block": block,
                                "colCode": column,
                                "rowCode": row,
                                "value": removeDataJustUploadedParam(value),
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultInsert) => {
                                    if (refillCell) {
                                        setLanguages(
                                            languages.map(info => {
                                                info.data.map(languageValue => {
                                                    if (languageValue.code === languageCode) {
                                                        languageValue.data[0][block].data.map(rowData => {
                                                            if (rowData.code === row) {
                                                                rowData.row.map(columnData => {
                                                                    if (columnData.code === column) {
                                                                        console.log("THERE THERE THERE 1", columnData, columnData.text, value)

                                                                        columnData.text = value

                                                                        console.log("THERE THERE THERE 2", columnData, columnData.text, value)

                                                                        triggerOpenInModalAddedImage()
                                                                    }

                                                                    return columnData
                                                                })
                                                            }

                                                            return rowData
                                                        })
                                                    }

                                                    return languageValue
                                                })

                                                return info
                                            })
                                        )
                                    }

                                    triggerOpenInModalAddedImage()

                                    setModalAddLinksAndImagesData(
                                        modalAddLinksAndImagesData.map(info => {
                                            info.identificationData = []
                                            info.links = []
                                            info.images = []
                                            info.bufferedImages = []

                                            return info
                                        })
                                    )

                                    setModalTemplateTextSelectionData(
                                        modalTemplateTextSelectionData.map(info => {
                                            info.data = []
                                            info.selectedValue = 0

                                            return info
                                        })
                                    )

                                    setModalAddLinksAndImagesActive(false)
                                    setModalTemplateTextSelectionActive(false)
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    }
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    function removeDataJustUploadedParam(value) {
        let newValue = value

        newValue = newValue.replace(/data-just-uploaded='1'/g,'')
        newValue = newValue.replace(/data-just-uploaded="1"/g,'')

        return newValue
    }

    function selectTemplateTextForLanguageTabPreferences(languageCode, block, column, row, type, templateTextSelection, refillCell) {
        setModalTemplateTextSelectionData(
            modalTemplateTextSelectionData.map(info => {
                info.data.push({
                    languageCode: languageCode,
                    block: block,
                    column: column,
                    row: row,
                    type: type,
                    templateTextSelection: templateTextSelection,
                    refillCell: refillCell
                })

                return info
            })
        )

        setModalTemplateTextSelectionActive(true)
    }

    function setModalTemplateTextSelectionDataSelectedValue(selectedValue) {
        setModalTemplateTextSelectionData(
            modalTemplateTextSelectionData.map(info => {
                info.selectedValue = selectedValue

                return info
            })
        )
    }

    function backToPB() {
        let link = window.location.href.split("/")

        /*window.open('http://' + link[2] + '/managed_quality_on_project/project_bible/view?client_name=' +
            encodeURIComponent(clientName) + '&project_name=' + encodeURIComponent(projectName) +
            '&project_code=' + encodeURIComponent(projectCode))*/
        window.location.href = 'http://' + link[2] + '/managed_quality_on_project/project_bible/view?client_name=' +
            encodeURIComponent(clientName) + '&project_name=' + encodeURIComponent(projectName) +
            '&project_code=' + encodeURIComponent(projectCode);
    }

    function clearModalOpenCellDataHistoryData() {
        setModalOpenCellDataHistoryData(
            modalOpenCellDataHistoryData.map(info => {
                info.modalTitle = ""
                info.tableName = ""
                info.saveMethod = ""
                info.data = []
                info.identityData = []

                return info
            })
        )
    }

    async function replaceToOldValueFromDataHistory(saveMethod, identityData, tableName, newValue) {
        //todo

        console.log("replaceToOldValueFromDataHistory", saveMethod, identityData, tableName, newValue)

        if (saveMethod === "generalTabSaveCellValue") {
            await generalTabSaveCellValue(identityData.column, identityData.row,
                identityData.type, newValue, identityData.refillCell)

            setModalOpenCellDataHistoryActive(false)
        } else if (saveMethod === "oninputLogOfNewBatchesCell") {
            await oninputLogOfNewBatchesCell(identityData.row, identityData.column,
                identityData.type, newValue, identityData.refillCell)

            setModalOpenCellDataHistoryActive(false)
        } else if (saveMethod === "languageTabSavePreferencesCellValue") {
            console.log("await languageTabSavePreferencesCellValue", identityData, newValue)

            await languageTabSavePreferencesCellValue(identityData.language, identityData.block, identityData.column, identityData.row,
                identityData.type, newValue, identityData.refillCell)

            setModalOpenCellDataHistoryActive(false)
        }
        /*if (saveMethod === "generalTabSaveCellValue") {
            await generalTabSaveCellValue(identityData.column, identityData.row,
                identityData.type, newValue, identityData.refillCell)

            setModalOpenCellDataHistoryActive(false)
        } else if (saveMethod === "generalTabSaveBoolCellValue") {
            await generalTabSaveBoolCellValue(identityData.column, identityData.row,
                identityData.type, newValue)

            setModalOpenCellDataHistoryActive(false)
            window.location.reload()
        }*/
    }

    function editModalHistoryDataCheckedValue(key, isСhecked) {
        setModalOpenCellDataHistoryData(
            modalOpenCellDataHistoryData.map(info => {
                info.data.map((storedValue, index) => {
                    storedValue.isOtherValueChecked = isСhecked && index === key

                    return storedValue
                })

                return info
            })
        )
    }

    if (error) {
        return (
            <div className="row">
                <div className="col-sm-12 center">
                    <br />
                    <h3>Ошибка: {error.message}</h3>
                </div>
            </div>
        )
    } else if (!isLoaded || !isLanguageLoaded || (communicationBetweenCharacters && !isCharacterCommunicationLoaded) ||
        (logOfNewBatches && !isLogOfNewBatches) || !isLinguistDataLoaded) {
        /*console.log("isLoaded", isLoaded, isLanguageLoaded, communicationBetweenCharacters,
            isCharacterCommunicationLoaded, logOfNewBatches, isLogOfNewBatches, isLinguistDataLoaded)*/

        return (
            <div className="row" style={styles.progressBar}>
                <div className="col-sm-4">

                </div>
                <div className="col-sm-4 center">
                    <h4>Loading...</h4>
                    <ProgressBar now={progressPercent} label={`${progressPercent}%`} />
                </div>
                <div className="col-sm-4">

                </div>
            </div>
        )
    } else {
        return(
            <div className="main-block-div-dark">
                <div className="row">
                    <div className="col-sm-4">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicClientName">
                                <Form.Label>Client name</Form.Label>
                                <Form.Control type="clientName" disabled
                                              placeholder={queryStringParams.client_name} />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="col-sm-4">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicProjectName">
                                <Form.Label>Project name</Form.Label>
                                <Form.Control type="projectName" disabled
                                              placeholder={queryStringParams.project_name} />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="col-sm-4">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicProjectCode">
                                <Form.Label>Project code</Form.Label>
                                <Form.Control type="projectCode" disabled
                                              placeholder={queryStringParams.project_code} />
                            </Form.Group>
                        </Form>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-12 center">
                        <Button variant="light" onClick={(e) => backToPB()}>
                            Back to PB&nbsp;&nbsp;<FontAwesomeIcon icon={faLink}/>
                        </Button>
                        &nbsp;&nbsp;
                        <button className="btn btn-blue"
                                onClick={(e) => openStyleGuideSettingsModalWindow()}>
                            Change styleguide settings&nbsp;&nbsp;<FontAwesomeIcon icon={faMagic}/>
                        </button>
                    </div>
                </div>
                <br/>
                <StyleGuideTabs logOfNewBatches={logOfNewBatches}
                                logOfNewBatchesHeaders={logOfNewBatchesData[0].headers}
                                deleteLogOfNewBatchesRow={deleteLogOfNewBatchesRow}
                                addLogOfNewBatchesRowToTheBeginning={addLogOfNewBatchesRowToTheBeginning}
                                oninputLogOfNewBatchesCell={oninputLogOfNewBatchesCell}
                                logOfNewBatchesRowsCount={logOfNewBatchesData[0].rowsCount}
                                logOfNewBatchesRowsData={logOfNewBatchesData[0].rowsData}
                                rows={rows[0].data} columns={columns[0].data}
                                languages={languages[0].data}
                                addRowToStyleGuideLanguageBlock={addRowToStyleGuideLanguageBlock}
                                characterCommunications={characterCommunications}
                                communicationBetweenCharacters={communicationBetweenCharacters}
                                projectManagementInfo={projectManagementInfo}
                                oninputCharacterCommunicationCell={oninputCharacterCommunicationCell}
                                linguistLinks={linguistLinks[0].data}
                                // returnLinguistLinkToWork={returnLinguistLinkToWork}
                                deleteRowFromStyleGuideLanguageBlock={deleteRowFromStyleGuideLanguageBlock}
                    // setInputRegexModalValue={setInputRegexModalValue}
                                openModalToAddLinksAndImages={openModalToAddLinksAndImages}
                                modalAddLinksAndImagesActive={modalAddLinksAndImagesActive}
                                modalOpenAddedImageActive={modalOpenAddedImageActive}
                                generalTabSaveCellValue={generalTabSaveCellValue}
                    // openInModalAddedImage={openInModalAddedImage}
                                triggerOpenInModalAddedImage={triggerOpenInModalAddedImage}
                    // triggerOpenInTooltipAddedImage={triggerOpenInTooltipAddedImage}
                                languageTabSavePreferencesCellValue={languageTabSavePreferencesCellValue}
                                selectTemplateTextForLanguageTabPreferences={selectTemplateTextForLanguageTabPreferences}
                                resetAcceptedFromOtherLinguistCharactersValues={resetAcceptedFromOtherLinguistCharactersValues}
                                setLinguistCharactersValues={setLinguistCharactersValues}
                                getCharacterId={getCharacterId}
                                employeeId={employeeId}
                                employeeName={employeeName}
                />
                <StyleGuideSettingsModal styleGuideSettingsModalActive={styleGuideSettingsModalActive}
                                         setStyleGuideSettingsModalActive={setStyleGuideSettingsModalActive}
                                         changeStyleGuideSettings={changeStyleGuideSettings}
                                         languages={languages[0].data}
                                         addRowToAllStyleGuideLanguageBlock={addRowToAllStyleGuideLanguageBlock}
                                         logOfNewBatches={logOfNewBatches}
                                         communicationBetweenCharacters={communicationBetweenCharacters}
                                         projectManagementInfo={projectManagementInfo}
                                         templateData={characterCommunications[0].templateData}
                                         languagesData={characterCommunications[0].languagesData}
                                         addCharacter={addCharacter}
                                         deleteCharacter={deleteCharacter}
                                         updateCharacterName={updateCharacterName}
                                         oninputCharacterCommunicationCell={oninputCharacterCommunicationCell}
                />
                <AddLinksAndImagesModal modalAddLinksAndImagesActive={modalAddLinksAndImagesActive}
                                        setModalAddLinksAndImagesActive={setModalAddLinksAndImagesActive}
                                        modalAddLinksAndImagesData={modalAddLinksAndImagesData}
                                        setModalAddLinksAndImagesData={setModalAddLinksAndImagesData}
                                        generalTabSaveCellValue={generalTabSaveCellValue}
                                        openInModalAddedImage={openInModalAddedImage}
                                        setImagesBufferedData={setImagesBufferedData}
                                        oninputLogOfNewBatchesCell={oninputLogOfNewBatchesCell}
                                        languageTabSavePreferencesCellValue={languageTabSavePreferencesCellValue}
                                        modalOpenCellDataHistoryData={modalOpenCellDataHistoryData}
                                        editModalHistoryDataCheckedValue={editModalHistoryDataCheckedValue}
                                        replaceToOldValueFromDataHistory={replaceToOldValueFromDataHistory}
                />
                <OpenInModalAddedImage modalOpenAddedImageActive={modalOpenAddedImageActive}
                                       setModalOpenAddedImageActive={setModalOpenAddedImageActive}
                                       modalOpenAddedImageSrc={modalOpenAddedImageSrc}
                                       imgFolderSrc={imgFolderSrc}
                />
                <OpenInTooltipAddedImage tooltipImageSrc={tooltipImageData[0].src}
                                         tooltipImageTitle={tooltipImageData[0].title}
                                         tooltipImageJustUploaded={tooltipImageData[0].justUploaded}
                                         saveImgTooltipChanges={saveImgTooltipChanges}
                                         deleteImgTooltip={deleteImgTooltip}
                                         imgFolderSrc={imgFolderSrc}
                />
                <TemplateTextSelectionModal modalTemplateTextSelectionActive={modalTemplateTextSelectionActive}
                                            setModalTemplateTextSelectionActive={setModalTemplateTextSelectionActive}
                                            modalTemplateTextSelectionData={modalTemplateTextSelectionData[0].data}
                                            selectedValue={modalTemplateTextSelectionData[0].selectedValue}
                                            setModalTemplateTextSelectionData={setModalTemplateTextSelectionData}
                                            languageTabSavePreferencesCellValue={languageTabSavePreferencesCellValue}
                                            setModalTemplateTextSelectionDataSelectedValue={setModalTemplateTextSelectionDataSelectedValue}
                />
            </div>
        )
    }
}