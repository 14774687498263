import {Alert, Button, Col, Row, Nav, Table, Tabs, Tab} from "react-bootstrap";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faMagic, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";
import LanguageTabPreferences from "../style_guide_linguist/LinguistLanguageTabPreferences";
import LanguageTabCharacterCommunication from "../style_guide_linguist/LinguistLanguageTabCharacterCommunication";

export default function LinguistLanguageTab(props) {
    let showAlertMessage = false
    let alertMessageText = ""
    let alertMessageVariant = ""

    console.log("LinguistLanguageTab props.characterCommunications[0]", props.characterCommunications[0])

    /*if (props.submit) {*/
        if (props.language.code in props.acceptedData.accept && props.acceptedData.accept[props.language.code] &&
            !Object.keys(props.bufferedData.languagePreferences[props.language.code]).length > 0 &&
            !props.bufferedData.communicationBetweenCharacters[props.language.code]) {
            alertMessageText = "Style Guide has been updated."
            alertMessageVariant = "success"
            showAlertMessage = true
        } /*else {
            alertMessageText = "Your changes are pending now. Please wait."
            alertMessageVariant = "primary"
            showAlertMessage = true
        }*/
    /*}*/

    return (
        <div>
            {
                showAlertMessage &&
                <Alert variant={alertMessageVariant}>
                    {alertMessageText}
                </Alert>
            }
            {
                props.communicationBetweenCharacters ? (
                    <Tab.Container defaultActiveKey={"languagePreferences"}>
                        <Nav variant="pills" className="nav-custom-grey">
                            <Nav.Item>
                                <Nav.Link eventKey={"languagePreferences"}>
                                    Language Preferences
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={"communicationBetweenCharacters"}>
                                    Communication between characters
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey={"languagePreferences"}>
                                <div className="tab-item-wrapper">
                                    <br/>
                                    <LanguageTabPreferences
                                        // submit={props.submit}
                                        linguistCode={props.linguistCode}
                                        language={props.language}
                                        acceptedData={props.acceptedData}
                                        bufferedData={props.bufferedData}
                                        addRowToStyleGuideLanguageBlock={props.addRowToStyleGuideLanguageBlock}
                                        setBufferedLanguagePreferencesUpdatedCellData={props.setBufferedLanguagePreferencesUpdatedCellData}
                                        modalAddLinksAndImagesActive={props.modalAddLinksAndImagesActive}
                                        modalOpenAddedImageActive={props.modalOpenAddedImageActive}
                                        triggerOpenInModalAddedImage={props.triggerOpenInModalAddedImage}
                                        openModalToAddLinksAndImages={props.openModalToAddLinksAndImages}
                                        languageTabSavePreferencesCellValue={props.languageTabSavePreferencesCellValue}
                                    />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey={"communicationBetweenCharacters"}>
                                <div className="tab-item-wrapper">
                                    <br/>
                                    <LanguageTabCharacterCommunication
                                        // submit={props.submit}
                                        language={props.language.code}
                                        characters={props.characterCommunications[0].characters}
                                        // baseStyleGuideData={props.characterCommunications[0].baseStyleGuideData}
                                        templateData={props.characterCommunications[0].templateData/*[props.language.code].data*/}
                                        languageData={props.characterCommunications[0].languagesData[props.language.code].data}
                                        otherLinguistAcceptedData={props.characterCommunications[0].languagesData[props.language.code].otherLinguistAcceptedData}
                                        currentLinguistData={props.characterCommunications[0].languagesData[props.language.code].currentLinguistData}
                                        oninputLinguistCharacterCommunicationCell={props.oninputLinguistCharacterCommunicationCell}
                                    />
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                ) : (
                    <LanguageTabPreferences
                        // submit={props.submit}
                        linguistCode={props.linguistCode}
                        language={props.language}
                        acceptedData={props.acceptedData}
                        bufferedData={props.bufferedData}
                        addRowToStyleGuideLanguageBlock={props.addRowToStyleGuideLanguageBlock}
                        setBufferedLanguagePreferencesUpdatedCellData={props.setBufferedLanguagePreferencesUpdatedCellData}
                        modalAddLinksAndImagesActive={props.modalAddLinksAndImagesActive}
                        modalOpenAddedImageActive={props.modalOpenAddedImageActive}
                        triggerOpenInModalAddedImage={props.triggerOpenInModalAddedImage}
                        openModalToAddLinksAndImages={props.openModalToAddLinksAndImages}
                        languageTabSavePreferencesCellValue={props.languageTabSavePreferencesCellValue}
                    />
                )
            }
        </div>
    )
}