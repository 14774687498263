import {Button, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import queryString from "query-string";
import Editor from 'react-medium-editor';
import MediumButton from 'medium-button';

const styles = {
    columnNumeration: {
        backgroundColor: '#f6f6f6',
        width: '5%'
    },

    columnNoEditable: {
        backgroundColor: '#f6f6f6',
        width: '20%',
    },

    editableCells: {
        backgroundColor: '#fff',
    },

    tableFixHeadTh: {
        position: 'sticky',
        top: -2,
        zIndex: 1,
        whiteSpace: 'pre-line',
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderTopColor: '#4A8EFF',
    },

    tableRowHeader: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderTopColor: '#4A8EFF',
    },
}

export default function GeneralTab(props) {
    let rowNum = 0

    function openModalToAddLinksAndImages(element, /*row, column*/modalHistoryTitle, tableDBName, data) {
        let cellText = ""

        if (element.target.nodeName === "TD") {
            cellText = element.target.innerHTML
        } else {
            cellText = element.target.closest(".select-double-click-add-links-and-images").innerHTML
        }

        props.openModalToAddLinksAndImages(
            cellText,
            "generalTabSaveCellValue",
            modalHistoryTitle,
            tableDBName,
            data
        )
    }

    return(
        /*<div className="row">
            <div className="col-sm-12 div-table-blue">*/
                <div className="div-table-blue">
                <Table responsive bordered>
                    <thead>
                    <tr style={styles.tableRowHeader}>
                        <th key="№" className="center" style={styles.tableFixHeadTh}>№</th>
                        {
                            props.columns.map(column => {
                                if (column["col_styleguide"]) {
                                    return (
                                        <th key={column.name} className="center" style={styles.tableFixHeadTh}>
                                            {column.name}
                                        </th>
                                    )
                                }
                            })
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.rows.map((row, rowIndex) => {
                            if (row.show) {
                                return (
                                    <tr key={row.code}>
                                        <td className="center" style={styles.columnNumeration}>{++rowNum}</td>
                                        {
                                            props.columns.map((column, columnIndex) => {
                                                if (column["col_styleguide"]) {
                                                    if (column.editable) {
                                                        let cellValue = row.data[column.code]

                                                        function handleChange(text, medium) {
                                                            console.log("handleChange", text)

                                                            cellValue = text
                                                        }

                                                        function onBlurSave(e) {
                                                            if (!props.modalAddLinksAndImagesActive && !props.modalOpenAddedImageActive) {
                                                                if (e.target.nodeName === "TD") {
                                                                    console.log("onBlurSave", e.target.innerHTML, cellValue)

                                                                    props.generalTabSaveCellValue(
                                                                        column.code, row.code, "input", e.target.innerHTML, false
                                                                    )
                                                                }
                                                            }
                                                        }

                                                        return(
                                                            <Editor
                                                                key={column.code}
                                                                style={styles.editableCells}
                                                                className="whiteSpacePreLine select-double-click-add-links-and-images"
                                                                suppressContentEditableWarning={true}
                                                                tag="td"
                                                                text={cellValue}
                                                                onChange={handleChange}
                                                                options={{
                                                                    toolbar: {
                                                                        buttons: [
                                                                            'bold',
                                                                            'italic',
                                                                            'underline',
                                                                            'addImage'
                                                                        ]
                                                                    },
                                                                    extensions: {
                                                                        'addImage': new MediumButton({
                                                                            label: '<b>img</b>',
                                                                            start: '<button class="btn btn-secondary btn-sm open-in-modal-added-image" type="button" contenteditable="false" data-src="">',
                                                                            end:   '</button>',
                                                                            action: function(html){
                                                                                props.triggerOpenInModalAddedImage()

                                                                                return html
                                                                            }
                                                                        })
                                                                    },
                                                                    placeholder: false
                                                                }}
                                                                onBlur={(e) => onBlurSave(e)}
                                                                onDoubleClick={(e) =>
                                                                    openModalToAddLinksAndImages(
                                                                        e, /*row, column*/
                                                                        "History of changes in \"" + column.name + "\" column",
                                                                        "project_bible_text",
                                                                        {
                                                                            row: row.code,
                                                                            column: column.code,
                                                                            type: "input",
                                                                            refillCell: true
                                                                        }
                                                                    )}
                                                            />
                                                        )
                                                    } else {
                                                        return (
                                                            <td key={column.code} style={styles.editableCells}
                                                                className="whiteSpacePreLine" contentEditable={false}
                                                                suppressContentEditableWarning={true}
                                                                dangerouslySetInnerHTML={{__html: row.data[column.code]}}
                                                                style={styles.columnNoEditable}></td>
                                                        )
                                                    }
                                                }
                                            })
                                        }
                                    </tr>
                                )
                            }
                        })
                    }
                    </tbody>
                </Table>
            </div>
        // </div>
    )
}