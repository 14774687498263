import {Button, Form, Table} from "react-bootstrap";
import React, {useState} from "react";

const styles = {
    noEditableCell: {
        backgroundColor: '#fafafa'
    },

    disabledInput: {
        backgroundColor: 'white'
    },

    editedCell: {
        backgroundColor: '#ffffff'
    },

    acceptedCell: {
        backgroundColor: '#dffdde'
    },

    bufferedCell: {
        backgroundColor: '#fff8e1'
    },

    firstColumn: {
        // backgroundColor: '#EEF5FF' //blue
        backgroundColor: '#e8f6e9' //light green
        // backgroundColor: '#FFE5E5' //pink
        // backgroundColor: '#f2e9fc' //purple
    },

    firstRow: {
        // backgroundColor: '#EEF5FF' //blue
        // backgroundColor: '#e8f6e9' //light green
        backgroundColor: '#FFE5E5', //pink
        // backgroundColor: '#f2e9fc' //purple
        minWidth: '200px'
    },

    imgArrowPercentSize: {
        maxHeight: '40px',
        maxWidth: '40px'
    }
}

export default function LinguistLanguageTabCharacterCommunication(props) {
    const [tooltipText, setTooltipText] = useState("")

    console.log("LinguistLanguageTabCharacterCommunication", props)

    return (
        <div>
            <div className="row">
                <div className="col-sm-12">
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicTemplateValue">
                            <Form.Label>
                                You can see a hint about the characters' relationships in the box below.
                                Put in the table how a character from the <strong>green column</strong> addresses characters from the <strong>red row</strong>.
                            </Form.Label>
                            <Form.Control as="textarea" rows={2}
                                          value={tooltipText} disabled style={styles.disabledInput}/>
                        </Form.Group>
                    </Form>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    {
                        props.characters.length > 0 &&
                        <div className="table-responsive character-communication-container">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th className="center align-middle" style={{minWidth: '200px'}}>
                                        <img alt="logo" src={require(`../img/right-arrow.png`).default}
                                             data-pr-tooltip="PrimeReact-Logo" style={styles.imgArrowPercentSize}
                                             onError={(e) =>
                                                 e.target.src = require(`../img/error.jpg`).default}/>
                                    </th>
                                    {
                                        props.templateData.map((value, valueIndex) => {
                                            return (
                                                <th key={valueIndex} className="center align-middle" contentEditable={false}
                                                    style={styles.firstRow}
                                                    dangerouslySetInnerHTML={{__html: value.name}}>
                                                </th>
                                            )
                                        })
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    props.templateData.map((value, valueIndex) => {
                                        const rowCells = [];

                                        console.log("Linguist language tab character communications table valueIndex", value, valueIndex)

                                        // console.log("props.baseStyleGuideData", props.baseStyleGuideData)

                                        for (let key in props.templateData) {
                                            let columnIndex = +key
                                            let cellStyle = styles.editedCell
                                            let actualValueForTable = ""
                                            let defaultCellValue = ""

                                            console.log("Linguist language tab character communications table key", props.templateData[columnIndex], columnIndex)

                                            if (valueIndex in props.templateData && columnIndex in props.templateData[valueIndex].data) {
                                                let cellValue = props.templateData[valueIndex].data[columnIndex]

                                                defaultCellValue = cellValue
                                                // actualValueForTable = cellValue
                                            }

                                            if (valueIndex in props.languageData && columnIndex in props.languageData[valueIndex]) {
                                                let cellValue = props.languageData[valueIndex][columnIndex]

                                                defaultCellValue = cellValue
                                                actualValueForTable = cellValue
                                            }

                                            if (valueIndex in props.otherLinguistAcceptedData && columnIndex in props.otherLinguistAcceptedData[valueIndex]) {
                                                let cellValue = props.otherLinguistAcceptedData[valueIndex][columnIndex].value

                                                defaultCellValue = cellValue
                                                actualValueForTable = cellValue
                                            }

                                            if (valueIndex in props.currentLinguistData && columnIndex in props.currentLinguistData[valueIndex]) {
                                                let cellData = props.currentLinguistData[valueIndex][columnIndex]

                                                /*if (props.submit) {*/
                                                    if (cellData.isAccepted) {
                                                        defaultCellValue = cellData.acceptedValue
                                                        actualValueForTable = cellData.acceptedValue
                                                    }

                                                    if (cellData.isBuffered) {
                                                        cellStyle = styles.bufferedCell
                                                        actualValueForTable = cellData.value
                                                    }
                                                /*} else {
                                                    actualValueForTable = cellData.value
                                                }*/
                                            }

                                            console.log("valueIndex !== key", valueIndex, columnIndex)

                                            if (valueIndex !== columnIndex) {
                                                rowCells.push(
                                                    <td key={columnIndex} className="whiteSpacePreLine"
                                                        contentEditable={true} style={cellStyle}
                                                        suppressContentEditableWarning={true}
                                                        dangerouslySetInnerHTML={{__html: actualValueForTable}}
                                                        onBlur={(e) =>
                                                            props.oninputLinguistCharacterCommunicationCell(
                                                                props.language, value.id, props.templateData[columnIndex].id, "input", e)}
                                                        onMouseOver={(e) => setTooltipText(defaultCellValue)}
                                                        onMouseOut={(e) => setTooltipText("")}>
                                                    </td>
                                                );
                                            } else {
                                                rowCells.push(
                                                    <td key={columnIndex} className="whiteSpacePreLine"
                                                        contentEditable={false}
                                                        style={styles.noEditableCell}
                                                        dangerouslySetInnerHTML={{__html: ""}}>
                                                    </td>
                                                );
                                            }
                                        }

                                        /*for (let i = 0; i < Object.keys(props.templateData).length; i++) {
                                            let cellStyle = styles.editedCell
                                            let actualValueForTable = ""
                                            let defaultCellValue = ""
                                            // let defaultLanguageCellValue = ""
                                            // let languageCellValue = ""

                                            // console.log("Linguist language tab character communications table i", props.templateData[i], i)

                                            /!*if (valueIndex in props.baseStyleGuideData && i in props.baseStyleGuideData[valueIndex]) {
                                                defaultCellValue = props.baseStyleGuideData[valueIndex][i]
                                                actualValueForTable = defaultCellValue
                                            }*!/

                                            if (valueIndex in props.templateData && i in props.templateData[valueIndex].data) {
                                                // defaultLanguageCellValue = props.templateData[valueIndex][i]
                                                let cellValue = props.templateData[valueIndex].data[i]

                                                defaultCellValue = cellValue
                                                actualValueForTable = cellValue
                                            }

                                            if (valueIndex in props.languageData && i in props.languageData[valueIndex]) {
                                                // languageCellValue = props.languageData[valueIndex][i]
                                                let cellValue = props.languageData[valueIndex][i]

                                                defaultCellValue = cellValue
                                                actualValueForTable = cellValue
                                            }

                                            if (valueIndex in props.otherLinguistAcceptedData && i in props.otherLinguistAcceptedData[valueIndex]) {
                                                // let acceptedFromLinguistData = props.otherLinguistAcceptedData[valueIndex][i]
                                                let cellValue = props.otherLinguistAcceptedData[valueIndex][i].value

                                                defaultCellValue = cellValue
                                                actualValueForTable = cellValue
                                                // actualValueForTable = acceptedFromLinguistData.value
                                                // acceptFromLinguist = acceptedFromLinguistData.linguist
                                            }

                                            // console.log("currentLinguistData", props.currentLinguistData)

                                            if (valueIndex in props.currentLinguistData && i in props.currentLinguistData[valueIndex]) {
                                                let cellData = props.currentLinguistData[valueIndex][i]

                                                // console.log("currentLinguistData", props.currentLinguistData)

                                                if (props.submit) {
                                                    if (cellData.isAccepted) {
                                                        // cellStyle = styles.acceptedCell
                                                        defaultCellValue = cellData.acceptedValue
                                                        actualValueForTable = cellData.acceptedValue
                                                    }

                                                    if (cellData.isBuffered) {
                                                        cellStyle = styles.bufferedCell
                                                        actualValueForTable = cellData.value
                                                    }
                                                } else {
                                                    actualValueForTable = cellData.value
                                                }
                                            }

                                            if (valueIndex !== i) {
                                                // console.log("CEEEEEELL", value, props.templateData[i])

                                                rowCells.push(
                                                    <td key={i} className="whiteSpacePreLine"
                                                        contentEditable={true} style={cellStyle}
                                                        suppressContentEditableWarning={true}
                                                        dangerouslySetInnerHTML={{__html: actualValueForTable}}
                                                        onBlur={(e) =>
                                                            props.oninputLinguistCharacterCommunicationCell(props.language,
                                                                /!*valueIndex + 1,*!/ value.id, /!*i + 1,*!/ props.templateData[i].id, "input", e/!*, languageCellValue*!/)}
                                                        onMouseOver={(e) => setTooltipText(defaultCellValue)}
                                                        onMouseOut={(e) => setTooltipText("")}>
                                                    </td>
                                                );

                                                /!*if (languageCellValue) {
                                                    rowCells.push(
                                                        <td key={i} className="whiteSpacePreLine"
                                                            contentEditable={true} style={styles.editedCell}
                                                            suppressContentEditableWarning={true}
                                                            dangerouslySetInnerHTML={{__html: languageCellValue}}
                                                            onBlur={(e) =>
                                                                props.oninputLinguistCharacterCommunicationCell(props.language,
                                                                    valueIndex + 1, i + 1, "input", e, languageCellValue)}
                                                            onMouseOver={(e) => setTooltipText(defaultCellValue)}
                                                            onMouseOut={(e) => setTooltipText("")}>
                                                        </td>
                                                    );
                                                } else {
                                                    rowCells.push(
                                                        <td key={i} className="whiteSpacePreLine"
                                                            contentEditable={true}
                                                            suppressContentEditableWarning={true}
                                                            dangerouslySetInnerHTML={{__html: defaultLanguageCellValue}}
                                                            onBlur={(e) =>
                                                                props.oninputLinguistCharacterCommunicationCell(props.language,
                                                                    valueIndex + 1, i + 1, "input", e, defaultLanguageCellValue)}
                                                            onMouseOver={(e) => setTooltipText(defaultCellValue)}
                                                            onMouseOut={(e) => setTooltipText("")}>
                                                        </td>
                                                    );
                                                }*!/

                                                /!*rowCells.push(
                                                    <td key={i} className="whiteSpacePreLine"
                                                        contentEditable={true}
                                                        suppressContentEditableWarning={true}
                                                        dangerouslySetInnerHTML={{__html: languageCellValue}}
                                                        onBlur={(e) =>
                                                            props.oninputLinguistCharacterCommunicationCell(props.language,
                                                                valueIndex + 1, i + 1, "input", e, languageCellValue)}
                                                        onMouseOver={(e) => setTooltipText(defaultCellValue)}
                                                        onMouseOut={(e) => setTooltipText("")}>
                                                    </td>
                                                );*!/
                                            } else {
                                                rowCells.push(
                                                    <td key={i} className="whiteSpacePreLine"
                                                        contentEditable={false}
                                                        style={styles.noEditableCell}
                                                        dangerouslySetInnerHTML={{__html: ""}}>
                                                    </td>
                                                );
                                            }
                                        }*/

                                        return (
                                            <tr key={valueIndex}>
                                                <th className="center align-middle" contentEditable={false}
                                                    style={styles.firstColumn}
                                                    dangerouslySetInnerHTML={{__html: value.name}}>
                                                </th>
                                                {
                                                    rowCells
                                                }
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}