import React, {useState} from "react";
import "./css/forms.css"
import queryString from "query-string";
import {Button, Form, FormControl} from "react-bootstrap";
import DateInput from 'react-input-date'
import { MultiSelect } from 'primereact/multiselect';

const styles = {
    formLabel: {
        marginBottom: '0px'
    },

    requiredAlert: {
        color: "#ff0000",
        display: "none"
    },

    multiselectStyle: {
        width: '100%'
    }
}

export default function CategoryFieldsForm(props) {
    let getFields = fields => {
        let content = []

        for (let key in fields) {
            let value = fields[key]

            content.push(
                <div key={key} className="row formFieldRow">
                    <div className="col-sm-12">
                        <Form>
                            <Form.Group className="mb-12">
                                <Form.Label style={styles.formLabel}>
                                    <strong>
                                        {value.title}
                                        {
                                            value.required &&
                                            <span className="redText">*</span>
                                        }
                                    </strong>
                                </Form.Label>
                                {
                                    "description" in value &&
                                    <>
                                        <br/>
                                        <Form.Text muted>
                                            {value.description}
                                            {
                                                value.type === "num" &&
                                                <strong>&nbsp;(only numbers, dots, and dashes are allowed)</strong>
                                            }
                                        </Form.Text>
                                    </>
                                }
                                { getFormField(key, value.type, "selectOptions" in value ? value.selectOptions : [], value.required) }
                            </Form.Group>
                        </Form>
                    </div>
                </div>
            )
        }

        return content
    };

    let getFormField = (id, type, selectOptions, required) => {
        let content = []

        if (type === "input") {
            content.push(
                <Form.Control
                    id={"field-" + props.category + "-" + id}
                    key={"field-" + props.category + "-" + id}
                    onBlur={(e) =>
                        validateInputText(id, type, e.target.value)} />
            )
        } else if (type === "textarea") {
            content.push(
                <Form.Control
                    id={"field-" + props.category + "-" + id}
                    key={"field-" + props.category + "-" + id}
                    as="textarea" rows={3}
                    onBlur={(e) =>
                        props.setFieldValue(props.category, id, type, e.target.value)} />
            )
        } else if (type === "num") {
            content.push(
                <Form.Control
                    id={"field-" + props.category + "-" + id}
                    key={"field-" + props.category + "-" + id}
                    onKeyPress={(e) => validateInputNum(e)}
                    onBlur={(e) =>
                        props.setFieldValue(props.category, id, type, e.target.value)} />
            )
        } else if (type === "checkbox") {
            content.push(
                <Form.Check
                    id={"field-" + props.category + "-" + id}
                    key={"field-" + props.category + "-" + id}
                    type="checkbox"
                    onChange={(e) =>
                        props.setFieldValue(props.category, id, type, e.target.checked)} />
            )
        } else if (type === "select") {
            content.push(
                <Form.Select
                    id={"field-" + props.category + "-" + id}
                    key={"field-" + props.category + "-" + id}
                    defaultValue="default"
                    onChange={(e) =>
                        props.setFieldValue(props.category, id, type, e.target.value)} >
                    <option value="default" disabled></option>
                    { getSelectOptions(selectOptions) }
                </Form.Select>
            )

            for (let key in selectOptions) {
                let value = selectOptions[key]

                if ("id" in value &&
                    props.category in props.filledValues &&
                    id in props.filledValues[props.category] &&
                    props.filledValues[props.category][id].value === key) {
                    if (value.type === "input") {
                        content.push(
                            <>
                                <br />
                                <Form.Control
                                    id={"field-" + props.category + "-" + id + "-" + value.id}
                                    key={"field-" + props.category + "-" + id + "-" + value.id}
                                    onBlur={(e) =>
                                        props.setAdditionalFieldValue(props.category, id, value.id, type, e.target.value)} />
                            </>
                        )
                    }

                    if (value.required) {
                        content.push(
                            <Form.Text
                                id={"red-required-text-" + props.category + "-" + id + "-" + value.id}
                                key={"red-required-text-" + props.category + "-" + id + "-" + value.id}
                                style={styles.requiredAlert} >
                                This is a required field, please fill it in
                            </Form.Text>
                        )
                    }
                }
            }
        } else if (type === "multiselect") {
            const multiselectOptions = []
            let selectedOptionsArray = []

            for (let key in selectOptions) {
                multiselectOptions.push({
                    label: key,
                    value: key
                })
            }

            if (props.category in props.filledValues &&
                id in props.filledValues[props.category]) {
                selectedOptionsArray = props.filledValues[props.category][id].value
            }

            content.push(
                <MultiSelect
                    id={"field-" + props.category + "-" + id}
                    key={"field-" + props.category + "-" + id}
                    value={selectedOptionsArray}
                    options={multiselectOptions}
                    style={styles.multiselectStyle}
                    className="multiselect-custom-style"
                    onChange={(e) =>
                        props.setFieldValue(props.category, id, type, e.value)}
                    maxSelectedLabels={1} />
            )
        } else if (type === "file") {
            content.push(
                <Form.Control
                    id={"field-" + props.category + "-" + id}
                    key={"field-" + props.category + "-" + id}
                    type="file"
                    onChange={(e) =>
                        props.setFieldValue(props.category, id, type, e.target.files)} />
            )
        }

        if (required) {
            content.push(
                <Form.Text
                    id={"red-required-text-" + props.category + "-" + id}
                    key={"red-required-text-" + props.category + "-" + id}
                    style={styles.requiredAlert} >
                    This is a required field, please fill it in
                </Form.Text>
            )
        }

        return content
    }

    let getSelectOptions = options => {
        let content = []

        for (let key in options) {
            // let value = options[key]

            content.push(
                <option key={key} value={key}>{key}</option>
            )
        }

        return content
    }

    function validateInputText(id, type, value) {
        // id.match(/email/i)
        if (id.toLowerCase().indexOf("email") !== -1 && !props.validateEmail(value)) {
            props.showAlertMessage("email")
        }

        props.setFieldValue(props.category, id, type, value)
    }

    function validateInputNum(event) {
        //8 Backspase
        //9 Tab
        //27 Escape
        //45 -
        //46 .

        if (event.charCode === 45 || event.charCode === 46 || event.charCode === 8 ||
            event.charCode === 9 || event.charCode === 27 ||
            // Разрешаем: Ctrl+A
            (event.charCode === 65 && event.ctrlKey === true) /*||
            // Разрешаем: home, end, влево, вправо
            (event.charCode >= 35 && event.charCode <= 39)*/) {

            // Ничего не делаем
            // return;
        } else {
            // Запрещаем все, кроме цифр на основной клавиатуре, а так же Num-клавиатуре
            if (event.charCode < 48 || event.charCode > 57) {
                event.preventDefault()
            }
        }
    }

    return(
        <>
            { getFields(props.data[props.category].fields) }
        </>
    )
}