import React, {useEffect, useState} from "react";
import {Button, Nav, Tab, Table, Tabs, Row, Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import BlockRows from "./BlockRows";

const styles = {
    tableHeaderRow: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    noEditableCell: {
        backgroundColor: '#fafafa',
    },

    editableCell: {
        backgroundColor: '#fff',
    },

    nowrapCell: {
        backgroundColor: '#fafafa',
        whiteSpace: 'nowrap'
    },
}

export default function Block(props) {
    let blocksData = props.data.blocks

    return(
        <div>
            <div className="row">
                <div className="col-sm-12">
                    <Tab.Container defaultActiveKey={"blocks"}>
                        <Row>
                            <Col sm={12}>
                                <Nav variant="pills" className="nav-custom-grey">
                                    <Nav.Item>
                                        <Nav.Link eventKey={"blocks"}>
                                            Table
                                        </Nav.Link>
                                    </Nav.Item>
                                    {
                                        props.blockSequence.map(info => {
                                            let blockCode = info.code
                                            let blockName = blocksData[blockCode].blockName
                                            let blockRows = blocksData[blockCode].rows

                                            return(
                                                <Nav.Item key={blockCode}>
                                                    <Nav.Link eventKey={blockCode}>
                                                        {blockName}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            )
                                        })
                                    }
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey={"blocks"}>
                                        <div className="tab-item-wrapper">
                                            <br/>
                                            <div className="row">
                                                <div className="col-sm-4 table-blue-thead-not-fixed">
                                                    <Table responsive bordered>
                                                        <thead>
                                                        <tr style={styles.tableHeaderRow}>
                                                            <th className="center" style={styles.tableHeaderRow}>№</th>
                                                            <th className="center" style={styles.tableHeaderRow}>Block
                                                            </th>
                                                            <th className="center"
                                                                style={styles.tableHeaderRow}>Action
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            props.blockSequence.map((info, index) => {
                                                                let blockCode = info.code
                                                                let blockName = blocksData[blockCode].blockName

                                                                return (
                                                                    <tr key={blockCode}>
                                                                        <td contentEditable={false}
                                                                            style={styles.noEditableCell}>
                                                                            {index + 1}
                                                                        </td>
                                                                        <td className="whiteSpacePreLine"
                                                                            contentEditable={true}
                                                                            suppressContentEditableWarning={true}
                                                                            style={styles.editableCell}
                                                                            dangerouslySetInnerHTML={{__html: blockName}}
                                                                            onBlur={(e) =>
                                                                                props.editBlockTitle(props.techProcessCode, blockCode, e)}>
                                                                        </td>
                                                                        <td contentEditable={false}
                                                                            style={styles.nowrapCell}>
                                                                            {
                                                                                index !== 0 &&
                                                                                <>
                                                                                    <Button
                                                                                        size="sm"
                                                                                        variant="primary"
                                                                                        className="btn btn-blue"
                                                                                        onClick={(e) =>
                                                                                            props.moveUpBlock(props.techProcessCode, blockCode)}>
                                                                                        <FontAwesomeIcon
                                                                                            icon={faChevronUp}/>
                                                                                    </Button>
                                                                                    &nbsp;
                                                                                </>
                                                                            }
                                                                            {
                                                                                index !== props.blockSequence.length - 1 &&
                                                                                <>
                                                                                    <Button
                                                                                        size="sm"
                                                                                        variant="primary"
                                                                                        className="btn btn-blue"
                                                                                        onClick={(e) =>
                                                                                            props.moveDownBlock(props.techProcessCode, blockCode)}>
                                                                                        <FontAwesomeIcon
                                                                                            icon={faChevronDown}/>
                                                                                    </Button>
                                                                                    &nbsp;
                                                                                </>
                                                                            }
                                                                            <Button
                                                                                size="sm"
                                                                                variant="success"
                                                                                onClick={(e) =>
                                                                                    props.addBlock(props.techProcessCode, blockCode)}>
                                                                                <FontAwesomeIcon icon={faPlus}/>
                                                                            </Button>
                                                                            &nbsp;
                                                                            <Button
                                                                                size="sm"
                                                                                variant="danger"
                                                                                onClick={(e) =>
                                                                                    props.deleteBlock(props.techProcessCode, blockCode)}>
                                                                                <FontAwesomeIcon icon={faTimes}/>
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        </tbody>
                                                    </Table>
                                                    <br/>
                                                    <Button
                                                        variant="primary" className="btn btn-blue"
                                                        onClick={(e) =>
                                                            props.addBlockToTheEnd(props.techProcessCode)}>
                                                        <FontAwesomeIcon icon={faPlus}/> Add
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    {
                                        props.blockSequence.map(info => {
                                            let blockCode = info.code
                                            let blockName = blocksData[blockCode].blockName
                                            let blockRows = blocksData[blockCode].rows

                                            return(
                                                <Tab.Pane key={blockCode} eventKey={blockCode}>
                                                    <div className="tab-item-wrapper">
                                                        <br/>
                                                        <BlockRows
                                                            techProcessCode={props.techProcessCode}
                                                            blockCode={blockCode}
                                                            rows={blockRows}
                                                            rowsSequence={props.rowsSequence[blockCode]}
                                                            columns={props.data.columns}
                                                            columnSequence={props.columnSequence}
                                                            responsible={props.data.responsible}
                                                            openModalForTags={props.openModalForTags}
                                                            openModalForResponsibles={props.openModalForResponsibles}
                                                            updateBlockRowColumnValue={props.updateBlockRowColumnValue}
                                                            deleteRow={props.deleteRow}
                                                            addRow={props.addRow}
                                                            addRowToTheEnd={props.addRowToTheEnd}
                                                            moveUpRow={props.moveUpRow}
                                                            moveDownRow={props.moveDownRow}
                                                            moveRow={props.moveRow}
                                                            associativeArrayOfTagsById={props.associativeArrayOfTagsById}
                                                        />
                                                    </div>
                                                </Tab.Pane>
                                            )
                                        })
                                    }
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </div>
    )
}