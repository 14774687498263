import "../css/modal.css"
import {Col, Form, FormCheck, FormControl, FormLabel, Image, Row, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Tab} from "bootstrap";

const styles = {
    modalWH: {
        width: '75%',
        height: '85%'
    },

    imgDivStyle: {
        width: '100%',
        height: '100%'
    },

    imgStyle: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain'
    }
}

export default function OpenInModalAddedImage(props) {
    /*let pathValue = props.modalOpenAddedImageActive ?
        props.modalOpenAddedImageSrc :
        'error.jpg'*/

    return(
        <div className={props.modalOpenAddedImageActive ? "modal active" : "modal"} onClick={() => props.setModalOpenAddedImageActive(false)} >
            <div className={props.modalOpenAddedImageActive ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}
                 style={styles.modalWH} >
                <div className="row">
                    <div className="col-sm-12 center" style={styles.imgDivStyle}>
                        {
                            props.modalOpenAddedImageActive ?
                                <img src={require(`../../../../../../img/pb${props.modalOpenAddedImageSrc}`).default}
                                     style={styles.imgStyle} />
                                :
                                <img src={require(`../img/error.jpg`).default} style={styles.imgStyle} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}