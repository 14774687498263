import "../css/modal.css"
import {FormCheck} from "react-bootstrap";
import React, {useEffect, useState} from "react";

const styles = {
    tableCell: {
        background: '#fff'
    }
}

export default function ModalCellDataHistoryList(props) {
    // console.log("ModalCellDataHistoryList", props)

    return(
        <>
            {
                props.data.map((value, key) => {
                    let tstampArray = value.tstamp.split("T")
                    let tstamp = tstampArray[0] + " " + tstampArray[1].substring(0, 8)
                    let isNotAcceptedFromClientValue = "notAcceptedValuesFromClient" in value && value.notAcceptedValuesFromClient
                    let backgroundStyle = isNotAcceptedFromClientValue ? 'rgb(239,239,239)' : '#fff'
                    let showCheckboxField = true
                    let changedByText = []

                    if ("changeAcceptedByClient" in value["changed_by"] &&
                        value["changed_by"].changeAcceptedByClient &&
                        (!("client" in value["changed_by"]) ||
                            ("client" in value["changed_by"] && !value["changed_by"].client))) {
                        backgroundStyle = 'rgb(220,255,204)'
                        showCheckboxField = false
                        changedByText.push(
                            <>Client accepted the changes</>
                        )
                    } else if (isNotAcceptedFromClientValue) {
                        showCheckboxField = false
                        changedByText.push(
                            <>Change by Client, not accepted</>
                        )
                    } else {
                        changedByText.push(
                            <>
                                <a href={"https://b24.allcorrectgames.com/company/personal/user/" + value["changed_by"].id + "/"}
                                   title={value["changed_by"].name} target="_blank">
                                    {value["changed_by"].name}
                                </a>
                                {
                                    "client" in value["changed_by"] && value["changed_by"].client &&
                                    <>
                                        <br />
                                        accepted changes from Client
                                    </>
                                }
                            </>
                        )
                    }

                    if (value["data_type"] === "string" || value["data_type"] === "date") {
                        console.log("string/date value", value, value.value, "isOtherValueChecked", value.isOtherValueChecked)

                        return (
                            <tr key={key}>
                            <td contentEditable={false} className="center align-middle" style={{width: '5%', background: backgroundStyle}}>
                                    {
                                        showCheckboxField &&
                                        <FormCheck checked={value.isOtherValueChecked}
                                                   onChange={(e) =>
                                                       props.selectNewValue(+key, e.target.checked, value.value[0])}/>
                                    }
                                </td>
                                <td className="center align-middle" contentEditable={false} style={{width: '15%', background: backgroundStyle}}>
                                    {changedByText}
                                    {/*{
                                        "changeAcceptedByClient" in value["changed_by"] &&
                                        value["changed_by"].changeAcceptedByClient ? (
                                            <>
                                                Client accepted the changes
                                            </>
                                        ) : (
                                            <>
                                                {
                                                    isNotAcceptedFromClientValue ? (
                                                        <>Change by Client, not accepted</>
                                                    ) : (
                                                        <>
                                                            <a href={"https://b24.allcorrectgames.com/company/personal/user/" + value["changed_by"].id + "/"}
                                                               title={value["changed_by"].name} target="_blank">
                                                                {value["changed_by"].name}
                                                            </a>
                                                            {
                                                                "client" in value["changed_by"] && value["changed_by"].client &&
                                                                <>
                                                                    <br />
                                                                    accepted changes from Client
                                                                </>
                                                            }
                                                        </>
                                                    )
                                                }
                                            </>
                                        )
                                    }*/}
                                </td>
                                <td className="whiteSpacePreLine" contentEditable={false} style={{width: '60%', background: backgroundStyle}}
                                    dangerouslySetInnerHTML={{__html: value.value[0]}}></td>
                                <td contentEditable={false} style={{width: '20%', background: backgroundStyle}}>{tstamp}</td>
                            </tr>
                        )
                    } else if (value["data_type"] === "bool") {
                        console.log("bool value", value, value.value)

                        return (
                            <tr key={key}>
                                <td contentEditable={false} className="center align-middle" style={{width: '5%', background: backgroundStyle}}>
                                    {
                                        !isNotAcceptedFromClientValue &&
                                        <FormCheck checked={value.isOtherValueChecked}
                                                   onChange={(e) =>
                                                       props.selectNewValue(+key, e.target.checked, value.value[0])}/>
                                    }
                                </td>
                                <td className="center align-middle" contentEditable={false} style={{width: '15%', background: backgroundStyle}}>
                                    {
                                        isNotAcceptedFromClientValue ? (
                                            <>Client</>
                                        ) : (
                                            <a href={"https://b24.allcorrectgames.com/company/personal/user/" + value["changed_by"].id + "/"}
                                               title={value["changed_by"].name} target="_blank">
                                                {value["changed_by"].name}
                                            </a>
                                        )
                                    }
                                </td>
                                <td className="center align-middle whiteSpacePreLine" contentEditable={false}
                                    style={{width: '35%', background: backgroundStyle}}
                                    dangerouslySetInnerHTML={{__html: value.value[0] ? "✅" : "❌"}}></td>
                                <td contentEditable={false} style={{width: '45%', background: backgroundStyle}}>{tstamp}</td>
                            </tr>
                        )
                    }
                })
            }
        </>
    )
}