import React, {useEffect, useState} from "react";
import queryString from "query-string";
import {Accordion, Button, Form, ProgressBar} from "react-bootstrap";
import ProjectStagesTabs from "./ProjectStagesTabs";
import "../css/ps.css";
import ModalCellDataHistory from "../ModalCellDataHistory";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserTie} from "@fortawesome/free-solid-svg-icons";

const styles = {
    blockView: {
        border: '1px solid #ccc',
        padding: '1rem',
        borderRadius: '5px',
        margin: '0px'
    },

    progressBar: {
        margin: '10rem 10rem'
    },
}

export default function ProjectStagesView(props) {
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [progressPercent, setProgressPercent] = useState(0)
    const [projectInfo, setProjectInfo] = useState(false)
    const [orderNumberInfo, setOrderNumberInfo] = useState(false)
    const [subordersInfo, setSubordersInfo] = useState([])
    const queryStringParams = queryString.parse(window.location.search)
    const project = queryStringParams.project
    const order = queryStringParams.order
    const service = queryStringParams.service
    const [projectData, setProjectData] = useState([{
        data: []
    }])
    const [techProcessList, setTechProcessList] = useState([{
        data: {},
        reverseData: {}
    }])
    const [modalOpenCellDataHistoryActive, setModalOpenCellDataHistoryActive] = useState(false);
    const [modalOpenCellDataHistoryData, setModalOpenCellDataHistoryData] = useState([{
        modalTitle: "",
        tableName: "",
        saveMethod: "",
        identityData: [],
        data: []
    }]);

    let cellTechProcessOnCount = 0
    const authorizationCookieName = "b24_authorization_log"
    const authorizationCookieMatches = document.cookie.match(new RegExp(
        "(?:^|; )" + authorizationCookieName.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    const employeeId = authorizationCookieMatches ?
        +JSON.parse(window.atob(decodeURIComponent(authorizationCookieMatches[1]))).userId :
        window.location.href.split("/")[2] === "localhost:3000" ? 191 : 0
    const employeeName = authorizationCookieMatches ?
        JSON.parse(window.atob(decodeURIComponent(authorizationCookieMatches[1]))).name :
        window.location.href.split("/")[2] === "localhost:3000" ? "Daria Arisova" : ""
    const [listOfTechProcessCode, setListOfTechProcessCode] = useState([{
        data: []
    }])

    useEffect(async () => {
        if (/*await lookIp()*/checkCookie()) {
            deleteRedirectCookie()

            await fetch("/proxy/project_bible_template/projectStagesTemplateTechProcessAll", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    async (resultTechProcesses) => {
                        setProjectData(
                            projectData.map(value => {
                                for (let i = 0; i < resultTechProcesses.length; i++) {
                                    value.data.push({
                                        code: resultTechProcesses[i].code,
                                        name1c: resultTechProcesses[i].name_1c,
                                        tabName: resultTechProcesses[i].tab_name,
                                        columns: [],
                                        blocks: []
                                    })
                                }

                                return value
                            })
                        )

                        setTechProcessList(
                            techProcessList.map(info => {
                                for (let i = 0; i < resultTechProcesses.length; i++) {
                                    info.data[resultTechProcesses[i].code] = resultTechProcesses[i].name_1c
                                    info.reverseData[resultTechProcesses[i].name_1c] = resultTechProcesses[i].code
                                }

                                return info
                            })
                        )

                        const response = await fetch('/proxy/project_stages', {
                            method: 'POST',
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                project: project,
                                order: order,
                                service: service
                            })
                        });
                        let result = await response.json();

                        await fetch("/proxy/project_bible_template/addProjectStages", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": result.response.Client,
                                "projectName": result.response.Project,
                                "projectCode": result.response.ProjectCode,
                                "orderNumber": result.response.OrderNumber,
                                "project": project,
                                "order": order,
                                "service": service
                            }),
                        })
                            .then(res => res.json())
                            .then(
                                async (resultAdd) => {
                                    if (!resultAdd.exist && order !== "00000000-0000-0000-0000-000000000000") {
                                        await setSpecRequirementsFromTemplate()
                                    }

                                    if (resultAdd.exist && !resultAdd.specRequirementsSent) {
                                        await sentInfoAboutRowsInWorkflow(result.response.TechProcess)
                                    }

                                    setProjectInfo(result.response.Project)
                                    setOrderNumberInfo(result.response.OrderNumber)
                                    setSubordersInfo(result.response.Suborders)

                                    if (resultAdd.isNeedToSetSpecRequirementsTemplate) {
                                        for (let i = 0; i < result.response.TechProcess.length; i++) {
                                            let techProcessFrom1C = decodeURIComponent(result.response.TechProcess[i].TechProcessService)

                                            setListOfTechProcessCode(
                                                listOfTechProcessCode.map(info => {
                                                    info.data.push(techProcessList[0].reverseData[techProcessFrom1C])

                                                    return info
                                                })
                                                // listOfTechProcessCode.push(techProcessList[0].reverseData[techProcessFrom1C])
                                            )
                                        }

                                        await setSpecRequirementsTemplate(order, order)
                                    }

                                    fillStagesData(result.response)
                                },
                                (error) => {
                                    setIsLoaded(true);
                                    setError({
                                        message: "Error on added current workflow to db. Please reload and try again."
                                    });
                                }
                            )
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                )
        } else {
            // let link = window.location.href.split("/")
            // window.location.replace('http://' + link[2] + '/autorization')

            setRedirectCookie()
            window.location.replace('https://b24.allcorrectgames.com');
        }
    },[])

    async function setSpecRequirementsTemplate(fromOrder, toOrder, message){
        await fetch("/proxy/project_bible_template/projectStagesSetSpecRequirementsTemplate", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "project": project,
                "fromOrder": fromOrder,
                "toOrder": toOrder,
                "service": service
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    if (message) {
                        alert(message)
                    }
                },
                (error) => {

                }
            )
    }

    async function setSpecRequirementsFromTemplate() {
        await fetch("/proxy/project_bible_template/projectStagesSetSpecRequirementsFromTemplate", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "project": project,
                "order": order,
                "service": service
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    window.location.reload()
                },
                (error) => {

                }
            )
    }

    async function sentInfoAboutRowsInWorkflow(techProcessesList) {
        let techProcessArray = []

        for (let i = 0; i < techProcessesList.length; i++) {
            techProcessArray.push(decodeURIComponent(techProcessesList[i].TechProcessService))
        }

        await fetch("/proxy/project_bible_template/getSpecialRequirementsRowsFromProjectStages", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcessArray": techProcessArray
            }),
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    let specRequirements = {
                        type: 1,
                        project: project,
                        order: order,
                        service: service,
                        data: []
                    }
                    let associativeArray = {}
                    let associativeColumnsArray = {}

                    for (let techProcessCode in result.data) {
                        let techProcessValue = result.data[techProcessCode]
                        let associativeBlockRowsArray = {}

                        associativeColumnsArray[techProcessCode] = {}

                        for (let i = 0; i < techProcessValue.blockList.length; i++) {
                            let blockValue = techProcessValue.blockList[i]

                            associativeBlockRowsArray[blockValue.code] = {}
                        }

                        for (let i = 0; i < techProcessValue.rowList.length; i++) {
                            let rowValue = techProcessValue.rowList[i]

                            if (rowValue.block in associativeBlockRowsArray) {
                                associativeBlockRowsArray[rowValue.block][rowValue.code] = {}
                            }
                        }

                        for (let i = 0; i < techProcessValue.columnList.length; i++) {
                            let columnValue = techProcessValue.columnList[i]

                            if (columnValue.type === "checkbox") {
                                associativeColumnsArray[techProcessCode]["special_requirements"] = columnValue.code
                            } else if (columnValue.type === "input") {
                                associativeColumnsArray[techProcessCode]["text"] = columnValue.code
                            }
                        }

                        for (let i = 0; i < techProcessValue.textData.length; i++) {
                            let textValue = techProcessValue.textData[i]

                            if (textValue.block in associativeBlockRowsArray &&
                                textValue.row_code in associativeBlockRowsArray[textValue.block] &&
                                textValue.col_code === associativeColumnsArray[techProcessCode]["text"]) {
                                associativeBlockRowsArray[textValue.block][textValue.row_code][textValue.col_code] =
                                    textValue.value
                            }
                        }

                        for (let i = 0; i < techProcessValue.boolData.length; i++) {
                            let boolValue = techProcessValue.boolData[i]

                            if (boolValue.block in associativeBlockRowsArray &&
                                boolValue.row_code in associativeBlockRowsArray[boolValue.block] &&
                                boolValue.col_code === associativeColumnsArray[techProcessCode]["special_requirements"]) {
                                if (!(techProcessCode in associativeArray)) {
                                    associativeArray[techProcessCode] = {}
                                }

                                if (!(boolValue.block in associativeArray[techProcessCode])) {
                                    associativeArray[techProcessCode][boolValue.block] = {}
                                }

                                if (!(boolValue.row_code in associativeArray[techProcessCode][boolValue.block])) {
                                    associativeArray[techProcessCode][boolValue.block][boolValue.row_code] = {}
                                }

                                associativeArray[techProcessCode][boolValue.block][boolValue.row_code] =
                                    associativeBlockRowsArray[boolValue.block][boolValue.row_code]
                            }
                        }
                    }

                    for (let techProcessCode in associativeArray) {
                        let techProcessValue = associativeArray[techProcessCode]
                        let techProcess1cName = techProcessList[0].data[techProcessCode]
                        let techProcessData = {
                            techProcess: techProcess1cName,
                            data: []
                        }

                        for (let blockCode in techProcessValue) {
                            let blockValue = techProcessValue[blockCode]
                            let blockData = []

                            for (let rowCode in blockValue) {
                                let textColumnCode = associativeColumnsArray[techProcessCode]["text"]
                                let textColumnValue = ""

                                if (textColumnCode in blockValue[rowCode]) {
                                    textColumnValue = blockValue[rowCode][textColumnCode]
                                }

                                blockData.push({
                                    rowCode: +rowCode,
                                    completeCheck: false,
                                    text: textColumnValue,
                                    midline: ""
                                })
                            }

                            techProcessData.data.push({
                                block: +blockCode,
                                data: blockData
                            })
                        }

                        specRequirements.data.push(techProcessData)

                        const response = await fetch('/proxy/workflow_spec_requirements', {
                            method: 'POST',
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                specRequirements: specRequirements
                            })
                        });
                        let result = await response.json();

                        if ("error" in result) {
                            alert("Произошла ошибка при отправке данных, касающихся спецтребований, в 1С")
                        } else {
                            await setSpecRequirementsSent()
                        }
                    }
                },
                (error) => {
                    alert("Ошибка при получении строк спецтребований перед отправкой их в 1С. Перезагрузите страницу, чтобы процесс запустился заново")
                }
            )
    }

    async function setSpecRequirementsSent() {
        await fetch("/proxy/project_bible_template/projectStagesSetSpecRequirementsSent", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "project": project,
                "order": order,
                "service": service
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {

                },
                (error) => {
                    // setIsLoaded(true);
                    // setError(error);
                }
            )
    }

    function checkCookie() {
        let name = "testCookie"

        document.cookie = "testCookie=test; max-age=3600";

        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));

        if (!matches) {
            alert("Allow cookies in your browser settings for correct work.")

            return false
        } else {
            return employeeId > 0
        }
    }

    function setRedirectCookie() {
        let cookieName =  'apps_allcorrect_redirect'
        let cookieValue = window.btoa(window.location.href)
        let date = new Date(Date.now() + 1800e3);
        let options = {
            path: '/',
            domain: 'allcorrectgames.com',
            expires: date.toUTCString(),
            samesite: 'lax'
        };
        let updatedCookie = cookieName + "=" + cookieValue;

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;

            let optionValue = options[optionKey];

            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    function deleteRedirectCookie() {
        let cookieName =  'apps_allcorrect_redirect'
        let cookieValue = 'val'
        let date = new Date();

        date.setMonth(date.getMonth() - 1);

        let options = {
            path: '/',
            domain: 'allcorrectgames.com',
            expires: date.toUTCString(),
            samesite: 'lax'
        };
        let updatedCookie = cookieName + "=" + cookieValue;

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;

            let optionValue = options[optionKey];

            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    function fillStagesData(resultResponse) {
        for (let i = 0; i < resultResponse.TechProcess.length; i++) {
            let techProcessFrom1C = decodeURIComponent(resultResponse.TechProcess[i].TechProcessService)
            let techProcessTags = []

            for (let j = 0; j < resultResponse.TechProcess[i].Tags.length; j++) {
                techProcessTags.push(resultResponse.TechProcess[i].Tags[j].ID)
            }

            setProjectData(
                projectData.map(value => {
                    value.data.map(async techProcessValue => {
                        if (techProcessValue.name1c === techProcessFrom1C) {
                            await fetch("/proxy/project_bible_template/projectStagesTemplateBlocksByTechProcess", {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "techProcess": techProcessValue.code
                                }),
                            })
                                .then(res => res.json())
                                .then(
                                    async (resultBlocks) => {
                                        await fetch("/proxy/project_bible_template/projectStagesTemplateRowsByTechProcess", {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                                "techProcess": techProcessValue.code
                                            }),
                                        })
                                            .then(res => res.json())
                                            .then(
                                                async (resultRows) => {
                                                    await fetch("/proxy/project_bible_template/projectStagesExtraSpecReqRowsByTechProcess", {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-Type': 'application/json'
                                                        },
                                                        body: JSON.stringify({
                                                            "project": project,
                                                            "order": order,
                                                            "service": service,
                                                            "techProcess": techProcessValue.code
                                                        }),
                                                    })
                                                        .then(res => res.json())
                                                        .then(
                                                            async (resultExtraSpecReqRows) => {
                                                                await fetch("/proxy/project_bible_template/projectStagesTemplateColumnsByTechProcess", {
                                                                    method: 'POST',
                                                                    headers: {
                                                                        'Content-Type': 'application/json'
                                                                    },
                                                                    body: JSON.stringify({
                                                                        "techProcess": techProcessValue.code
                                                                    }),
                                                                })
                                                                    .then(res => res.json())
                                                                    .then(
                                                                        async (resultColumns) => {
                                                                            await fetch("/proxy/project_bible_template/projectStagesTemplateResponsiblesByTechProcess", {
                                                                                method: 'POST',
                                                                                headers: {
                                                                                    'Content-Type': 'application/json'
                                                                                },
                                                                                body: JSON.stringify({
                                                                                    "techProcess": techProcessValue.code
                                                                                }),
                                                                            })
                                                                                .then(res => res.json())
                                                                                .then(
                                                                                    async (resultResponsible) => {
                                                                                        await fetch("/proxy/project_bible_template/projectStagesTemplateDataByTechProcess", {
                                                                                            method: 'POST',
                                                                                            headers: {
                                                                                                'Content-Type': 'application/json'
                                                                                            },
                                                                                            body: JSON.stringify({
                                                                                                "techProcess": techProcessValue.code
                                                                                            }),
                                                                                        })
                                                                                            .then(res => res.json())
                                                                                            .then(
                                                                                                async (resultTemplateData) => {
                                                                                                    await fetch("/proxy/project_bible_template/projectStagesBlockDataGetAllByTechProcess", {
                                                                                                        method: 'POST',
                                                                                                        headers: {
                                                                                                            'Content-Type': 'application/json'
                                                                                                        },
                                                                                                        body: JSON.stringify({
                                                                                                            "project": project,
                                                                                                            "order": order,
                                                                                                            "service": service,
                                                                                                            "techProcess": techProcessValue.code
                                                                                                        }),
                                                                                                    })
                                                                                                        .then(res => res.json())
                                                                                                        .then(
                                                                                                            async (resultBlockData) => {
                                                                                                                await fetch("/proxy/project_bible_template/projectStagesBlockRowDataCommentGetAllByTechProcess", {
                                                                                                                    method: 'POST',
                                                                                                                    headers: {
                                                                                                                        'Content-Type': 'application/json'
                                                                                                                    },
                                                                                                                    body: JSON.stringify({
                                                                                                                        "project": project,
                                                                                                                        "order": order,
                                                                                                                        "service": service,
                                                                                                                        "techProcess": techProcessValue.code
                                                                                                                    }),
                                                                                                                })
                                                                                                                    .then(res => res.json())
                                                                                                                    .then(
                                                                                                                        async (resultBlockRowCommentData) => {
                                                                                                                            await fetch("/proxy/project_bible_template/projectStagesBlockRowDataAcceptGetAllByTechProcess", {
                                                                                                                                method: 'POST',
                                                                                                                                headers: {
                                                                                                                                    'Content-Type': 'application/json'
                                                                                                                                },
                                                                                                                                body: JSON.stringify({
                                                                                                                                    "project": project,
                                                                                                                                    "order": order,
                                                                                                                                    "service": service,
                                                                                                                                    "techProcess": techProcessValue.code
                                                                                                                                }),
                                                                                                                            })
                                                                                                                                .then(res => res.json())
                                                                                                                                .then(
                                                                                                                                    async (resultBlockRowAcceptData) => {
                                                                                                                                        await fetch("/proxy/project_bible_template/projectStagesBlockRowResponsibleGetAllByTechProcess", {
                                                                                                                                            method: 'POST',
                                                                                                                                            headers: {
                                                                                                                                                'Content-Type': 'application/json'
                                                                                                                                            },
                                                                                                                                            body: JSON.stringify({
                                                                                                                                                "project": project,
                                                                                                                                                "order": order,
                                                                                                                                                "service": service,
                                                                                                                                                "techProcess": techProcessValue.code
                                                                                                                                            }),
                                                                                                                                        })
                                                                                                                                            .then(res => res.json())
                                                                                                                                            .then(
                                                                                                                                                async (resultBlockRowResponsibleData) => {
                                                                                                                                                    await fetch("/proxy/project_bible_template/projectStagesBlockRowBoolGetAllByTechProcess", {
                                                                                                                                                        method: 'POST',
                                                                                                                                                        headers: {
                                                                                                                                                            'Content-Type': 'application/json'
                                                                                                                                                        },
                                                                                                                                                        body: JSON.stringify({
                                                                                                                                                            "project": project,
                                                                                                                                                            "order": order,
                                                                                                                                                            "service": service,
                                                                                                                                                            "techProcess": techProcessValue.code
                                                                                                                                                        }),
                                                                                                                                                    })
                                                                                                                                                        .then(res => res.json())
                                                                                                                                                        .then(
                                                                                                                                                            async (resultBlockRowBoolData) => {
                                                                                                                                                                await fetch("/proxy/project_bible_template/projectStagesBlockRowDateGetAllByTechProcess", {
                                                                                                                                                                    method: 'POST',
                                                                                                                                                                    headers: {
                                                                                                                                                                        'Content-Type': 'application/json'
                                                                                                                                                                    },
                                                                                                                                                                    body: JSON.stringify({
                                                                                                                                                                        "project": project,
                                                                                                                                                                        "order": order,
                                                                                                                                                                        "service": service,
                                                                                                                                                                        "techProcess": techProcessValue.code
                                                                                                                                                                    }),
                                                                                                                                                                })
                                                                                                                                                                    .then(res => res.json())
                                                                                                                                                                    .then(
                                                                                                                                                                        async (resultBlockRowDateData) => {
                                                                                                                                                                            await fetch("/proxy/project_bible_template/projectStagesExtraSpecReqRowsDataTextByTechProcess", {
                                                                                                                                                                                method: 'POST',
                                                                                                                                                                                headers: {
                                                                                                                                                                                    'Content-Type': 'application/json'
                                                                                                                                                                                },
                                                                                                                                                                                body: JSON.stringify({
                                                                                                                                                                                    "project": project,
                                                                                                                                                                                    "order": order,
                                                                                                                                                                                    "service": service,
                                                                                                                                                                                    "techProcess": techProcessValue.code
                                                                                                                                                                                }),
                                                                                                                                                                            })
                                                                                                                                                                                .then(res => res.json())
                                                                                                                                                                                .then(
                                                                                                                                                                                    (resultBlockExtraSpecReqRowDataText) => {
                                                                                                                                                                                        let arrayResponsible = {}

                                                                                                                                                                                        for (let j = 0; j < resultColumns.length; j++) {
                                                                                                                                                                                            if (resultColumns[j].code !== 1 && resultColumns[j].code !== 3 &&
                                                                                                                                                                                                resultColumns[j].code !== 11 && resultColumns[j].code !== 15) {
                                                                                                                                                                                                techProcessValue.columns.push({
                                                                                                                                                                                                    code: resultColumns[j].code,
                                                                                                                                                                                                    name: resultColumns[j].name,
                                                                                                                                                                                                    type: resultColumns[j].type,
                                                                                                                                                                                                    col_special_requirements: resultColumns[j].col_special_requirements,
                                                                                                                                                                                                    col_task: resultColumns[j].col_task
                                                                                                                                                                                                })
                                                                                                                                                                                            }
                                                                                                                                                                                        }

                                                                                                                                                                                        for (let j = 0; j < resultBlocks.length; j++) {
                                                                                                                                                                                            techProcessValue.blocks[resultBlocks[j].code] = {
                                                                                                                                                                                                code: resultBlocks[j].code,
                                                                                                                                                                                                name: resultBlocks[j].name,
                                                                                                                                                                                                comment: "",
                                                                                                                                                                                                rows: []
                                                                                                                                                                                            }
                                                                                                                                                                                        }

                                                                                                                                                                                        techProcessValue.blocks[0] = {
                                                                                                                                                                                            code: 0,
                                                                                                                                                                                            name: "Extra",
                                                                                                                                                                                            rows: []
                                                                                                                                                                                        }

                                                                                                                                                                                        for (let j = 0; j < resultResponsible.length; j++) {
                                                                                                                                                                                            arrayResponsible[resultResponsible[j].code] = resultResponsible[j].title
                                                                                                                                                                                        }

                                                                                                                                                                                        for (let j = 0; j < resultRows.length; j++) {
                                                                                                                                                                                            if (techProcessTags.filter(el => resultRows[j].tags.indexOf(el) > -1).length > 0) {
                                                                                                                                                                                                if (resultRows[j].block in techProcessValue.blocks) {
                                                                                                                                                                                                    techProcessValue.blocks[resultRows[j].block].rows[resultRows[j].code] = {
                                                                                                                                                                                                        code: resultRows[j].code,
                                                                                                                                                                                                        tags: resultRows[j].tags,
                                                                                                                                                                                                        responsible: getResponsibleTitles(arrayResponsible, resultRows[j].responsible, resultResponse.ResponsibleOrder[0]),
                                                                                                                                                                                                        columns: [],
                                                                                                                                                                                                        comment: "",
                                                                                                                                                                                                        num: resultRows[j].num,
                                                                                                                                                                                                        accept: false
                                                                                                                                                                                                    }

                                                                                                                                                                                                    for (let k = 0; k < resultColumns.length; k++) {
                                                                                                                                                                                                        if (resultColumns[k].code !== 1 && resultColumns[k].code !== 3 &&
                                                                                                                                                                                                            resultColumns[k].code !== 11 && resultColumns[k].code !== 15) {
                                                                                                                                                                                                            let columnValue

                                                                                                                                                                                                            if (resultColumns[k].type === "input") {
                                                                                                                                                                                                                columnValue = ""
                                                                                                                                                                                                            } else if (resultColumns[k].type === "bool") {
                                                                                                                                                                                                                columnValue = false
                                                                                                                                                                                                            } else if (resultColumns[k].type === "date") {
                                                                                                                                                                                                                columnValue = ""
                                                                                                                                                                                                            }

                                                                                                                                                                                                            techProcessValue.blocks[resultRows[j].block].rows[resultRows[j].code].columns[resultColumns[k].code] = {
                                                                                                                                                                                                                code: resultColumns[k].code,
                                                                                                                                                                                                                value: columnValue,
                                                                                                                                                                                                                type: resultColumns[k].type,
                                                                                                                                                                                                                col_special_requirements: resultColumns[k].col_special_requirements,
                                                                                                                                                                                                                col_task: resultColumns[k].col_task,
                                                                                                                                                                                                            }
                                                                                                                                                                                                        }
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                            } else {
                                                                                                                                                                                                // console.log("соответствие не нашли", techProcessTags, resultRows[j].tags)
                                                                                                                                                                                            }
                                                                                                                                                                                        }

                                                                                                                                                                                        for (let j = 0; j < resultExtraSpecReqRows.length; j++) {
                                                                                                                                                                                            techProcessValue.blocks[0].rows[resultExtraSpecReqRows[j].code] = {
                                                                                                                                                                                                code: resultExtraSpecReqRows[j].code,
                                                                                                                                                                                                columns: [],
                                                                                                                                                                                                accept: false,
                                                                                                                                                                                                num: resultExtraSpecReqRows[j].num
                                                                                                                                                                                            }

                                                                                                                                                                                            for (let k = 0; k < resultColumns.length; k++) {
                                                                                                                                                                                                if (resultColumns[k].col_special_requirements && resultColumns[k].type === "input") {
                                                                                                                                                                                                    techProcessValue.blocks[0].rows[resultExtraSpecReqRows[j].code].columns[resultColumns[k].code] = {
                                                                                                                                                                                                        code: resultColumns[k].code,
                                                                                                                                                                                                        value: "",
                                                                                                                                                                                                        type: resultColumns[k].type,
                                                                                                                                                                                                        col_special_requirements: resultColumns[k].col_special_requirements,
                                                                                                                                                                                                        col_task: resultColumns[k].col_task,
                                                                                                                                                                                                    }
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }

                                                                                                                                                                                        for (let j = 0; j < resultBlockData.length; j++) {
                                                                                                                                                                                            if (resultBlockData[j].block in techProcessValue.blocks) {
                                                                                                                                                                                                techProcessValue.blocks[resultBlockData[j].block].comment = resultBlockData[j].comment_value
                                                                                                                                                                                            }
                                                                                                                                                                                        }

                                                                                                                                                                                        for (let j = 0; j < resultBlockRowCommentData.length; j++) {
                                                                                                                                                                                            if (resultBlockRowCommentData[j].block in techProcessValue.blocks &&
                                                                                                                                                                                                resultBlockRowCommentData[j].row_code in techProcessValue.blocks[resultBlockRowCommentData[j].block].rows) {
                                                                                                                                                                                                techProcessValue.blocks[resultBlockRowCommentData[j].block].rows[resultBlockRowCommentData[j].row_code]["comment"] =
                                                                                                                                                                                                    resultBlockRowCommentData[j].comment_value
                                                                                                                                                                                            }
                                                                                                                                                                                        }

                                                                                                                                                                                        for (let j = 0; j < resultBlockRowAcceptData.length; j++) {
                                                                                                                                                                                            if (resultBlockRowAcceptData[j].block in techProcessValue.blocks &&
                                                                                                                                                                                                resultBlockRowAcceptData[j].row_code in techProcessValue.blocks[resultBlockRowAcceptData[j].block].rows) {
                                                                                                                                                                                                techProcessValue.blocks[resultBlockRowAcceptData[j].block].rows[resultBlockRowAcceptData[j].row_code]["accept"] =
                                                                                                                                                                                                    resultBlockRowAcceptData[j].accept_value
                                                                                                                                                                                            }
                                                                                                                                                                                        }

                                                                                                                                                                                        for (let j = 0; j < resultBlockRowResponsibleData.length; j++) {
                                                                                                                                                                                            if (resultBlockRowResponsibleData[j].block in techProcessValue.blocks &&
                                                                                                                                                                                                resultBlockRowResponsibleData[j].row_code in techProcessValue.blocks[resultBlockRowResponsibleData[j].block].rows &&
                                                                                                                                                                                                resultBlockRowResponsibleData[j].responsible_code in techProcessValue.blocks[resultBlockRowResponsibleData[j].block].rows[resultBlockRowResponsibleData[j].row_code].responsible) {
                                                                                                                                                                                                if (techProcessValue.blocks[resultBlockRowResponsibleData[j].block]
                                                                                                                                                                                                    .rows[resultBlockRowResponsibleData[j].row_code].responsible[resultBlockRowResponsibleData[j].responsible_code]
                                                                                                                                                                                                    .additionalResponsible.includes(resultBlockRowResponsibleData[j].responsible)) {
                                                                                                                                                                                                    techProcessValue.blocks[resultBlockRowResponsibleData[j].block].rows[resultBlockRowResponsibleData[j].row_code]
                                                                                                                                                                                                        .responsible[resultBlockRowResponsibleData[j].responsible_code].isSelected = true
                                                                                                                                                                                                    techProcessValue.blocks[resultBlockRowResponsibleData[j].block].rows[resultBlockRowResponsibleData[j].row_code]
                                                                                                                                                                                                        .responsible[resultBlockRowResponsibleData[j].responsible_code].selectedValue =
                                                                                                                                                                                                        resultBlockRowResponsibleData[j].responsible
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }

                                                                                                                                                                                        fillTemplateColumnData(resultResponse, techProcessFrom1C, resultTemplateData.text)
                                                                                                                                                                                        fillTemplateColumnData(resultResponse, techProcessFrom1C, resultTemplateData.bool)

                                                                                                                                                                                        for (let j = 0; j < resultBlockRowBoolData.length; j++) {
                                                                                                                                                                                            let boolValueBlock = resultBlockRowBoolData[j].block
                                                                                                                                                                                            let rowCode = resultBlockRowBoolData[j].row_code
                                                                                                                                                                                            let colCode = resultBlockRowBoolData[j].col_code
                                                                                                                                                                                            let boolValue = resultBlockRowBoolData[j].value

                                                                                                                                                                                            if (boolValueBlock in techProcessValue.blocks &&
                                                                                                                                                                                                rowCode in techProcessValue.blocks[boolValueBlock].rows &&
                                                                                                                                                                                                colCode in techProcessValue.blocks[boolValueBlock].rows[rowCode].columns) {
                                                                                                                                                                                                techProcessValue.blocks[boolValueBlock].rows[rowCode].columns[colCode].value = boolValue

                                                                                                                                                                                                if (techProcessValue.blocks[boolValueBlock].rows[rowCode].columns[colCode].col_special_requirements && boolValue) {
                                                                                                                                                                                                    techProcessValue.blocks[boolValueBlock].rows[rowCode]["checkedSpecialRequirements"] = boolValue
                                                                                                                                                                                                }
                                                                                                                                                                                            }
                                                                                                                                                                                        }

                                                                                                                                                                                        for (let j = 0; j < resultBlockExtraSpecReqRowDataText.length; j++) {
                                                                                                                                                                                            let rowCode = resultBlockExtraSpecReqRowDataText[j].row_code
                                                                                                                                                                                            let colCode = resultBlockExtraSpecReqRowDataText[j].col_code
                                                                                                                                                                                            let specReqRowDataTextValue = resultBlockExtraSpecReqRowDataText[j].value

                                                                                                                                                                                            if (rowCode in techProcessValue.blocks[0].rows &&
                                                                                                                                                                                                colCode in techProcessValue.blocks[0].rows[rowCode].columns) {
                                                                                                                                                                                                techProcessValue.blocks[0].rows[rowCode].columns[colCode].value = specReqRowDataTextValue
                                                                                                                                                                                            }
                                                                                                                                                                                        }

                                                                                                                                                                                        for (let j = 0; j < resultBlockRowDateData.length; j++) {
                                                                                                                                                                                            let dateValueBlock = resultBlockRowDateData[j].block
                                                                                                                                                                                            let rowCode = resultBlockRowDateData[j].row_code
                                                                                                                                                                                            let colCode = resultBlockRowDateData[j].col_code
                                                                                                                                                                                            let dateValue = new Date(resultBlockRowDateData[j].timestamp)

                                                                                                                                                                                            if (dateValueBlock in techProcessValue.blocks &&
                                                                                                                                                                                                rowCode in techProcessValue.blocks[dateValueBlock].rows &&
                                                                                                                                                                                                colCode in techProcessValue.blocks[dateValueBlock].rows[rowCode].columns) {
                                                                                                                                                                                                techProcessValue.blocks[dateValueBlock].rows[rowCode].columns[colCode].value = dateValue
                                                                                                                                                                                            }
                                                                                                                                                                                        }

                                                                                                                                                                                        cellTechProcessOnCount++
                                                                                                                                                                                        setProgressPercent(Math.round(cellTechProcessOnCount / resultResponse.TechProcess.length * 100))

                                                                                                                                                                                        if (cellTechProcessOnCount === resultResponse.TechProcess.length && !error) {
                                                                                                                                                                                            setIsLoaded(true);
                                                                                                                                                                                        }
                                                                                                                                                                                    },
                                                                                                                                                                                    (error) => {
                                                                                                                                                                                        setIsLoaded(true);
                                                                                                                                                                                        setError(error);
                                                                                                                                                                                    }
                                                                                                                                                                                )
                                                                                                                                                                        },
                                                                                                                                                                        (error) => {
                                                                                                                                                                            setIsLoaded(true);
                                                                                                                                                                            setError(error);
                                                                                                                                                                        }
                                                                                                                                                                    )
                                                                                                                                                            },
                                                                                                                                                            (error) => {
                                                                                                                                                                setIsLoaded(true);
                                                                                                                                                                setError(error);
                                                                                                                                                            }
                                                                                                                                                        )
                                                                                                                                                },
                                                                                                                                                (error) => {
                                                                                                                                                    setIsLoaded(true);
                                                                                                                                                    setError(error);
                                                                                                                                                }
                                                                                                                                            )
                                                                                                                                    },
                                                                                                                                    (error) => {
                                                                                                                                        setIsLoaded(true);
                                                                                                                                        setError(error);
                                                                                                                                    }
                                                                                                                                )
                                                                                                                        },
                                                                                                                        (error) => {
                                                                                                                            setIsLoaded(true);
                                                                                                                            setError(error);
                                                                                                                        }
                                                                                                                    )
                                                                                                            },
                                                                                                            (error) => {
                                                                                                                setIsLoaded(true);
                                                                                                                setError(error);
                                                                                                            }
                                                                                                        )
                                                                                                },
                                                                                                (error) => {
                                                                                                    setIsLoaded(true);
                                                                                                    setError(error);
                                                                                                }
                                                                                            )
                                                                                    },
                                                                                    (error) => {
                                                                                        setIsLoaded(true);
                                                                                        setError(error);
                                                                                    }
                                                                                )
                                                                        },
                                                                        (error) => {
                                                                            setIsLoaded(true);
                                                                            setError(error);
                                                                        }
                                                                    )
                                                            },
                                                            (error) => {
                                                                setIsLoaded(true);
                                                                setError(error);
                                                            }
                                                        )
                                                },
                                                (error) => {
                                                    setIsLoaded(true);
                                                    setError(error);
                                                }
                                            )
                                    },
                                    (error) => {
                                        setIsLoaded(true);
                                        setError(error);
                                    }
                                )
                        }

                        return techProcessValue
                    })

                    return value
                })
            )
        }
    }

    function getResponsibleTitles(data, cellData, responsibleOrder) {
        let responsibleTitleArray = {}

        for (let i = 0; i < cellData.length; i++) {
            let isSelected = false
            let selectedValue = ""
            let responsibleDataArray = []

            if (data[cellData[i]] in responsibleOrder) {
                let responsibleOrderValue = responsibleOrder[data[cellData[i]]]
                let additionalArray = responsibleOrderValue[1].additional

                if (!additionalArray.includes(responsibleOrderValue[0])) {
                    additionalArray.push(responsibleOrderValue[0])
                }

                isSelected = true
                selectedValue = responsibleOrderValue[0]
                responsibleDataArray = additionalArray
            }

            responsibleTitleArray[cellData[i]] = {
                title: data[cellData[i]],
                isSelected: isSelected,
                selectedValue: selectedValue,
                additionalResponsible: responsibleDataArray
            }
        }

        return responsibleTitleArray
    }

    function fillTemplateColumnData(resultResponse, techProcessFrom1C, templateColumnData) {
        setProjectData(
            projectData.map(value => {
                value.data.map(async techProcessValue => {
                    if (techProcessValue.name1c === techProcessFrom1C) {
                        for (let j = 0; j < templateColumnData.length; j++) {
                            if (templateColumnData[j].col_code !== 1 && templateColumnData[j].col_code !==3) {
                                if (templateColumnData[j].block in techProcessValue.blocks &&
                                    templateColumnData[j].row_code in techProcessValue.blocks[templateColumnData[j].block].rows &&
                                    templateColumnData[j].col_code in techProcessValue.blocks[templateColumnData[j].block].rows[templateColumnData[j].row_code].columns) {
                                    let colData = techProcessValue.blocks[templateColumnData[j].block].rows[templateColumnData[j].row_code].columns[templateColumnData[j].col_code]

                                    colData.value = templateColumnData[j].value

                                    if (colData["col_special_requirements"]) {
                                        /*if (colData.type === "input") {

                                        } else if (colData.type === "date") {

                                        } else */if (colData.type === "checkbox" && templateColumnData[j].value) {
                                            techProcessValue.blocks[templateColumnData[j].block].rows[templateColumnData[j].row_code]["checkedSpecialRequirements"] = true
                                            colData["defaultCheckSpecialRequirements"] = true
                                        }
                                    }
                                }
                            }
                        }
                    }

                    return techProcessValue
                })

                return value
            })
        )
    }

    function refillCellValue(techProcess, block, row, column, value, type) {
        setProjectData(
            projectData.map(info => {
                info.data.map( techProcessValue => {
                    if (techProcessValue.code === techProcess) {
                        techProcessValue.blocks[block].rows[row].columns[column].value = value

                        if (type === "checkbox") {
                            techProcessValue.blocks[block].rows[row].checkedSpecialRequirements = value
                        }
                    }

                    return techProcessValue
                })

                return info
            })
        )
    }

    function refillAcceptRowValue(techProcess, block, row, value) {
        setProjectData(
            projectData.map(info => {
                info.data.map( techProcessValue => {
                    if (techProcessValue.code === techProcess) {
                        techProcessValue.blocks[block].rows[row].accept = value
                    }

                    return techProcessValue
                })

                return info
            })
        )
    }

    async function openCellDataHistory(modalTitle, tableName, saveMethod, identityData) {
        let queryDB = "/proxy/project_bible_template/"

        clearModalOpenCellDataHistoryData()

        if (tableName === "project_stages_block_data") {
            queryDB += "getHistoryOfChangesInProjectStagesBlockData"

            if (typeof identityData.value !== "string") {
                return
            }
        } else if (tableName === "project_stages_block_row_data_comment") {
            queryDB += "getHistoryOfChangesInProjectStagesBlockRowDataComment"

            if (typeof identityData.value !== "string") {
                return
            }
        } else if (tableName === "project_stages_block_row_data_accept") {
            queryDB += "getHistoryOfChangesInProjectStagesBlockRowDataAccept"

            if (typeof identityData.value !== "boolean") {
                return
            }
        } else if (tableName === "project_stages_block_row_responsible") {
            queryDB += "getHistoryOfChangesInProjectStagesBlockRowResponsible"

            if (typeof identityData.value !== "string") {
                return
            }
        } else if (tableName === "project_stages_block_row_data_bool") {
            queryDB += "getHistoryOfChangesInProjectStagesBlockRowBoolColumns"

            if (typeof identityData.value !== "boolean") {
                return
            }
        } else if (tableName === "project_stages_block_row_data_date") {
            queryDB += "getHistoryOfChangesInProjectStagesBlockRowDataColumns"


        }

        await fetch(queryDB, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "project": project,
                "order": order,
                "service": service,
                "tableName": tableName,
                "identityData": identityData
            }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setModalOpenCellDataHistoryData(
                        modalOpenCellDataHistoryData.map(info => {
                            for (let i = 0; i < result.length; i++) {
                                result[i]["isOtherValueChecked"] = false
                            }

                            info.modalTitle = modalTitle
                            info.tableName = tableName
                            info.saveMethod = saveMethod
                            info.data = result
                            info.identityData = identityData

                            return info
                        })
                    )
                    setModalOpenCellDataHistoryActive(true)
                },
                (error) => {

                }
            )
    }

    function clearModalOpenCellDataHistoryData() {
        setModalOpenCellDataHistoryData(
            modalOpenCellDataHistoryData.map(info => {
                info.modalTitle = ""
                info.tableName = ""
                info.saveMethod = ""
                info.data = []
                info.identityData = []

                return info
            })
        )
    }

    async function replaceToOldValueFromDataHistory(saveMethod, identityData, tableName, newValue) {
        if (saveMethod === "oninputBlockAdditionalComment") {
            await oninputBlockAdditionalComment(identityData.techProcess, identityData.block,
                identityData.type, newValue)
        } else if (saveMethod === "oninputBlockRowData") {
            await oninputBlockRowData(identityData.techProcess, identityData.block, identityData.row,
                identityData.column, identityData.type, newValue)
        } else if (saveMethod === "setColumnValue") {
            await setColumnValue(identityData.techProcess, identityData.block, identityData.row,
                identityData.column, identityData.type, newValue)
        } else if (saveMethod === "changeResponsible") {
            await changeResponsible(identityData.techProcess, identityData.block, identityData.row,
                identityData.responsibleCode, value)
        }

        setModalOpenCellDataHistoryActive(false)
        window.location.reload()
    }

    async function oninputBlockAdditionalComment(techProcess, block, type, value) {
        let queryLinkExist = '/proxy/project_bible_template/'
        let queryUpdateCell = '/proxy/project_bible_template/'
        let queryInsertCell = '/proxy/project_bible_template/'

        if (type === "input") {
            queryLinkExist += 'projectStagesBlockDataGetComment'
            queryUpdateCell += 'projectStagesBlockDataUpdateComment'
            queryInsertCell += 'projectStagesBlockDataAddComment'
        }

        await fetch(queryLinkExist, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "project": project,
                "order": order,
                "service": service,
                "techProcess": techProcess,
                "block": block
            })
        })
            .then(res => res.json())
            .then(
                async (resultEditable) => {
                    if (resultEditable.count > 0) {
                        await fetch(queryUpdateCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "project": project,
                                "order": order,
                                "service": service,
                                "techProcess": techProcess,
                                "block": block,
                                "value": value,
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultUpdate) => {

                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    } else {
                        await fetch(queryInsertCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "project": project,
                                "order": order,
                                "service": service,
                                "techProcess": techProcess,
                                "block": block,
                                "value": value,
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultInsert) => {

                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    }
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    async function changeResponsible(techProcess, block, row, responsibleCode, value) {
        let queryLinkExist = '/proxy/project_bible_template/projectStagesBlockRowResponsibleGetValue'
        let queryUpdateCell = '/proxy/project_bible_template/projectStagesBlockRowResponsibleUpdateValue'
        let queryInsertCell = '/proxy/project_bible_template/projectStagesBlockRowResponsibleAddValue'
        // let value = e.target.value

        await fetch(queryLinkExist, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "project": project,
                "order": order,
                "service": service,
                "techProcess": techProcess,
                "block": block,
                "rowCode": row,
                "responsibleCode": responsibleCode
            })
        })
            .then(res => res.json())
            .then(
                async (resultEditable) => {
                    if (resultEditable.count > 0) {
                        await fetch(queryUpdateCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "project": project,
                                "order": order,
                                "service": service,
                                "techProcess": techProcess,
                                "block": block,
                                "rowCode": row,
                                "responsibleCode": responsibleCode,
                                "responsible": value,
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultUpdate) => {

                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    } else {
                        await fetch(queryInsertCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "project": project,
                                "order": order,
                                "service": service,
                                "techProcess": techProcess,
                                "block": block,
                                "rowCode": row,
                                "responsibleCode": responsibleCode,
                                "responsible": value,
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultInsert) => {

                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    }
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    async function oninputBlockRowData(techProcess, block, key, column, type, value) {
        let queryLinkExist = '/proxy/project_bible_template/'
        let queryUpdateCell = '/proxy/project_bible_template/'
        let queryInsertCell = '/proxy/project_bible_template/'

        if (column === "rowCheckbox" || column === "rowComment") {
            if (type === "input" && column === "rowComment") {
                queryLinkExist += 'projectStagesBlockRowDataTextGetComment'
                queryUpdateCell += 'projectStagesBlockRowDataTextUpdateComment'
                queryInsertCell += 'projectStagesBlockRowDataTextAddComment'
            } else if (type === "checkbox" && column === "rowCheckbox") {
                queryLinkExist += 'projectStagesBlockRowDataBoolGetCheckValue'
                queryUpdateCell += 'projectStagesBlockRowDataBoolUpdateCheckValue'
                queryInsertCell += 'projectStagesBlockRowDataBoolAddCheckValue'
            }
        }

        await fetch(queryLinkExist, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "project": project,
                "order": order,
                "service": service,
                "techProcess": techProcess,
                "block": block,
                "rowCode": key
            })
        })
            .then(res => res.json())
            .then(
                async (resultEditable) => {
                    if (resultEditable.count > 0) {
                        await fetch(queryUpdateCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "project": project,
                                "order": order,
                                "service": service,
                                "techProcess": techProcess,
                                "block": block,
                                "rowCode": key,
                                "value": value,
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultUpdate) => {
                                    if (type === "checkbox" && column === "rowCheckbox") {
                                        refillAcceptRowValue(techProcess, block, key, value)
                                    }

                                    if (type === "checkbox" && column === "rowCheckbox") {
                                        await sentInfoAboutUpdatedCompleteCheck(techProcess, block, key, value)
                                    }
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    } else {
                        await fetch(queryInsertCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "project": project,
                                "order": order,
                                "service": service,
                                "techProcess": techProcess,
                                "block": block,
                                "rowCode": key,
                                "value": value,
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultInsert) => {
                                    if (type === "checkbox" && column === "rowCheckbox") {
                                        refillAcceptRowValue(techProcess, block, key, value)
                                    }

                                    if (type === "checkbox" && column === "rowCheckbox") {
                                        await sentInfoAboutUpdatedCompleteCheck(techProcess, block, key, value)
                                    }
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    }
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    async function setColumnValue(techProcess, block, row, column, type, value) {
        let queryLinkExist = '/proxy/project_bible_template/'
        let queryUpdateCell = '/proxy/project_bible_template/'
        let queryInsertCell = '/proxy/project_bible_template/'
        let valueToSave

        if (type === "checkbox") {
            queryLinkExist += 'projectStagesBlockRowDataBoolGetValue'
            queryUpdateCell += 'projectStagesBlockRowDataBoolUpdateValue'
            queryInsertCell += 'projectStagesBlockRowDataBoolAddValue'
            valueToSave = value
        } else if (type === "date") {
            queryLinkExist += 'projectStagesBlockRowDataDateGetValue'
            queryUpdateCell += 'projectStagesBlockRowDataDateUpdateValue'
            queryInsertCell += 'projectStagesBlockRowDataDateAddValue'

            let mm = value.getMonth() + 1; // getMonth() is zero-based
            let dd = value.getDate();
            let hours = value.getHours()
            let minutes = value.getMinutes()
            let dateValue = [value.getFullYear(), '-', (mm>9 ? '' : '0') + mm, '-', (dd>9 ? '' : '0') + dd].join('');
            let timeValue = [(hours>9 ? '' : '0') + hours, ':', (minutes>9 ? '' : '0') + minutes, ':00.000000'].join('');

            valueToSave = [dateValue, timeValue].join(' ')
        }

        await fetch(queryLinkExist, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "project": project,
                "order": order,
                "service": service,
                "techProcess": techProcess,
                "block": block,
                "rowCode": row,
                "colCode": column
            })
        })
            .then(res => res.json())
            .then(
                async (resultExist) => {
                    if (resultExist.count > 0) {
                        await fetch(queryUpdateCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "project": project,
                                "order": order,
                                "service": service,
                                "techProcess": techProcess,
                                "block": block,
                                "rowCode": row,
                                "colCode": column,
                                "value": valueToSave,
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultUpdate) => {
                                    refillCellValue(techProcess, block, row, column, value, type)

                                    if (type === "checkbox" && order === "00000000-0000-0000-0000-000000000000") {
                                        await setSpecRequirementsTemplate(order, order)
                                    }

                                    await sentInfoAboutUpdatedColumns(techProcess, block, row, column, value, type)
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    } else {
                        await fetch(queryInsertCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "project": project,
                                "order": order,
                                "service": service,
                                "techProcess": techProcess,
                                "block": block,
                                "rowCode": row,
                                "colCode": column,
                                "value": valueToSave,
                                "changedBy": {
                                    id: employeeId,
                                    name: employeeName
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultInsert) => {
                                    refillCellValue(techProcess, block, row, column, value, type)

                                    if (type === "checkbox" && order === "00000000-0000-0000-0000-000000000000") {
                                        await setSpecRequirementsTemplate(order, order)
                                    }

                                    await sentInfoAboutUpdatedColumns(techProcess, block, row, column, value, type)
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    }
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    async function sentInfoAboutUpdatedColumns(techProcess, block, row, column, value, type) {
        let completeCheck = false
        let text = ""
        let midline = ""
        let data = {}
        let sentRequestTo1c = false

        projectData.map(projectDataValue => {
            projectDataValue.data.map(techProcessData => {
                if (techProcessData.code === techProcess) {
                    completeCheck = techProcessData.blocks[block].rows[row].accept

                    for (let columnKey in techProcessData.blocks[block].rows[row].columns) {
                        let columnValue = techProcessData.blocks[block].rows[row].columns[columnKey]

                        if (columnValue["col_special_requirements"]) {
                            if (columnValue.type === "input") {
                                text = columnValue.value
                            } else if (columnValue.type === "date") {
                                let midlineDateValue = new Date()

                                if (+columnKey === column) {
                                    midlineDateValue = value
                                } else {
                                    midlineDateValue = columnValue.value
                                }

                                if (typeof midlineDateValue !== "string" && midlineDateValue) {
                                    let mm = midlineDateValue.getMonth() + 1; // getMonth() is zero-based
                                    let dd = midlineDateValue.getDate();
                                    let hours = midlineDateValue.getHours()
                                    let minutes = midlineDateValue.getMinutes()
                                    let dateValue = [midlineDateValue.getFullYear(), '-', (mm>9 ? '' : '0') + mm, '-', (dd>9 ? '' : '0') + dd].join('');
                                    let timeValue = [(hours>9 ? '' : '0') + hours, ':', (minutes>9 ? '' : '0') + minutes, ':00'/*':00.000000'*/].join('');

                                    midline = [dateValue, timeValue].join('T')
                                }
                            }
                        }
                    }
                }

                return techProcessData
            })

            return projectDataValue
        })

        if (type === "checkbox") {
            if (value) {
                sentRequestTo1c = true

                data = {
                    "type": 1,
                    "project": project,
                    "order": order,
                    "service": service,
                    "data": [
                        {
                            "techProcess": techProcessList[0].data[techProcess],
                            "data": [
                                {
                                    "block": +block,
                                    "data": [
                                        {
                                            "rowCode": +row,
                                            "completeCheck": completeCheck,
                                            "text": text,
                                            "midline": midline
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            } else {

            }
        } else if (type === "date") {
            sentRequestTo1c = true

            data = {
                "type": 2,
                "project": project,
                "order": order,
                "service": service,
                "techProcess": techProcessList[0].data[techProcess],
                "block": +block,
                "rowCode": +row,
                "data": {
                    "completeCheck": completeCheck,
                    "text": text,
                    "midline": midline
                }
            }
        }

        if (sentRequestTo1c) {
            const response = await fetch('/proxy/workflow_spec_requirements', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    specRequirements: data
                })
            });
            let result = await response.json();

            if ("error" in result) {
                alert("Произошла ошибка при отправке данных, касающихся спецтребований, в 1С")
            }
        }
    }

    async function sentInfoAboutUpdatedCompleteCheck(techProcess, block, row, value) {
        let text = ""
        let midline = ""
        let sentRequestTo1c = false

        projectData.map(projectDataValue => {
            projectDataValue.data.map(techProcessData => {
                if (techProcessData.code === techProcess) {
                    for (let columnKey in techProcessData.blocks[block].rows[row].columns) {
                        let columnValue = techProcessData.blocks[block].rows[row].columns[columnKey]

                        if (columnValue["col_special_requirements"]) {
                            if (columnValue.type === "input") {
                                text = columnValue.value
                            } else if (columnValue.type === "date") {
                                let midlineDateValue = columnValue.value

                                if (typeof midlineDateValue !== "string" && midlineDateValue) {
                                    let day = String(midlineDateValue.getDate()).padStart(2, '0')
                                    let month = String(midlineDateValue.getMonth() + 1).padStart(2, '0')
                                    let year = midlineDateValue.getFullYear()

                                    midline = year + "-" + month + "-" + day
                                }
                            } else if (columnValue.type === "checkbox") {
                                if (columnValue.value) {
                                    sentRequestTo1c = true
                                }
                            }
                        }
                    }
                }

                return techProcessData
            })

            return projectDataValue
        })

        if (+block === 0) {
            sentRequestTo1c = true
        }

        if (sentRequestTo1c) {
            let data = {
                "type": 2,
                "project": project,
                "order": order,
                "service": service,
                "techProcess": techProcessList[0].data[techProcess],
                "block": +block,
                "rowCode": +row,
                "data": {
                    "completeCheck": value,
                    "text": text,
                    "midline": midline
                }
            }

            const response = await fetch('/proxy/workflow_spec_requirements', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    specRequirements: data
                })
            });
            let result = await response.json();

            if ("error" in result) {
                alert("Произошла ошибка при отправке данных, касающихся спецтребований, в 1С")
            }
        }
    }

    function editModalHistoryDataCheckedValue(key, isСhecked) {
        setModalOpenCellDataHistoryData(
            modalOpenCellDataHistoryData.map(info => {
                info.data.map((storedValue, index) => {
                    storedValue.isOtherValueChecked = isСhecked && index === key

                    return storedValue
                })

                return info
            })
        )
    }

    if (error) {
        return (
            <div className="row">
                <div className="col-sm-12 center">
                    <br />
                    <h3>Ошибка: {error.message}</h3>
                </div>
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div className="row" style={styles.progressBar}>
                <div className="col-sm-4">

                </div>
                <div className="col-sm-4 center">
                    <h4>Loading...</h4>
                    <ProgressBar now={progressPercent} label={`${progressPercent}%`} />
                </div>
                <div className="col-sm-4">

                </div>
            </div>
        )
    } else {
        return(
            <div className="row">
                <div className="col-sm-12">
            <div className="main-block-div-light">
                <div className="row">
                    <div className="col-sm-4">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicProject">
                                <Form.Label>Project</Form.Label>
                                <Form.Control type="clientName" disabled
                                              placeholder={projectInfo} />
                            </Form.Group>
                        </Form>
                    </div>
                    {
                        subordersInfo.length > 0 ? (
                            <>
                                <div className="col-sm-4">
                                    <Form>
                                        <Form.Group className="mb-3" controlId="formBasicOrderNumber">
                                            <Form.Label>Order number</Form.Label>
                                            <Form.Control type="projectName" disabled
                                                          placeholder={orderNumberInfo} />
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div className="col-sm-4">
                                    <Form>
                                        <Form.Group className="mb-3" controlId="formBasicSuborders">
                                            <Form.Label>Suborders</Form.Label>
                                            <Accordion id="accordeonSuborders">
                                                <Accordion.Item eventKey="0df">
                                                    <Accordion.Header>Suborders</Accordion.Header>
                                                    <Accordion.Body>
                                                        {
                                                            subordersInfo.map(suborder => {
                                                                return(
                                                                    <div>
                                                                        {suborder.SuborderNumber}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </>
                        ) : (
                            <>
                                {
                                    order !== "00000000-0000-0000-0000-000000000000" ? (
                                        <>
                                            <div className="col-sm-4">
                                                <Form>
                                                    <Form.Group className="mb-3" controlId="formBasicOrderNumber">
                                                        <Form.Label>Order number</Form.Label>
                                                        <Form.Control type="projectName" disabled
                                                                      placeholder={orderNumberInfo}/>
                                                    </Form.Group>
                                                </Form>
                                            </div>
                                            <div className="col-sm-4">
                                                <Form>
                                                    <Form.Group className="mb-3 center" controlId="formBasicButtons">
                                                        <Form.Label>Actions with special requirements</Form.Label>
                                                        <div className="row">
                                                            <div className="col-sm-6 center">
                                                                <Button className="center" variant="outline-primary"
                                                                        size="sm"
                                                                        onClick={(e) =>
                                                                            setSpecRequirementsTemplate(order, "00000000-0000-0000-0000-000000000000", "Default requirements are set!")}>
                                                                    Set as default requirements
                                                                </Button>
                                                            </div>
                                                            <div className="col-sm-6 center">
                                                                <Button className="center" variant="outline-secondary"
                                                                        size="sm"
                                                                        onClick={(e) =>
                                                                            setSpecRequirementsFromTemplate()}>
                                                                    Reset to default requirements
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </Form>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="col-sm-8">
                                            <Form>
                                                <Form.Group className="mb-3" controlId="formBasicOrderNumber">
                                                    <Form.Label>Order number</Form.Label>
                                                    <Form.Control type="projectName" disabled
                                                                  placeholder={orderNumberInfo}/>
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </div>
                <br/>
                <ProjectStagesTabs data={projectData[0].data} techProcessList={techProcessList[0].data}
                                   refillCellValue={refillCellValue} refillAcceptRowValue={refillAcceptRowValue}
                                   openCellDataHistory={openCellDataHistory}
                                   oninputBlockAdditionalComment={oninputBlockAdditionalComment}
                                   changeResponsible={changeResponsible}
                                   oninputBlockRowData={oninputBlockRowData}
                                   setColumnValue={setColumnValue}
                />
                <ModalCellDataHistory modalOpenCellDataHistoryActive={modalOpenCellDataHistoryActive}
                                      setModalOpenCellDataHistoryActive={setModalOpenCellDataHistoryActive}
                                      modalOpenCellDataHistoryData={modalOpenCellDataHistoryData}
                                      replaceToOldValueFromDataHistory={replaceToOldValueFromDataHistory}
                                      editModalHistoryDataCheckedValue={editModalHistoryDataCheckedValue} />
            </div>
                </div>
            </div>
        )
    }
}