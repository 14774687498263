import {Button, Table} from "react-bootstrap";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";

const styles = {
    tableHeaders: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    tableMiniHeader: {
        backgroundColor: '#f5f9ff'
    },

    tableHeaderTh: {
        backgroundColor: '#eaeaea',
        background: '#4A8EFF',
        color: '#FFFFFF'
    },

    tableBodyTopBorder: {
        borderTop: '0px'
    },

    nowrapCell: {
        whiteSpace: 'nowrap'
    },

    noEditableCell: {
        backgroundColor: '#fafafa'
    }
}

export default function LinguistLogOfNewBatchesTab(props) {
    const queryStringParams = queryString.parse(window.location.search)
    const clientName = queryStringParams.client_name
    const projectName = queryStringParams.project_name
    const projectCode = queryStringParams.project_code

    let getRows = (headers, count, data) => {
        let content = []

        for (let i = 0; i < count; i++) {
            content.push(
                <tr key={i}>
                    <td className="center" style={styles.noEditableCell}>{ i + 1 }</td>
                    {
                        headers.map(header => {
                            let colStyle = {}

                            if (header.wordBreak) {
                                colStyle.wordBreak = 'break-all'
                            }

                            if (header.minWidth) {
                                colStyle.minWidth = header.minWidth
                            }

                            return(
                                <td key={header.code} className="whiteSpacePreLine"
                                    contentEditable={false} style={Object.assign({}, styles.noEditableCell , colStyle)}
                                    dangerouslySetInnerHTML={{__html: data[i][header.code]}}>
                                </td>
                            )
                        })
                    }
                </tr>
            )
        }

        return content
    };

    return(
        <div className="row">
            <div className="col-sm-12 table-blue-thead-not-fixed">
                <Table responsive bordered>
                    <thead>
                    <tr style={styles.tableHeaders}>
                        <th key="№" className="center" style={styles.tableHeaders}>№</th>
                        {
                            props.logOfNewBatchesHeaders.map(header => {
                                return(
                                    <th key={header.code} className="center" style={styles.tableHeaders}>
                                        {header.name}
                                        <br />
                                        <br />
                                        {header.extra_header}
                                    </th>
                                )
                            })
                        }
                    </tr>
                    </thead>
                    <tbody style={styles.tableBodyTopBorder}>
                    {
                        getRows(props.logOfNewBatchesHeaders, props.logOfNewBatchesRowsCount, props.logOfNewBatchesRowsData)
                    }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}