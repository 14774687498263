import React, {useEffect, useState} from "react";
import {Col, Nav, Row, Tab, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import TechProcess from "./TechProcess";

export default function AllTechProcess(props) {
    return(
        <div>
            <Tab.Container defaultActiveKey={props.templateData.techProcessSequence[0].code}>
                <Row>
                    <Col sm={12}>
                        <Nav variant="pills"
                             style={{background: '#2c2c2c', padding: '5px', borderRadius: '5px'}}>
                            {
                                props.templateData.techProcessSequence.map(info => {
                                    let techProcessCode = info.code
                                    let data = props.templateData.data[techProcessCode]

                                    return(
                                        <Nav.Item>
                                            <Nav.Link key={techProcessCode} eventKey={techProcessCode}>
                                                {data.techProcessName + " (1C code: " + data.techProcessName1C +")"}
                                            </Nav.Link>
                                        </Nav.Item>
                                    )
                                })
                            }
                        </Nav>
                        <Tab.Content>
                            {
                                props.templateData.techProcessSequence.map(info => {
                                    let techProcessCode = info.code
                                    let data = props.templateData.data[techProcessCode]

                                    return(
                                        <Tab.Pane key={techProcessCode} eventKey={techProcessCode}>
                                            <div className="tab-item-wrapper">
                                                <br/>
                                                <TechProcess
                                                    techProcessCode={techProcessCode}
                                                    data={data}
                                                    blockSequence={props.templateData.blockSequence[techProcessCode]}
                                                    columnSequence={props.templateData.columnSequence[techProcessCode]}
                                                    rowsSequence={props.templateData.rowsSequence}
                                                    responsibleSequence={props.templateData.responsibleSequence[techProcessCode]}
                                                    addResponsible={props.addResponsible}
                                                    editResponsible={props.editResponsible}
                                                    deleteResponsible={props.deleteResponsible}
                                                    editBlockTitle={props.editBlockTitle}
                                                    addBlock={props.addBlock}
                                                    addBlockToTheEnd={props.addBlockToTheEnd}
                                                    deleteBlock={props.deleteBlock}
                                                    moveDownBlock={props.moveDownBlock}
                                                    moveUpBlock={props.moveUpBlock}
                                                    openModalForTags={props.openModalForTags}
                                                    openModalForResponsibles={props.openModalForResponsibles}
                                                    updateBlockRowColumnValue={props.updateBlockRowColumnValue}
                                                    deleteRow={props.deleteRow}
                                                    addRow={props.addRow}
                                                    addRowToTheEnd={props.addRowToTheEnd}
                                                    moveUpRow={props.moveUpRow}
                                                    moveDownRow={props.moveDownRow}
                                                    moveRow={props.moveRow}
                                                    editColumnTitle={props.editColumnTitle}
                                                    addColumn={props.addColumn}
                                                    addColumnToTheEnd={props.addColumnToTheEnd}
                                                    deleteColumn={props.deleteColumn}
                                                    moveDownColumn={props.moveDownColumn}
                                                    moveUpColumn={props.moveUpColumn}
                                                    associativeArrayOfTagsById={props.associativeArrayOfTagsById}
                                                />
                                            </div>
                                        </Tab.Pane>
                                    )
                                })
                            }
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    )
}