import "../css/modal.css"
import {Col, FormCheck, FormLabel, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import parse from 'html-react-parser';
import TagGroupTable from "../template_tags/TagGroupTable";

const styles = {
    modalWH: {
        width: '75%',
        height: '70%'
    }
}

export default function TagModal(props) {
    /*const [selectedTags, setSelectedTags] = useState([{
        data: props.tagModalData[0].selectedTags
    }]);*/
    let selectedTags = props.tagModalData[0].selectedTags

    console.log("TagModal", props, selectedTags)

    function setRowTags(group, tag, e) {
        if (e.target.checked) {
            if (group in selectedTags) {
                let oldValues = selectedTags[group]

                if (!oldValues.includes(tag)) {
                    oldValues.push(tag)
                }

                selectedTags[group] = oldValues
            } else {
                selectedTags[group] = [tag]
            }
        } else {
            if (group in selectedTags) {
                let oldValues = selectedTags[group]

                if (oldValues.includes(tag)) {
                    let newValues = []

                    for (let i = 0; i < oldValues.length; i++) {
                        if (oldValues[i] !== tag) {
                            newValues.push(oldValues[i])
                        }
                    }

                    if (newValues.length > 0) {
                        selectedTags[group] = newValues
                    } else {
                        removeGroup(group)
                    }
                }
            }
        }

        console.log("selectedTags", selectedTags)
    }

    function removeGroup(groupToRemove) {
        let newValues = {}

        for (let group in selectedTags) {
            if (group !== groupToRemove) {
                newValues[group] = selectedTags[group]
            }
        }

        selectedTags = newValues
    }

    let getAllTags = groups => {
        let content = []

        groups.forEach((value, key) => {
            content.push(
                <div className="col-sm-4" key={key}>
                    <FormLabel><h5>{key}</h5></FormLabel>
                    { getTagsByGroup(key, value) }
                    <br />
                </div>
            )
        });

        for (let key in groups) {
            let value = groups[key]

            content.push(
                <div className="col-sm-4" key={key}>
                    <FormLabel><h5>{key}</h5></FormLabel>
                    { getTagsByGroup(key, value) }
                    <br />
                </div>
            )
        }

        return content
    };

    let getTagsByGroup = (group, tags) => {
        let content = []

        for (let i = 0; i < tags.length; i++) {
            if (group === "LP") {
                console.log("HHH", group, tags[i])
            }

            if (group in props.tagModalData[0].selectedTags && props.tagModalData[0].selectedTags[group].includes(tags[i])) {
                console.log("***", group, tags[i])
                content.push(
                    <FormCheck defaultChecked={true} label={tags[i]}
                               onChange={(e) =>
                                   setRowTags(group, tags[i], e)} key={tags[i]} />
                )
            } else {
                content.push(
                    <FormCheck defaultChecked={false} label={tags[i]}
                               onChange={(e) =>
                                   setRowTags(group, tags[i], e)} key={tags[i]} />
                )
            }
        }

        return content
    };

    function setTags(row, column, data) {
        selectedTags = {}

        props.saveTagCell(row, column, data)
    }

    if (props.tagModalActive) {
        return(
            <div className={props.tagModalActive ? "modal active" : "modal"} onClick={() => props.setTagModalActive(false)} >
                <div className={props.tagModalActive ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}
                     style={styles.modalWH} >
                    <div className="row">
                        <div className="col-sm-8">
                            <h4>Здесь вы можете выбрать теги</h4>
                        </div>
                        <div className="col-sm-4 center">
                            <button className="btn btn-blue"
                                    onClick={(e) =>
                                        setTags(props.tagModalData[0].row, props.tagModalData[0].column, selectedTags)
                                        /*props.saveTagCell(props.tagModalData[0].row, props.tagModalData[0].column,
                                            selectedTags)*/}>Сохранить</button>
                        </div>
                    </div>
                    <hr />
                    <br />
                    <div className="row">
                        { getAllTags(props.tagModalData[0].allTags) }
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <div className={props.tagModalActive ? "modal active" : "modal"} onClick={() => props.setTagModalActive(false)} >
                <div className={props.tagModalActive ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}
                     style={styles.modalWH} >
                </div>
            </div>
        )
    }
}
