import "./css/modal.css"
import React, {useEffect, useState} from "react";
import ModalCellDataHistoryList from "../managed_quality_on_project/project_stages/ModalCellDataHistoryList";
import {Button} from "react-bootstrap";

const styles = {
    modalWH: {
        width: '30%'/*,
        height: '40%'*/
    },

    btnOk: {
        width: '100px'
    }
}

export default function AlertMessageModal(props) {
    return (
        <div className={props.modalAlertActive ? "modal active" : "modal"}
             onClick={() => props.setModalAlertActive(false)}>
            <div className={props.modalAlertActive ? "modal__content active" : "modal__content"}
                 onClick={e => e.stopPropagation()}
                 style={styles.modalWH}>
                <div className="row">
                    <div className="col-sm-12 center">
                        <h5>{props.message}</h5>
                    </div>
                </div>
                <br/>
                <br/>
                <div className="row">
                    <div className="col-sm-12 center">
                        <Button variant="primary" style={styles.btnOk}
                                onClick={() => props.setModalAlertActive(false)}>
                            Ok
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}