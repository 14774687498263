import React, {useState} from "react";
import {Button, OverlayTrigger} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faKeyboard, faTextWidth, faTimes, faRetweet} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from 'primereact/tooltip';

export default function TemplatesFilterButtons(props) {
    const [btnTooltipText, setBtnTooltipText] = useState('')

    return(
        <>
            <div className="row">
                <div className="col-sm-12 center">
                    {
                        props.isOn.value ? (
                            <>
                                {
                                    props.isOn.type !== "default" &&
                                    <>
                                        <Button className="btn-update-filter" variant="success"
                                                onClick={(e) =>
                                                    props.updateTemplateFilter()}>
                                            <FontAwesomeIcon icon={faRetweet}/>
                                        </Button>
                                        &nbsp;
                                        <Button className="btn-rename-filter" variant="dark"
                                                onClick={(e) =>
                                                    props.openModalToRenameTemplateFilter()}>
                                            <FontAwesomeIcon icon={faKeyboard}/>
                                        </Button>
                                        &nbsp;
                                        <Tooltip target=".btn-update-filter" position={'top'}>
                                            Update filter
                                        </Tooltip>
                                        <Tooltip target=".btn-rename-filter" position={'top'}>
                                            Rename filter
                                        </Tooltip>
                                    </>
                                }
                                <Button className="btn btn-blue btn-save-new-filter" variant="primary"
                                        onClick={(e) =>
                                    props.openModalToSaveNewTemplateFilter()}>
                                    <FontAwesomeIcon icon={faFilter}/>
                                </Button>
                                {
                                    props.isOn.type !== "default" &&
                                        <>
                                            &nbsp;
                                            <Button className="btn-delete-filter" variant="danger"
                                                    onClick={(e) =>
                                                        props.deleteTemplateFilter()}>
                                                <FontAwesomeIcon icon={faTimes}/>
                                            </Button>
                                            <Tooltip target=".btn-delete-filter" position={'top'}>
                                                Delete filter
                                            </Tooltip>
                                        </>
                                }
                                <Tooltip target=".btn-save-new-filter" position={'top'}>
                                    Save new filter
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <Button variant="primary" className="btn btn-blue"
                                        onClick={(e) =>
                                            props.openModalToSaveNewTemplateFilter()}>
                                    Save new filter&nbsp;&nbsp;<FontAwesomeIcon icon={faFilter}/>
                                </Button>
                            </>
                        )
                    }
                </div>
            </div>
        </>
    )
}