import React, {useCallback, useEffect, useRef, useState} from "react";
import {Tooltip} from "primereact/tooltip";

const styles = {
    alignRight: {
        textAlign: 'right'
    },

    imgPercentSize: {
        maxHeight: '50em',
        maxWidth: '50em'
    }
}

export default function OpenInTooltipAddedImage(props) {
    const tooltipRef = useRef(null);
    const [imgTitle, setImgTitle] = useState("")
    const [imgSrc, setImgSrc] = useState("")
    const [imgJustUploaded, setImgJustUploaded] = useState(false)

    useEffect(() => {
        setImgTitle(props.tooltipImageTitle)
        setImgSrc(props.tooltipImageSrc)
        setImgJustUploaded(props.tooltipImageJustUploaded)

        tooltipRef.current && tooltipRef.current.updateTargetEvents();
    });

    return(
        <Tooltip ref={tooltipRef} target=".open-in-modal-added-image"
                 autoHide={false} showDelay={500} position={'top'}
                 onBeforeHide={false} >
            {
                imgSrc ? (
                    <>
                        {
                            imgJustUploaded ? (
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12 center">
                                            <img alt="logo" src={require(`../img/wait_refresh_page.png`).default}
                                                 data-pr-tooltip="PrimeReact-Logo" style={{maxHeight: "150px", maxWidth: "150px"}}
                                                 onError={(e) =>
                                                     e.target.src = require(`../img/error.jpg`).default}/>
                                            <br />
                                            <br />
                                            <p>You will be able to see image after reloading the page</p>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12 center">
                                            <img alt="logo" src={require(`../../../../../../img/pb${imgSrc}`).default}
                                                 data-pr-tooltip="PrimeReact-Logo" style={styles.imgPercentSize}
                                                 onError={(e) =>
                                                     e.target.src = require(`../img/error.jpg`).default}/>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </>
                ) : (
                    <div>
                        <div className="row">
                            <div className="col-sm-12 center">
                            <span>Add image</span>
                            </div>
                        </div>
                    </div>
                )
            }
        </Tooltip>
    )
}
