import {Button, Table} from "react-bootstrap";
import React from "react";
import queryString from "query-string";

const styles = {
    columnNumeration: {
        backgroundColor: '#fafafa',
        width: '4%'
    },

    columnNoEditable: {
        backgroundColor: '#fafafa',
        width: '48%',
    },

    tableFixHeadTh: {
        position: 'sticky',
        top: -2,
        zIndex: 1,
        whiteSpace: 'pre-line',
        background: '#4A8EFF',
        color: '#FFFFFF'
    },

    tableHeaders: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    tableBodyTopBorder: {
        borderTop: '0px'
    }
}

export default function GeneralTab(props) {
    let rowNum = 0

    return(
        <div className="row">
            <div className="col-sm-12  table-blue-thead-not-fixed">
                <Table responsive bordered>
                    <thead>
                    <tr style={styles.tableHeaders}>
                        <th key="№" className="center" style={styles.tableHeaders}>№</th>
                        {
                            props.columns.map(column => {
                                if (column["col_styleguide"]) {
                                    return (
                                        <th key={column.name} className="center" style={styles.tableHeaders}>
                                            {column.name}
                                        </th>
                                    )
                                }
                            })
                        }
                    </tr>
                    </thead>
                    <tbody style={styles.tableBodyTopBorder}>
                    {
                        props.rows.map((row, rowIndex) => {
                            if (row.show) {
                                return (
                                    <tr key={row.code}>
                                        <td className="center" style={styles.columnNumeration}>{++rowNum}</td>
                                        {
                                            props.columns.map((column, columnIndex) => {
                                                if (column["col_styleguide"]) {
                                                    return (
                                                        <td key={column.code} className="whiteSpacePreLine"
                                                            contentEditable={false}
                                                            dangerouslySetInnerHTML={{__html: row.data[column.code]}}
                                                            style={styles.columnNoEditable}></td>
                                                    )
                                                }
                                            })
                                        }
                                    </tr>
                                )
                            }
                        })
                    }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}