import React, {useState} from "react";
import {Button, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import XLSX from "xlsx";
import translate from "translate";

const styles = {
    tableFixHeadTh: {
        position: 'sticky',
        top: -2,
        zIndex: 1,
        whiteSpace: 'pre-line',
        backgroundColor: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: '#4A8EFF'
    },

    tableRowHeader: {
        backgroundColor: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: '#4A8EFF',
    },

    tableBodyTopBorder: {
        borderTop: '0px'
    },

    noEditableCell: {
        backgroundColor: '#fff'
    },
}

export default function LocReviewsDataSteam(props) {
    let tableData = []

    function createExcel() {
        console.log("LOC reviews", props.data)

        let wb = XLSX.utils.book_new()
        let ws_data = excelData()
        let ws = XLSX.utils.aoa_to_sheet(ws_data)

        wb.Props = {
            Title: "Loc Reviews",
            Subject: "Loc Reviews",
            Author: "Allcorrect"
        }

        console.log("props.appId", props.appId)

        wb.SheetNames.push("Loc_Reviews")
        wb.Sheets["Loc_Reviews"] = ws
        XLSX.writeFile(wb, props.appId + ".xlsx")
    }

    function excelData() {
        let data = []
        let rowCount = 1

        data.push(["№", "User", "Date", "Review", "Google translation", "Found keys", "Link", "Rating", "Language",
            "Type", "L+", "L-", "RL", "YL"])

        props.data.map((value, index) => {
            data.push([rowCount++, value.user, value.date, value.review, value.googleTranslation,
                value.foundedKeys, value.link, value.rating, value.language, value.type, value["prob_l+"],
                value["prob_l-"], value.prob_rl, value.prob_yl])
        })

        return data
    }

    return(
        <div className="row">
            <div className="col-sm-12 center">
                {
                    props.data.length &&
                    <div>
                        <Button variant="success" onClick={(e) => createExcel()}>
                            Выгрузить excel&nbsp;&nbsp;<FontAwesomeIcon icon={faFileExcel}/>
                        </Button>
                        <br/>
                        <br/>
                        <Table className="table-loc-reviews" responsive bordered>
                            <thead>
                            <tr style={styles.tableRowHeader}>
                                <th className="center" style={styles.tableFixHeadTh}>№</th>
                                <th className="center" style={styles.tableFixHeadTh}>User</th>
                                <th className="center" style={styles.tableFixHeadTh}>Date</th>
                                <th className="center" style={styles.tableFixHeadTh}>Review</th>
                                <th className="center" style={styles.tableFixHeadTh}>Google translation</th>
                                <th className="center" style={styles.tableFixHeadTh}>Found keys</th>
                                <th className="center" style={styles.tableFixHeadTh}>Link</th>
                                <th className="center" style={styles.tableFixHeadTh}>Rating</th>
                                <th className="center" style={styles.tableFixHeadTh}>Language</th>
                                <th className="center" style={styles.tableFixHeadTh}>Type</th>
                                <th className="center" style={styles.tableFixHeadTh}>L+</th>
                                <th className="center" style={styles.tableFixHeadTh}>L-</th>
                                <th className="center" style={styles.tableFixHeadTh}>RL</th>
                                <th className="center" style={styles.tableFixHeadTh}>YL</th>
                            </tr>
                            </thead>
                            <tbody style={styles.tableBodyTopBorder}>
                            {
                                props.reviewsColorKeys.map((value, index) => {
                                    return(
                                        <tr key={index}>
                                            <td style={styles.noEditableCell}>{index + 1}</td>
                                            <td style={styles.noEditableCell}>{value.user}</td>
                                            <td style={styles.noEditableCell}>{value.date}</td>
                                            <td style={styles.noEditableCell} dangerouslySetInnerHTML={{__html: value.reviewColorKeys}}></td>
                                            <td style={styles.noEditableCell}>{value.reviewGoogleTranslation}</td>
                                            <td style={styles.noEditableCell}>{value.foundedKeys}</td>
                                            <td style={styles.noEditableCell}><a href={value.link} target="_blank" style={{textDecoration: 'none'}}>🎮</a></td>
                                            <td style={styles.noEditableCell}>{value.rating}</td>
                                            <td style={styles.noEditableCell}>{value.language}</td>
                                            <td style={styles.noEditableCell}>{value.type}</td>
                                            <td style={styles.noEditableCell}>{value["prob_l+"]}</td>
                                            <td style={styles.noEditableCell}>{value["prob_l-"]}</td>
                                            <td style={styles.noEditableCell}>{value.prob_rl}</td>
                                            <td style={styles.noEditableCell}>{value.prob_yl}</td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </div>
                }
            </div>
        </div>
    )
}