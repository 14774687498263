import "../css/modal.css"
import React, {useEffect, useState} from "react";
import {Button, Col, FormControl, Nav, Row, Tab, Tabs} from "react-bootstrap";
import ClientViewGeneralTab from "./ClientViewGeneralTab";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";
import ClientViewLanguageTab from "./ClientViewLanguageTab";

const styles = {
    modalWH: {
        width: '95%',
        height: '95%',
        backgroundColor: '#222' /* dark-theme */
    }
}

export default function ClientViewAcceptChangesModal(props) {
    const queryStringParams = queryString.parse(window.location.search)
    const clientName = queryStringParams.client_name
    const projectName = queryStringParams.project_name
    const projectCode = queryStringParams.project_code
    const [changes, setChanges] = useState([{
        general: {},
        sgLanguagesData: {}
    }])

    async function submitClientViewChanges() {
        await fetch('/proxy/project_bible_template/pbAcceptChangesFromClient', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "viewCode": +props.viewCode,
                "generalData": getGeneralChangesArray(),
                "sgLanguagesData": getSGLanguagesChangesArray(),
                "changedBy": {
                    id: props.employeeId,
                    name: props.employeeName,
                    client: true,
                    changeAcceptedByClient: true
                }
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    window.location.reload()
                    // alert("вернуть сюда перезагрузку страницы")
                },
                (error) => {
                    alert("An error occurred while saving client changes.")
                }
            )
    }

    function setAcceptedGeneralRows(row, column, value) {
        setChanges(
            changes.map(info => {
                if (value) {
                    if (!(row in info.general)) {
                        info.general[row] = []
                    }

                    if (!info.general[row].includes(column)) {
                        info.general[row].push(column)
                    }
                } else {
                    if (row in info.general && info.general[row].includes(column)) {
                        info.general[row].splice(info.general[row].indexOf(column), 1)
                    }
                }

                return info
            })
        )

        console.log("changes", changes)
    }

    function getGeneralChangesArray() {
        let array = []

        for (let key in changes[0].general) {
            let value = changes[0].general[key]

            for (let i = 0; i < value.length; i++) {
                array.push({
                    row: key,
                    column: value[i]
                })
            }
        }

        return array
    }

    function setAcceptedSGLanguageRows(language, block, row, column, value) {
        setChanges(
            changes.map(info => {
                if (value) {
                    if (!(language in info.sgLanguagesData)) {
                        info.sgLanguagesData[language] = {}
                    }

                    if (!(block in info.sgLanguagesData[language])) {
                        info.sgLanguagesData[language][block] = {}
                    }

                    if (!(row in info.sgLanguagesData[language][block])) {
                        info.sgLanguagesData[language][block][row] = []
                    }

                    if (!info.sgLanguagesData[language][block][row].includes(column)) {
                        info.sgLanguagesData[language][block][row].push(column)
                    }
                } else {
                    if (language in info.sgLanguagesData && block in info.sgLanguagesData[language] &&
                        row in info.sgLanguagesData[language][block] &&
                        info.sgLanguagesData[language][block][row].includes(column)) {
                        info.sgLanguagesData[language][block][row].splice(info.sgLanguagesData[language][block][row].indexOf(column), 1)
                    }
                }

                return info
            })
        )

        console.log("changes", changes)
    }

    function getSGLanguagesChangesArray() {
        let array = []

        for (let language in changes[0].sgLanguagesData) {
            let languageData = changes[0].sgLanguagesData[language]

            for (let block in languageData) {
                let blockData = languageData[block]

                for (let row in blockData) {
                    let rowData = blockData[row]

                    for (let i = 0; i < rowData.length; i++) {
                        array.push({
                            language: language,
                            block: block,
                            row: row,
                            column: rowData[i]
                        })
                    }
                }
            }
        }

        return array
    }

    if (props.clientViewAcceptDataModalActive) {
        return(
            <div className={props.clientViewAcceptDataModalActive ? "modal active" : "modal"} onClick={() => props.setClientViewAcceptDataModalActive(false)} >
                <div className={props.clientViewAcceptDataModalActive ? "modal__content active" : "modal__content"}
                     onClick={e => e.stopPropagation()}
                     style={styles.modalWH}>
                    <div className="row">
                        <div className="col-sm-12">
                            <FormControl
                                as="textarea"
                                rows={5}
                                placeholder={props.additionalComment}
                                disabled
                            />
                        </div>
                    </div>
                    <br/>
                    <Tab.Container defaultActiveKey={"general"}>
                        <Row>
                            <Col sm={12}>
                                <Nav variant="pills" className="nav-custom-grey">
                                    <Nav.Item>
                                        <Nav.Link eventKey={"general"}>
                                            General
                                        </Nav.Link>
                                    </Nav.Item>
                                    {
                                        props.languageTabData.map(language => {
                                            return(
                                                <Nav.Item key={language.code}>
                                                    <Nav.Link eventKey={language.code}>
                                                        {language.title}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            )
                                        })
                                    }
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey={"general"}>
                                        <div className="tab-item-wrapper">
                                            <br/>
                                            <div className="row">
                                                <div className="col-sm-12 table-blue-thead-not-fixed">
                                                    <ClientViewGeneralTab
                                                        viewCode={props.viewCode}
                                                        columns={props.generalTabColumns}
                                                        rows={props.generalTabRows}
                                                        setAcceptedGeneralRows={setAcceptedGeneralRows}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    {
                                        props.languageTabData.map(language => {
                                            return(
                                                <Tab.Pane eventKey={language.code}>
                                                    <div className="tab-item-wrapper">
                                                        <br/>
                                                        <ClientViewLanguageTab
                                                            language={language}
                                                            setAcceptedSGLanguageRows={setAcceptedSGLanguageRows}
                                                        />
                                                    </div>
                                                </Tab.Pane>
                                            )
                                        })
                                    }
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                    <br/>
                    <div className="row">
                        <div className="col-sm-12 center">
                            <Button variant="primary" className="btn btn-blue"
                                    onClick={(e) => submitClientViewChanges()}>
                                 Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={props.clientViewAcceptDataModalActive ? "modal active" : "modal"}
                 onClick={() => props.setClientViewAcceptDataModalActive(false)}>
                <div className={props.clientViewAcceptDataModalActive ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}
                     style={styles.modalWH} >
                </div>
            </div>
        )
    }
}