import React, {useEffect, useState} from "react";
import {Button, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";

const styles = {
    tableHeaderRow: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    noEditableCell: {
        backgroundColor: '#fafafa',
    },

    editableCell: {
        backgroundColor: '#fff',
    },

    nowrapCell: {
        backgroundColor: '#fafafa',
        whiteSpace: 'nowrap'
    },
}

export default function ColumnsTable(props) {
    let columnsData = props.data.columns

    return(
        <div className="row">
            <div className="col-sm-6 table-blue-thead-not-fixed">
                <Table responsive bordered>
                    <thead>
                    <tr style={styles.tableHeaderRow}>
                        <th className="center" style={styles.tableHeaderRow}>№</th>
                        <th className="center" style={styles.tableHeaderRow}>Column</th>
                        <th className="center" style={styles.tableHeaderRow}>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.columnSequence.map((info, index) => {
                            let columnCode = info.code
                            let columnName = columnsData[columnCode].columnName

                            return(
                                <tr key={columnCode}>
                                    <td contentEditable={false} style={styles.noEditableCell}>
                                        {index + 1}
                                    </td>
                                    <td className="whiteSpacePreLine"
                                        contentEditable={true}
                                        suppressContentEditableWarning={true}
                                        style={styles.editableCell}
                                        dangerouslySetInnerHTML={{__html: columnName}}
                                        onBlur={(e) =>
                                            props.editColumnTitle(props.techProcessCode, columnCode, e)}>
                                    </td>
                                    <td contentEditable={false}
                                        style={styles.nowrapCell}>
                                        {
                                            index !== 0 &&
                                            <>
                                                <Button
                                                    size="sm"
                                                    variant="primary"
                                                    className="btn btn-blue"
                                                    onClick={(e) =>
                                                        props.moveUpColumn(props.techProcessCode, columnCode)}>
                                                    <FontAwesomeIcon icon={faChevronUp} />
                                                </Button>
                                                &nbsp;
                                            </>
                                        }
                                        {
                                            index !== props.columnSequence.length - 1 &&
                                            <>
                                                <Button
                                                    size="sm"
                                                    variant="primary"
                                                    className="btn btn-blue"
                                                    onClick={(e) =>
                                                        props.moveDownColumn(props.techProcessCode, columnCode)}>
                                                    <FontAwesomeIcon icon={faChevronDown} />
                                                </Button>
                                                &nbsp;
                                            </>
                                        }
                                        <Button
                                            size="sm"
                                            variant="success"
                                            onClick={(e) =>
                                                props.addColumn(props.techProcessCode, columnCode)}>
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                        {/*&nbsp;
                                        <Button
                                            size="sm"
                                            variant="danger"
                                            onClick={(e) =>
                                                props.deleteColumn(props.techProcessCode, columnCode)}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </Button>*/}
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
                <br/>
                <Button
                    variant="primary" className="btn btn-blue"
                    onClick={(e) =>
                        props.addColumnToTheEnd(props.techProcessCode)}>
                    <FontAwesomeIcon icon={faPlus} /> Add
                </Button>
            </div>
        </div>
    )
}