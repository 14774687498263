import React, {useEffect, useState, useRef, useCallback} from "react";
import {Table} from "react-bootstrap";

export default function Test(props) {
    const authorizationCookieName = "b24_authorization_log"
    const authorizationCookieMatches = document.cookie.match(new RegExp(
        "(?:^|; )" + authorizationCookieName.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    const employeeId = authorizationCookieMatches ?
        +JSON.parse(window.atob(decodeURIComponent(authorizationCookieMatches[1]))).userId :
        window.location.href.split("/")[2] === "localhost:3000" ? 191 : 0

    useEffect(async () => {
        if (checkCookie()) {
            deleteRedirectCookie()

        } else {
            setRedirectCookie()
            window.location.replace('https://b24.allcorrectgames.com');
        }
    }, [])

    function checkCookie() {
        let name = "testCookie"

        document.cookie = "testCookie=test; max-age=3600";

        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));

        if (!matches) {
            alert("Allow cookies in your browser settings for correct work.")

            return false
        } else {
            return employeeId > 0
        }
    }

    function setRedirectCookie() {
        let cookieName =  'apps_allcorrect_redirect'
        let cookieValue = window.btoa(window.location.href)
        let date = new Date(Date.now() + 1800e3);
        let options = {
            path: '/',
            domain: 'allcorrectgames.com',
            expires: date.toUTCString(),
            samesite: 'lax'
        };
        let updatedCookie = cookieName + "=" + cookieValue;

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;

            let optionValue = options[optionKey];

            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    function deleteRedirectCookie() {
        let cookieName =  'apps_allcorrect_redirect'
        let cookieValue = 'val'
        let date = new Date();

        date.setMonth(date.getMonth() - 1);

        let options = {
            path: '/',
            domain: 'allcorrectgames.com',
            expires: date.toUTCString(),
            samesite: 'lax'
        };
        let updatedCookie = cookieName + "=" + cookieValue;

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;

            let optionValue = options[optionKey];

            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    return(
        <div className="managedQualityOnProjectBlockView">
            <div className="row">
                <div className="col-sm-12 testTableCss">
                    <Table responsive bordered >
                        <thead>
                        <tr>
                            <th>№</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                    </Table>
                </div>
            </div>
        </div>
    )
}