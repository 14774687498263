import {FormCheck} from "react-bootstrap";
import React, {useState} from "react";
import queryString from "query-string";
import Editor from "react-medium-editor";
import MediumButton from "medium-button";

const styles = {
    editableCell: {
        backgroundColor: '#ffffff',
        whiteSpace: 'pre-line',
        minWidth: '250px',
        wordBreak: 'break-word'
    },

    noEditableCell: {
        backgroundColor: '#fafafa',
        whiteSpace: 'pre-line',
        minWidth: '100px',
        wordBreak: 'break-word'
    },

    clientNotAcceptedCell: {
        backgroundColor: '#fff8e1',
        whiteSpace: 'pre-line',
        minWidth: '250px',
        wordBreak: 'break-word'
    }
}

export default function ClientViewGeneralTableCell(props) {
    if (props.column.type === "input") {
        let cellStyle = styles.noEditableCell
        let value = props.cellData.mainValue

        if (props.clientValue) {
            cellStyle = styles.clientNotAcceptedCell
            value = props.cellData.clientValue
        }

        return (
            <td className="whiteSpacePreLine"
                contentEditable={false}
                suppressContentEditableWarning={true}
                style={cellStyle}
                dangerouslySetInnerHTML={{__html: value}}>
            </td>
        )
    } else {
        return (
            <td className="whiteSpacePreLine"
                contentEditable={false}
                suppressContentEditableWarning={true}
                style={styles.noEditableCell}
                dangerouslySetInnerHTML={{__html: ""}}>
            </td>
        )
    }
}