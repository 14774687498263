import React, {useState} from "react";
import "./css/forms.css"
import queryString from "query-string";
import {Button, Form, FormControl, Tab, Tabs} from "react-bootstrap";
import DateInput from 'react-input-date'
import CategoryFieldsForm from "./CategoryFieldsForm"
import AlertMessageModal from "./AlertMessageModal";

const styles = {
    formLabel: {
        marginBottom: '0px'
    }
}

export default function LocForm(props) {
    const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    // const [selectedCategory, setSelectedCategory] = useState("default")
    const [categoriesData, setCategoriesData] = useState({
        localization: {
            title: "translator/editor",
            submit: false,
            fields: {
                freelancerOrAgency: {
                    title: "Are you a freelancer or an agency? (select an option)",
                    type: "select",
                    selectOptions: {
                        '-Individual freelancer': {},
                        '-Translation agency': {}
                    },
                    required: true
                },
                firstName: {
                    title: "First name",
                    description: "What is your first name?",
                    type: "input",
                    required: true
                },
                lastName: {
                    title: "Last Name",
                    description: "What is your last name?",
                    type: "input",
                    required: true
                },
                preferredGenderPronoun: {
                    title: "Preferred gender pronoun",
                    description: "Please select the pronoun you prefer to be addressed by.",
                    type: "select",
                    selectOptions: {
                        'He/Him': {},
                        'She/Her': {},
                        'They/Them': {},
                        'Other (please specify)': {
                            id: "preferredGenderPronounOther",
                            title: "",
                            type: "input",
                            required: true
                        },
                        'Prefer not to say': {}
                    },
                    required: false
                },
                email: {
                    title: "E-mail",
                    description: "What is your best contact email address?",
                    type: "input",
                    required: true
                },
                secondaryEmail: {
                    title: "Secondary e-mail (if used) ",
                    type: "input",
                    required: false
                },
                contacts: {
                    title: "Skype / Discord / Telegram / WhatsApp",
                    description: "Please provide your contact information for any of the following messengers you use.",
                    type: "textarea",
                    required: false
                },
                availability : {
                    title: "Availability",
                    description: "Please indicate your available days and hours for work.",
                    type: "textarea",
                    required: false
                },
                workOnWeekends: {
                    title: "Do you work weekends? (select an option)",
                    type: "select",
                    selectOptions: {
                        'Yes, always': {},
                        'Yes, sometimes': {},
                        'No': {},
                        'Only on Saturdays': {},
                        'Only on Sundays': {},
                        'It depends': {}
                    },
                    required: false
                },
                cv: {
                    title: "CV",
                    description: "Please attach your CV.",
                    type: "file",
                    required: true
                },
                certifications  : {
                    title: "Certifications",
                    description: "Please list any relevant certifications you hold.",
                    type: "textarea",
                    required: false
                },
                country: {
                    title: "Country",
                    description: "What country do you live in?",
                    type: "input",
                    required: true
                },
                city: {
                    title: "City",
                    description: "What city do you live in?",
                    type: "input",
                    required: true
                },
                motherLanguage: {
                    title: "Mother language",
                    type: "select",
                    selectOptions: {
                        'Albanian': {},
                        'Arabic': {},
                        'Armenian': {},
                        'Azerbaijani': {},
                        'Belarusian': {},
                        'Bulgarian': {},
                        'Chechen': {},
                        'Chinese Simplified': {},
                        'Chinese Trad HK': {},
                        'Chinese Trad TW': {},
                        'Croatian': {},
                        'Czech': {},
                        'Danish': {},
                        'Dutch': {},
                        'English': {},
                        'Estonian': {},
                        'Finnish': {},
                        'French': {},
                        'French Canadian': {},
                        'Georgian': {},
                        'German': {},
                        'Greek (Modern)': {},
                        'Hebrew (modern)': {},
                        'Hindi': {},
                        'Hungarian': {},
                        'Indonesian': {},
                        'Italian': {},
                        'Japanese': {},
                        'Kazakh': {},
                        'Khmer': {},
                        'Kirghiz or Kyrgyz': {},
                        'Korean': {},
                        'Latvian': {},
                        'Lithuanian': {},
                        'Malay': {},
                        'Marathi': {},
                        'Mongolian': {},
                        'Norwegian': {},
                        'Persian': {},
                        'Polish': {},
                        'Portuguese (BR)': {},
                        'Portuguese (ES)': {},
                        'Romanian': {},
                        'Russian': {},
                        'Serbian': {},
                        'Slovak': {},
                        'Slovenian': {},
                        'Spanish American': {},
                        'Spanish Castilian': {},
                        'Swedish': {},
                        'Tagalog': {},
                        'Tajik': {},
                        'Thai': {},
                        'Turkish': {},
                        'Turkmen': {},
                        'Ukrainian': {},
                        'Urdu': {},
                        'Urdu India': {},
                        'Uzbek': {},
                        'Vietnamese': {}
                    },
                    required: true
                },
                sourceLanguageFirst: {
                    title: "First source language (select options)",
                    type: "select",
                    selectOptions: {
                        'Albanian': {},
                        'Arabic': {},
                        'Armenian': {},
                        'Azerbaijani': {},
                        'Belarusian': {},
                        'Bulgarian': {},
                        'Chechen': {},
                        'Chinese Simplified': {},
                        'Chinese Trad HK': {},
                        'Chinese Trad TW': {},
                        'Croatian': {},
                        'Czech': {},
                        'Danish': {},
                        'Dutch': {},
                        'English': {},
                        'Estonian': {},
                        'Finnish': {},
                        'French': {},
                        'French Canadian': {},
                        'Georgian': {},
                        'German': {},
                        'Greek (Modern)': {},
                        'Hebrew (modern)': {},
                        'Hindi': {},
                        'Hungarian': {},
                        'Indonesian': {},
                        'Italian': {},
                        'Japanese': {},
                        'Kazakh': {},
                        'Khmer': {},
                        'Kirghiz or Kyrgyz': {},
                        'Korean': {},
                        'Latvian': {},
                        'Lithuanian': {},
                        'Malay': {},
                        'Marathi': {},
                        'Mongolian': {},
                        'Norwegian': {},
                        'Persian': {},
                        'Polish': {},
                        'Portuguese (BR)': {},
                        'Portuguese (ES)': {},
                        'Romanian': {},
                        'Russian': {},
                        'Serbian': {},
                        'Slovak': {},
                        'Slovenian': {},
                        'Spanish American': {},
                        'Spanish Castilian': {},
                        'Swedish': {},
                        'Tagalog': {},
                        'Tajik': {},
                        'Thai': {},
                        'Turkish': {},
                        'Turkmen': {},
                        'Ukrainian': {},
                        'Urdu': {},
                        'Urdu India': {},
                        'Uzbek': {},
                        'Vietnamese': {}
                    },
                    required: true
                },
                sourceLanguageSecond: {
                    title: "Second source language (select options)",
                    type: "select",
                    selectOptions: {
                        'Albanian': {},
                        'Arabic': {},
                        'Armenian': {},
                        'Azerbaijani': {},
                        'Belarusian': {},
                        'Bulgarian': {},
                        'Chechen': {},
                        'Chinese Simplified': {},
                        'Chinese Trad HK': {},
                        'Chinese Trad TW': {},
                        'Croatian': {},
                        'Czech': {},
                        'Danish': {},
                        'Dutch': {},
                        'English': {},
                        'Estonian': {},
                        'Finnish': {},
                        'French': {},
                        'French Canadian': {},
                        'Georgian': {},
                        'German': {},
                        'Greek (Modern)': {},
                        'Hebrew (modern)': {},
                        'Hindi': {},
                        'Hungarian': {},
                        'Indonesian': {},
                        'Italian': {},
                        'Japanese': {},
                        'Kazakh': {},
                        'Khmer': {},
                        'Kirghiz or Kyrgyz': {},
                        'Korean': {},
                        'Latvian': {},
                        'Lithuanian': {},
                        'Malay': {},
                        'Marathi': {},
                        'Mongolian': {},
                        'Norwegian': {},
                        'Persian': {},
                        'Polish': {},
                        'Portuguese (BR)': {},
                        'Portuguese (ES)': {},
                        'Romanian': {},
                        'Russian': {},
                        'Serbian': {},
                        'Slovak': {},
                        'Slovenian': {},
                        'Spanish American': {},
                        'Spanish Castilian': {},
                        'Swedish': {},
                        'Tagalog': {},
                        'Tajik': {},
                        'Thai': {},
                        'Turkish': {},
                        'Turkmen': {},
                        'Ukrainian': {},
                        'Urdu': {},
                        'Urdu India': {},
                        'Uzbek': {},
                        'Vietnamese': {}
                    },
                    required: false
                },
                memoQ: {
                    title: "MemoQ experience",
                    description: "Please check the box if you are familiar with this translation software.",
                    type: "checkbox",
                    required: false
                },
                rateForTranslation: {
                    title: "Translation rate per source word/character",
                    description: "What is your standard rate for translation?",
                    type: "num",
                    required: true
                },
                rateForProofreading: {
                    title: "Proofreading rate per source word/character ",
                    description: "What is your standard rate for proofreading? (Please, leave a dash if you don’t proofread).",
                    type: "num",
                    required: true
                },
                hourlyRate: {
                    title: "Standard hourly rate",
                    description: "What is your standard hourly rate?",
                    type: "num",
                    required: true
                },
                currency: {
                    title: "Currency",
                    description: "What currency do you prefer to get payment in?",
                    type: "select",
                    selectOptions: {
                        'USD': {},
                        'EUR': {},
                        'RUR': {}
                    },
                    required: true
                },
                yearsOfLocalizing: {
                    title: "How many years have you been localizing games?",
                    type: "num",
                    required: true
                },
                favoriteGenres: {
                    title: "Favorite genres (select options)",
                    type: "multiselect",
                    selectOptions: {
                        'Action': {},
                        'Adventure': {},
                        'Role-Playing (RPG)': {},
                        'Simulation': {},
                        'Strategy': {},
                        'Sports': {},
                        'Puzzle': {},
                        'Casual': {},
                        'Arcade': {},
                        'Shooter': {},
                        'Horror': {},
                        'Racing': {},
                        'Fighting': {},
                        'Platformer': {},
                        'Stealth': {},
                        'Survival': {},
                        'Music/Rhythm': {},
                        'Battle Royale': {},
                        'Visual Novel': {},
                        'Sandbox': {}
                    },
                    required: false
                },
                genresToAvoid: {
                    title: "Genres to avoid (select options)",
                    type: "multiselect",
                    selectOptions: {
                        'Action': {},
                        'Adventure': {},
                        'Role-Playing (RPG)': {},
                        'Simulation': {},
                        'Strategy': {},
                        'Sports': {},
                        'Puzzle': {},
                        'Casual': {},
                        'Arcade': {},
                        'Shooter': {},
                        'Horror': {},
                        'Racing': {},
                        'Fighting': {},
                        'Platformer': {},
                        'Stealth': {},
                        'Survival': {},
                        'Music/Rhythm': {},
                        'Battle Royale': {},
                        'Visual Novel': {},
                        'Sandbox': {}
                    },
                    required: false
                },
                titlesWorkedOn : {
                    title: "Relevant titles you've worked on ",
                    description: "Please list the relevant projects or titles you have worked on in the past.",
                    type: "textarea",
                    required: false
                },
                typeOfEmployment: {
                    title: "Type of employment",
                    type: "select",
                    selectOptions: {
                        'I\'m officially registered as self-employed': {},
                        'I\'m officially registered as a business': {},
                        'Other': {}
                    },
                    required: true
                },
                coverLetter: {
                    title: "Cover letter",
                    description: "We'll be happy to get to know you a bit more, so write a few lines about yourself if you'd like to.",
                    type: "textarea",
                    required: false
                },
                resource: {
                    title: "Resource (select options)",
                    description: "How you heard about us?",
                    type: "select",
                    selectOptions: {
                        '- https://www.proz.com': {},
                        '- https://www.translatorscafe.com': {},
                        '- http://www.trworkshop.net': {},
                        '- www.allcorrectgames.com': {},
                        '- networking': {},
                        '- https://www.linkedin.com': {},
                        '- HH': {},
                        '- discord': {},
                        '- JAT': {},
                        '- other': {}
                    },
                    required: true
                },
                agreement: {
                    title: "Agreement to personal data processing",
                    description: "I hereby give consent for my personal data included in the CV to be processed for the purposes of recruitment. I agree with GDPR policy https://allcorrectgames.com/gdpr/",
                    type: "checkbox",
                    required: true
                },
                sendCopyOfAnswers: {
                    title: "Send copy of answers to my email",
                    type: "checkbox",
                    required: false
                }
            }
        },
        lqa: {
            title: "linguistic game tester",
            submit: false,
            fields: {
                freelancerOrAgency: {
                    title: "Are you a freelancer or an agency? (select an option)",
                    type: "select",
                    selectOptions: {
                        '-Individual freelancer': {},
                        '-Translation agency': {}
                    },
                    required: true
                },
                firstName: {
                    title: "First name",
                    description: "What is your first name?",
                    type: "input",
                    required: true
                },
                lastName: {
                    title: "Last Name",
                    description: "What is your last name?",
                    type: "input",
                    required: true
                },
                preferredGenderPronoun: {
                    title: "Preferred gender pronoun",
                    description: "Please select the pronoun you prefer to be addressed by.",
                    type: "select",
                    selectOptions: {
                        'He/Him': {},
                        'She/Her': {},
                        'They/Them': {},
                        'Other (please specify)': {
                            id: "preferredGenderPronounOther",
                            title: "",
                            type: "input",
                            required: true
                        },
                        'Prefer not to say': {}
                    },
                    required: false
                },
                email: {
                    title: "E-mail",
                    description: "What is your best contact email address?",
                    type: "input",
                    required: true
                },
                secondaryEmail: {
                    title: "Secondary e-mail (if used) ",
                    type: "input",
                    required: false
                },
                contacts: {
                    title: "Skype / Discord / Telegram / WhatsApp",
                    description: "Please provide your contact information for any of the following messengers you use.",
                    type: "textarea",
                    required: false
                },
                availability : {
                    title: "Availability",
                    description: "Please indicate your available days and hours for work.",
                    type: "textarea",
                    required: false
                },
                workOnWeekends: {
                    title: "Do you work weekends? (select an option)",
                    type: "select",
                    selectOptions: {
                        'Yes, always': {},
                        'Yes, sometimes': {},
                        'No': {},
                        'Only on Saturdays': {},
                        'Only on Sundays': {},
                        'It depends': {}
                    },
                    required: false
                },
                cv: {
                    title: "CV",
                    description: "Please attach your CV.",
                    type: "file",
                    required: true
                },
                certifications  : {
                    title: "Certifications",
                    description: "Please list any relevant certifications you hold.",
                    type: "textarea",
                    required: false
                },
                country: {
                    title: "Country",
                    description: "What country do you live in?",
                    type: "input",
                    required: true
                },
                city: {
                    title: "City",
                    description: "What city do you live in?",
                    type: "input",
                    required: true
                },
                motherLanguage: {
                    title: "Mother language (select an option)",
                    type: "select",
                    selectOptions: {
                        'Albanian': {},
                        'Arabic': {},
                        'Armenian': {},
                        'Azerbaijani': {},
                        'Belarusian': {},
                        'Bulgarian': {},
                        'Chechen': {},
                        'Chinese Simplified': {},
                        'Chinese Trad HK': {},
                        'Chinese Trad TW': {},
                        'Croatian': {},
                        'Czech': {},
                        'Danish': {},
                        'Dutch': {},
                        'English': {},
                        'Estonian': {},
                        'Finnish': {},
                        'French': {},
                        'French Canadian': {},
                        'Georgian': {},
                        'German': {},
                        'Greek (Modern)': {},
                        'Hebrew (modern)': {},
                        'Hindi': {},
                        'Hungarian': {},
                        'Indonesian': {},
                        'Italian': {},
                        'Japanese': {},
                        'Kazakh': {},
                        'Khmer': {},
                        'Kirghiz or Kyrgyz': {},
                        'Korean': {},
                        'Latvian': {},
                        'Lithuanian': {},
                        'Malay': {},
                        'Marathi': {},
                        'Mongolian': {},
                        'Norwegian': {},
                        'Persian': {},
                        'Polish': {},
                        'Portuguese (BR)': {},
                        'Portuguese (ES)': {},
                        'Romanian': {},
                        'Russian': {},
                        'Serbian': {},
                        'Slovak': {},
                        'Slovenian': {},
                        'Spanish American': {},
                        'Spanish Castilian': {},
                        'Swedish': {},
                        'Tagalog': {},
                        'Tajik': {},
                        'Thai': {},
                        'Turkish': {},
                        'Turkmen': {},
                        'Ukrainian': {},
                        'Urdu': {},
                        'Urdu India': {},
                        'Uzbek': {},
                        'Vietnamese': {}
                    },
                    required: true
                },
                sourceLanguageFirst: {
                    title: "First source language (select options)",
                    type: "select",
                    selectOptions: {
                        'Albanian': {},
                        'Arabic': {},
                        'Armenian': {},
                        'Azerbaijani': {},
                        'Belarusian': {},
                        'Bulgarian': {},
                        'Chechen': {},
                        'Chinese Simplified': {},
                        'Chinese Trad HK': {},
                        'Chinese Trad TW': {},
                        'Croatian': {},
                        'Czech': {},
                        'Danish': {},
                        'Dutch': {},
                        'English': {},
                        'Estonian': {},
                        'Finnish': {},
                        'French': {},
                        'French Canadian': {},
                        'Georgian': {},
                        'German': {},
                        'Greek (Modern)': {},
                        'Hebrew (modern)': {},
                        'Hindi': {},
                        'Hungarian': {},
                        'Indonesian': {},
                        'Italian': {},
                        'Japanese': {},
                        'Kazakh': {},
                        'Khmer': {},
                        'Kirghiz or Kyrgyz': {},
                        'Korean': {},
                        'Latvian': {},
                        'Lithuanian': {},
                        'Malay': {},
                        'Marathi': {},
                        'Mongolian': {},
                        'Norwegian': {},
                        'Persian': {},
                        'Polish': {},
                        'Portuguese (BR)': {},
                        'Portuguese (ES)': {},
                        'Romanian': {},
                        'Russian': {},
                        'Serbian': {},
                        'Slovak': {},
                        'Slovenian': {},
                        'Spanish American': {},
                        'Spanish Castilian': {},
                        'Swedish': {},
                        'Tagalog': {},
                        'Tajik': {},
                        'Thai': {},
                        'Turkish': {},
                        'Turkmen': {},
                        'Ukrainian': {},
                        'Urdu': {},
                        'Urdu India': {},
                        'Uzbek': {},
                        'Vietnamese': {}
                    },
                    required: true
                },
                sourceLanguageSecond: {
                    title: "Second source language (select options)",
                    type: "select",
                    selectOptions: {
                        'Albanian': {},
                        'Arabic': {},
                        'Armenian': {},
                        'Azerbaijani': {},
                        'Belarusian': {},
                        'Bulgarian': {},
                        'Chechen': {},
                        'Chinese Simplified': {},
                        'Chinese Trad HK': {},
                        'Chinese Trad TW': {},
                        'Croatian': {},
                        'Czech': {},
                        'Danish': {},
                        'Dutch': {},
                        'English': {},
                        'Estonian': {},
                        'Finnish': {},
                        'French': {},
                        'French Canadian': {},
                        'Georgian': {},
                        'German': {},
                        'Greek (Modern)': {},
                        'Hebrew (modern)': {},
                        'Hindi': {},
                        'Hungarian': {},
                        'Indonesian': {},
                        'Italian': {},
                        'Japanese': {},
                        'Kazakh': {},
                        'Khmer': {},
                        'Kirghiz or Kyrgyz': {},
                        'Korean': {},
                        'Latvian': {},
                        'Lithuanian': {},
                        'Malay': {},
                        'Marathi': {},
                        'Mongolian': {},
                        'Norwegian': {},
                        'Persian': {},
                        'Polish': {},
                        'Portuguese (BR)': {},
                        'Portuguese (ES)': {},
                        'Romanian': {},
                        'Russian': {},
                        'Serbian': {},
                        'Slovak': {},
                        'Slovenian': {},
                        'Spanish American': {},
                        'Spanish Castilian': {},
                        'Swedish': {},
                        'Tagalog': {},
                        'Tajik': {},
                        'Thai': {},
                        'Turkish': {},
                        'Turkmen': {},
                        'Ukrainian': {},
                        'Urdu': {},
                        'Urdu India': {},
                        'Uzbek': {},
                        'Vietnamese': {}
                    },
                    required: false
                },
                foreignLanguagesLevel: {
                    title: "Foreign languages level (select options)",
                    description: "Select your level of proficiency in foreign languages from the following options.",
                    type: "select",
                    selectOptions: {
                        'Beginner': {},
                        'Elementary': {},
                        'Intermediate': {},
                        'Upper-Intermediate': {},
                        'Advanced': {},
                        'Proficient': {},
                        'Native': {}
                    },
                    required: true
                },
                hourlyRate: {
                    title: "Standard hourly rate",
                    description: "What is your standard hourly rate?",
                    type: "num",
                    required: true
                },
                currency: {
                    title: "Currency",
                    description: "What currency do you prefer to get payment in?",
                    type: "select",
                    selectOptions: {
                        'USD': {},
                        'EUR': {},
                        'RUR': {}
                    },
                    required: true
                },
                mobileOperatingSystem: {
                    title: "Mobile operating system (select options)",
                    description: "Select your mobile operating system from the following options.",
                    type: "multiselect",
                    selectOptions: {
                        'Android': {},
                        'iOS': {},
                        'Windows Phone': {},
                        'Other': {}
                    },
                    required: true
                },
                platformKnowledge: {
                    title: "Platform knowledge (select options)",
                    description: "Please select the platforms you have knowledge of from the dropdown list.",
                    type: "multiselect",
                    selectOptions: {
                        'Windows': {},
                        'macOS': {},
                        'Linux': {},
                        'Android': {},
                        'iOS': {},
                        'Web Development (HTML/CSS/JavaScript)': {},
                        'Cloud Platforms (AWS, Azure, Google Cloud)': {},
                        'Other': {}
                    },
                    required: true
                },
                devicesOwn: {
                    title: "What devices do you own to play games? (select options)",
                    description: "Please select the devices you own for playing games from the dropdown list.",
                    type: "multiselect",
                    selectOptions: {
                        'PC': {},
                        'Mac': {},
                        'PlayStation': {},
                        'Xbox': {},
                        'Nintendo Switch': {},
                        'Smartphone': {},
                        'Tablet': {},
                        'VR Headset': {},
                        'Other': {}
                    },
                    required: true
                },
                favoriteGenres: {
                    title: "Favorite genres (select options)",
                    type: "multiselect",
                    selectOptions: {
                        'Action': {},
                        'Adventure': {},
                        'Role-Playing (RPG)': {},
                        'Simulation': {},
                        'Strategy': {},
                        'Sports': {},
                        'Puzzle': {},
                        'Casual': {},
                        'Arcade': {},
                        'Shooter': {},
                        'Horror': {},
                        'Racing': {},
                        'Fighting': {},
                        'Platformer': {},
                        'Stealth': {},
                        'Survival': {},
                        'Music/Rhythm': {},
                        'Battle Royale': {},
                        'Visual Novel': {},
                        'Sandbox': {}
                    },
                    required: false
                },
                genresToAvoid: {
                    title: "Genres to avoid (select options)",
                    type: "multiselect",
                    selectOptions: {
                        'Action': {},
                        'Adventure': {},
                        'Role-Playing (RPG)': {},
                        'Simulation': {},
                        'Strategy': {},
                        'Sports': {},
                        'Puzzle': {},
                        'Casual': {},
                        'Arcade': {},
                        'Shooter': {},
                        'Horror': {},
                        'Racing': {},
                        'Fighting': {},
                        'Platformer': {},
                        'Stealth': {},
                        'Survival': {},
                        'Music/Rhythm': {},
                        'Battle Royale': {},
                        'Visual Novel': {},
                        'Sandbox': {}
                    },
                    required: false
                },
                titlesWorkedOn : {
                    title: "Relevant titles you've worked on ",
                    description: "Please list the relevant projects or titles you have worked on in the past.",
                    type: "textarea",
                    required: false
                },
                typeOfEmployment: {
                    title: "Type of employment",
                    description: "",
                    type: "select",
                    selectOptions: {
                        'I\'m officially registered as self-employed': {},
                        'I\'m officially registered as a business': {},
                        'Other': {}
                    },
                    required: true
                },
                coverLetter: {
                    title: "Cover letter",
                    description: "We'll be happy to get to know you a bit more, so write a few lines about yourself if you'd like to.",
                    type: "textarea",
                    required: false
                },
                resource: {
                    title: "Resource (select options)",
                    description: "How you heard about us?",
                    type: "select",
                    selectOptions: {
                        '- https://www.proz.com': {},
                        '- https://www.translatorscafe.com': {},
                        '- http://www.trworkshop.net': {},
                        '- www.allcorrectgames.com': {},
                        '- networking': {},
                        '- https://www.linkedin.com': {},
                        '- HH': {},
                        '- discord': {},
                        '- JAT': {},
                        '- other': {}
                    },
                    required: true
                },
                agreement: {
                    title: "Agreement to personal data processing",
                    description: "I hereby give consent for my personal data included in the CV to be processed for the purposes of recruitment. I agree with GDPR policy https://allcorrectgames.com/gdpr/",
                    type: "checkbox",
                    required: true
                },
                sendCopyOfAnswers: {
                    title: "Send copy of answers to my email",
                    type: "checkbox",
                    required: false
                }
            }
        }
    })
    const [filledValues, setFilledValues] = useState([{
        data: []
    }])
    const [modalAlertActive, setModalAlertActive] = useState(false);
    const [modalAlertData, setModalAlertData] = useState([{
        message: ""
    }]);

    let getCategoryTabs = () => {
        let content = []

        for (let key in categoriesData) {
            let value = categoriesData[key]

            content.push(
                <Tab key={key} eventKey={key}
                     title={value.title}>
                    <div className="tab-item-wrapper">
                        <br/>
                        <CategoryFieldsForm
                            category={key}
                            data={categoriesData}
                            setFieldValue={setFieldValue}
                            setAdditionalFieldValue={setAdditionalFieldValue}
                            showAlertMessage={showAlertMessage}
                            validateEmail={validateEmail}
                            filledValues={filledValues[0].data}
                        />
                        <br/>
                        <br/>
                        {
                            !value.submit &&
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <Button
                                        variant="primary"
                                        onClick={(e) =>
                                            checkFillIn(key)}>
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                </Tab>
            )
        }

        return content
    }

    async function setFieldValue(selectedCategory, id, type, value) {
        // console.log("setFieldValue", filledValues, id, type, value)

        let filledValueToSet = ""

        if (type === "file") {
            if (value) {
                const formData = new FormData()
                let date = new Date();
                let separator = 'img/folk/cv'
                let fileDirectoryLocation = separator + '/' + ISODateString(date)

                formData.append('myFile', value[0])

                const response = await fetch('/proxy/upload_file?fileDirectoryLocation=' + fileDirectoryLocation + '&separator=' + separator, {
                    method: 'POST',
                    body: formData
                });
                let result = await response.json();

                filledValueToSet = {
                    value: result.fullPath,
                    additionalFields: {}
                }
            }
        } else {
            filledValueToSet = {
                value: value,
                additionalFields: {}
            }
        }

        setFilledValues(
            filledValues.map(info => {
                if (!(selectedCategory in info.data)) {
                    info.data[selectedCategory] = {}
                }

                info.data[selectedCategory][id] = filledValueToSet

                return info
            })
        )
    }

    async function setAdditionalFieldValue(selectedCategory, id, additionalId, type, value) {
        // console.log("setAdditionalFieldValue", filledValues, id, type, value)

        setFilledValues(
            filledValues.map(info => {
                info.data[selectedCategory][id].additionalFields[additionalId] = value

                return info
            })
        )
    }

    function ISODateString(date) {
        function pad(n) {
            return n < 10 ? '0' + n : n
        }
        return date.getUTCFullYear() + '-'
            + pad(date.getUTCMonth() + 1 )+ '-'
            + pad(date.getUTCDate())/* + 'T'
            + pad(date.getUTCHours()) + ':'
            + pad(date.getUTCMinutes()) + ':'
            + pad(date.getUTCSeconds()) + 'Z'*/
    }

    async function checkFillIn(selectedCategory) {
        let isPermissionToSubmit = true
        let listOfFields = categoriesData[selectedCategory].fields

        for (let key in listOfFields) {
            let value = listOfFields[key]

            if (value.required) {
                let fieldElId = "field-" + selectedCategory + "-" + key
                let fieldRedTextElId = "red-required-text-" + selectedCategory + "-" + key

                removeRedAlertBorder(fieldElId, fieldRedTextElId)

                if (!isValueFilled(selectedCategory, key, value, value.required)) {
                    isPermissionToSubmit = false

                    showRedAlertBorder(fieldElId, fieldRedTextElId)
                    showAlertMessage("required fields")
                }
            } else if (value.type === "select") {
                if (!isValueFilled(selectedCategory, key, value, value.required)) {
                    isPermissionToSubmit = false

                    showAlertMessage("required fields")
                }
            }
        }

        if (isPermissionToSubmit) {
            await submitForm(selectedCategory)
        }
    }

    function isValueFilled(selectedCategory, key, value, required) {
        // console.log("isValueFilled", selectedCategory, key, value, required)

        if (value.type === "select" && !required) {
            if (selectedCategory in filledValues[0].data) {
                if (key in filledValues[0].data[selectedCategory]) {
                    let fieldValue = filledValues[0].data[selectedCategory][key].value

                    return isSelectAdditionalFieldFilled(selectedCategory, key, fieldValue, value);
                } else {
                    return true
                }
            } else {
                return true
            }
        } else {
            if (selectedCategory in filledValues[0].data) {
                if (key in filledValues[0].data[selectedCategory]) {
                    let fieldValue = filledValues[0].data[selectedCategory][key].value

                    if (value.type === "input" || value.type === "textarea" || value.type === "num") {
                        let fieldValueWithoutSpaces = fieldValue.replace(/^\s+/, '').replace(/\s+$/, '');

                        if (fieldValueWithoutSpaces === "") {
                            return false
                        } else {
                            if (key.toLowerCase().indexOf("email") !== -1 && !validateEmail(fieldValue)) {
                                showAlertMessage("email")

                                return false
                            } else {
                                return true
                            }
                        }
                    } else if (value.type === "checkbox") {
                        return !!fieldValue;
                    } else if (value.type === "select") {
                        if (fieldValue === "") {
                            return false
                        } else {
                            return isSelectAdditionalFieldFilled(selectedCategory, key, fieldValue, value);
                        }
                    } else if (value.type === "multiselect") {
                        return fieldValue.length !== 0;
                    } else if (value.type === "file") {
                        return fieldValue !== "";
                    }
                } else {
                    return false
                }
            } else {
                return false
            }
        }

        return true
    }

    function isSelectAdditionalFieldFilled(selectedCategory, key, fieldValue, value) {
        let selectOptionsValue = value.selectOptions[fieldValue]

        if ("id" in selectOptionsValue && selectOptionsValue.required) {
            let additionalFieldId = selectOptionsValue.id
            let additionalFieldType = selectOptionsValue.type
            let additionalFieldElId = "field-" + selectedCategory + "-" + key + "-" + additionalFieldId
            let additionalFieldRedTextElId = "red-required-text-" + selectedCategory + "-" + key + "-" + additionalFieldId

            removeRedAlertBorder(additionalFieldElId, additionalFieldRedTextElId)

            if (additionalFieldId in filledValues[0].data[selectedCategory][key].additionalFields) {
                let additionalFieldValue = filledValues[0].data[selectedCategory][key].additionalFields[additionalFieldId]

                if (additionalFieldType === "input") {
                    let additionalFieldValueWithoutSpaces = additionalFieldValue.replace(/^\s+/, '').replace(/\s+$/, '');

                    if (additionalFieldValueWithoutSpaces === "") {
                        showRedAlertBorder(additionalFieldElId, additionalFieldRedTextElId)

                        return false
                    } else {
                        return true
                    }
                }
            } else {
                showRedAlertBorder(additionalFieldElId, additionalFieldRedTextElId)

                return false
            }
        } else {
            return true
        }
    }

    function removeRedAlertBorder(fieldElId, redTextId) {
        document.getElementById(redTextId).style.display = "none"
        document.getElementById(fieldElId).style.borderColor = "rgb(222, 226, 230)"
    }

    function showRedAlertBorder(fieldElId, redTextId) {
        document.getElementById(redTextId).style.display = "inline"
        document.getElementById(fieldElId).style.border = "1px solid #ff0000"
        document.getElementById(fieldElId).style.borderRadius = "5px"
        document.getElementById(fieldElId).focus()
    }

    function validateEmail(value) {
        return EMAIL_REGEXP.test(value)
    }

    async function submitForm(selectedCategory) {
        showAlertMessage("submit waiting")

        const response = await fetch('/proxy/addRecordToFolk', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                category: selectedCategory,
                data: getArrayOfData(selectedCategory)
            })
        });
        let result = await response.json();

        if ("error" in result) {
            showAlertMessage("submit fail")
        } else {
            showAlertMessage("submit success")
            hideSubmitBtn(selectedCategory)
        }
    }

    function hideSubmitBtn(selectedCategory) {
        categoriesData[selectedCategory].submit = true
    }

    function getArrayOfData(selectedCategory) {
        let arrayOfData = {}

        for (let fieldKey in categoriesData[selectedCategory].fields) {
            let value = categoriesData[selectedCategory].fields[fieldKey]

            if (value.type === "input" || value.type === "textarea" || value.type === "num" || value.type === "file") {
                if (fieldKey in filledValues[0].data[selectedCategory]) {
                    arrayOfData[fieldKey] = filledValues[0].data[selectedCategory][fieldKey].value
                } else {
                    arrayOfData[fieldKey] = ""
                }
            } else if (value.type === "select") {
                if (fieldKey in filledValues[0].data[selectedCategory]) {
                    let currentFieldFilledData = filledValues[0].data[selectedCategory][fieldKey]
                    let valueToSet = currentFieldFilledData.value

                    arrayOfData[fieldKey] = valueToSet

                    if ("id" in value.selectOptions[valueToSet] &&
                        value.selectOptions[valueToSet].id in currentFieldFilledData.additionalFields) {
                        arrayOfData[fieldKey] += ": " + currentFieldFilledData.additionalFields[value.selectOptions[valueToSet].id]
                    }
                } else {
                    arrayOfData[fieldKey] = ""
                }
            } else if (value.type === "multiselect") {
                if (fieldKey in filledValues[0].data[selectedCategory]) {
                    arrayOfData[fieldKey] = filledValues[0].data[selectedCategory][fieldKey].value
                } else {
                    arrayOfData[fieldKey] = []
                }
            } else if (value.type === "checkbox") {
                if (fieldKey in filledValues[0].data[selectedCategory]) {
                    arrayOfData[fieldKey] = filledValues[0].data[selectedCategory][fieldKey].value ? "✅" : "❌"
                } else {
                    arrayOfData[fieldKey] = "❌"
                }
            }
        }

        return arrayOfData
    }

    function showAlertMessage(type) {
        let message  = ""

        if (type === "email") {
            message = "Please enter a valid email"
        } else if (type === "required fields") {
            message = "Please fill in required fields"
        } else if (type === "submit success") {
            message = "The application form has been successfully submitted."
        } else if (type === "submit fail") {
            message = "An error occurred during submission."
        } else if (type === "submit waiting") {
            message = "Please wait."
        }

        setModalAlertData(
            modalAlertData.map(info => {
                info.message = message

                return info
            })
        )

        setModalAlertActive(true)
    }

    return (
        <div className="formBlockView">
            <div className="row formFieldRow">
                <div className="col-sm-12 center">
                    <h2>Join our team!</h2>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-sm-12">
                    <Tabs defaultActiveKey="localization">
                        { getCategoryTabs() }
                    </Tabs>
                </div>
            </div>
            <AlertMessageModal
                modalAlertActive={modalAlertActive}
                setModalAlertActive={setModalAlertActive}
                message={modalAlertData[0].message} />
        </div>
    )
}