import {Button, Col, Nav, Row, Table, Tabs, Tab} from "react-bootstrap";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faMagic, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";
import LanguageTabPreferences from "./LanguageTabPreferences";
import LanguageTabCharacterCommunication from "./LanguageTabCharacterCommunication";

const styles = {
    blockHeader: {
        backgroundColor: '#e0f0ff',
    },

    editableCell: {
        width: '50%'
    },

    noEditableCell: {
        backgroundColor: '#f6f6f6',
        width: '50%'
    },
}

export default function LanguageTab(props) {
    /*console.log("LANGUAGE TAB props.characterCommunications[0]",
        props.characterCommunications[0].languagesData,
        props.characterCommunications[0].languagesData[props.language.code])*/

    return (
        <div>
            {
                props.communicationBetweenCharacters ? (
                    <Tab.Container defaultActiveKey={"languagePreferences"}>
                        <Row>
                            <Col sm={12}>
                                <Nav variant="pills" className="nav-custom-grey">
                                    <Nav.Item>
                                        <Nav.Link eventKey={"languagePreferences"}>
                                            Language Preferences
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={"communicationBetweenCharacters"}>
                                            Communication between characters
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey={"languagePreferences"}>
                                        <div className="tab-item-wrapper">
                                            <br/>
                                            <LanguageTabPreferences
                                                language={props.language}
                                                linguistLinks={props.linguistLinks}
                                                addRowToStyleGuideLanguageBlock={props.addRowToStyleGuideLanguageBlock}
                                                deleteRowFromStyleGuideLanguageBlock={props.deleteRowFromStyleGuideLanguageBlock}
                                                modalAddLinksAndImagesActive={props.modalAddLinksAndImagesActive}
                                                modalOpenAddedImageActive={props.modalOpenAddedImageActive}
                                                triggerOpenInModalAddedImage={props.triggerOpenInModalAddedImage}
                                                languageTabSavePreferencesCellValue={props.languageTabSavePreferencesCellValue}
                                                openModalToAddLinksAndImages={props.openModalToAddLinksAndImages}
                                                selectTemplateTextForLanguageTabPreferences={props.selectTemplateTextForLanguageTabPreferences}
                                                projectManagementInfo={props.projectManagementInfo}
                                                employeeId={props.employeeId}
                                                employeeName={props.employeeName}
                                            />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={"communicationBetweenCharacters"}>
                                        <div className="tab-item-wrapper">
                                            <br/>
                                            <LanguageTabCharacterCommunication
                                                language={props.language.code}
                                                characters={props.characterCommunications[0].characters}
                                                templateData={props.characterCommunications[0].templateData}
                                                languageData={props.characterCommunications[0].languagesData[props.language.code].data}
                                                languageDataFromLinguist={props.characterCommunications[0].languagesData[props.language.code].linguistAcceptedData}
                                                // languageAccept={props.characterCommunications[0].languagesData[props.language.code].accept}
                                                oninputCharacterCommunicationCell={props.oninputCharacterCommunicationCell}
                                            />
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                ) : (
                    <LanguageTabPreferences
                        language={props.language}
                        linguistLinks={props.linguistLinks}
                        addRowToStyleGuideLanguageBlock={props.addRowToStyleGuideLanguageBlock}
                        deleteRowFromStyleGuideLanguageBlock={props.deleteRowFromStyleGuideLanguageBlock}
                        modalAddLinksAndImagesActive={props.modalAddLinksAndImagesActive}
                        modalOpenAddedImageActive={props.modalOpenAddedImageActive}
                        triggerOpenInModalAddedImage={props.triggerOpenInModalAddedImage}
                        languageTabSavePreferencesCellValue={props.languageTabSavePreferencesCellValue}
                        openModalToAddLinksAndImages={props.openModalToAddLinksAndImages}
                        selectTemplateTextForLanguageTabPreferences={props.selectTemplateTextForLanguageTabPreferences}
                        projectManagementInfo={props.projectManagementInfo}
                    />
                )
            }
        </div>
    )
}
