import {Nav, Tabs, Tab, Row, Col} from "react-bootstrap";
import React from "react";
import ClientViewLanguageTab from "./ClientViewLanguageTab";
import TableInfo from "./TableInfo";

export default function ClientViewTabs(props) {
    return(
        <Tab.Container defaultActiveKey={"project"}>
            <Row>
                <Col sm={12}>
                    <Nav variant="pills" className="nav-custom-grey">
                        <Nav.Item>
                            <Nav.Link eventKey={"project"}>
                                Project
                            </Nav.Link>
                        </Nav.Item>
                        {
                            props.languages.map(language => {
                                if (language.value) {
                                    return(
                                        <Nav.Item>
                                            <Nav.Link key={language.code} eventKey={language.code}>
                                                {language.title}
                                            </Nav.Link>
                                        </Nav.Item>
                                    )
                                }
                            })
                        }
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey={"project"}>
                            <div className="tab-item-wrapper">
                                <br/>
                                <div className="row">
                                    <div className="col-sm-12 tableFixHead">
                                        {<TableInfo columns={props.columns} rows={props.rows} submit={props.submit}
                                                    setFormUpdateValue={props.setFormUpdateValue}/>}
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        {
                            props.languages.map(language => {
                                if (language.value) {
                                    return(
                                        <Tab.Pane key={language.code} eventKey={language.code}>
                                            <div className="tab-item-wrapper">
                                                <br/>
                                                <ClientViewLanguageTab
                                                    language={language} submit={props.submit}
                                                    characterCommunications={props.characterCommunications}
                                                    communicationBetweenCharacters={props.communicationBetweenCharacters}
                                                    setFormUpdateValue={props.setFormUpdateValue}/>
                                            </div>
                                        </Tab.Pane>
                                    )
                                }
                            })
                        }
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
}