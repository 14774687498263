import "../css/modal.css"
import {Button, Col, Form, FormCheck, FormControl, FormLabel, ProgressBar, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Tabs} from "react-bootstrap";
import {Tab} from "bootstrap";
import LinguistLanguageTab from "./LinguistLanguageTab";

const styles = {
    modalWH: {
        width: '95%',
        height: '95%',
        background: '#222' /* dark-theme */
    }
}

export default function LinguistAcceptDataModal(props) {
    console.log("LinguistAcceptDataModal props", props)

    if (props.linguistAcceptDataModalActive) {
        return(
            <div className={props.linguistAcceptDataModalActive ? "modal active" : "modal"} onClick={() => props.setLinguistAcceptDataModalActive(false)} >
                <div className={props.linguistAcceptDataModalActive ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}
                     style={styles.modalWH} >
                    {
                        props.linguistData.data.languagePreferences.map(language => {
                            if (language.code === props.languageCode) {
                                /*let defaultLanguageData = []

                                props.allLanguages.map(value => {
                                    if (value.title === language.title) {
                                        // defaultLanguageData = value/!*.data*!/

                                        setDefaultLanguage(value)
                                    }
                                })*/

                                /*props.allDefaultLanguages.map(value => {
                                    if (value.title === language.title) {
                                        defaultLanguageData = value.data
                                    }
                                })*/

                                return (
                                    <div key={language.code} className="tab-item-wrapper">
                                        <div className="row">
                                            <div className="col-sm-12 center">
                                                <h5 className="center">{language.title}</h5>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <LinguistLanguageTab
                                                    linguistCode={props.linguistData.code}
                                                    acceptData={props.linguistData.acceptData}
                                                    bufferedData={props.linguistData.bufferedData}
                                                    language={language}
                                                    defaultLanguageData={props.defaultLanguageData/*defaultLanguageData*//*[0]*/}
                                                    characterCommunications={props.linguistData.data.characterCommunications}
                                                    setLinguistAcceptDataModalActive={props.setLinguistAcceptDataModalActive}
                                                    linguistLinks={props.linguistLinks}
                                                    characterCommunicationsDefault={props.characterCommunications}
                                                    resetAcceptedFromOtherLinguistCharactersValues={props.resetAcceptedFromOtherLinguistCharactersValues}
                                                    setLinguistCharactersValues={props.setLinguistCharactersValues}
                                                    getCharacterId={props.getCharacterId}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        )
    } else {
        return(
            <div className={props.linguistAcceptDataModalActive ? "modal active" : "modal"} onClick={() => props.setLinguistAcceptDataModalActive(false)} >
                <div className={props.linguistAcceptDataModalActive ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}
                     style={styles.modalWH} >
                </div>
            </div>
        )
    }
}