import React, {useState} from "react";
import {Button, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

const styles = {
    blockHeaderRow: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    blockHeader: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent',
        width: "45%"
    },

    blockHeaderAccept: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent',
        width: "10%"
    },

    bufferedRow: {
        backgroundColor: '#fffee0'
    },

    normalRow: {

    },

    rejectedRow: {
        backgroundColor: '#f8e7e7'
    },

    acceptedRow: {
        backgroundColor: '#e9f8e7'
    },

    noEditableCell: {
        background: '#fff'
    }
}

export default function LinguistLanguageTabPreferences(props) {
    const [hideRejectedRows, setHideRejectedRows] = useState(false)

    console.log("LinguistLanguageTabPreferences", props)

    function onHideRejectedRows(value) {
        setHideRejectedRows(value)
    }

    let getLanguagePreferencesBlocks = (blocks) => {
        let content = []
        let acceptData = getAcceptData(props.defaultLanguageData, props.linguistLinks) //массив только тех строк, которые были приняты

        // console.log("LinguistLanguageTabPreferences acceptData", acceptData)

        for (let key in blocks) {
            let value = blocks[key]

            console.log("block rows", key, value)

            if (value.linguistEditable) {
                let fieldName = getBlockId(value.title)
                let rowsForRender = {
                    default: [],
                    extra: []
                }
                let updatedDefaultLanguageData = []
                let listOfRowsCode = []
                let linguistLanguageData = { //только дефолтные и родные строки данного лингвиста (без принятых строк от других лингвистов)
                    default: [],
                    extra: []
                }

                props.defaultLanguageData.data[0][fieldName].data.map(defaultData => {
                    if (fieldName in acceptData && defaultData.code in acceptData[fieldName].default) {
                        let rowValue = defaultData
                        let acceptValue = acceptData[fieldName].default[defaultData.code]

                        rowValue["acceptFromLinguistRow"] = acceptValue.data
                        /*if (props.linguistCode === acceptValue.acceptFromLinguist) {
                            rowValue["acceptFromLinguistRow"] = acceptValue.data
                        } else {
                            rowValue.row = acceptValue.data
                        }*/

                        rowValue["acceptFromLinguist"] = acceptValue.acceptFromLinguist
                        updatedDefaultLanguageData.push(rowValue)
                        listOfRowsCode.push(rowValue.code)
                    } else {
                        updatedDefaultLanguageData.push(defaultData)
                        listOfRowsCode.push(defaultData.code)
                    }

                    return defaultData
                })

                if (fieldName in acceptData) {
                    acceptData[fieldName].extra.map(extraData => {
                        // console.log("extraData!!!", extraData)

                        /*let rowValue = extraData

                        rowValue["acceptFromLinguist"] = extraData.acceptFromLinguist
                        updatedDefaultLanguageData.push(rowValue)*/
                        updatedDefaultLanguageData.push(extraData)
                        listOfRowsCode.push(extraData.code)

                        return extraData
                    })
                }

                if (fieldName in props.bufferedData) {
                    if ("otherLinguistExtra" in props.bufferedData[fieldName]) { //экстра строки другого лингвиста
                        let bufferedDataForOtherLinguistRows = props.bufferedData[fieldName].otherLinguistExtra

                        for (let otherLinguistId in bufferedDataForOtherLinguistRows) {
                            props.linguistLinks[otherLinguistId].data.languagePreferences.map(searchLanguage => {
                                if (searchLanguage.code === props.language.code) {
                                    searchLanguage.data[0][fieldName].data.map(searchRow => {
                                        if (searchRow.code in bufferedDataForOtherLinguistRows[otherLinguistId] &&
                                            !listOfRowsCode.includes(searchRow.code)) {
                                            searchRow["createdBy"] = otherLinguistId

                                            if (!("acceptFromLinguist" in searchRow)) {
                                                searchRow["acceptFromLinguist"] = ""
                                            }

                                            updatedDefaultLanguageData.push(searchRow)
                                            listOfRowsCode.push(searchRow.code)
                                        }

                                        return searchRow
                                    })
                                }

                                return searchLanguage
                            })
                        }
                    }
                }

                console.log("updatedDefaultLanguageData", updatedDefaultLanguageData)

                //здесь данные строк на лингвиста, по которому открыли модальное окно
                value.data.map(data => {
                    if ("linguistExtraRow" in data && data.linguistExtraRow) {
                        // linguistLanguageData.extra.push(data)
                        linguistLanguageData.extra[data.code] = data
                    } else {
                        linguistLanguageData.default[data.code] = data
                    }

                    return data
                })

                updatedDefaultLanguageData.map(updatedData => {
                    let checked = false
                    let checkedFixed = false
                    let bufferedRow = false
                    let rowColor = styles.normalRow
                    let linguist = []
                    // let linguist = updatedData
                    let acceptFromLinguist = ""
                    let linguistExtraRow = false
                    let createdBy = ""

                    //если рассматривается дефолтная строка
                    if (updatedData.code in linguistLanguageData.default) {
                        let defaultRowData = updatedData.row
                        linguist = linguistLanguageData.default[updatedData.code]
                        // let linguistRowData = linguist.row

                        if ("acceptFromLinguist" in updatedData && updatedData.acceptFromLinguist) {
                            acceptFromLinguist = updatedData.acceptFromLinguist
                            linguist.row = updatedData.acceptFromLinguistRow //TODO
                            // linguist["replaceToLinguistAccepted"] = true
                            // linguist["acceptFromLinguistRow"] = updatedData.acceptFromLinguistRow
                            rowColor = styles.acceptedRow
                            checkedFixed = updatedData.acceptFromLinguist !== props.linguistCode

                            /*if (updatedData.acceptFromLinguist === props.linguistCode) {
                                // linguist = linguistLanguageData.default[updatedData.code]

                                updatedData["replaceToLinguistAccepted"] = false //todo?
                                checkedFixed = false
                                rowColor = styles.acceptedRow
                            } else {
                                // linguist = linguistLanguageData.default[updatedData.code]
                                // updatedData.row = updatedData.acceptFromLinguistRow //TODO

                                updatedData["replaceToLinguistAccepted"] = true //todo?
                                checkedFixed = true
                            }*/
                        } else {
                            // linguist = linguistLanguageData.default[updatedData.code]

                            let linguistRowData = linguist.row

                            checkedFixed = true

                            //мне кажется это лишнее
                           /* for (let i = 0; i < defaultRowData.length; i++) {
                                if (defaultRowData[i].text !== linguistRowData[i].text) {
                                    checkedFixed = false

                                    break;
                                }
                            }*/
                        }

                        /*if (defaultRowData.length > 0 && linguistRowData.length === defaultRowData.length) {
                            if ("acceptFromLinguist" in updatedData &&
                                updatedData.acceptFromLinguist === props.linguistCode) {
                                acceptFromLinguist = updatedData.acceptFromLinguist
                                checkedFixed = false
                                rowColor = styles.acceptedRow
                            } else {
                                checkedFixed = true

                                for (let i = 0; i < defaultRowData.length; i++) {
                                    if (defaultRowData[i].text !== linguistRowData[i].text) {
                                        checkedFixed = false

                                        break;
                                    }
                                }
                            }
                        }*/
                    //если рассматривается экстра строка данного лингвиста
                    } else if (updatedData.code in linguistLanguageData.extra) {
                        linguist = updatedData
                        checkedFixed = false

                        if ("acceptFromLinguist" in updatedData) {
                            acceptFromLinguist = updatedData.acceptFromLinguist
                            rowColor = styles.acceptedRow
                            checkedFixed = updatedData.acceptFromLinguist !== props.linguistCode;
                        }

                        createdBy = props.linguistCode
                        linguistExtraRow = true
                    //если рассматривается экстра строка другого лингвиста
                    } else {
                        // console.log("если рассматривается экстра строка другого лингвиста", updatedData)

                        linguist = updatedData
                        acceptFromLinguist = updatedData.acceptFromLinguist
                        linguistExtraRow = true
                        checkedFixed = acceptFromLinguist !== props.linguistCode;

                        //наличие параметра "createdBy" подразумевает, что это - экстра строка другого лингвиста, но
                        //которая была изменена еще одним другим лингвистом (может и текущим) и его изменения
                        if ("createdBy" in updatedData) {
                            createdBy = updatedData.createdBy
                        } else {
                            createdBy = acceptFromLinguist
                        }
                    }

                    if (checkedFixed) {
                        checked = true
                    } else {
                        if (fieldName in props.acceptedCheckedInfo) {
                            /*if ((props.acceptedCheckedInfo[fieldName].default.includes(updatedData.code) ||
                                props.acceptedCheckedInfo[fieldName].extra.includes(updatedData.code))/!* || "createdBy" in updatedData*!/) {
                                checked = true
                            }*/

                            if (acceptFromLinguist) {
                                checked = true
                            }
                        }
                    }

                    if (fieldName in props.bufferedData) {
                        if (updatedData.code in props.bufferedData[fieldName].default ||
                            updatedData.code in props.bufferedData[fieldName].extra) {
                            bufferedRow = true
                            checkedFixed = false
                            checked = false

                            if (updatedData.code in props.bufferedData[fieldName].default) {
                                for (let i = 0; i < updatedData.row.length; i++) {
                                    if (updatedData.row[i].code in props.bufferedData[fieldName].default[updatedData.code]) {
                                        updatedData.row[i]["bufferedText"] = []
                                        updatedData.row[i]["bufferedText"][props.linguistCode] = props.bufferedData[fieldName].default[updatedData.code][updatedData.row[i].code]
                                        linguist.row[i]["bufferedText"] = []
                                        linguist.row[i]["bufferedText"][props.linguistCode] = props.bufferedData[fieldName].default[updatedData.code][updatedData.row[i].code]
                                    }
                                }
                            } else if (updatedData.code in props.bufferedData[fieldName].extra) {
                                for (let i = 0; i < updatedData.row.length; i++) {
                                    if (updatedData.row[i].code in props.bufferedData[fieldName].extra[updatedData.code]) {
                                        updatedData.row[i]["bufferedText"] = []
                                        updatedData.row[i]["bufferedText"][props.linguistCode] = props.bufferedData[fieldName].extra[updatedData.code][updatedData.row[i].code]
                                        linguist.row[i]["bufferedText"] = []
                                        linguist.row[i]["bufferedText"][props.linguistCode] = props.bufferedData[fieldName].extra[updatedData.code][updatedData.row[i].code]
                                    }
                                }
                            }
                        } else if ("otherLinguistExtra" in props.bufferedData[fieldName]) { //экстра строки другого лингвиста
                            let bufferedDataForOtherLinguistRows = props.bufferedData[fieldName].otherLinguistExtra

                            if (createdBy in bufferedDataForOtherLinguistRows &&
                                updatedData.code in bufferedDataForOtherLinguistRows[createdBy]) {
                                let rowBufferedData = bufferedDataForOtherLinguistRows[createdBy][updatedData.code]

                                bufferedRow = true
                                checkedFixed = false
                                checked = false

                                for (let i = 0; i < updatedData.row.length; i++) {
                                    if (updatedData.row[i].code in rowBufferedData) {
                                        updatedData.row[i]["bufferedText"] = []
                                        updatedData.row[i]["bufferedText"][props.linguistCode] = rowBufferedData[updatedData.row[i].code]
                                        linguist.row[i]["bufferedText"] = []
                                        linguist.row[i]["bufferedText"][props.linguistCode] = rowBufferedData[updatedData.row[i].code]
                                    }
                                }
                            }

                            /*searchRow.row.map(searchColumn => {
                                searchColumn.text =
                                    bufferedDataForOtherLinguistRows[otherLinguistId][searchRow.code][searchColumn.code]

                                return searchColumn
                            })*/
                        }

                        /*if (props.bufferedData[fieldName].default.includes(updatedData.code)) {
                            bufferedRow = true
                            checked = false
                        } else if ("acceptFromLinguist" in updatedData &&
                            updatedData.acceptFromLinguist === props.linguistCode &&
                            props.bufferedData[fieldName].extra.includes(updatedData.code)) {
                            bufferedRow = true
                            checked = false
                            updatedData.row = updatedData.acceptFromLinguistRow
                        }*/
                    }

                    if (bufferedRow) {
                        rowColor = styles.bufferedRow
                    } else if (!checked) {
                        rowColor = styles.rejectedRow
                    }

                    rowsForRender.default.push({
                        linguist: linguist,
                        default: updatedData,
                        checked: checked,
                        checkedFixed: checkedFixed,
                        bufferedRow: bufferedRow,
                        rowColor: rowColor,
                        acceptFromLinguist: acceptFromLinguist,
                        linguistExtraRow: linguistExtraRow,
                        createdBy: createdBy
                    })

                    return updatedData
                })

                for (let linguistExtraData in linguistLanguageData.extra) {
                    let linguistExtraDataValue = linguistLanguageData.extra[linguistExtraData]

                    if (!(fieldName in props.acceptedCheckedInfo) ||
                        (fieldName in props.acceptedCheckedInfo &&
                            !props.acceptedCheckedInfo[fieldName].extra.includes(linguistExtraDataValue.code))) {
                        // let bufferedRow = false
                        let rowColor = /*styles.normalRow*/styles.rejectedRow

                        /*if (fieldName in props.bufferedData) {
                            if (props.bufferedData[fieldName].extra.includes(linguistExtraDataValue.code)) {
                                bufferedRow = true
                            }
                        }

                        if (bufferedRow) {
                            rowColor = styles.bufferedRow
                        } else {
                            rowColor = styles.rejectedRow
                        }*/

                        rowsForRender.extra.push({
                            linguist: linguistExtraDataValue,
                            checked: false,
                            // bufferedRow: bufferedRow,
                            rowColor: rowColor
                        })
                    }
                }

                if (fieldName === "target_language_features") {
                    // console.log("acceptData", acceptData)
                    // console.log("updatedDefaultLanguageData", updatedDefaultLanguageData)
                    // console.log("linguistLanguageData", linguistLanguageData)
                    // console.log("props.acceptedCheckedInfo", props.acceptedCheckedInfo)
                    console.log("rowsForRender", rowsForRender)
                }

                content.push(
                    <div className="row">
                        <div className="col-sm-12 table-blue-thead-not-fixed">
                            <Table responsive bordered>
                                <thead>
                                <tr style={styles.blockHeaderRow}>
                                    <th className="center" colSpan={2} style={styles.blockHeader}>
                                        {value.title}
                                    </th>
                                    <th className="center" style={styles.blockHeaderAccept}>
                                        Accept
                                    </th>
                                    <th className="center" colSpan={2} style={styles.blockHeader}>
                                        Default data {value.title}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    rowsForRender.default.map(defaultRows => {
                                        let linguistRow = defaultRows.linguist
                                        let defaultRow = defaultRows.default
                                        let rowType = "linguistExtraRow" in defaultRows && defaultRows.linguistExtraRow ? "extra" : "default"
                                        let acceptedByLinguistValue = defaultRows.acceptFromLinguist
                                        let createdByValue = defaultRows.createdBy
                                        let isBufferedRowValue = defaultRows.bufferedRow
                                        let baseRowInformation = {
                                            createdBy: createdByValue,
                                            previouslyAcceptedBy: acceptedByLinguistValue,
                                            isBuffered: isBufferedRowValue,
                                            checked: defaultRows.checked
                                        }

                                        if (!(hideRejectedRows && !defaultRows.checked)) {
                                            return(
                                                <tr key={linguistRow.code} id={linguistRow.code} style={defaultRows.rowColor}>
                                                    {
                                                        rowType === "default" ? (
                                                            <>
                                                                {
                                                                    acceptedByLinguistValue ? (
                                                                        <>
                                                                            {
                                                                                isBufferedRowValue ? (
                                                                                    <>
                                                                                        {
                                                                                            acceptedByLinguistValue !== props.linguistCode &&
                                                                                            <>
                                                                                                {
                                                                                                    linguistRow.row.map((rowData, rowDataIndex) => {
                                                                                                        let cellText = rowData.text

                                                                                                        if ("bufferedText" in rowData && props.linguistCode in rowData.bufferedText) {
                                                                                                            cellText = rowData.bufferedText[props.linguistCode]
                                                                                                        }

                                                                                                        return (
                                                                                                            <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                                colSpan={linguistRow.colspan ? 2 : 1}
                                                                                                                contentEditable={false}
                                                                                                                style={styles.noEditableCell}
                                                                                                                dangerouslySetInnerHTML={{__html: cellText}}>
                                                                                                            </td>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                                <td className="center" style={styles.noEditableCell}>
                                                                                                    <input type="checkbox" className="form-check-input store-checkbox"
                                                                                                           onChange={(e) =>
                                                                                                               props.setLanguagePreferencesAcceptValue(key, linguistRow.code, rowType, e.target.checked, baseRowInformation)}
                                                                                                           defaultChecked={defaultRows.checked} />
                                                                                                </td>
                                                                                                {
                                                                                                    linguistRow.row/*defaultRow.acceptFromLinguistRow*/.map((rowData, rowDataIndex) => {
                                                                                                        return (
                                                                                                            <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                                colSpan={linguistRow.colspan ? 2 : 1}
                                                                                                                contentEditable={false} style={styles.noEditableCell}
                                                                                                                dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                                                            </td>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                    </>
                                                                                ) : (
                                                                                    acceptedByLinguistValue === props.linguistCode ? (
                                                                                        <>
                                                                                            {
                                                                                                linguistRow.row.map((rowData, rowDataIndex) => {
                                                                                                    return (
                                                                                                        <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                            colSpan={linguistRow.colspan ? 2 : 1}
                                                                                                            contentEditable={false} style={styles.noEditableCell}
                                                                                                            dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                                                        </td>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                            <td className="center" style={styles.noEditableCell}>
                                                                                                <input type="checkbox"
                                                                                                       className="form-check-input store-checkbox"
                                                                                                       onChange={(e) =>
                                                                                                           props.setLanguagePreferencesAcceptValue(key, linguistRow.code, rowType, e.target.checked, baseRowInformation)}
                                                                                                       defaultChecked={defaultRows.checked}/>
                                                                                            </td>
                                                                                            {
                                                                                                defaultRow.row.map((rowData, rowDataIndex) => {
                                                                                                    return (
                                                                                                        <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                            colSpan={defaultRow.colspan ? 2 : 1}
                                                                                                            contentEditable={false} style={styles.noEditableCell}
                                                                                                            dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                                                        </td>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            {
                                                                                                linguistRow.row.map((rowData, rowDataIndex) => {
                                                                                                    return (
                                                                                                        <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                            colSpan={defaultRow.colspan ? 2 : 1}
                                                                                                            contentEditable={false} style={styles.noEditableCell}
                                                                                                            dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                                                        </td>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                            <td className="center" style={styles.noEditableCell}>
                                                                                                <input type="checkbox"
                                                                                                       className="form-check-input store-checkbox"
                                                                                                       /*onChange={(e) =>
                                                                                                           props.setLanguagePreferencesAcceptValue(key, linguistRow.code, rowType, e.target.checked, baseRowInformation)}*/
                                                                                                       checked={defaultRows.checked} readOnly={true}/>
                                                                                            </td>
                                                                                            {
                                                                                                defaultRow.row.map((rowData, rowDataIndex) => {
                                                                                                    return (
                                                                                                        <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                            colSpan={defaultRow.colspan ? 2 : 1}
                                                                                                            contentEditable={false} style={styles.noEditableCell}
                                                                                                            dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                                                        </td>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                )
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                isBufferedRowValue ? (
                                                                                    <>
                                                                                        {
                                                                                            linguistRow.row.map((rowData, rowDataIndex) => {
                                                                                                return (
                                                                                                    <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                        colSpan={linguistRow.colspan ? 2 : 1}
                                                                                                        contentEditable={false} style={styles.noEditableCell}
                                                                                                        dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        <td className="center" style={styles.noEditableCell}>
                                                                                            <input type="checkbox"
                                                                                                   className="form-check-input store-checkbox"
                                                                                                   onChange={(e) =>
                                                                                                       props.setLanguagePreferencesAcceptValue(key, linguistRow.code, rowType, e.target.checked, baseRowInformation)}
                                                                                                   defaultChecked={defaultRows.checked}/>
                                                                                        </td>
                                                                                        {
                                                                                            defaultRow.row.map((rowData, rowDataIndex) => {
                                                                                                return (
                                                                                                    <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                        colSpan={defaultRow.colspan ? 2 : 1}
                                                                                                        contentEditable={false} style={styles.noEditableCell}
                                                                                                        dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {
                                                                                            defaultRow.row.map((rowData, rowDataIndex) => {
                                                                                                return (
                                                                                                    <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                        colSpan={defaultRow.colspan ? 2 : 1}
                                                                                                        contentEditable={false} style={styles.noEditableCell}
                                                                                                        dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        <td className="center" style={styles.noEditableCell}>
                                                                                            <input type="checkbox"
                                                                                                   className="form-check-input store-checkbox"
                                                                                                   /*onChange={(e) =>
                                                                                                       props.setLanguagePreferencesAcceptValue(key, linguistRow.code, rowType, e.target.checked, baseRowInformation)}*/
                                                                                                   checked={defaultRows.checked} readOnly={true}/>
                                                                                        </td>
                                                                                        {
                                                                                            defaultRow.row.map((rowData, rowDataIndex) => {
                                                                                                return (
                                                                                                    <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                        colSpan={defaultRow.colspan ? 2 : 1}
                                                                                                        contentEditable={false} style={styles.noEditableCell}
                                                                                                        dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                {
                                                                    acceptedByLinguistValue === props.linguistCode ? (
                                                                        <>
                                                                            {
                                                                                linguistRow.row.map((rowData, rowDataIndex) => {
                                                                                    let cellText = "acceptedText" in rowData ? rowData.acceptedText : rowData.text

                                                                                    return (
                                                                                        <td key={rowData.code} className="whiteSpacePreLine"
                                                                                            colSpan={linguistRow.colspan ? 2 : 1}
                                                                                            contentEditable={false} style={styles.noEditableCell}
                                                                                            dangerouslySetInnerHTML={{__html: cellText}}>
                                                                                        </td>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <td className="center" style={styles.noEditableCell}>
                                                                                <input type="checkbox"
                                                                                       className="form-check-input store-checkbox"
                                                                                       onChange={(e) =>
                                                                                           props.setLanguagePreferencesAcceptValue(key, linguistRow.code, rowType, e.target.checked, baseRowInformation)}
                                                                                       defaultChecked={defaultRows.checked}/>
                                                                            </td>
                                                                            {
                                                                                createdByValue === props.linguistCode ? (
                                                                                    <td className="whiteSpacePreLine" colSpan="2"
                                                                                        contentEditable={false} style={{textAlign: 'center', background: '#fff'}}>
                                                                                        -
                                                                                    </td>
                                                                                ) : (
                                                                                    <>
                                                                                        {
                                                                                            defaultRow.row.map((rowData, rowDataIndex) => {
                                                                                                return (
                                                                                                    <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                        colSpan={defaultRow.colspan ? 2 : 1}
                                                                                                        contentEditable={false} style={styles.noEditableCell}
                                                                                                        dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                createdByValue === props.linguistCode ? (
                                                                                    <>
                                                                                        {/*{
                                                                                            isBufferedRowValue ? (
                                                                                                <>
                                                                                                    {
                                                                                                        linguistRow.row.map((rowData, rowDataIndex) => {
                                                                                                            return (
                                                                                                                <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                                    colSpan={linguistRow.colspan ? 2 : 1}
                                                                                                                    contentEditable={false}
                                                                                                                    dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                                                                </td>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                    <td className="center">
                                                                                                        <input type="checkbox"
                                                                                                               className="form-check-input store-checkbox"
                                                                                                               onChange={(e) =>
                                                                                                                   props.setLanguagePreferencesAcceptValue(key, linguistRow.code, rowType, e.target.checked, baseRowInformation)}
                                                                                                               defaultChecked={defaultRows.checked}/>
                                                                                                    </td>
                                                                                                    {
                                                                                                        linguistRow.row.map((rowData, rowDataIndex) => {
                                                                                                            let cellText = "acceptedText" in rowData ? rowData.acceptedText : rowData.text

                                                                                                            return (
                                                                                                                <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                                    colSpan={linguistRow.colspan ? 2 : 1}
                                                                                                                    contentEditable={false}
                                                                                                                    dangerouslySetInnerHTML={{__html: cellText}}>
                                                                                                                </td>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {
                                                                                                        linguistRow.row.map((rowData, rowDataIndex) => {
                                                                                                            let cellText = "acceptedText" in rowData ? rowData.acceptedText : rowData.text

                                                                                                            return (
                                                                                                                <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                                    colSpan={linguistRow.colspan ? 2 : 1}
                                                                                                                    contentEditable={false}
                                                                                                                    dangerouslySetInnerHTML={{__html: cellText}}>
                                                                                                                </td>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                    <td className="center">
                                                                                                        <input type="checkbox"
                                                                                                               className="form-check-input store-checkbox"
                                                                                                               checked={defaultRows.checked} readOnly={true}/>
                                                                                                    </td>
                                                                                                    {
                                                                                                        defaultRow.row.map((rowData, rowDataIndex) => {
                                                                                                            return (
                                                                                                                <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                                    colSpan={defaultRow.colspan ? 2 : 1}
                                                                                                                    contentEditable={false}
                                                                                                                    dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                                                                </td>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </>
                                                                                            )
                                                                                        }*/}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {
                                                                                            isBufferedRowValue ? (
                                                                                                <>
                                                                                                    {
                                                                                                        linguistRow.row.map((rowData, rowDataIndex) => {
                                                                                                            let cellText = rowData.text

                                                                                                            if ("bufferedText" in rowData && props.linguistCode in rowData.bufferedText) {
                                                                                                                cellText = rowData.bufferedText[props.linguistCode]
                                                                                                            }

                                                                                                            return (
                                                                                                                <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                                    colSpan={linguistRow.colspan ? 2 : 1}
                                                                                                                    contentEditable={false}
                                                                                                                    dangerouslySetInnerHTML={{__html: cellText}}
                                                                                                                    style={{backgroundColor: defaultRows.rowColor.backgroundColor}}>
                                                                                                                </td>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                    <td className="center" style={{backgroundColor: defaultRows.rowColor.backgroundColor}}>
                                                                                                        <input type="checkbox"
                                                                                                               className="form-check-input store-checkbox"
                                                                                                               onChange={(e) =>
                                                                                                                   props.setLanguagePreferencesAcceptValue(key, linguistRow.code, rowType, e.target.checked, baseRowInformation)}
                                                                                                               defaultChecked={defaultRows.checked}/>
                                                                                                    </td>
                                                                                                    {
                                                                                                        linguistRow.row/*defaultRow.acceptFromLinguistRow*/.map((rowData, rowDataIndex) => {
                                                                                                            return (
                                                                                                                <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                                    colSpan={linguistRow.colspan ? 2 : 1}
                                                                                                                    contentEditable={false}
                                                                                                                    dangerouslySetInnerHTML={{__html: rowData.text}}
                                                                                                                    style={{backgroundColor: defaultRows.rowColor.backgroundColor}}>
                                                                                                                </td>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {
                                                                                                        linguistRow.row.map((rowData, rowDataIndex) => {
                                                                                                            let cellText = "acceptedText" in rowData ? rowData.acceptedText : rowData.text

                                                                                                            return (
                                                                                                                <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                                    colSpan={linguistRow.colspan ? 2 : 1}
                                                                                                                    contentEditable={false} style={styles.noEditableCell}
                                                                                                                    dangerouslySetInnerHTML={{__html: cellText}}>
                                                                                                                </td>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                    <td className="center" style={styles.noEditableCell}>
                                                                                                        <input type="checkbox"
                                                                                                               className="form-check-input store-checkbox"
                                                                                                               /*onChange={(e) =>
                                                                                                                   props.setLanguagePreferencesAcceptValue(key, linguistRow.code, rowType, e.target.checked, baseRowInformation)}*/
                                                                                                               checked={defaultRows.checked} readOnly={true}/>
                                                                                                    </td>
                                                                                                    {
                                                                                                        createdByValue === acceptedByLinguistValue ? (
                                                                                                            <td className="whiteSpacePreLine" colSpan="2"
                                                                                                                contentEditable={false} style={{textAlign: 'center', background: '#fff'}}>
                                                                                                                -
                                                                                                            </td>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {
                                                                                                                    linguistRow.row.map((rowData, rowDataIndex) => {
                                                                                                                        return (
                                                                                                                            <td key={rowData.code} className="whiteSpacePreLine"
                                                                                                                                colSpan={linguistRow.colspan ? 2 : 1}
                                                                                                                                contentEditable={false} style={styles.noEditableCell}
                                                                                                                                dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                                                                            </td>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </tr>
                                            )

                                            /*return(
                                                <tr key={linguistRow.code} id={linguistRow.code} style={defaultRows.rowColor}>
                                                    {
                                                        "replaceToLinguistAccepted" in linguistRow &&
                                                        linguistRow["replaceToLinguistAccepted"] ? (
                                                            <>
                                                                {
                                                                    linguistRow.acceptFromLinguistRow.map((rowData, rowDataIndex) => {
                                                                        return (
                                                                            <td key={rowData.code} className="whiteSpacePreLine"
                                                                                colSpan={linguistRow.colspan ? 2 : 1}
                                                                                contentEditable={false}
                                                                                dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                            </td>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                {
                                                                    linguistRow.row.map((rowData, rowDataIndex) => {
                                                                        let cellText = rowData.text

                                                                        if ("createdBy" in linguistRow && linguistRow.acceptFromLinguist !== linguistRow.acceptFromLinguist) {
                                                                            cellText = rowData.acceptedText
                                                                        }

                                                                        return (
                                                                            <td key={rowData.code} className="whiteSpacePreLine"
                                                                                colSpan={linguistRow.colspan ? 2 : 1}
                                                                                contentEditable={false}
                                                                                dangerouslySetInnerHTML={{__html: cellText}}>
                                                                            </td>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        )
                                                    }
                                                    <td className="center">
                                                        {
                                                            defaultRows.checkedFixed ? (
                                                                <input type="checkbox" className="form-check-input store-checkbox"
                                                                       /!*onChange={(e) =>
                                                                           props.setLanguagePreferencesAcceptValue(key, linguistRow.code, rowType, e.target.checked, baseRowInformation)}*!/
                                                                       checked={defaultRows.checked} />
                                                            ) : (
                                                                <input type="checkbox" className="form-check-input store-checkbox"
                                                                       onChange={(e) =>
                                                                           props.setLanguagePreferencesAcceptValue(key, linguistRow.code, rowType, e.target.checked, baseRowInformation)}
                                                                       defaultChecked={defaultRows.checked} />
                                                            )
                                                        }
                                                    </td>
                                                    {
                                                        defaultRows.linguistExtraRow &&
                                                            defaultRows.acceptFromLinguist === props.linguistCode ? (
                                                               <td className="whiteSpacePreLine" colSpan="2"
                                                                   contentEditable={false} style={{textAlign: 'center'}}>
                                                                   -
                                                               </td>
                                                        ) : (
                                                            <>
                                                                {
                                                                    "replaceToLinguistAccepted" in defaultRow &&
                                                                    defaultRow["replaceToLinguistAccepted"] ? (
                                                                        <>
                                                                            {
                                                                                defaultRow.acceptFromLinguistRow.map((rowData, rowDataIndex) => {
                                                                                    return (
                                                                                        <td key={rowData.code} className="whiteSpacePreLine"
                                                                                            colSpan={defaultRow.colspan ? 2 : 1}
                                                                                            contentEditable={false}
                                                                                            dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                                        </td>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                defaultRow.row.map((rowData, rowDataIndex) => {
                                                                                    return (
                                                                                        <td key={rowData.code} className="whiteSpacePreLine"
                                                                                            colSpan={defaultRow.colspan ? 2 : 1}
                                                                                            contentEditable={false}
                                                                                            dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                                        </td>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </tr>
                                            )*/
                                        }
                                    })
                                }
                                {
                                    rowsForRender.extra.map(extraRows => {
                                        let linguistRow = extraRows.linguist
                                        let baseRowInformation = {
                                            createdBy: props.linguistCode,
                                            previouslyAcceptedBy: "",
                                            isBuffered: false,
                                            checked: false
                                        }

                                        if (!(hideRejectedRows && !extraRows.checked)) {
                                            return(
                                                <tr key={linguistRow.code} id={linguistRow.code} style={extraRows.rowColor}>
                                                    {
                                                        linguistRow.row.map((rowData, rowDataIndex) => {
                                                            return (
                                                                <td key={rowData.code} className="whiteSpacePreLine"
                                                                    colSpan={linguistRow.colspan ? 2 : 1}
                                                                    contentEditable={false} style={styles.noEditableCell}
                                                                    dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                    <td className="center" style={styles.noEditableCell}>
                                                        <input type="checkbox" className="form-check-input store-checkbox"
                                                               onChange={(e) =>
                                                                   props.setLanguagePreferencesAcceptValue(key, linguistRow.code, "extra", e.target.checked, baseRowInformation)}
                                                               defaultChecked={false} />
                                                    </td>
                                                    <td className="whiteSpacePreLine" colSpan="2"
                                                        contentEditable={false} style={{textAlign: 'center', background: '#fff'}}>
                                                        -
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })
                                }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                )
            }
        }

        return content
    }

    function getBlockId(title) {
        if (title === "Project Management Info") {
            return "project_management_info"
        } else if (title === "Punctuation marks") {
            return "punctuation_marks"
        } else if (title === "Capital letters") {
            return "capital_letters"
        } else if (title === "Target language features") {
            return "target_language_features"
        } else if (title === "Inclusive language guidelines") {
            return "inclusive_language_guidelines"
        } else {
            return "base_info"
        }
    }

    function getAcceptData(defaultLanguageData, linguistLinks) {
        let languageAcceptData = defaultLanguageData.accept
        let acceptData = {}

        for (let key in languageAcceptData) {
            let linguistValue = languageAcceptData[key]
            let languageAcceptDataLinguistId = linguistValue.linguistId
            let languageAcceptDataValue = linguistValue.value

            if (Object.keys(languageAcceptDataValue).length > 0) {
                linguistLinks[languageAcceptDataLinguistId].data.languagePreferences.map(infoLinguistLinks => {
                    if (infoLinguistLinks.code === defaultLanguageData.code) { //проверка на нужный язык
                        defaultLanguageData.data.map(languageDataInfo => {
                            for (let blockName in languageAcceptDataValue) {
                                languageDataInfo[blockName].data.map(languageBlockData => {
                                    let rowCode = languageBlockData.code

                                    if (languageAcceptDataValue[blockName].default.includes(rowCode)) {
                                        infoLinguistLinks.data[0][blockName].data.map(infoLinguistLinkKeyBlock => {
                                            if (infoLinguistLinkKeyBlock.code === rowCode) {
                                                if (!(blockName in acceptData)) {
                                                    acceptData[blockName] = {
                                                        "default": [],
                                                        "extra": []
                                                    }
                                                }

                                                acceptData[blockName].default[rowCode] = {
                                                    acceptFromLinguist: languageAcceptDataLinguistId,
                                                    data: infoLinguistLinkKeyBlock.row
                                                }
                                            }

                                            return infoLinguistLinkKeyBlock
                                        })
                                    }

                                    return languageBlockData
                                })

                                for (let i = 0; i < languageAcceptDataValue[blockName].extra.length; i++) {
                                    infoLinguistLinks.data[0][blockName].data.map(infoLinguistLinkKeyBlock => {
                                        if (infoLinguistLinkKeyBlock.code === languageAcceptDataValue[blockName].extra[i]) {
                                            if (!(blockName in acceptData)) {
                                                acceptData[blockName] = {
                                                    "default": [],
                                                    "extra": []
                                                }
                                            }

                                            infoLinguistLinkKeyBlock["acceptFromLinguist"] = languageAcceptDataLinguistId
                                            acceptData[blockName].extra.push(infoLinguistLinkKeyBlock)
                                        }

                                        return infoLinguistLinkKeyBlock
                                    })
                                }

                                if ("otherLinguistExtra" in languageAcceptDataValue[blockName]) {
                                    let otherLinguistExtra = languageAcceptDataValue[blockName].otherLinguistExtra

                                    for (let otherLinguist in otherLinguistExtra) {
                                        linguistLinks[otherLinguist].data.languagePreferences.map(infoOtherLinguistLinks => {
                                            if (infoOtherLinguistLinks.code === defaultLanguageData.code) { //проверка на нужный язык
                                                let infoOtherLinguistLinksRows =  infoOtherLinguistLinks.data[0][blockName].data

                                                for (let i = 0; i < infoOtherLinguistLinksRows.length; i++) {
                                                    if (infoOtherLinguistLinksRows[i].code in otherLinguistExtra[otherLinguist]) {
                                                        if (!(blockName in acceptData)) {
                                                            acceptData[blockName] = {
                                                                "default": [],
                                                                "extra": []
                                                            }
                                                        }

                                                        infoOtherLinguistLinksRows[i]["acceptFromLinguist"] = languageAcceptDataLinguistId
                                                        infoOtherLinguistLinksRows[i]["createdBy"] = otherLinguist
                                                        acceptData[blockName].extra.push(infoOtherLinguistLinksRows[i])
                                                    }
                                                }
                                            }

                                            return infoOtherLinguistLinks
                                        })
                                    }

                                }
                            }

                            return languageDataInfo
                        })
                    }

                    return infoLinguistLinks
                })
            }
        }

        return acceptData
    }

    return(
        <div key={props.language.code} className="row">
            <div className="row">
                <div className="col-sm-12">
                    <div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input"
                               onChange={(e) => onHideRejectedRows(e.target.checked)}
                               checked={hideRejectedRows}/>
                        <label className="form-check-label">Hide rejected rows</label>
                    </div>
                </div>
            </div>
            { getLanguagePreferencesBlocks(props.language.data[0]) }
        </div>
    )
}