import React, {useState} from "react";
import {Button, ProgressBar, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import XLSX from "xlsx";
import translate from "translate";

const styles = {
    progressBar: {
        margin: '10rem 10rem'
    },

    tableFixHeadTh: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        whiteSpace: 'pre-line',
        backgroundColor: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: '#4A8EFF'
    },

    tableRowHeader: {
        backgroundColor: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: '#4A8EFF',
    },

    tableBodyTopBorder: {
        borderTop: '0px'
    },

    noEditableCell: {
        backgroundColor: '#fff'
    },
}

export default function LocReviewsDataSteam(props) {
    const [isTranslated, setIsTranslated] = React.useState(false)
    const [translationOnLoad, setTranslationOnLoad] = React.useState(false)
    const [translationOnLoadPercentage, setTranslationOnLoadPercentage] = React.useState(0)
    const [reviewsArray, setReviewsArray] = useState([{
        data: props.data
    }])

    function createExcel() {
        let wb = XLSX.utils.book_new()
        let ws_data = excelData()
        let ws = XLSX.utils.aoa_to_sheet(ws_data)

        wb.Props = {
            Title: "Loc Reviews",
            Subject: "Loc Reviews",
            Author: "Allcorrect"
        }

        wb.SheetNames.push("Loc_Reviews")
        wb.Sheets["Loc_Reviews"] = ws
        XLSX.writeFile(wb, props.appId + ".xlsx")
    }

    function excelData() {
        let data = []
        let rowCount = 1

        data.push(["№", "User", "Date", "Review", "Google translation", "Found keys", "Link", "Rating", "Language",
            "Type", "L+", "L-", "RL", "YL"])

        reviewsArray.map(info => {
            info.data.map(value => {
                data.push([rowCount++, value.user, value.date, value.review, value.googleTranslation,
                    value.foundedKeys, value.link, value.rating, value.language, value.type, value["prob_l+"],
                    value["prob_l-"], value.prob_rl, value.prob_yl])
            })

            return info
        })

        return data
    }

    function translateReviews() {
        setIsTranslated(true)

        setReviewsArray(
            reviewsArray.map(info => {
                let reviewsCount = info.data.length

                setTranslationOnLoad(true)

                info.data.map(async (value, index) => {
                    const translateResponse = await fetch('/proxy/mobile_store_proxy/google_cloud_translate', {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({review: value.review})
                    });
                    let translationResult = await translateResponse.json();

                    value.googleTranslation = translationResult.translation

                    setTranslationOnLoadPercentage(Math.round((index + 1) / reviewsCount * 100))

                    return value
                })

                setTranslationOnLoad(false)

                return info
            })
        )
    }

    const getRows = (reviews) => {
        let content = []

        reviews.map(info => {
            console.log("INFO", info)

            info.data.map((value, index) => {
                content.push(
                    <tr key={index}>
                        <td style={styles.noEditableCell}>{index + 1}</td>
                        <td style={styles.noEditableCell}><a href={value.user} target="_blank" style={{textDecoration: 'none'}}>👾</a></td>
                        <td style={styles.noEditableCell}>{value.date}</td>
                        <td style={styles.noEditableCell} dangerouslySetInnerHTML={{__html: value.reviewColorKeys}}></td>
                        <td style={styles.noEditableCell}>{value.googleTranslation}</td>
                        <td style={styles.noEditableCell}>{value.foundedKeys}</td>
                        <td style={styles.noEditableCell}><a href={value.link} target="_blank" style={{textDecoration: 'none'}}>🎮</a></td>
                        <td style={styles.noEditableCell}>{value.voted_up ? "👍" : "👎"}</td>
                        <td style={styles.noEditableCell}>{value.language}</td>
                        <td style={styles.noEditableCell}>{value.type}</td>
                        <td style={styles.noEditableCell}>{value["prob_l+"]}</td>
                        <td style={styles.noEditableCell}>{value["prob_l-"]}</td>
                        <td style={styles.noEditableCell}>{value.prob_rl}</td>
                        <td style={styles.noEditableCell}>{value.prob_yl}</td>
                    </tr>
                )
            })

            return info
        })

        return content
    }

    return(
        <div className="row">
            <div className="col-sm-12 center">
                {
                    props.data &&
                    <div>
                        <Button variant="success" onClick={(e) => createExcel()}>
                            Выгрузить excel&nbsp;&nbsp;<FontAwesomeIcon icon={faFileExcel}/>
                        </Button>
                        {
                            !isTranslated &&
                            <>
                                &nbsp;&nbsp;
                                <Button variant="primary" className="btn btn-blue" onClick={(e) => translateReviews()}>
                                    Перевести отзывы&nbsp;&nbsp;<FontAwesomeIcon icon={faFileExcel}/>
                                </Button>
                            </>
                        }
                        {
                            translationOnLoad &&
                            <div className="row" style={styles.progressBar}>
                                <div className="col-sm-4">

                                </div>
                                <div className="col-sm-4 center">
                                    <h4>Loading...</h4>
                                    <ProgressBar now={translationOnLoadPercentage} label={`${translationOnLoadPercentage}%`} />
                                </div>
                                <div className="col-sm-4">

                                </div>
                            </div>
                        }
                        <br/>
                        <br/>
                        <Table className="table-loc-reviews" responsive bordered>
                            <thead>
                            <tr style={styles.tableRowHeader}>
                                <th className="center" style={styles.tableFixHeadTh}>№</th>
                                <th className="center" style={styles.tableFixHeadTh}>User</th>
                                <th className="center" style={styles.tableFixHeadTh}>Date</th>
                                <th className="center" style={styles.tableFixHeadTh}>Review</th>
                                <th className="center" style={styles.tableFixHeadTh}>Google translation</th>
                                <th className="center" style={styles.tableFixHeadTh}>Founded keys</th>
                                <th className="center" style={styles.tableFixHeadTh}>Link</th>
                                <th className="center" style={styles.tableFixHeadTh}>Rating</th>
                                <th className="center" style={styles.tableFixHeadTh}>Language</th>
                                <th className="center" style={styles.tableFixHeadTh}>Type</th>
                                <th className="center" style={styles.tableFixHeadTh}>L+</th>
                                <th className="center" style={styles.tableFixHeadTh}>L-</th>
                                <th className="center" style={styles.tableFixHeadTh}>RL</th>
                                <th className="center" style={styles.tableFixHeadTh}>YL</th>
                            </tr>
                            </thead>
                            <tbody style={styles.tableBodyTopBorder}>
                            { getRows(reviewsArray) }
                            </tbody>
                        </Table>
                    </div>
                }
            </div>
        </div>
    )
}
