import React, {useState} from "react";
import ClientViewGeneralTableCell from "./ClientViewGeneralTableCell";

const styles = {
    noEditableCell: {
        backgroundColor: '#fafafa',
        whiteSpace: 'pre-line',
    },
}

export default function ClientViewGeneralTableRow(props) {
    return(
        <tr>
            <td className="center" style={styles.noEditableCell}>{props.rowIndex + 1}</td>
            {
                props.columns.map((column, columnIndex) => {
                    if (column.editableByClient) {
                        return(
                            <>
                                <ClientViewGeneralTableCell
                                    viewCode={props.viewCode}
                                    key={props.row.code + "_" + column.code}
                                    column={column}
                                    rowCode={props.row.code}
                                    cellData={props.row.data[column.code]}
                                    clientValue={false}
                                    columnIndex={columnIndex}
                                />
                                <td className="center" style={styles.noEditableCell}>
                                    <input
                                        type="checkbox" onChange={(e) =>
                                        props.setAcceptedGeneralRows(props.row.code, column.code, e.target.checked)}
                                        defaultChecked={false}
                                    />
                                </td>
                                <ClientViewGeneralTableCell
                                    viewCode={props.viewCode}
                                    key={props.row.code + "_" + column.code + "_client"}
                                    column={column}
                                    rowCode={props.row.code}
                                    cellData={props.row.data[column.code]}
                                    clientValue={true}
                                    columnIndex={columnIndex}
                                />
                            </>
                        )
                    } else {
                        return (
                            <ClientViewGeneralTableCell
                                viewCode={props.viewCode}
                                key={props.row.code + "_" + column.code}
                                column={column}
                                rowCode={props.row.code}
                                cellData={props.row.data[column.code]}
                                clientValue={false}
                                columnIndex={columnIndex}
                            />
                        )
                    }
                })
            }
        </tr>
    )
}