import {Nav, Tabs, Tab, Row, Col} from "react-bootstrap";
import React from "react";
import LanguageTabPreferences from "./ClientViewLanguageTabPreferences";
import LanguageTabCharacterCommunication from "./ClientViewLanguageTabCharacterCommunication";

export default function ClientViewLanguageTab(props) {
    return (
        <div>
            {
                props.communicationBetweenCharacters ? (
                    <Tab.Container defaultActiveKey={"languagePreferences"}>
                        <Row>
                            <Col sm={12}>
                                <Nav variant="pills" className="nav-custom-grey">
                                    <Nav.Item>
                                        <Nav.Link eventKey={"languagePreferences"}>
                                            Language Preferences
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={"communicationBetweenCharacters"}>
                                            Communication between characters
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey={"languagePreferences"}>
                                        <div className="tab-item-wrapper">
                                            <br/>
                                            <LanguageTabPreferences
                                                language={props.language}
                                                sgTabSaveCellValue={props.sgTabSaveCellValue}
                                            />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={"communicationBetweenCharacters"}>
                                        <div className="tab-item-wrapper">
                                            <br/>
                                            <LanguageTabCharacterCommunication
                                                language={props.language.code}
                                                characters={props.characterCommunications[0].characters}
                                                templateData={props.characterCommunications[0].templateData}
                                                languageData={props.characterCommunications[0].languagesData[props.language.code].data}
                                                languageDataFromLinguist={props.characterCommunications[0].languagesData[props.language.code].linguistAcceptedData}
                                            />
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                ) : (
                    <LanguageTabPreferences
                        language={props.language}
                        sgTabSaveCellValue={props.sgTabSaveCellValue}
                    />
                )
            }
        </div>
    )
}