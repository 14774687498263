import {Button, FormCheck, Table} from "react-bootstrap";
import React, {useState} from "react";
import queryString from "query-string";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faComment,
    faCopy,
    faExclamation,
    faFilePdf,
    faLink,
    faMagic,
    faTimes,
    faUserTie
} from "@fortawesome/free-solid-svg-icons";
import LinguistAcceptDataModal from "./LinguistAcceptDataModal";

const styles = {
    blockView: {
        backgroundColor: 'rgb(61, 63, 65)',
        color: 'white',
        border: '1px solid rgb(61, 63, 65)',
        padding: '1rem',
        borderRadius: '5px',
        margin: '0px'
    },

    linguistLanguageRow: {
        marginBottom: '15px'
    }
}

export default function LinguistsTab(props) {
    const queryStringParams = queryString.parse(window.location.search)
    const clientName = queryStringParams.client_name
    const projectName = queryStringParams.project_name
    const projectCode = queryStringParams.project_code
    const [linguistAcceptDataModalActive, setLinguistAcceptDataModalActive] = useState(false)
    const [linguistData, setLinguistData] = useState([{
        languageCode: 0,
        data: [],
        defaultLanguageData: []
    }])

    console.log("LinguistsTab", props)

    function createLinguistLink() {
        let area = document.createElement('textarea');
        let link = window.location.href.split("/")

        document.body.appendChild(area);
        area.value = 'https://' + link[2] + '/managed_quality_on_project/project_bible/styleguidelinguist?client_name=' +
            encodeURIComponent(clientName) + '&project_name=' +
            encodeURIComponent(projectName) + '&project_code=' +
            encodeURIComponent(projectCode);
        area.select();
        document.execCommand("copy");
        document.body.removeChild(area);
        alert("Ссылка скопирована в буфер обмена")
    }

    function openLinguistAcceptDataModalWindow(value, languageCode) {
        console.log("openLinguistAcceptDataModalWindow function", value, languageCode, props.languages)

        setLinguistData(
            linguistData.map(info => {
                info.languageCode = languageCode
                info.data = value

                value.data.languagePreferences.map(language => {
                    if (language.code === languageCode) {
                        props.languages.map(languagesValue => {
                            if (languagesValue.title === language.title) {
                                info.defaultLanguageData = languagesValue
                            }
                        })
                    }

                    return language
                })

                return info
            })
        )

        setLinguistAcceptDataModalActive(true)
    }

    async function removeLinguistAcceptData(linguistId, languageCode) {
        console.log("removeLinguistAcceptData", linguistId, languageCode)

        await fetch("/proxy/project_bible_template/deleteByLanguageCodeStyleGuideLinguistAcceptChanges", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "linguistId": linguistId,
                "languageCode": languageCode
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    await fetch("/proxy/project_bible_template/styleGuideLinguistResetAcceptedCharactersValuesByLinguist", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "clientName": clientName,
                            "projectName": projectName,
                            "projectCode": projectCode,
                            "linguistId": linguistId,
                            "languageCode": languageCode
                        })
                    })
                        .then(res => res.json())
                        .then(
                            async (result) => {
                                window.location.reload()
                            },
                            (error) => {
                                alert("Error")
                            }
                        )
                },
                (error) => {
                    alert("Error")
                }
            )
    }

    let getLinguistsLinks = linguists => {
        // console.log("LINGUISTS", linguists)

        let content = []

        for (let key in linguists) {
            let value = linguists[key]

            // console.log("LINGUISTS VALUE", value)

            content.push(
                <div key={key}>
                    <div className="row">
                        <div className="col-sm-4 center">
                            <b>Лингвист:</b> {/*key*/value.linguistName + " (e-mail: " + key + ")"}
                            &nbsp;&nbsp;
                            {/*{
                                value.submit ? (
                                    <div>
                                        <b>(Завершена)</b>
                                        &nbsp;&nbsp;
                                        <Button variant="light"
                                                onClick={(e) =>
                                                    props.returnLinguistLinkToWork(key)}>
                                            Вернуть в работу&nbsp;&nbsp;<FontAwesomeIcon icon={faLink}/>
                                        </Button>
                                    </div>
                                ) : (
                                    <b>(В процессе)</b>
                                )
                            }*/}
                        </div>
                        <div className="col-sm-8 center">
                            {
                                value.languagesNames.map(language => {
                                    // let messageEdit = "Редактировать изменения"
                                    let isCharacterCommunicationsBuffered = false

                                    if (language.code in value.data.characterCommunications.languagesData) {
                                        isCharacterCommunicationsBuffered = value.data.characterCommunications.languagesData[language.code].isBuffered
                                    }

                                    if (language.code in value.bufferedData || isCharacterCommunicationsBuffered) {
                                        let isLanguagePreferencesBuffered = false

                                        if (language.code in value.bufferedData &&
                                            "languagePreferences" in value.bufferedData[language.code]) {
                                            let languageBuffData = value.bufferedData[language.code].languagePreferences

                                            if (Object.keys(languageBuffData.value).length > 0) {
                                                isLanguagePreferencesBuffered = true
                                            }
                                        }

                                        if (isLanguagePreferencesBuffered || isCharacterCommunicationsBuffered
                                            /*languageBuffData.communicationBetweenCharacters.value*/) {
                                            // messageEdit += "&nbsp;&nbsp; <FontAwesomeIcon icon={faComment}/>"

                                            return(
                                                <div key={language.code} className="row" style={styles.linguistLanguageRow}>
                                                    <div className="col-sm-3 center">
                                                        {language.name} [code: {language.code}]
                                                    </div>
                                                    <div className="col-sm-9 center">
                                                        {
                                                            language.code in value.acceptData && Object.keys(value.acceptData).length > 0 ? (
                                                                <div>
                                                                    <Button variant="warning" className="btn btn-yellow"
                                                                            onClick={(e) =>
                                                                                openLinguistAcceptDataModalWindow(value, language.code)}>
                                                                        Редактировать изменения&nbsp;&nbsp;<FontAwesomeIcon
                                                                        icon={faExclamation}/>
                                                                    </Button>
                                                                    &nbsp;&nbsp;
                                                                    <Button variant="secondary"
                                                                            onClick={(e) =>
                                                                                removeLinguistAcceptData(key, language.code)}>
                                                                        Отменить изменения&nbsp;&nbsp;<FontAwesomeIcon
                                                                        icon={faTimes}/>
                                                                    </Button>
                                                                </div>
                                                            ) : (
                                                                <Button variant="success" className="btn btn-blue"
                                                                        onClick={(e) =>
                                                                            openLinguistAcceptDataModalWindow(value, language.code)}>
                                                                    Принять изменения
                                                                </Button>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return(
                                                <div key={language.code} className="row" style={styles.linguistLanguageRow}>
                                                    <div className="col-sm-3 center">
                                                        {language.name} [code: {language.code}]
                                                    </div>
                                                    <div className="col-sm-9 center">
                                                        {
                                                            language.code in value.acceptData && Object.keys(value.acceptData).length > 0 ? (
                                                                <div>
                                                                    <Button variant="success" className="btn btn-blue"
                                                                            onClick={(e) =>
                                                                                openLinguistAcceptDataModalWindow(value, language.code)}>
                                                                        Редактировать изменения
                                                                    </Button>
                                                                    &nbsp;&nbsp;
                                                                    <Button variant="secondary"
                                                                            onClick={(e) =>
                                                                                removeLinguistAcceptData(key, language.code)}>
                                                                        Отменить изменения&nbsp;&nbsp;<FontAwesomeIcon
                                                                        icon={faTimes}/>
                                                                    </Button>
                                                                </div>
                                                            ) : (
                                                                <Button variant="success" className="btn btn-blue"
                                                                        onClick={(e) =>
                                                                            openLinguistAcceptDataModalWindow(value, language.code)}>
                                                                    Принять изменения
                                                                </Button>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    } else {
                                        return(
                                            <div key={language.code} className="row" style={styles.linguistLanguageRow}>
                                                <div className="col-sm-3 center">
                                                    {language.name} [code: {language.code}]
                                                </div>
                                                <div className="col-sm-9 center">
                                                    {
                                                        language.code in value.acceptData && Object.keys(value.acceptData).length > 0 ? (
                                                            <div>
                                                                <Button variant="success" className="btn btn-blue"
                                                                        onClick={(e) =>
                                                                            openLinguistAcceptDataModalWindow(value, language.code)}>
                                                                    Редактировать изменения
                                                                </Button>
                                                                &nbsp;&nbsp;
                                                                <Button variant="secondary"
                                                                        onClick={(e) =>
                                                                            removeLinguistAcceptData(key, language.code)}>
                                                                    Отменить изменения&nbsp;&nbsp;<FontAwesomeIcon
                                                                    icon={faTimes}/>
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            <Button variant="success" className="btn btn-blue"
                                                                    onClick={(e) =>
                                                                        openLinguistAcceptDataModalWindow(value, language.code)}>
                                                                Принять изменения
                                                            </Button>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    <br />
                </div>
            )
        }

        return content
    };

    return(
        <div>
            <div className="row">
                <div className="col-sm-12 center">
                    <br />
                    <Button variant="secondary" onClick={(e) => createLinguistLink()}>
                        Сформировать ссылку лингвисту&nbsp;&nbsp;<FontAwesomeIcon icon={faUserTie}/>
                    </Button>
                </div>
            </div>
            <br />
            <br />
            <div className="row">
                <div className="col-sm-12 center">
                    <div style={styles.blockView}>
                        <h4>Стайлгайды, отправленные лингвистам:</h4>
                        <br />
                        { getLinguistsLinks(props.linguistLinks) }
                    </div>
                </div>
            </div>
            <br />
            <LinguistAcceptDataModal
                linguistAcceptDataModalActive={linguistAcceptDataModalActive}
                setLinguistAcceptDataModalActive={setLinguistAcceptDataModalActive}
                linguistData={linguistData[0].data}
                languageCode={linguistData[0].languageCode}
                defaultLanguageData={linguistData[0].defaultLanguageData}
                allLanguages={props.languages}
                linguistLinks={props.linguistLinks}
                characterCommunications={props.characterCommunications}
                resetAcceptedFromOtherLinguistCharactersValues={props.resetAcceptedFromOtherLinguistCharactersValues}
                setLinguistCharactersValues={props.setLinguistCharactersValues}
                getCharacterId={props.getCharacterId}
            />
        </div>
    )
}