import {Button, FormCheck, Table} from "react-bootstrap";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";
import Editor from "react-medium-editor";
import MediumButton from "medium-button";

const styles = {
    tableHeaders: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    tableHeaderTh: {
        backgroundColor: '#eaeaea',
        background: '#4A8EFF',
        color: '#FFFFFF'
    },

    nowrapCell: {
        whiteSpace: 'nowrap'
    },

    noEditableCell: {
        backgroundColor: "#fff"
    },

    tableFixHeadTh: {
        position: 'sticky',
        top: -2,
        zIndex: 1,
        whiteSpace: 'pre-line',
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderTopColor: '#4A8EFF',
    },

    tableRowHeader: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderTopColor: '#4A8EFF',
    },
}

export default function LogOfNewBatchesTab(props) {
    const queryStringParams = queryString.parse(window.location.search)
    const clientName = queryStringParams.client_name
    const projectName = queryStringParams.project_name
    const projectCode = queryStringParams.project_code

    function openModalToAddLinksAndImages(element, /*row, column*/modalHistoryTitle, tableDBName, data) {
        let cellText = ""

        if (element.target.nodeName === "TD") {
            cellText = element.target.innerHTML
        } else {
            cellText = element.target.closest(".select-double-click-add-links-and-images").innerHTML
        }

        props.openModalToAddLinksAndImages(
            cellText,
            "oninputLogOfNewBatchesCell",
            modalHistoryTitle,
            tableDBName,
            data
            /*{
                column: column.code,
                row: row,
                type: "input",
                value: cellText
            }*/
        )
    }

    let getRows = (headers, count, data) => {
        let content = []

        // console.log("LOG OF NEW BATCHES", headers, count, data)

        for (let i = 0; i < count; i++) {
            let correctRowNum = +i + 1

            content.push(
                <tr key={i}>
                    <td className="center">{ correctRowNum }</td>
                    {
                        headers.map(header => {
                            let cellValue = data[i][header.code]
                            let colStyle = {}

                            colStyle.backgroundColor = '#fff'

                            function handleChange(text, medium) {
                                console.log("handleChange", text)

                                cellValue = text
                            }

                            function onBlurSave(e) {
                                if (!props.modalAddLinksAndImagesActive && !props.modalOpenAddedImageActive) {
                                    if (e.target.nodeName === "TD") {
                                        console.log("onBlurSave", e.target.innerHTML, cellValue)

                                        props.oninputLogOfNewBatchesCell(
                                            correctRowNum, header.code, "input", e.target.innerHTML, false
                                        )
                                    }
                                }
                            }

                            if (header.wordBreak) {
                                colStyle.wordBreak = 'break-all'
                            }

                            if (header.minWidth) {
                                colStyle.minWidth = header.minWidth
                            }

                            return(
                                <Editor
                                    key={header.code}
                                    className="whiteSpacePreLine select-double-click-add-links-and-images"
                                    suppressContentEditableWarning={true}
                                    tag="td"
                                    text={cellValue}
                                    onChange={handleChange}
                                    style={colStyle}
                                    options={{
                                        toolbar: {
                                            buttons: [
                                                'bold',
                                                'italic',
                                                'underline',
                                                'addImage'
                                            ]
                                        },
                                        extensions: {
                                            'addImage': new MediumButton({
                                                label: '<b>img</b>',
                                                start: '<button class="btn btn-secondary btn-sm open-in-modal-added-image" type="button" contenteditable="false" data-src="">',
                                                end:   '</button>',
                                                action: function(html){
                                                    props.triggerOpenInModalAddedImage()

                                                    return html
                                                }
                                            })
                                        },
                                        placeholder: false
                                    }}
                                    onBlur={(e) => onBlurSave(e)}
                                    onDoubleClick={(e) => openModalToAddLinksAndImages(
                                        e,
                                        /*i, header*/
                                        "History of changes in \"" + header.name + "\" column",
                                        "style_guide_log_of_new_batches_rows_text",
                                        {
                                            row: correctRowNum,
                                            column: header.code,
                                            type: "input",
                                            refillCell: true
                                        }
                                    )}
                                />
                            )

                            /*return(
                                <td key={header.code} className="whiteSpacePreLine"
                                    contentEditable={true}
                                    suppressContentEditableWarning={true}
                                    dangerouslySetInnerHTML={{__html: data[i][header.code]}}
                                    onBlur={(e) =>
                                        props.oninputLogOfNewBatchesCell(i, header.code, "input", e)}>
                                </td>
                            )*/
                        })
                    }
                    <td className="center" style={styles.noEditableCell}>
                        <Button size="sm" variant="danger" onClick={(e) =>
                            props.deleteLogOfNewBatchesRow(i)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    </td>
                </tr>
            )
        }

        return content
    };

    return (
        <>
            <div className="div-table-blue">
                <Table responsive bordered>
                    <thead>
                    <tr style={styles.tableRowHeader}>
                        <th key="№" className="center" style={styles.tableFixHeadTh}>№</th>
                        {
                            props.logOfNewBatchesHeaders.map(header => {
                                return (
                                    <th key={header.code} className="center" style={styles.tableFixHeadTh}>
                                        {header.name}
                                        <br/>
                                        <br/>
                                        {header.extra_header}
                                    </th>
                                )
                            })
                        }
                        <th className="center" style={styles.tableFixHeadTh}>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        getRows(props.logOfNewBatchesHeaders, props.logOfNewBatchesRowsCount, props.logOfNewBatchesRowsData)
                    }
                    </tbody>
                </Table>
            </div>
            <br/>
            <br/>
            <div className="center">
                <Button variant="primary" className="btn btn-blue" onClick={(e) =>
                    props.addLogOfNewBatchesRowToTheBeginning()}>Add&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                </Button>
            </div>
        </>
    )
}