import {FormCheck} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import queryString from "query-string";
import parse from "html-react-parser"
import Editor from "react-medium-editor";
import MediumButton from "medium-button";

const styles = {
    defaultCell: {
        backgroundColor: '#fff',
    },

    noEditableCell: {
        backgroundColor: '#fafafa',
        whiteSpace: 'pre-line',
    },

    emptyCell: {
        backgroundColor: '#f8e7e7',
        whiteSpace: 'pre-line',
    },

    filledCell: {
        backgroundColor: '#dffdde',
        whiteSpace: 'pre-line',
    },

    changedCheckbox: {
        backgroundColor: '#fff8e1',
    }
}

export default function TableCell(props) {
    const [cellInputColor, setCellInputColor] = useState(props.value ? "green" : "red")
    const [cellCheckboxColor, setCellCheckboxColor] = useState(
        props.type === "checkbox" && props.template && props.editable && props.value !== props.row.template[props.column.code] ? "yellow" : "white"
    )

    async function saveBoolCellValue(column, row, type, value) {
        if (props.template && props.editable) {
            if (value === props.row.template[props.column.code]) {
                setCellCheckboxColor("white")
            } else {
                setCellCheckboxColor("yellow")
            }
        }

        props.generalTabSaveBoolCellValue(column, row, type, value)
    }

    function pressKey(colCode, rowNum) {
        let pressed = new Set()

        document.addEventListener('keydown', function(event) {
            pressed.add(event.code)

            if (pressed.has("ShiftLeft") && pressed.has("ArrowUp")) {
                let newId = rowNum === 1 ? rowNum : rowNum - 1

                document.getElementById(colCode + "_" + newId).focus()
            } else if (pressed.has("ShiftLeft") && pressed.has("ArrowDown")) {
                let newId = rowNum + 1

                if (document.getElementById(colCode + "_" + newId)) {
                    document.getElementById(colCode + "_" + newId).focus()
                }
            } else {
                return
            }

            pressed.clear()
        });

        document.addEventListener('keyup', function(event) {
            pressed.delete(event.code)
        });
    }

    function captureKeyCombination(modalTitle, tableName, saveMethod, identityData) {
        let pressed = new Set()

        document.addEventListener('keydown', function(event) {
            pressed.add(event.code)

            if (pressed.has("ControlLeft") && pressed.has("AltLeft") && pressed.has("KeyH")) {
                props.openCellDataHistory(modalTitle, tableName, saveMethod, identityData)
            } else {
                return
            }

            pressed.clear()
        });

        document.addEventListener('mouseout', function(event) {
            pressed.delete(event.code)
        });
    }

    let getTags = data => {
        let content = []

        if (typeof data === 'undefined') {
            // console.log("getTags", props)

            data = []
        }

        if (Object.keys(data).length > 0) {
            for (let key in data) {
                let value = data[key]

                content.push(
                    <div key={key}>
                        <strong>{key}</strong>
                        <br />
                        {
                            value.map(tag => {
                                return(
                                    <div key={tag}>
                                        - {tag}
                                    </div>
                                )
                            })
                        }
                        <br />
                    </div>
                )
            }
        }

        return content
    }

    function openModalToAddLinksAndImages(element, modalHistoryTitle, tableDBName) {
        let cellText = ""

        if (element.target.nodeName === "TD") {
            cellText = element.target.innerHTML
        } else {
            cellText = element.target.closest(".select-double-click-add-links-and-images").innerHTML
        }

        props.openModalToAddLinksAndImages(
            cellText,
            "generalTabSaveCellValue",
            modalHistoryTitle,
            tableDBName,
            {
                column: props.column.code,
                row: props.rowCode,
                type: "input"
            }
        )
    }

    if (props.type === "input") {
        if (props.editable) {
            let setStyle = styles.emptyCell
            let cellValue = props.value

            if (cellInputColor === "green") {
                setStyle = styles.filledCell
            } else if (cellInputColor === "red") {
                setStyle = styles.emptyCell
            }

            function handleChange(text, medium) {
                cellValue = text

                if (text) {
                    setCellInputColor("green")
                } else {
                    setCellInputColor("red")
                }
            }

            function onBlurSave(e) {
                if (!props.modalAddLinksAndImagesActive && !props.modalOpenAddedImageActive) {
                    if (e.target.nodeName === "TD") {
                        console.log("generalTabSaveCellValue", e.target.innerHTML)

                        props.generalTabSaveCellValue(
                            props.column.code,
                            props.rowCode,
                            "input",
                            e.target.innerHTML,
                            /*false*/true
                        )
                    }
                }
            }

            return(
                <Editor
                    key={props.column.code}
                    id={props.column.code + '_' + props.rowNum}
                    className="whiteSpacePreLine select-double-click-add-links-and-images"
                    suppressContentEditableWarning={true}
                    tag="td"
                    text={cellValue}
                    style={setStyle}
                    onChange={handleChange}
                    options={{
                        toolbar: {
                            buttons: [
                                'bold',
                                'italic',
                                'underline',
                                'addImage'
                            ]
                        },
                        extensions: {
                            'addImage': new MediumButton({
                                label: '<b>img</b>',
                                start: '<button class="btn btn-secondary btn-sm open-in-modal-added-image" type="button" contenteditable="false" data-src="">',
                                end:   '</button>',
                                action: function(html){
                                    props.triggerOpenInModalAddedImage()

                                    return html
                                }
                            })
                        },
                        placeholder: false
                    }}
                    onBlur={(e) => onBlurSave(e)}
                    onDoubleClick={(e) => openModalToAddLinksAndImages(
                        e,
                        "History of changes in \"" + props.column.name + "\" column",
                        "project_bible_text"
                    )}
                    onKeyDown={(e) => pressKey(
                        props.column.code,
                        props.rowNum
                    )}
                    /*onMouseOver={(e) =>
                        captureKeyCombination(
                            "History of changes in \"" + props.column.name + "\" column",
                            "project_bible_text",
                            "generalTabSaveCellValue", {
                                column: props.column.code,
                                row: props.rowCode,
                                type: "input",
                                value: e.target.innerHTML,
                                refillCell: true
                            }
                        )}*/
                />
            )
        } else {
            return (
                <td contentEditable={false} style={styles.noEditableCell}>{props.value}</td>
            )
        }
    } else if (props.type === "checkbox") {
        if (props.editable) {
            if (cellCheckboxColor === "white") {
                return (
                    <td contentEditable={false} className="center align-middle" style={styles.defaultCell}>
                        <FormCheck defaultChecked={props.value}
                                   onInput={(e) =>
                                       saveBoolCellValue(props.column.code, props.rowCode, "checkbox", e.target.checked)}
                                   onMouseOver={(e) =>
                                       captureKeyCombination(
                                           "History of changes in \"" + props.column.name + "\" column",
                                           "project_bible_bool",
                                           "generalTabSaveBoolCellValue", {
                                               column: props.column.code,
                                               row: props.rowCode,
                                               type: "checkbox",
                                               value: e.target.checked
                                           }
                                       )}
                        />
                    </td>
                )
            } else if (cellCheckboxColor === "yellow") {
                return (
                    <td contentEditable={false} className="center align-middle" style={styles.changedCheckbox}>
                        <FormCheck defaultChecked={props.value}
                                   onInput={(e) =>
                                       saveBoolCellValue(props.column.code, props.rowCode, "checkbox", e.target.checked)}
                                   onMouseOver={(e) =>
                                       captureKeyCombination(
                                           "History of changes in \"" + props.column.name + "\" column",
                                           "project_bible_bool",
                                           "generalTabSaveBoolCellValue", {
                                               column: props.column.code,
                                               row: props.rowCode,
                                               type: "checkbox",
                                               value: e.target.checked
                                           }
                                       )}
                        />
                    </td>
                )
            }
        } else {
            return (
                <td contentEditable={false} style={styles.noEditableCell} className="center align-middle">
                    <FormCheck checked={props.value} readOnly/>
                </td>
            )
        }
    } else if (props.type === "tags_list") {
        return(
            <td contentEditable={false} style={styles.noEditableCell} >
                { getTags(props.value) }
            </td>
        )
    }
}