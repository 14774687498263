import {Button, Table} from "react-bootstrap";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faChevronDown, faChevronUp, faMagic, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";
import Editor from "react-medium-editor";
import MediumButton from "medium-button";

const styles = {
    blockHeader: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    editableCell: {
        backgroundColor: '#fff',
        width: '50%'
    },

    noEditableCell: {
        backgroundColor: '#fafafa',
        width: '50%'
    },

    blockStyle: {
        marginBottom: '15px'
    },

    bufferedCell: {
        width: '50%',
        backgroundColor: '#fff8e1'
    }
}

export default function LinguistLanguageTabPreferences(props) {
    const queryStringParams = queryString.parse(window.location.search)
    const clientName = queryStringParams.client_name
    const projectName = queryStringParams.project_name
    const projectCode = queryStringParams.project_code
    console.log("LinguistLanguageTab", props.language)

    async function oninputCell(block, column, row, type, e, linguistExtraRow) {
        console.log("oninputCell", props.language.code, props.acceptedData.accept)

        let queryLinkExist = '/proxy/project_bible_template/'
        let queryUpdateCell = '/proxy/project_bible_template/'
        let queryInsertCell = '/proxy/project_bible_template/'
        let value

        if (type === "input") {
            queryLinkExist += 'styleGuideLinguistLanguagesFilledCellTextByCode'
            queryUpdateCell += 'styleGuideLinguistLanguagesOninputUpdateTextCell'
            queryInsertCell += 'styleGuideLinguistLanguagesOninputInsertTextCell'
            value = e.target.innerText
        }

        await fetch(queryLinkExist, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "id": props.linguistCode,
                "languageCode": props.language.code,
                "block": block,
                "colCode": column,
                "rowCode": row
            })
        })
            .then(res => res.json())
            .then(
                async (resultEditable) => {
                    if (resultEditable.length) {
                        await fetch(queryUpdateCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "id": props.linguistCode,
                                "languageCode": props.language.code,
                                "block": block,
                                "colCode": column,
                                "rowCode": row,
                                "value": value
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultUpdate) => {
                                    if (/*props.submit && */props.acceptedData.accept[props.language.code]) {
                                        props.setBufferedLanguagePreferencesUpdatedCellData(props.language.code, block, row, linguistExtraRow)
                                    }
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    } else {
                        await fetch(queryInsertCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "id": props.linguistCode,
                                "languageCode": props.language.code,
                                "block": block,
                                "colCode": column,
                                "rowCode": row,
                                "value": value
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultInsert) => {
                                    if (/*props.submit && */props.acceptedData.accept[props.language.code]) {
                                        props.setBufferedLanguagePreferencesUpdatedCellData(props.language.code, block, row, linguistExtraRow)
                                    }
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    }
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    function openModalToAddLinksAndImages(element, language, block, row, column) {
        let cellText = ""

        if (element.target.nodeName === "TD") {
            cellText = element.target.innerHTML
        } else {
            cellText = element.target.closest(".select-double-click-add-links-and-images").innerHTML
        }

        props.openModalToAddLinksAndImages(
            cellText,
            "languageTabSavePreferencesCellValue",
            {
                language: language.code,
                block: block,
                column: column.code,
                row: row.code,
                type: "input",
                linguistExtraRow: "linguistExtraRow" in row ? row.linguistExtraRow : false,
                extraFromOtherLinguist: "extraFromOtherLinguist" in row ? row["extraFromOtherLinguist"] : ""
            }
        )
    }

    let getBlockData = languageData => {
        let content = []

        for (let block in languageData) {
            let value = languageData[block]

            content.push(
                <div key={block} style={styles.blockStyle}>
                    <div className="table-blue-thead-not-fixed">
                        <Table responsive bordered>
                            <thead>
                            <tr style={styles.blockHeader}>
                                <th className="center" colSpan={2} style={styles.blockHeader}>
                                    {props.language.data[0][block].title}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                props.language.data[0][block].data.map(data => {
                                    return (
                                        <tr key={data.code}>
                                        {
                                                data.row.map((rowData, rowDataIndex) => {
                                                    if (data.linguistExtraRow || (rowData.linguistEditable && (data.row.length === 1 || (data.row.length > 1 && rowDataIndex !== 0)))) {
                                                        let cellValue = rowData.text
                                                        let cellStyle = styles.editableCell

                                                        if ("isBuffered" in rowData && rowData.isBuffered) {
                                                            cellStyle = styles.bufferedCell
                                                        }

                                                        function handleChange(text, medium) {
                                                            cellValue = text
                                                        }

                                                        function onBlurSave(e) {
                                                            if (!props.modalAddLinksAndImagesActive && !props.modalOpenAddedImageActive) {
                                                                if (e.target.nodeName === "TD") {
                                                                    props.languageTabSavePreferencesCellValue(
                                                                        props.language.code, block, rowData.code, data.code,
                                                                        "input", e.target.innerHTML, "linguistExtraRow" in data ? data.linguistExtraRow : false,
                                                                        false, "extraFromOtherLinguist" in data ?
                                                                            data["extraFromOtherLinguist"] : ""
                                                                    )
                                                                }
                                                            }
                                                        }

                                                        return (
                                                            <Editor
                                                                key={rowData.code}
                                                                className="whiteSpacePreLine select-double-click-add-links-and-images"
                                                                suppressContentEditableWarning={true}
                                                                tag="td"
                                                                colSpan={data.colspan ? 2 : 1}
                                                                text={cellValue}
                                                                onChange={handleChange}
                                                                style={cellStyle}
                                                                options={{
                                                                    toolbar: {
                                                                        buttons: [
                                                                            'bold',
                                                                            'italic',
                                                                            'underline',
                                                                            'addImage'
                                                                        ]
                                                                    },
                                                                    extensions: {
                                                                        'addImage': new MediumButton({
                                                                            label: '<b>img</b>',
                                                                            start: '<button class="btn btn-secondary btn-sm open-in-modal-added-image" type="button" contenteditable="false" data-src="">',
                                                                            end: '</button>',
                                                                            action: function (html) {
                                                                                props.triggerOpenInModalAddedImage()

                                                                                return html
                                                                            }
                                                                        })
                                                                    },
                                                                    placeholder: false
                                                                }}
                                                                onBlur={(e) => onBlurSave(e)}
                                                                onDoubleClick={(e) =>
                                                                    openModalToAddLinksAndImages(e, props.language, block, data, rowData)}
                                                            />
                                                        )

                                                        /*return(
                                                            <td key={rowData.code} className="whiteSpacePreLine"
                                                                colSpan={data.colspan ? 2 : 1}
                                                                contentEditable={true}
                                                                suppressContentEditableWarning={true}
                                                                style={styles.editableCell}
                                                                dangerouslySetInnerHTML={{__html: rowData.text}}
                                                                onBlur={(e) =>
                                                                    oninputCell("base_info", rowData.code, data.code, "input", e, data.linguistExtraRow)}>
                                                            </td>
                                                        )*/
                                                    } else {
                                                        return (
                                                            <td key={rowData.code} className="whiteSpacePreLine"
                                                                colSpan={data.colspan ? 2 : 1}
                                                                contentEditable={false}
                                                                style={styles.noEditableCell}
                                                                dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                            </td>
                                                        )
                                                    }
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                        {
                            props.language.data[0][block].linguistAddRows &&
                            <div className="center">
                                <button className="btn btn-blue"
                                        onClick={(e) =>
                                            props.addRowToStyleGuideLanguageBlock(props.language.code, block,
                                                props.language.data[0][block].linguistEditable, 1)}>
                                    Add row with 1 cell&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                                </button>
                                &nbsp;&nbsp;
                                <button className="btn btn-blue"
                                        onClick={(e) =>
                                            props.addRowToStyleGuideLanguageBlock(props.language.code, block,
                                                props.language.data[0][block].linguistEditable, 2)}>
                                    Add row with 2 cells&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            )
        }

        return content
    }

    return (
        <div key={props.language.code} className="row">
            <div className="col-sm-12">
                {getBlockData(props.language.data[0])}
            </div>
        </div>
    )
}