import {Button, Table} from "react-bootstrap";
import React, {useState} from "react";

const styles = {
    headerCell: {
        background: '#4A8EFF',
        color: '#FFFFFF'
    },

    noEditableCell: {
        backgroundColor: '#f6f6f6'
    },
}

export default function ClientViewLanguageTabCharacterCommunication(props) {
    return (
        <div className="row">
            <div className="col-sm-12">
                {
                    props.templateData.length > 0 &&
                    <div className="table-responsive character-communication-container">
                        <table className="table table-bordered">
                            <thead>
                            <tr style={styles.headerCell}>
                                <th style={styles.headerCell}></th>
                                {
                                    props.templateData.map((value, valueIndex) => {
                                        return (
                                            <th key={valueIndex} className="center" contentEditable={false}
                                                dangerouslySetInnerHTML={{__html: value.name}}
                                                style={styles.headerCell}>
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                props.templateData.map((value, valueIndex) => {
                                    const rowCells = [];

                                    for (let i = 0; i < Object.keys(props.templateData).length; i++) {
                                        let actualValueForTable = ""

                                        /*if (valueIndex in props.languageData && i in props.languageData[valueIndex]) {
                                            languageCellValue = props.languageData[valueIndex][i]
                                        }*/

                                        if (valueIndex in props.templateData && i in props.templateData[valueIndex].data) {
                                            actualValueForTable = props.templateData[valueIndex].data[i]
                                        }

                                        if (valueIndex in props.languageData && i in props.languageData[valueIndex]) {
                                            actualValueForTable = props.languageData[valueIndex][i]
                                        }

                                        if (valueIndex in props.languageDataFromLinguist && i in props.languageDataFromLinguist[valueIndex]) {
                                            actualValueForTable = props.languageDataFromLinguist[valueIndex][i].value
                                        }

                                        if (valueIndex !== i) {
                                            rowCells.push(
                                                <td key={i} className="whiteSpacePreLine"
                                                    contentEditable={false}
                                                    style={styles.noEditableCell}
                                                    dangerouslySetInnerHTML={{__html: actualValueForTable}}>
                                                </td>
                                            )
                                        } else {
                                            rowCells.push(
                                                <td key={i} className="whiteSpacePreLine"
                                                    contentEditable={false}
                                                    style={styles.noEditableCell}
                                                    dangerouslySetInnerHTML={{__html: ""}}>
                                                </td>
                                            );
                                        }
                                    }

                                    return (
                                        <tr key={valueIndex}>
                                            <th className="center" contentEditable={false}
                                                dangerouslySetInnerHTML={{__html: value.name}}
                                                style={styles.headerCell}>
                                            </th>
                                            {
                                                rowCells
                                            }
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>
    )
}