import {Table} from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";

const styles = {
    tableFixHeadTable: {
        borderCollapse: 'collapse',
        width: '100%',
    },

    tableHeaderRow: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderTopColor: '#4A8EFF',
        // borderLeftColor: '#4A8EFF',
        // borderRightColor: '#4A8EFF'
    },

    tableFixHeadTh: {
        position: 'sticky',
        top: -2,
        zIndex: 1,
        whiteSpace: 'pre-line',
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderTopColor: '#4A8EFF',
        // borderLeftColor: '#4A8EFF',
        // borderRightColor: '#4A8EFF'
    },

    cellStyle: {
        background: '#fff',
        whiteSpace: 'pre-line',
        minWidth: '150px',
        maxWidth: '200px',
        wordBreak: 'break-word'
    },

    stagesFillMore90: {
        background: '#d7ffcf',
        whiteSpace: 'pre-line',
        minWidth: '100px',
        wordBreak: 'break-word'
    },

    stagesFillUnder90: {
        background: '#fffedd',
        whiteSpace: 'pre-line',
        minWidth: '100px',
        wordBreak: 'break-word'
    },
}

export default function TableInfo(props) {
    let rows = props.rows.sort(function (a, b) {
        return a.clientName.localeCompare(b.clientName) ||
            a.projectName.localeCompare(b.projectName) ||
            a.projectCode.localeCompare(b.projectCode)
    });

    function openView(clientName, projectName, projectCode) {
        let link = window.location.href.split("/")

        window.open(
            'http://' + link[2] + '/managed_quality_on_project/project_bible/view?client_name=' +
            encodeURIComponent(clientName) + '&project_name=' +
            encodeURIComponent(projectName) + '&project_code=' +
            encodeURIComponent(projectCode), '_blank')
    }

    function openSGView(clientName, projectName, projectCode) {
        let link = window.location.href.split("/")

        window.open(
            'http://' + link[2] + '/managed_quality_on_project/project_bible/styleguide?client_name=' +
            encodeURIComponent(clientName) + '&project_name=' +
            encodeURIComponent(projectName) + '&project_code=' +
            encodeURIComponent(projectCode), '_blank')
    }

    function openPSView(project, order, service) {
        let link = window.location.href.split("/")

        window.open(
            'http://' + link[2] + '/managed_quality_on_project/project_stages?service=' +
            encodeURIComponent(service) + '&project=' +
            encodeURIComponent(project) + '&order=' +
            encodeURIComponent(order), '_blank')
    }

    return (
        <div className="div-table-blue">
        <Table responsive bordered style={styles.tableFixHeadTable}>
            <thead>
            <tr style={styles.tableHeaderRow}>
                <th className="center" style={styles.tableFixHeadTh}>Client Name</th>
                <th className="center" style={styles.tableFixHeadTh}>Project Name</th>
                <th className="center" style={styles.tableFixHeadTh}>Project Code</th>
                <th className="center" style={styles.tableFixHeadTh}>All Stages</th>
                {
                    props.stagesList.map(stage => {
                        return (
                            <th key={stage} className="center" style={styles.tableFixHeadTh}>{stage}</th>
                        )
                    })
                }
                {/*<th className="center" style={styles.tableFixHeadTh}>KPI<br/>(avg: { +(props.statistics.sumKpi / props.statistics.createdSG).toFixed(2) }%)</th>*/}
                <th className="center" style={styles.tableFixHeadTh}>Style Guide<br />(count: { props.statistics.createdSG })</th>
                <th className="center" style={styles.tableFixHeadTh}>Workflow<br/>(count: { props.statistics.createdWorkflow })</th>
            </tr>
            </thead>
            <tbody>
            {
                rows.map((data, index) => {
                    let psValue = "🔴"

                    if (data.ps.value && data.ps.filled) {
                        psValue = "🟢"
                    } else if (data.ps.value) {
                        psValue = "🟡"
                    }

                    return(
                        <tr key={index}>
                            <td onClick={(e) =>
                                openView(data.clientName, data.projectName, data.projectCode)}
                                style={styles.cellStyle}>{data.clientName}</td>
                            <td onClick={(e) =>
                                openView(data.clientName, data.projectName, data.projectCode)}
                                style={styles.cellStyle}>{data.projectName}</td>
                            <td onClick={(e) =>
                                openView(data.clientName, data.projectName, data.projectCode)}
                                style={styles.cellStyle}>{data.projectCode}</td>
                            <td className="center" onClick={(e) =>
                                openView(data.clientName, data.projectName, data.projectCode)}
                                style={data.fillingByStage.all >= 90 ? styles.stagesFillMore90 : styles.stagesFillUnder90}>{data.fillingByStage.all}%</td>
                            {
                                props.stagesList.map(stage => {
                                    let percentage = 0


                                    if (stage in data.fillingByStage.byStages) {
                                        percentage = data.fillingByStage.byStages[stage].percentage
                                    }

                                    return (
                                        <td key={stage} className="center" onClick={(e) =>
                                            openView(data.clientName, data.projectName, data.projectCode)}
                                            style={percentage >= 90 ? styles.stagesFillMore90 : styles.stagesFillUnder90}>{percentage}%</td>
                                    )
                                })
                            }
                            {/*{
                                data.kpi < 50 &&
                                <td className="center" onClick={(e) =>
                                    openView(data.clientName, data.projectName, data.projectCode)}
                                    style={{background: '#F47C7C', minWidth: '200px'}}>{data.kpi}%</td>
                            }
                            {
                                data.kpi >= 50 && data.kpi < 100 &&
                                <td className="center" onClick={(e) =>
                                    openView(data.clientName, data.projectName, data.projectCode)}
                                    style={{background: '#F7F48B', minWidth: '200px'}}>{data.kpi}%</td>
                            }
                            {
                                data.kpi === 100 &&
                                <td className="center" onClick={(e) =>
                                    openView(data.clientName, data.projectName, data.projectCode)}
                                    style={{background: '#A1DE93', minWidth: '200px'}}>{data.kpi}%</td>
                            }*/}
                            <td onClick={(e) =>
                                openSGView(data.clientName, data.projectName, data.projectCode)}
                                className="center" style={styles.cellStyle}>{data.sg ? "🟢" : "🔴"}</td>
                            <td onClick={(e) =>
                                openPSView(data.ps.project, data.ps.order, data.ps.service)}
                                className="center" style={styles.cellStyle}>{psValue}</td>
                        </tr>
                    )
                })
            }
            </tbody>
        </Table>
        </div>
    )
}