import {Table} from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";

const styles = {
    tableFixHeadTable: {
        borderCollapse: 'collapse',
        width: '100%',
    },

    tableHeaderRow: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderTopColor: '#4A8EFF',
        // borderLeftColor: '#4A8EFF',
        // borderRightColor: '#4A8EFF'
    },

    tableFixHeadTh: {
        position: 'sticky',
        top: -2,
        zIndex: 1,
        whiteSpace: 'pre-line',
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderTopColor: '#4A8EFF',
        // borderLeftColor: '#4A8EFF',
        // borderRightColor: '#4A8EFF'
    },

    cellStyle: {
        backgroundColor: '#fff',
        whiteSpace: 'pre-line',
        minWidth: '100px',
        wordBreak: 'break-word'
    }
}

export default function UncreatedTableInfo(props) {
    let getRows = data => {
        let content = []

        for (let clientName in data) {
            for (let projectName in data[clientName]) {
                for (let projectCode in data[clientName][projectName]) {
                    if (!data[clientName][projectName][projectCode].created) {
                        content.push(
                            <tr key={clientName + "_" + projectName + "_" + projectCode}>
                                <td style={styles.cellStyle}>{clientName}</td>
                                <td style={styles.cellStyle}>{projectName}</td>
                                <td style={styles.cellStyle}>{projectCode}</td>
                            </tr>
                        )
                    }
                }
            }
        }

        return content
    }

    return (
        <div className="div-table-blue">
            <Table responsive bordered style={styles.tableFixHeadTable}>
                <thead>
                <tr style={styles.tableHeaderRow}>
                    <th className="center" style={styles.tableFixHeadTh}>Client Name</th>
                    <th className="center" style={styles.tableFixHeadTh}>Project Name</th>
                    <th className="center" style={styles.tableFixHeadTh}>Project Code</th>
                </tr>
                </thead>
                <tbody>
                {getRows(props.rows)}
                </tbody>
            </Table>
        </div>
    )
}
