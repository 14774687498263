import {Table} from "react-bootstrap";
import React, {useState} from "react";
import TableRow from "../client_view/TableRow";

const styles = {
    noEditableCell: {
        backgroundColor: '#fafafa',
    },

    tableFixHeadTable: {
        borderCollapse: 'collapse',
        width: '100%',
    },

    tableHeaderRow: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    tableFixHeadTh: {
        position: 'sticky',
        top: -2,
        zIndex: 1,
        whiteSpace: 'pre-line',
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    tableBodyTopBorder: {
        borderTop: '0px'
    }
}

export default function TableInfo(props) {
    console.log("props.columns, props.rows", props.columns, props.rows)

    return (
        <div className="div-table-blue">
            <Table responsive bordered>
                <thead>
                <tr style={styles.tableHeaderRow}>
                    <th key="№" className="center" style={styles.tableFixHeadTh}>№</th>
                    {
                        props.columns.map(column => {
                            return (
                                <th key={column.name} className="center"
                                    style={styles.tableFixHeadTh}>{column.name}</th>
                            )
                        })
                    }
                </tr>
                </thead>
                <tbody style={styles.tableBodyTopBorder}>
                {
                    props.rows.map((row, rowIndex) => {
                        return (
                            <TableRow key={rowIndex} row={row} rowIndex={rowIndex} columns={props.columns}
                                      submit={props.submit} setFormUpdateValue={props.setFormUpdateValue}/>
                        )
                    })
                }
                </tbody>
            </Table>
        </div>
    )
}