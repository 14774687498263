import {Button, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const styles = {
    noEditableCell: {
        backgroundColor: '#f6f6f6'
    },

    deleteCharacterColumn: {
        background: '#fff',
        width: '95%'
    },

    deleteCharacterColumnAction: {
        background: '#fff',
        width: '5%'
    },

    imgArrowPercentSize: {
        maxHeight: '40px',
        maxWidth: '40px'
    },

    firstColumn: {
        backgroundColor: '#e8f6e9' //light green
    },

    firstRow: {
        backgroundColor: '#FFE5E5', //pink
        minWidth: '200px'
    },

    tableCell: {
        background: '#fff'
    }
}

export default function StyleGuideModalSettingsCharacterCommunication(props) {
    console.log("CHARACTER SETTINGS", props)

    if (props.communicationBetweenCharacters) {
        return(
            <div>
                <br />
                <hr />
                <br />
                <div className="row">
                    <div className="col-sm-12">
                        <h4>Communications between characters</h4>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-12">
                        {
                            props.templateData.length > 0 &&
                            <div className="table-responsive character-communication-container">
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th className="center align-middle">
                                            <img alt="logo" src={require(`../img/right-arrow.png`).default}
                                                 style={{minWidth: '200px'}}
                                                 data-pr-tooltip="PrimeReact-Logo" style={styles.imgArrowPercentSize}
                                                 onError={(e) =>
                                                     e.target.src = require(`../img/error.jpg`).default}/>
                                        </th>
                                        {
                                            props.templateData.map((value, valueIndex) => {
                                                return (
                                                    <th key={valueIndex} className="center" contentEditable={true}
                                                        suppressContentEditableWarning={true} style={styles.firstRow}
                                                        dangerouslySetInnerHTML={{__html: value.name}}
                                                        onBlur={(e) =>
                                                            props.updateCharacterName(valueIndex + 1, value.id, "input", e)}>
                                                    </th>
                                                )
                                            })
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        props.templateData.map((value, valueIndex) => {
                                            const rowCells = [];

                                            for (let i = 0; i < Object.keys(props.templateData).length; i++) {
                                                let cellValue = /*props.templateData[valueIndex].data[i]*/""

                                                if (valueIndex in props.templateData && i in props.templateData[valueIndex].data) {
                                                    cellValue = props.templateData[valueIndex].data[i]
                                                }

                                                /*if (i in props.templateData[valueIndex].data) {
                                                    cellValue = props.templateData[valueIndex].data[i]
                                                }*/

                                                if (valueIndex !== i) {
                                                    rowCells.push(
                                                        <td key={i} className="whiteSpacePreLine"
                                                            contentEditable={true}
                                                            style={styles.tableCell}
                                                            suppressContentEditableWarning={true}
                                                            dangerouslySetInnerHTML={{__html: cellValue}}
                                                            onBlur={(e) =>
                                                                props.oninputCharacterCommunicationCell(0,
                                                                    value.id, valueIndex + 1, i + 1, "input", e)}>
                                                        </td>
                                                    );
                                                } else {
                                                    rowCells.push(
                                                        <td key={i} className="whiteSpacePreLine"
                                                            contentEditable={false}
                                                            style={styles.noEditableCell}
                                                            dangerouslySetInnerHTML={{__html: cellValue}}>
                                                        </td>
                                                    );
                                                }
                                            }

                                            return (
                                                <tr key={valueIndex}>
                                                    <th className="center" contentEditable={true}
                                                        suppressContentEditableWarning={true}
                                                        dangerouslySetInnerHTML={{__html: value.name}}
                                                        style={styles.firstColumn}
                                                        onBlur={(e) =>
                                                            props.updateCharacterName(valueIndex + 1, value.id, "input", e)}>
                                                    </th>
                                                    {
                                                        rowCells
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
                <br />
                    {
                        props.templateData.length > 0 ? (
                            <div className="row">
                                <div className="col-sm-8 center">
                                    <Button variant="primary" className="btn btn-blue" onClick={(e) =>
                                        props.addCharacter()}>
                                        Добавить персонажа&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                                    </Button>
                                </div>
                                <div className="col-sm-4 center">
                                    <Table responsive bordered>
                                        <tbody>
                                        {
                                            props.templateData.map((value, valueIndex) => {
                                                return (
                                                    <tr key={valueIndex}>
                                                        <th className="center" contentEditable={true}
                                                            suppressContentEditableWarning={true}
                                                            dangerouslySetInnerHTML={{__html: value.name}}
                                                            onBlur={(e) =>
                                                                props.updateCharacterName(valueIndex + 1, value.id, "input", e)}
                                                            style={styles.deleteCharacterColumn}>
                                                        </th>
                                                        <td className="center"
                                                            style={styles.deleteCharacterColumnAction}>
                                                            <Button size="sm" variant="danger" onClick={(e) =>
                                                                props.deleteCharacter(value.id, valueIndex + 1)}>
                                                                <FontAwesomeIcon icon={faTimes} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <Button variant="primary" className="btn btn-blue" onClick={(e) =>
                                        props.addCharacter()}>
                                        Добавить персонажа&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                                    </Button>
                                </div>
                            </div>
                        )
                    }
            </div>
        )
    } else {
        return(
            <div>

            </div>
        )
    }
}