import "../css/modal.css"
import {Button, Col, FormControl, Nav, Row, Tabs, Tab} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import queryString from "query-string";
import ModalCellDataHistoryList from "../ModalCellDataHistoryList";

const styles = {
    modalWH: {
        width: '75%',
        height: '70%',
        backgroundColor: '#222' /* dark-theme */
    },

    alignRight: {
        textAlign: 'right'
    },

    noEditableCell: {
        background: '#fff',
    },

    tableHeaders: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },
}

export default function AddLinksAndImagesModal(props) {
    const queryStringParams = queryString.parse(window.location.search)
    const clientName = queryStringParams.client_name
    const projectName = queryStringParams.project_name
    const projectCode = queryStringParams.project_code
    const [isNewValueFromHistoryChecked, setIsNewValueFromHistoryChecked] = useState(false)
    const [newValueFromHistoryIndex, setNewValueFromHistoryIndex] = useState(0)
    const [newValueFromHistory, setNewValueFromHistory] = useState("")

    let associativeLinks = props.modalAddLinksAndImagesData[0].links
    let associativeImages = props.modalAddLinksAndImagesData[0].bufferedImages

    function setLinkTitle(link, e) {
        for (let key in associativeLinks) {
            let value = associativeLinks[key]

            if (key === link) {
                value.title = e.target.value
            }
        }
    }

    function setImageTitle(index, e) {
        associativeImages[index].title = e.target.value

        props.setImagesBufferedData(associativeImages)
    }

    function deleteImage(index) {
        associativeImages[index].src = ''

        props.setImagesBufferedData(associativeImages)
    }

    async function saveImage(index, file) {
        if (file) {
            const formData = new FormData()
            // let fileDirectoryLocation = 'managed_quality_on_project/project_bible/uploads/' + clientName + '/' + projectName
            let separator = 'img/pb'
            let fileDirectoryLocation = separator + '/' + clientName + '/' + projectName

            formData.append('myFile', file[0])

            const response = await fetch('/proxy/upload_file?fileDirectoryLocation=' + fileDirectoryLocation + '&separator=' + separator, {
                method: 'POST',
                body: formData
            });
            let result = await response.json();

            associativeImages[index].src = result.fullPath

            props.setImagesBufferedData(associativeImages)
        }
    }

    function saveAssociativeLinks() {
        let newValue = props.modalAddLinksAndImagesData[0].value
        let saveMethod = props.modalAddLinksAndImagesData[0].saveMethod

        for (let key in associativeLinks) {
            let value = associativeLinks[key]

            if (value.tagExist && value.title) {
                //todo заменяем в теге с ссылкой сокращение
                console.log("todo заменяем в теге с ссылкой сокращение")
                newValue = newValue.replace(value.tagExist, "<a href='" + value.link + "'>" + value.title + "</a>")
            } else if (value.tagExist && !value.title) {
                //todo оставляем просто ссылку
                console.log("todo оставляем просто ссылку")
                newValue = newValue.replace(value.tagExist, value.link)
            } else if (!value.tagExist && !value.title) {
                //todo ничего не делаем
                console.log("todo WTF", value.tagExist, value.title)
            } else if (!value.tagExist && value.title) {
                //todo заменяем ссылку на тег с сокращением
                console.log("todo заменяем ссылку на тег с сокращением", value.link)
                newValue = newValue.replace(value.link, "<a href='" + value.link + "'>" + value.title + "</a>")
            }
        }

        if (saveMethod === "generalTabSaveCellValue") {
            let identificationData = props.modalAddLinksAndImagesData[0].identificationData

            props.generalTabSaveCellValue(identificationData.column, identificationData.row, identificationData.type,
                newValue, true)
        }
    }

    function saveAssociativeImages() {
        let cellText = props.modalAddLinksAndImagesData[0].value
        let saveMethod = props.modalAddLinksAndImagesData[0].saveMethod
        let regexImg = /(<button[^>].*?class=\".*?open-in-modal-added-image.*?\".*?data-src=\".*?\">.*?<\/button>)/g;
        let imgArray = cellText.split(regexImg)
        let searchImgButtonsIndex = 0

        for (let i = 0; i < imgArray.length; i++) {
            if (imgArray[i].includes("open-in-modal-added-image")) {
                let srcValue = associativeImages[searchImgButtonsIndex].src
                let titleValue = associativeImages[searchImgButtonsIndex].title

                if (srcValue) {
                    /*imgArrayToDB[i] = "<button type=\"button\" class=\"btn btn-blue btn-sm open-in-modal-added-image\" contenteditable=\"false\" data-src=\"" +
                        srcValue + "\">" +
                        titleValue +"</button>"*/
                    imgArray[i] = "<button type=\"button\" class=\"btn btn-blue btn-sm open-in-modal-added-image\" contenteditable=\"false\" data-src=\"" +
                        srcValue + "\" data-just-uploaded='1'>" +
                        titleValue +"</button>"
                } else {
                    /*imgArrayToDB[i] = "<button type=\"button\" class=\"btn btn-secondary btn-sm open-in-modal-added-image\" contenteditable=\"false\" data-src=\"" +
                        srcValue + "\">" +
                        titleValue +"</button>"*/
                    imgArray[i] = "<button type=\"button\" class=\"btn btn-secondary btn-sm open-in-modal-added-image\" contenteditable=\"false\" data-src=\"" +
                        srcValue + "\">" +
                        titleValue +"</button>"
                }

                searchImgButtonsIndex++
            }
        }

        if (saveMethod === "generalTabSaveCellValue") {
            let identificationData = props.modalAddLinksAndImagesData[0].identificationData

            props.generalTabSaveCellValue(identificationData.column, identificationData.row, identificationData.type,
                imgArray.join(''), true)
        }
    }

    let getAllLinks = links => {
        let content = []

        for (let key in links) {
            let value = links[key]

            content.push(
                <div className="row" key={key}>
                    <div className="col-sm-8">
                        <FormControl placeholder={key} disabled />
                    </div>
                    <div className="col-sm-4">
                        <FormControl defaultValue={value.title}
                                     onBlur={(e) =>
                                         setLinkTitle(key, e)} />
                    </div>
                    <br />
                    <br />
                </div>
            )
        }

        return content
    };

    let getAllImages = images => {
        let content = []

        // console.log("getAllImages", images)

        for (let key in images) {
            let value = images[key]

            content.push(
                <div className="row" key={key}>
                    <div className="col-sm-2">
                        <FormControl defaultValue={value.title}
                                     onBlur={(e) =>
                                         setImageTitle(key, e)} />
                    </div>
                    <div className="col-sm-10">
                        {
                            value.src ? (
                                <div className="row">
                                    <div className="col-sm-11 center">
                                        <FormControl type="file"
                                                     onChange={(e) =>
                                                         saveImage(key, e.target.files)} />
                                    </div>
                                    {/*<div className="col-sm-3 center">
                                        <button className="btn btn-dark"
                                                onClick={(e) =>
                                                    props.openInModalAddedImage(value.src)}>
                                            Open Image <FontAwesomeIcon icon={faSearch}/>
                                        </button>
                                    </div>*/}
                                    <div className="col-sm-1" style={styles.alignRight}>
                                        <button className="btn btn-danger"
                                                onClick={(e) =>
                                                    deleteImage(key)}>
                                            <FontAwesomeIcon icon={faTimes}/>
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-sm-12">
                                        <FormControl type="file"
                                                     onChange={(e) =>
                                                         saveImage(key, e.target.files)} />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <br />
                    <br />
                </div>
            )
        }

        return content
    };

    function selectNewValueFromHistory(key, isСhecked, value) {
        console.log("selectNewValueFromHistory", key, isСhecked, value)

        if (isСhecked) {
            setIsNewValueFromHistoryChecked(true)
            setNewValueFromHistoryIndex(key)
            setNewValueFromHistory(value)

            props.editModalHistoryDataCheckedValue(key, isСhecked)
        } else {
            setIsNewValueFromHistoryChecked(false)

            props.editModalHistoryDataCheckedValue(key, isСhecked)
        }
    }

    return(
        <div className={props.modalAddLinksAndImagesActive ? "modal active" : "modal"} onClick={() => props.setModalAddLinksAndImagesActive(false)} >
            <div className={props.modalAddLinksAndImagesActive ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}
                 style={styles.modalWH} >
                <Tab.Container defaultActiveKey={"links"}>
                    <Row>
                        <Col sm={12}>
                            <Nav variant="pills" className="nav-custom-grey">
                                <Nav.Item>
                                    <Nav.Link eventKey={"links"}>
                                        Links
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={"images"}>
                                        Images
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={"history"}>
                                        History
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey={"links"}>
                                    <div className="tab-item-wrapper">
                                        <br/>
                                        <div className="row">
                                            <div className="col-sm-8">
                                                <h4>Create hyperlinks here</h4>
                                            </div>
                                            <div className="col-sm-4 center">
                                                <button className="btn btn-blue"
                                                        onClick={(e) => saveAssociativeLinks()}>Save
                                                </button>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <FormControl as="textarea" rows={5}
                                                             placeholder={props.modalAddLinksAndImagesData[0].value}
                                                             disabled/>
                                            </div>
                                        </div>
                                        <br/>
                                        <hr/>
                                        <br/>
                                        <div className="row">
                                            <div className="col-sm-8 center">
                                                <h5>Link</h5>
                                            </div>
                                            <div className="col-sm-4 center">
                                                <h5>Text to display (if it's empty, a hyperlink won't be added)</h5>
                                            </div>
                                        </div>
                                        {getAllLinks(props.modalAddLinksAndImagesData[0].links)}
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey={"images"}>
                                    <div className="tab-item-wrapper">
                                        <br/>
                                        <div className="row">
                                            <div className="col-sm-8">
                                                <h4>Attach images here</h4>
                                            </div>
                                            <div className="col-sm-4 center">
                                                <button className="btn btn-blue"
                                                        onClick={(e) => saveAssociativeImages()}>Save
                                                </button>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <FormControl as="textarea" rows={5}
                                                             placeholder={props.modalAddLinksAndImagesData[0].value}
                                                             disabled/>
                                            </div>
                                        </div>
                                        <br/>
                                        <hr/>
                                        <br/>
                                        <div className="row">
                                            <div className="col-sm-2 center">
                                                <h5>Short image name</h5>
                                            </div>
                                            <div className="col-sm-10 center">
                                                <h5>Upload area</h5>
                                            </div>
                                        </div>
                                        {getAllImages(associativeImages)}
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey={"history"}>
                                    <div className="tab-item-wrapper">
                                        <br/>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h4>{props.modalOpenCellDataHistoryData[0].modalTitle}</h4>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="table-responsive table-blue-thead-not-fixed">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                        <tr style={styles.tableHeaders}>
                                                            <th className="center align-middle"
                                                                style={styles.tableHeaders}></th>
                                                            <th className="center align-middle"
                                                                style={styles.tableHeaders}>Changed by
                                                            </th>
                                                            <th className="center align-middle"
                                                                style={styles.tableHeaders}>Value
                                                            </th>
                                                            <th className="center align-middle"
                                                                style={styles.tableHeaders}>Timestamp
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <ModalCellDataHistoryList
                                                            data={props.modalOpenCellDataHistoryData[0].data}
                                                            isNewValueChecked={isNewValueFromHistoryChecked}
                                                            newValueIndex={newValueFromHistoryIndex}
                                                            selectNewValue={selectNewValueFromHistory}
                                                            setIsNewValueChecked={setIsNewValueFromHistoryChecked}
                                                            setNewValueIndex={setNewValueFromHistoryIndex}
                                                            setNewValue={setNewValueFromHistory}
                                                        />
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        {
                                            isNewValueFromHistoryChecked &&
                                            <div className="row">
                                                <div className="col-sm-12 center">
                                                    <Button variant="primary" className="btn btn-blue"
                                                            onClick={(e) =>
                                                                props.replaceToOldValueFromDataHistory(
                                                                    props.modalOpenCellDataHistoryData[0].saveMethod,
                                                                    props.modalOpenCellDataHistoryData[0].identityData,
                                                                    props.modalOpenCellDataHistoryData[0].tableName,
                                                                    newValueFromHistory
                                                                )}>
                                                        Replace to old value
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </div>
    )
}
