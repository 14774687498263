import {Button, Form, FormCheck, Table} from "react-bootstrap";
import React, {useState} from "react";

const styles = {
    noEditableCell: {
        backgroundColor: '#f6f6f6'
    },

    noActionCell: {
        backgroundColor: '#fafafa'
    },

    disabledInput: {
        backgroundColor: 'white'
    },

    editedCell: {
        backgroundColor: '#ffffff'
    },

    acceptedCell: {
        backgroundColor: '#dffdde'
    },

    bufferedCell: {
        backgroundColor: '#fff8e1'
    },

    acceptedFromOtherBufferedCell: {
        backgroundColor: '#ffeee1'
    },

    rejectedCell: {
        backgroundColor: '#ffd4d4'
    },

    cellColorHelperTable: {
        width: '30%'
    },

    imgArrowPercentSize: {
        maxHeight: '40px',
        maxWidth: '40px'
    },

    firstColumn: {
        backgroundColor: '#e8f6e9' //light green
    },

    firstRow: {
        backgroundColor: '#FFE5E5' //pink
    },

    miniTableWhiteCells: {
        backgroundColor: '#fff'
    }
}

export default function LinguistLanguageTabCharacterCommunication(props) {
    console.log("Communication between characters", props)

    const [templateTooltipText, setTemplateTooltipText] = useState("")
    const [languageTemplateTooltipText, setLanguageTemplateTooltipText] = useState("")
    const [acceptedFromLinguistTooltipText, setAcceptedFromLinguistTooltipText] = useState("")
    let templateData = props.characterCommunicationsDefault.templateData
    let languagesData = props.characterCommunicationsDefault.languagesData[props.language]
    let templateLanguageData = languagesData.data
    let languageDataFromLinguist = languagesData.linguistAcceptedData

    function setTooltip(templateValue, languageTemplateValue, linguist) {
        setTemplateTooltipText(templateValue)
        setLanguageTemplateTooltipText(languageTemplateValue)
        setAcceptedFromLinguistTooltipText(linguist)
    }

    return(
        <div>
            <div className="row">
                <div className="col-sm-8">
                    <div className="row">
                        <div className="col-sm-12">
                            <FormCheck type="checkbox" label="Accept all character communication changes" defaultChecked={props.acceptAllCharactersCommunicationLinguistData}
                                onChange={(e) => props.setCharacterCommunicationsAcceptAllValue(e.target.checked)} />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-sm-12">
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicTemplateValue">
                                    <Form.Label>You can see a hint about the characters' relationships in the box below. Put in
                                        the table how a character from the first column addresses characters from the first
                                        row.</Form.Label>
                                    <Form.Control as="textarea" rows={2}
                                                  value={templateTooltipText} disabled style={styles.disabledInput} placeholder="Template value" />
                                    <br/>
                                    <Form.Control as="textarea" rows={2}
                                                  value={languageTemplateTooltipText} disabled style={styles.disabledInput} placeholder="Language template value" />
                                    <br/>
                                    <Form.Control as="textarea" rows={1}
                                                  value={acceptedFromLinguistTooltipText} disabled style={styles.disabledInput} placeholder="Linguist value" />
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="row">
                        <div className="col-sm-12">
                            <table className="table table-bordered">
                                <tbody>
                                <tr>
                                    <td style={{width: '20%', backgroundColor: '#fafafa'}}></td>
                                    <td style={styles.miniTableWhiteCells}>В данной ячейке от лингвиста не было изменений</td>
                                </tr>
                                <tr>
                                    <td style={{width: '20%', backgroundColor: '#fff8e1'}}></td>
                                    <td style={styles.miniTableWhiteCells}>От текущего лингвиста есть изменения в ячейке</td>
                                </tr>
                                <tr>
                                    <td style={{width: '20%', backgroundColor: '#ffeee1'}}></td>
                                    <td style={styles.miniTableWhiteCells}>Ячейка принята у другого лингвиста, а от текущего в ней есть изменения</td>
                                </tr>
                                <tr>
                                    <td style={{width: '20%', backgroundColor: '#ffd4d4'}}></td>
                                    <td style={styles.miniTableWhiteCells}>Ранее принятую ячейку у текущего лингвиста отклонили</td>
                                </tr>
                                <tr>
                                    <td style={{width: '20%', backgroundColor: '#dffdde'}}></td>
                                    <td style={styles.miniTableWhiteCells}>Ячейка принята у текущего лингвиста</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-sm-12 center">
                    {
                        templateData.length > 0 &&
                        <div className="table-responsive character-communication-container">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th className="center align-middle">
                                        <img alt="logo" src={require(`../img/right-arrow.png`).default}
                                             data-pr-tooltip="PrimeReact-Logo" style={styles.imgArrowPercentSize}
                                             onError={(e) =>
                                                 e.target.src = require(`../img/error.jpg`).default}/>
                                    </th>
                                    {
                                        templateData.map((value, valueIndex) => {
                                            return (
                                                <th key={valueIndex} className="center" contentEditable={false}
                                                    style={styles.firstRow}
                                                    dangerouslySetInnerHTML={{__html: value.name}}>
                                                </th>
                                            )
                                        })
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    templateData.map((value, valueIndex) => {
                                        const rowCells = [];

                                        for (let i = 0; i < Object.keys(templateData).length; i++) {
                                            let actualValueForTable = ""
                                            let defaultCellValue = ""
                                            let languageCellValue = ""
                                            let acceptFromLinguistValue = ""
                                            let acceptFromLinguist = "-"
                                            let isActiveCell = false
                                            let cellStyle = styles.editedCell

                                            if (valueIndex in templateData && i in templateData[valueIndex].data) {
                                                defaultCellValue = templateData[valueIndex].data[i]
                                                actualValueForTable = defaultCellValue
                                            }

                                            if (valueIndex in templateLanguageData && i in templateLanguageData[valueIndex]) {
                                                languageCellValue = templateLanguageData[valueIndex][i]
                                                actualValueForTable = languageCellValue
                                            }

                                            if (valueIndex in languageDataFromLinguist && i in languageDataFromLinguist[valueIndex]) {
                                                let acceptedFromLinguistData = languageDataFromLinguist[valueIndex][i]

                                                acceptFromLinguistValue = acceptedFromLinguistData.value
                                                actualValueForTable = acceptFromLinguistValue
                                                acceptFromLinguist = acceptedFromLinguistData.linguist
                                            }

                                            if (valueIndex in props.charactersCommunicationLinguistData
                                                && i in props.charactersCommunicationLinguistData[valueIndex]) {
                                                let cellValue = props.charactersCommunicationLinguistData[valueIndex][i]

                                                isActiveCell = true

                                                if ("acceptedValue" in cellValue) {
                                                    actualValueForTable = cellValue.acceptedValue
                                                    cellStyle = styles.acceptedCell
                                                }

                                                if ("isBuffered" in cellValue) {
                                                    actualValueForTable = cellValue.value

                                                    if (acceptFromLinguistValue) {
                                                        actualValueForTable += "<br><strong>[" + acceptFromLinguistValue +"]</strong>"
                                                        cellStyle = styles.acceptedFromOtherBufferedCell
                                                    } else {
                                                        cellStyle = styles.bufferedCell
                                                    }
                                                }

                                                if ("isAcceptedInCurrentSession" in cellValue) {
                                                    if (cellValue.isAcceptedInCurrentSession) {
                                                        cellStyle = styles.acceptedCell
                                                    } else {
                                                        let isAccepted = "acceptedValue" in cellValue
                                                        let isBuffered = "isBuffered" in cellValue

                                                        if (isBuffered) {
                                                            cellStyle = acceptFromLinguistValue ? styles.acceptedFromOtherBufferedCell : styles.bufferedCell
                                                        } else if (isAccepted) {
                                                            cellStyle = styles.rejectedCell
                                                        }
                                                    }
                                                }
                                            }

                                            if (valueIndex !== i) {
                                                if (isActiveCell) {
                                                    rowCells.push(
                                                        <td key={i} className="whiteSpacePreLine"
                                                            contentEditable={false}
                                                            style={cellStyle}
                                                            dangerouslySetInnerHTML={{__html: actualValueForTable}}
                                                            onClick={(e) =>
                                                                props.setCharacterCommunicationsAcceptValue(valueIndex, i)}
                                                            onMouseOver={(e) =>
                                                                setTooltip(defaultCellValue, languageCellValue, acceptFromLinguist)}
                                                            onMouseOut={(e) =>
                                                                setTooltip("", "", "")}>
                                                        </td>
                                                    );
                                                } else {
                                                    rowCells.push(
                                                        <td key={i} className="whiteSpacePreLine"
                                                            contentEditable={false}
                                                            style={styles.noActionCell}
                                                            dangerouslySetInnerHTML={{__html: actualValueForTable}}
                                                            onMouseOver={(e) => setTooltip(defaultCellValue, languageCellValue, acceptFromLinguist)}
                                                            onMouseOut={(e) => setTooltip("", "", "")}>
                                                        </td>
                                                    );
                                                }
                                            } else {
                                                rowCells.push(
                                                    <td key={i} className="whiteSpacePreLine"
                                                        contentEditable={false}
                                                        style={styles.noEditableCell}
                                                        dangerouslySetInnerHTML={{__html: languageCellValue}}>
                                                    </td>
                                                );
                                            }
                                        }

                                        return (
                                            <tr key={valueIndex}>
                                                <th className="center" contentEditable={false}
                                                    style={styles.firstColumn}
                                                    dangerouslySetInnerHTML={{__html: value.name}}>
                                                </th>
                                                {
                                                    rowCells
                                                }
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </div>
    )

    /*return(
        <div>
            <br />
            <div className="row">
                <div className="col-sm-12 center">
                    <h5>Linguist data</h5>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-sm-12">
                    {
                        props.characters.length > 0 &&
                        <Table responsive bordered>
                            <tbody>
                            <tr>
                                <th></th>
                                {
                                    props.characters.map((value, valueIndex) => {
                                        return (
                                            <th key={valueIndex} className="center" contentEditable={false}
                                                dangerouslySetInnerHTML={{__html: value.name}} >
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                            {
                                props.characters.map((value, valueIndex) => {
                                    const rowCells = [];

                                    for (let i = 0; i < Object.keys(props.characters).length; i++) {
                                        let defaultCellValue = ""
                                        let defaultLanguageCellValue = ""
                                        let languageCellValue = ""

                                        if (valueIndex in props.baseStyleGuideData && i in props.baseStyleGuideData[valueIndex]) {
                                            defaultCellValue = props.baseStyleGuideData[valueIndex][i]
                                        }

                                        if (valueIndex in props.templateData && i in props.templateData[valueIndex]) {
                                            defaultLanguageCellValue = props.templateData[valueIndex][i]
                                        }

                                        if (valueIndex in props.languageData && i in props.languageData[valueIndex]) {
                                            languageCellValue = props.languageData[valueIndex][i]
                                        }

                                        if (valueIndex !== i) {
                                            if (languageCellValue) {
                                                rowCells.push(
                                                    <td key={i} className="whiteSpacePreLine"
                                                        contentEditable={true} style={styles.editedCell}
                                                        suppressContentEditableWarning={true}
                                                        dangerouslySetInnerHTML={{__html: languageCellValue}}>
                                                    </td>
                                                );
                                            } else {
                                                rowCells.push(
                                                    <td key={i} className="whiteSpacePreLine"
                                                        contentEditable={true}
                                                        suppressContentEditableWarning={true}
                                                        dangerouslySetInnerHTML={{__html: languageCellValue}}>
                                                    </td>
                                                );
                                            }
                                        } else {
                                            rowCells.push(
                                                <td key={i} className="whiteSpacePreLine"
                                                    contentEditable={false}
                                                    style={styles.noEditableCell}
                                                    dangerouslySetInnerHTML={{__html: languageCellValue}}>
                                                </td>
                                            );
                                        }
                                    }

                                    return (
                                        <tr key={valueIndex}>
                                            <th className="center" contentEditable={false}
                                                dangerouslySetInnerHTML={{__html: value.name}} >
                                            </th>
                                            {
                                                rowCells
                                            }
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    }
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-sm-4">

                </div>
                <div className="col-sm-4 center">
                    <FormCheck type="checkbox" label="Accept character communication changes" defaultChecked={props.acceptedCheckedInfo}
                               onChange={(e) => props.setCharacterCommunicationsAcceptValue(e.target.checked)} />
                </div>
                <div className="col-sm-4">

                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-sm-12 center">
                    <h5>Default data</h5>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-sm-12">
                    {
                        props.characters.length > 0 &&
                        <Table responsive bordered>
                            <tbody>
                            <tr>
                                <th></th>
                                {
                                    props.characters.map((value, valueIndex) => {
                                        return (
                                            <th key={valueIndex} className="center" contentEditable={false}
                                                dangerouslySetInnerHTML={{__html: value.name}} >
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                            {
                                props.characters.map((value, valueIndex) => {
                                    const rowCells = [];

                                    for (let i = 0; i < Object.keys(props.characters).length; i++) {
                                        let defaultCellValue = ""
                                        let languageCellValue = ""

                                        if (valueIndex in props.baseStyleGuideData && i in props.baseStyleGuideData[valueIndex]) {
                                            defaultCellValue = props.baseStyleGuideData[valueIndex][i]
                                        }

                                        if (valueIndex in props.templateData && i in props.templateData[valueIndex]) {
                                            languageCellValue = props.templateData[valueIndex][i]
                                        }

                                        /!*if (valueIndex in props.languageData && i in props.languageData[valueIndex]) {
                                            languageCellValue = props.languageData[valueIndex][i]
                                        }*!/

                                        if (valueIndex !== i) {
                                            rowCells.push(
                                                <td key={i} className="whiteSpacePreLine"
                                                    contentEditable={true}
                                                    suppressContentEditableWarning={true}
                                                    dangerouslySetInnerHTML={{__html: languageCellValue}}>
                                                </td>
                                            );
                                        } else {
                                            rowCells.push(
                                                <td key={i} className="whiteSpacePreLine"
                                                    contentEditable={false}
                                                    style={styles.noEditableCell}
                                                    dangerouslySetInnerHTML={{__html: languageCellValue}}>
                                                </td>
                                            );
                                        }
                                    }

                                    return (
                                        <tr key={valueIndex}>
                                            <th className="center" contentEditable={false}
                                                dangerouslySetInnerHTML={{__html: value.name}} >
                                            </th>
                                            {
                                                rowCells
                                            }
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    }
                </div>
            </div>
        </div>
    )*/
}