import React, {useState} from "react";
import {Button, Form} from "react-bootstrap";

const styles = {
    modalWH: {
        width: '50%',
        height: '40%'
    },

    modalAlertWH: {
        width: '50%',
        height: '20%'
    }
}

export default function TemplatesFilterModal(props) {
    const [filterName, setFilterName] = useState("")

    if (props.modalOpenTemplatesFilterEditType === "save" || props.modalOpenTemplatesFilterEditType === "rename") {
        return(
            <div className={props.modalOpenTemplatesFilterEditActive ? "modal active" : "modal"} onClick={() => props.setModalOpenTemplatesFilterEditActive(false)} >
                <div className={props.modalOpenTemplatesFilterEditActive ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}
                     style={styles.modalWH} >
                    <div className="row">
                        <div className="col-sm-12">
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Enter template filter name:</Form.Label>
                                    <Form.Control as="textarea" rows={3} defaultValue={props.additionalComment}
                                                  onBlur={(e) =>
                                                      setFilterName(e.target.value)}/>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-sm-12 center">
                            {
                                props.modalOpenTemplatesFilterEditType === "save" &&
                                <Button variant="primary" className="btn btn-blue" onClick={(e) =>
                                    props.saveNewTemplateFilter(filterName)}>
                                    Сохранить фильтр
                                </Button>
                            }
                            {
                                props.modalOpenTemplatesFilterEditType === "rename" &&
                                <Button variant="primary" className="btn btn-blue" onClick={(e) =>
                                    props.renameTemplateFilter(filterName)}>
                                    Переименовать фильтр
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (props.modalOpenTemplatesFilterEditType === "update") {
        return(
            <div className={props.modalOpenTemplatesFilterEditActive ? "modal active" : "modal"} onClick={() => props.setModalOpenTemplatesFilterEditActive(false)} >
                <div className={props.modalOpenTemplatesFilterEditActive ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}
                     style={styles.modalAlertWH} >
                    <div className="row">
                        <div className="col-sm-12 center">
                            <h4>Updates saved</h4>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}