import {Table} from "react-bootstrap";
import React, {useState} from "react";
import ClientViewGeneralTableRow from "./ClientViewGeneralTableRow";

const styles = {
    noEditableCell: {
        backgroundColor: '#fafafa',
    },

    tableFixHeadTh: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    tableBodyTopBorder: {
        borderTop: '0px'
    }
}

export default function ClientViewGeneralTab(props) {
    return (
        <Table responsive bordered style={styles.tableFixHeadTable}>
            <thead>
            <tr style={styles.tableFixHeadTh}>
                <th key="№" className="center" style={styles.tableFixHeadTh}>№</th>
                {
                    props.columns.map(column => {
                        if (column.editableByClient) {
                            return (
                                <>
                                    <th key={column.code}
                                        className="center"
                                        style={styles.tableFixHeadTh}>
                                        {column.name}
                                    </th>
                                    <th key={"checkbox-col"}
                                        className="center"
                                        style={styles.tableFixHeadTh}>
                                    </th>
                                    <th key={column.code + "-client"}
                                        className="center"
                                        style={styles.tableFixHeadTh}>
                                        {column.name} (Client changes)
                                    </th>
                                </>
                            )
                        } else {
                            return (
                                <th key={column.code}
                                    className="center"
                                    style={styles.tableFixHeadTh}>
                                    {column.name}
                                </th>
                            )
                        }
                    })
                }
            </tr>
            </thead>
            <tbody style={styles.tableBodyTopBorder}>
            {
                props.rows.map((row, rowIndex) => {
                    return (
                        <ClientViewGeneralTableRow
                            viewCode={props.viewCode}
                            key={rowIndex}
                            row={row}
                            rowIndex={rowIndex}
                            columns={props.columns}
                            setAcceptedGeneralRows={props.setAcceptedGeneralRows}
                        />
                    )
                })
            }
            </tbody>
        </Table>
    )
}