import React, {useState} from "react";
import {Button, Col, Nav, Row, Tabs, Tab} from "react-bootstrap";
import LinguistLanguageTabPreferences from "./LinguistLanguageTabPreferences";
import LinguistLanguageTabCharacterCommunication from "./LinguistLanguageTabCharacterCommunication";
import queryString from "query-string";
import {faComment, faExclamation, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function LinguistLanguageTab(props) {
    const queryStringParams = queryString.parse(window.location.search)
    const clientName = queryStringParams.client_name
    const projectName = queryStringParams.project_name
    const projectCode = queryStringParams.project_code
    const [acceptedValues, setAcceptedValues] = useState([{
        languagePreferencesData: props.language.code in props.acceptData ? props.acceptData[props.language.code].languagePreferences.value : {}/*,
        characterCommunicationsData: props.language.code in props.acceptData ? props.acceptData[props.language.code].communicationBetweenCharacters.value : false*/
    }])
    const [bufferedValues, setBufferedValues] = useState([{
        languagePreferencesData: props.language.code in props.bufferedData ? props.bufferedData[props.language.code].languagePreferences.value : {}/*,
        characterCommunicationsData: props.language.code in props.bufferedData ? props.bufferedData[props.language.code].communicationBetweenCharacters.value : false*/
    }])
    const [linguistDataToSubmit, setLinguistDataToSubmit] = useState([{
        currentLinguist: {
            "accept": []/*,
            "buffered": []*/
        },
        otherLinguistsAcceptedRowsToRemove: []
    }])
    const [charactersCommunicationLinguistData, setCharactersCommunicationLinguistData] = useState([{
        data: props.language.code in props.linguistLinks[props.linguistCode].data.characterCommunications.languagesData ?
            props.linguistLinks[props.linguistCode].data.characterCommunications.languagesData[props.language.code].data : []
    }])
    const [acceptAllCharactersCommunicationLinguistData, setAcceptAllCharactersCommunicationLinguistData] = useState(false)

    console.log("LinguistLanguageTab props", props)

    function setLanguagePreferencesAcceptValue(block, rowCode, type, value, baseRowInformation) {
        let createdBy = baseRowInformation.createdBy
        let previouslyAcceptedBy = baseRowInformation.previouslyAcceptedBy
        let isBuffered = baseRowInformation.isBuffered
        let checked = baseRowInformation.checked

        console.log("setLanguagePreferencesAcceptValue", linguistDataToSubmit, block, rowCode, type, value, baseRowInformation)
        // console.log("setLanguagePreferencesAcceptValue start", acceptedValues[0].languagePreferencesData)

        setLinguistDataToSubmit(
            linguistDataToSubmit.map(data => {

                if (type === "default") {
                    //todo 3 случая
                    if (previouslyAcceptedBy) {
                        if (previouslyAcceptedBy === props.linguistCode) {
                            //1 случай
                            console.log("1 случай")

                            if (value) {
                                linguistAcceptedDataExcludeRow("remove", props.linguistCode, block, rowCode, "default")
                            } else {
                                linguistAcceptedDataPushRow("remove", props.linguistCode, block, rowCode, "default")
                            }
                        } else {
                            //2 случай
                            console.log("2 случай")

                            if (value) {
                                linguistAcceptedDataPushRow("add", props.linguistCode, block, rowCode, "default")
                                linguistAcceptedDataPushRow("remove", previouslyAcceptedBy, block, rowCode, "default")
                                // linguistBufferedDataPushRow("remove", block, rowCode, "default")
                            } else {
                                linguistAcceptedDataExcludeRow("add", props.linguistCode, block, rowCode, "default")
                                linguistAcceptedDataExcludeRow("remove", previouslyAcceptedBy, block, rowCode, "default")
                                // linguistBufferedDataExcludeRow("remove", block, rowCode, "default")
                            }
                        }
                    } else {
                        //0 случай

                        console.log("0 случай")
                        if (value) {
                            linguistAcceptedDataPushRow("add", props.linguistCode, block, rowCode, "default")
                            // linguistBufferedDataPushRow("remove", block, rowCode, "default")
                        } else {
                            linguistAcceptedDataExcludeRow("add", props.linguistCode, block, rowCode, "default")
                            // linguistBufferedDataExcludeRow("remove", block, rowCode, "default")
                        }
                    }
                } else if (type === "extra") {
                    if (createdBy === props.linguistCode) {
                        if (previouslyAcceptedBy) {
                            if (previouslyAcceptedBy === props.linguistCode) {
                                //3 случай
                                console.log("3 случай")

                                if (value) {
                                    linguistAcceptedDataExcludeRow("remove", props.linguistCode, block, rowCode, "extra")
                                } else {
                                    linguistAcceptedDataPushRow("remove", props.linguistCode, block, rowCode, "extra")
                                }
                            } else {
                                //5 случай
                                console.log("5 случай")

                                if (value) {
                                    linguistAcceptedDataPushRow("add", props.linguistCode, block, rowCode, "extra")
                                    linguistAcceptedDataPushRow("remove", previouslyAcceptedBy, block, rowCode, "extra")
                                    // linguistBufferedDataPushRow("remove", block, rowCode, "extra")
                                } else {
                                    linguistAcceptedDataExcludeRow("add", props.linguistCode, block, rowCode, "extra")
                                    linguistAcceptedDataExcludeRow("remove", previouslyAcceptedBy, block, rowCode, "extra")
                                    // linguistBufferedDataExcludeRow("remove", block, rowCode, "extra")
                                }
                            }
                        } else {
                            //8 случай
                            console.log("8 случай")

                            if (value) {
                                linguistAcceptedDataPushRow("add", props.linguistCode, block, rowCode, "extra")
                                // linguistBufferedDataPushRow("remove", block, rowCode, "extra")
                            } else {
                                linguistAcceptedDataExcludeRow("add", props.linguistCode, block, rowCode, "extra")
                                // linguistBufferedDataExcludeRow("remove", block, rowCode, "extra")
                            }
                        }
                    } else {
                        if (previouslyAcceptedBy === props.linguistCode) {
                            //4 случай
                            console.log("4 случай")

                            if (value) {
                                linguistAcceptedDataExcludeRow("remove", props.linguistCode, block, rowCode, createdBy)
                            } else {
                                linguistAcceptedDataPushRow("remove", props.linguistCode, block, rowCode, createdBy)
                            }
                        } else {
                            //6, 7 случай
                            console.log("6, 7 случай")

                            if (value) {
                                linguistAcceptedDataPushRow("add", props.linguistCode, block, rowCode, createdBy)
                                linguistAcceptedDataPushRow("remove", previouslyAcceptedBy, block, rowCode, createdBy) //todo здесь должны лезть не в currentLinguist
                                // linguistBufferedDataPushRow("remove", block, rowCode, createdBy)
                            } else {
                                linguistAcceptedDataExcludeRow("add", props.linguistCode, block, rowCode, createdBy)
                                linguistAcceptedDataExcludeRow("remove", previouslyAcceptedBy, block, rowCode, createdBy) //todo здесь должны лезть не в currentLinguist
                                // linguistBufferedDataExcludeRow("remove", block, rowCode, createdBy)
                            }
                        }
                    }
                }

                return data
            })
        )

        /*setAcceptedValues(
            acceptedValues.map(data => {
                if (block in data.languagePreferencesData) {
                    if (data.languagePreferencesData[block][type].includes(rowCode)) {
                        if (!value) {
                            let index = data.languagePreferencesData[block][type].indexOf(rowCode);

                            if (index >= 0) {
                                data.languagePreferencesData[block][type].splice(index, 1);
                            }

                            if ("deleteAnotherLinguistsChanges" in data.languagePreferencesData[block] &&
                                type in data.languagePreferencesData[block]["deleteAnotherLinguistsChanges"] &&
                                wasAcceptedFromAnotherLinguist in
                                data.languagePreferencesData[block]["deleteAnotherLinguistsChanges"][type]) {
                                let anotherLinguistIndex = data.languagePreferencesData[block]["deleteAnotherLinguistsChanges"][type][wasAcceptedFromAnotherLinguist].indexOf(rowCode);

                                if (anotherLinguistIndex >= 0) {
                                    data.languagePreferencesData[block]["deleteAnotherLinguistsChanges"][type][wasAcceptedFromAnotherLinguist].splice(anotherLinguistIndex, 1);
                                }
                            }
                        }
                    } else {
                        if (value) {
                            data.languagePreferencesData[block][type].push(rowCode)

                            if (wasAcceptedFromAnotherLinguist) {
                                data.languagePreferencesData[block]["deleteAnotherLinguistsChanges"] = []
                                data.languagePreferencesData[block]["deleteAnotherLinguistsChanges"][type] = []
                                data.languagePreferencesData[block]["deleteAnotherLinguistsChanges"][type][wasAcceptedFromAnotherLinguist].push(rowCode)
                            }
                        }
                    }
                } else {
                    if (value) {
                        data.languagePreferencesData[block] = {
                            "default": [],
                            "extra": []
                        }

                        if (wasAcceptedFromAnotherLinguist) {
                            data.languagePreferencesData[block]["deleteAnotherLinguistsChanges"] = []
                            data.languagePreferencesData[block]["deleteAnotherLinguistsChanges"][type] = []
                            data.languagePreferencesData[block]["deleteAnotherLinguistsChanges"][type][wasAcceptedFromAnotherLinguist].push(rowCode)
                        }

                        data.languagePreferencesData[block][type].push(rowCode)
                    }
                }

                console.log("setLanguagePreferencesAcceptValue end", acceptedValues[0].languagePreferencesData)

                return data
            })
        )*/
    }

    function linguistAcceptedDataPushRow(whatToDoWithRow, linguist, block, rowCode, type) {
        setLinguistDataToSubmit(
            linguistDataToSubmit.map(data => {
                if (linguist === props.linguistCode) {
                    if (!(block in data.currentLinguist.accept)) {
                        data.currentLinguist.accept[block] = {
                            "default": {
                                "add": [],
                                "remove": []
                            },
                            "extra": {
                                "add": [],
                                "remove": []
                            },
                            "otherLinguistExtra": []
                        }
                    }

                    if (type === "default") {
                        if (!data.currentLinguist.accept[block].default[whatToDoWithRow].includes(rowCode)) {
                            data.currentLinguist.accept[block].default[whatToDoWithRow].push(rowCode)
                        }
                    } else if (type === "extra") {
                        if (!data.currentLinguist.accept[block].extra[whatToDoWithRow].includes(rowCode)) {
                            data.currentLinguist.accept[block].extra[whatToDoWithRow].push(rowCode)
                        }
                    } else {
                        if (!(type in data.currentLinguist.accept[block].otherLinguistExtra)) {
                            data.currentLinguist.accept[block].otherLinguistExtra[type] = {
                                "add": [],
                                "remove": []
                            }
                        }

                        if (!(rowCode in data.currentLinguist.accept[block].otherLinguistExtra[type][whatToDoWithRow])) {
                            data.currentLinguist.accept[block].otherLinguistExtra[type][whatToDoWithRow].push(rowCode)
                        }
                    }
                } else {
                    if (!(linguist in data.otherLinguistsAcceptedRowsToRemove)) {
                        data.otherLinguistsAcceptedRowsToRemove[linguist] = []
                    }

                    if (!(block in data.otherLinguistsAcceptedRowsToRemove[linguist])) {
                        data.otherLinguistsAcceptedRowsToRemove[linguist][block] = {
                            "removeFromDefault": [],
                            "removeFromExtra": [],
                            "removeFromOtherLinguistExtra": []
                        }
                    }

                    //проверка на type будет показывать создателя строки
                    if (type === "default") { //default row
                        if (!(rowCode in data.otherLinguistsAcceptedRowsToRemove[linguist][block].removeFromDefault)) {
                            data.otherLinguistsAcceptedRowsToRemove[linguist][block].removeFromDefault.push(rowCode)
                        }
                    } else if (type === linguist) { //extra row, createdBy === linguist
                        if (!(rowCode in data.otherLinguistsAcceptedRowsToRemove[linguist][block].removeFromExtra)) {
                            data.otherLinguistsAcceptedRowsToRemove[linguist][block].removeFromExtra.push(rowCode)
                        }
                    } else { ////extra row, createdBy === otherLinguist
                        let createdBy = type === "extra" ? props.linguistCode : type

                        if (!(createdBy in data.otherLinguistsAcceptedRowsToRemove[linguist][block].removeFromOtherLinguistExtra)) {
                            data.otherLinguistsAcceptedRowsToRemove[linguist][block].removeFromOtherLinguistExtra[createdBy] = []
                        }

                        if (!(rowCode in data.otherLinguistsAcceptedRowsToRemove[linguist][block].removeFromOtherLinguistExtra[createdBy])) {
                            data.otherLinguistsAcceptedRowsToRemove[linguist][block].removeFromOtherLinguistExtra[createdBy].push(rowCode)
                        }
                    }
                }

                return data
            })
        )
    }

    /*function linguistBufferedDataPushRow(whatToDoWithRow, block, rowCode, type) {
        setLinguistDataToSubmit(
            linguistDataToSubmit.map(data => {
                if (!(block in data.currentLinguist.buffered)) {
                    data.currentLinguist.buffered[block] = {
                        "default": {
                            "add": [],
                            "remove": []
                        },
                        "extra": {
                            "add": [],
                            "remove": []
                        },
                        "otherLinguistExtra": []
                    }
                }

                if (type === "default") {
                    if (!data.currentLinguist.buffered[block].default[whatToDoWithRow].includes(rowCode)) {
                        data.currentLinguist.buffered[block].default[whatToDoWithRow].push(rowCode)
                    }
                } else if (type === "extra") {
                    if (!data.currentLinguist.buffered[block].extra[whatToDoWithRow].includes(rowCode)) {
                        data.currentLinguist.buffered[block].extra[whatToDoWithRow].push(rowCode)
                    }
                } else {
                    if (!(type in data.currentLinguist.buffered[block].otherLinguistExtra)) {
                        data.currentLinguist.buffered[block].otherLinguistExtra[type] = {
                            "add": [],
                            "remove": []
                        }
                    }

                    if (!data.currentLinguist.buffered[block].otherLinguistExtra[type][whatToDoWithRow].includes(rowCode)) {
                        data.currentLinguist.buffered[block].otherLinguistExtra[type][whatToDoWithRow].push(rowCode)
                    }
                }

                return data
            })
        )
    }*/

    function linguistAcceptedDataExcludeRow(whatToDoWithRow, linguist, block, rowCode, type, needToMakeChangesInCurrentLinguist) {
        setLinguistDataToSubmit(
            linguistDataToSubmit.map(data => {
                if (linguist === props.linguistCode) {
                    if (block in data.currentLinguist.accept) {
                        if (type === "default") {
                            let index = data.currentLinguist.accept[block].default[whatToDoWithRow].indexOf(rowCode);

                            if (index > -1) {
                                data.currentLinguist.accept[block].default[whatToDoWithRow].splice(index, 1);
                            }
                        } else if (type === "extra") {
                            let index = data.currentLinguist.accept[block].extra[whatToDoWithRow].indexOf(rowCode);

                            if (index > -1) {
                                data.currentLinguist.accept[block].extra[whatToDoWithRow].splice(index, 1);
                            }
                        } else {
                            if (type in data.currentLinguist.accept[block].otherLinguistExtra) {
                                let index = data.currentLinguist.accept[block].otherLinguistExtra[type][whatToDoWithRow].indexOf(rowCode);

                                if (index > -1) {
                                    data.currentLinguist.accept[block].otherLinguistExtra[type][whatToDoWithRow].splice(index, 1);
                                }
                            }
                        }
                    }
                } else {
                    //todo
                    //todo проверка на type будет показывать создателя строки

                    if (linguist in data.otherLinguistsAcceptedRowsToRemove &&
                        block in data.otherLinguistsAcceptedRowsToRemove[linguist]) {
                        //проверка на type будет показывать создателя строки
                        if (type === "default") { //default row
                            let index = data.otherLinguistsAcceptedRowsToRemove[linguist][block].removeFromDefault.indexOf(rowCode);

                            if (index > -1) {
                                data.otherLinguistsAcceptedRowsToRemove[linguist][block].removeFromDefault.splice(index, 1);
                            }
                        } else if (type === linguist) { //extra row, createdBy === linguist
                            let index = data.otherLinguistsAcceptedRowsToRemove[linguist][block].removeFromExtra.indexOf(rowCode);

                            if (index > -1) {
                                data.otherLinguistsAcceptedRowsToRemove[linguist][block].removeFromExtra.splice(index, 1);
                            }
                        } else { //extra row, createdBy === otherLinguist
                            let createdBy = type === "extra" ? props.linguistCode : type

                            if (createdBy in data.otherLinguistsAcceptedRowsToRemove[linguist][block].removeFromOtherLinguistExtra) {
                                let index = data.otherLinguistsAcceptedRowsToRemove[linguist][block].removeFromOtherLinguistExtra[createdBy].indexOf(rowCode);

                                if (index > -1) {
                                    data.otherLinguistsAcceptedRowsToRemove[linguist][block].removeFromOtherLinguistExtra[createdBy].splice(index, 1);
                                }
                            }
                        }
                    }
                }

                return data
            })
        )
    }

    /*function linguistBufferedDataExcludeRow(whatToDoWithRow, block, rowCode, type) {
        setLinguistDataToSubmit(
            linguistDataToSubmit.map(data => {
                if (type === "default") {
                    let index = data.currentLinguist.buffered[block].default[whatToDoWithRow].indexOf(rowCode);

                    if (index > -1) {
                        data.currentLinguist.buffered[block].default[whatToDoWithRow].splice(index, 1);
                    }
                } else if (type === "extra") {
                    let index = data.currentLinguist.buffered[block].extra[whatToDoWithRow].indexOf(rowCode);

                    if (index > -1) {
                        data.currentLinguist.buffered[block].extra[whatToDoWithRow].splice(index, 1);
                    }
                } else {
                    if (type in data.currentLinguist.buffered[block].otherLinguistExtra) {
                        let index = data.currentLinguist.buffered[block].otherLinguistExtra[type][whatToDoWithRow].indexOf(rowCode);

                        if (index > -1) {
                            data.currentLinguist.buffered[block].otherLinguistExtra[type][whatToDoWithRow].splice(index, 1);
                        }
                    }
                }

                return data
            })
        )
    }*/

    function setCharacterCommunicationsAcceptValue(characterFirst, characterSecond/*value*/) {
        setCharactersCommunicationLinguistData(
            charactersCommunicationLinguistData.map(info => {
                let cellData = info.data[characterFirst][characterSecond]

                if ("isAcceptedInCurrentSession" in cellData) {
                    let oldValue = cellData["isAcceptedInCurrentSession"]

                    cellData["isAcceptedInCurrentSession"] = !oldValue
                } else {
                    let isAccepted = "acceptedValue" in cellData
                    let isBuffered = "isBuffered" in cellData

                    if (isBuffered) {
                        cellData["isAcceptedInCurrentSession"] = true
                    } else if (isAccepted) {
                        cellData["isAcceptedInCurrentSession"] = false
                    }
                }

                return info
            })
        )

        /*console.log("setCharacterCommunicationsAcceptValue", acceptedValues[0].characterCommunicationsData, value)

        setAcceptedValues(
            acceptedValues.map(data => {
                data.characterCommunicationsData = value

                return data
            })
        )*/
    }

    function setCharacterCommunicationsAcceptAllValue(checkboxValue) {
        setCharactersCommunicationLinguistData(
            charactersCommunicationLinguistData.map(info => {
                for (let characterFirst in info.data) {
                    for (let characterSecond in info.data[characterFirst]) {
                        info.data[characterFirst][characterSecond].isAcceptedInCurrentSession = checkboxValue
                    }
                }

                return info
            })
        )
    }

    async function submitAcceptedData() {
        console.log("submitAcceptedData linguistDataToSubmit", linguistDataToSubmit)

        await fetch('/proxy/project_bible_template/getStyleGuideLinguistChangesByLanguageCode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "languageCode": props.language.code
            })
        })
            .then(res => res.json())
            .then(
                async (resultUpdate) => {
                    let isExistCurrentLinguist = false
                    let linguistExistedRows = []
                    console.log("submitAcceptedData resultUpdate", resultUpdate)

                    for (let i = 0; i < resultUpdate.length; i++) {
                        console.log("submitAcceptedData resultUpdate i", i)
                        if (resultUpdate[i].linguist_id === props.linguistCode) {
                            isExistCurrentLinguist = true

                            await updateOrInsertAcceptChangesRow("update", resultUpdate[i].accept_language_preferences)
                        } else {
                            //апдейтим только accept lang & communication, buffered не трогаем
                            //if acceptedValues[0].characterCommunicationsData ? false : оставляем значение, которое было
                            linguistDataToSubmit.map(async data => {
                                console.log("linguistDataToSubmit data", data)

                                if (resultUpdate[i].linguist_id in data.otherLinguistsAcceptedRowsToRemove) {
                                    let updatedAcceptedData = resultUpdate[i].accept_language_preferences

                                    for (let block in data.otherLinguistsAcceptedRowsToRemove[resultUpdate[i].linguist_id]) {
                                        let blockValue = data.otherLinguistsAcceptedRowsToRemove[resultUpdate[i].linguist_id][block]

                                        for (let j = 0; j < blockValue.removeFromDefault.length; j++) {
                                            let index = updatedAcceptedData[block].default.indexOf(blockValue.removeFromDefault[j]);

                                            if (index > -1) {
                                                updatedAcceptedData[block].default.splice(index, 1);
                                            }
                                        }

                                        for (let j = 0; j < blockValue.removeFromExtra.length; j++) {
                                            let index = updatedAcceptedData[block].extra.indexOf(blockValue.removeFromExtra[j]);

                                            if (index > -1) {
                                                updatedAcceptedData[block].extra.splice(index, 1);
                                            }
                                        }

                                        for (let j = 0; j < updatedAcceptedData[block].otherLinguistExtra.length; j++) {
                                            if (updatedAcceptedData[block].otherLinguistExtra[j].linguist in blockValue.removeFromOtherLinguistExtra) {
                                                for (let k = 0; k < blockValue.removeFromOtherLinguistExtra[updatedAcceptedData[block].otherLinguistExtra[j].linguist].length; k++) {
                                                    let index = updatedAcceptedData[block].otherLinguistExtra[j].rows.indexOf(blockValue.removeFromOtherLinguistExtra[updatedAcceptedData[block].otherLinguistExtra[j].linguist][k]);

                                                    if (index > -1) {
                                                        updatedAcceptedData[block].otherLinguistExtra[j].rows.splice(index, 1);
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    await fetch('/proxy/project_bible_template/updateStyleGuideLinguistAcceptAndBufferedChanges', {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            "clientName": clientName,
                                            "projectName": projectName,
                                            "projectCode": projectCode,
                                            "linguistId": resultUpdate[i].linguist_id,
                                            "languageCode": props.language.code,
                                            "acceptLanguagePreferences": updatedAcceptedData,
                                            "acceptCommunicationBetweenCharacters": false/*acceptedValues[0].characterCommunicationsData ? false : resultUpdate[i].accept_communication_between_characters*/,
                                            "bufferedLanguagePreferences": resultUpdate[i].buffered_changed_language_preferences,
                                            "bufferedCommunicationBetweenCharacters": resultUpdate[i].buffered_changed_communication_between_characters,
                                            "accept": true
                                        })
                                    })
                                        .then(res => res.json())
                                        .then(
                                            async (resultUpdate) => {

                                            },
                                            (error) => {
                                                alert("Ошибка при сохранении")
                                            }
                                        )
                                }

                                return data
                            })
                        }
                    }

                    if (!isExistCurrentLinguist) {
                        await updateOrInsertAcceptChangesRow("insert", {})
                    }

                    if (props.characterCommunications.value) {
                        await submitCharacterCommunicationsAcceptedData()
                    }

                    props.setLinguistAcceptDataModalActive(false)
                    window.location.reload()
                    // alert("Отключила тут перезагрузку страницы, надо вернуть. Если высветилось - значит я об этом забыла, пожалуйста напомните :)")
                },
                (error) => {
                    alert("Ошибка при сохранении")
                }
            )

        /*let queryLinkExist = '/proxy/project_bible_template/getByLanguageCodeStyleGuideLinguistAcceptChanges'
        let queryUpdateCell = '/proxy/project_bible_template/updateStyleGuideLinguistAcceptChanges'
        let queryInsertCell = '/proxy/project_bible_template/insertStyleGuideLinguistAcceptChanges'

        console.log("submitAcceptedData", props.linguistCode, props.language.code, acceptedValues[0].languagePreferencesData, acceptedValues[0].characterCommunicationsData)

        await fetch(queryLinkExist, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "linguistId": props.linguistCode,
                "languageCode": props.language.code
            })
        })
            .then(res => res.json())
            .then(
                async (resultEditable) => {
                    if (resultEditable.length) {
                        await fetch(queryUpdateCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "linguistId": props.linguistCode,
                                "languageCode": props.language.code,
                                "acceptLanguagePreferences": acceptedValues[0].languagePreferencesData,
                                "acceptCommunicationBetweenCharacters": acceptedValues[0].characterCommunicationsData
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultUpdate) => {
                                    props.setLinguistAcceptDataModalActive(false)

                                    window.location.reload()
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    } else {
                        await fetch(queryInsertCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "clientName": clientName,
                                "projectName": projectName,
                                "projectCode": projectCode,
                                "linguistId": props.linguistCode,
                                "languageCode": props.language.code,
                                "acceptLanguagePreferences": acceptedValues[0].languagePreferencesData,
                                "acceptCommunicationBetweenCharacters": acceptedValues[0].characterCommunicationsData
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultInsert) => {
                                    props.setLinguistAcceptDataModalActive(false)

                                    window.location.reload()
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    }
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )*/
    }

    async function submitCharacterCommunicationsAcceptedData() {
        let characterCommunicationsDataToSubmit = {
            currentLinguist: [],
            otherLinguists: []
        }

        charactersCommunicationLinguistData.map(async info => {
            console.log("submitCharacterCommunicationsAcceptedData 1", info.data)

            for (let firstCharacterKey in info.data) {
                console.log("submitCharacterCommunicationsAcceptedData 2", firstCharacterKey, info.data[firstCharacterKey])

                for (let secondCharacterKey in info.data[firstCharacterKey]) {
                    console.log("submitCharacterCommunicationsAcceptedData 3", secondCharacterKey, info.data[firstCharacterKey][secondCharacterKey])

                    let cellData = info.data[firstCharacterKey][secondCharacterKey]
                    let firstCharacterNum = +firstCharacterKey + 1
                    let secondCharacterNum = +secondCharacterKey + 1
                    let isBuffered = false
                    let isAccepted = false
                    let acceptedValue = ""
                    let needToUpdateLinguistCell = false

                    if ("isAcceptedInCurrentSession" in cellData) {
                        if (cellData.isAcceptedInCurrentSession) {
                            isAccepted = true

                            if ("isBuffered" in cellData) {
                                needToUpdateLinguistCell = true
                                acceptedValue = cellData.value

                                if (!("acceptedValue" in cellData)) {
                                    /*await props.resetAcceptedFromOtherLinguistCharactersValues(props.language.code,
                                        props.linguistCode, firstCharacterNum, secondCharacterNum)*/

                                    characterCommunicationsDataToSubmit.otherLinguists.push({
                                        firstCharacter: props.getCharacterId(firstCharacterNum),
                                        secondCharacter: props.getCharacterId(secondCharacterNum)
                                    })
                                }
                            }
                        } else {
                            needToUpdateLinguistCell = true
                        }

                        if (needToUpdateLinguistCell) {
                            /*await props.setLinguistCharactersValues(props.language.code, props.linguistCode,
                                firstCharacterNum, secondCharacterNum, isBuffered, isAccepted, acceptedValue)*/

                            characterCommunicationsDataToSubmit.currentLinguist.push({
                                firstCharacter: props.getCharacterId(firstCharacterNum),
                                secondCharacter: props.getCharacterId(secondCharacterNum),
                                isBuffered: isBuffered,
                                isAccepted: isAccepted,
                                acceptedValue: acceptedValue
                            })
                        }
                    }
                }
            }

            return info
        })

        await fetch('/proxy/project_bible_template/styleGuideLinguistsSubmitAcceptedCharactersCellValues', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "languageCode": props.language.code,
                "linguist": props.linguistCode,
                "data": characterCommunicationsDataToSubmit
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {

                },
                (error) => {
                    alert("Ошибка при сохранении.")
                }
            )
    }

    async function updateOrInsertAcceptChangesRow(type, updatedAcceptedData) {
        let link = "/proxy/project_bible_template/"

        if (type === "insert") {
            link += "insertStyleGuideLinguistAcceptAndBufferedChanges"
        } else if (type === "update") {
            link += "updateStyleGuideLinguistAcceptAndBufferedChanges"
        }

        linguistDataToSubmit.map(data => {
            for (let block in data.currentLinguist.accept) {
                let blockValue = data.currentLinguist.accept[block]

                if (block in updatedAcceptedData) {
                    for (let j = 0; j < blockValue.default.remove.length; j++) {
                        let index = updatedAcceptedData[block].default.indexOf(blockValue.default.remove[j]);

                        if (index > -1) {
                            updatedAcceptedData[block].default.splice(index, 1);
                        }
                    }

                    for (let j = 0; j < blockValue.extra.remove.length; j++) {
                        let index = updatedAcceptedData[block].extra.indexOf(blockValue.extra.remove[j]);

                        if (index > -1) {
                            updatedAcceptedData[block].extra.splice(index, 1);
                        }
                    }
                } else {
                    updatedAcceptedData[block] = {
                        "default": [],
                        "extra": [],
                        "otherLinguistExtra": []
                    }
                }

                for (let j = 0; j < blockValue.default.add.length; j++) {
                    if (!updatedAcceptedData[block].default.includes(blockValue.default.add[j])) {
                        updatedAcceptedData[block].default.push(blockValue.default.add[j])
                    }
                }

                for (let j = 0; j < blockValue.extra.add.length; j++) {
                    if (!updatedAcceptedData[block].extra.includes(blockValue.extra.add[j])) {
                        updatedAcceptedData[block].extra.push(blockValue.extra.add[j])
                    }
                }

                for (let otherLinguist in blockValue.otherLinguistExtra) {
                    let isFoundLinguist = false

                    for (let j = 0; j < updatedAcceptedData[block].otherLinguistExtra.length; j++) {
                        if (updatedAcceptedData[block].otherLinguistExtra[j].linguist === otherLinguist) {
                            isFoundLinguist = true

                            for (let k = 0; k < blockValue.otherLinguistExtra[otherLinguist].add.length; k++) {
                                if (!updatedAcceptedData[block].otherLinguistExtra[j].rows.includes(blockValue.otherLinguistExtra[otherLinguist].add[k])) {
                                    updatedAcceptedData[block].otherLinguistExtra[j].rows.push(blockValue.otherLinguistExtra[otherLinguist].add[k])
                                }
                            }

                            for (let k = 0; k < blockValue.otherLinguistExtra[otherLinguist].remove.length; k++) {
                                let index = updatedAcceptedData[block].otherLinguistExtra[j].rows.indexOf(blockValue.otherLinguistExtra[otherLinguist].remove[k]);

                                if (index > -1) {
                                    updatedAcceptedData[block].otherLinguistExtra[j].rows.splice(index, 1);
                                }
                            }
                        }
                    }

                    if (!isFoundLinguist) {
                        updatedAcceptedData[block].otherLinguistExtra.push({
                            "linguist": otherLinguist,
                            "rows": blockValue.otherLinguistExtra[otherLinguist].add
                        })
                    }
                }
            }

            return data
        })

        await fetch(link, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "linguistId": props.linguistCode,
                "languageCode": props.language.code,
                "acceptLanguagePreferences": updatedAcceptedData,
                "acceptCommunicationBetweenCharacters": /*acceptedValues[0].characterCommunicationsData*/false,
                "bufferedLanguagePreferences": {},
                "bufferedCommunicationBetweenCharacters": false,
                "accept": true
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {

                },
                (error) => {
                    alert("Ошибка при сохранении")
                }
            )
    }

    return(
        <div>
            <div className="row">
                <div className="col-sm-12">
                    {
                        props.characterCommunications.value ? (
                            <Tab.Container defaultActiveKey={"languagePreferences"}>
                                <Row>
                                    <Col sm={12}>
                                        <Nav variant="pills" className="nav-custom-grey">
                                            <Nav.Item>
                                                <Nav.Link eventKey={"languagePreferences"}>
                                                    {
                                                        Object.keys(bufferedValues[0].languagePreferencesData).length > 0 ?
                                                            <>Language Preferences&nbsp;&nbsp;<FontAwesomeIcon icon={faExclamation}/></> :
                                                            <>Language Preferences</>
                                                    }
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey={"communicationBetweenCharacters"}>
                                                    {
                                                        props.language.code in props.linguistLinks[props.linguistCode].data.characterCommunications.languagesData ?
                                                            props.linguistLinks[props.linguistCode].data.characterCommunications.languagesData[props.language.code].isBuffered ?
                                                                <>Communication between characters&nbsp;&nbsp;<FontAwesomeIcon icon={faExclamation}/></> :
                                                                <>Communication between characters</> :
                                                            <>Communication between characters</>
                                                    }
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content>
                                            <Tab.Pane eventKey={"languagePreferences"}>
                                                <div className="tab-item-wrapper">
                                                    <br/>
                                                    <LinguistLanguageTabPreferences
                                                        linguistCode={props.linguistCode}
                                                        language={props.language}
                                                        bufferedData={bufferedValues[0].languagePreferencesData}
                                                        defaultLanguageData={props.defaultLanguageData}
                                                        setLanguagePreferencesAcceptValue={setLanguagePreferencesAcceptValue}
                                                        acceptedCheckedInfo={acceptedValues[0].languagePreferencesData}
                                                        linguistLinks={props.linguistLinks}
                                                    />
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={"communicationBetweenCharacters"}>
                                                <div className="tab-item-wrapper">
                                                    <br/>
                                                    <LinguistLanguageTabCharacterCommunication
                                                        linguistCode={props.linguistCode}
                                                        language={props.language.code}
                                                        characters={props.characterCommunications.characters}
                                                        baseStyleGuideData={props.characterCommunications.baseStyleGuideData}
                                                        templateData={props.characterCommunications.templateData[props.language.code].data}
                                                        languageData={props.characterCommunications.languagesData[props.language.code].data}
                                                        setCharacterCommunicationsAcceptValue={setCharacterCommunicationsAcceptValue}
                                                        // acceptedCheckedInfo={acceptedValues[0].characterCommunicationsData}
                                                        acceptAllCharactersCommunicationLinguistData={acceptAllCharactersCommunicationLinguistData}
                                                        setCharacterCommunicationsAcceptAllValue={setCharacterCommunicationsAcceptAllValue}
                                                        characterCommunicationsDefault={props.characterCommunicationsDefault[0]}
                                                        charactersCommunicationLinguistData={charactersCommunicationLinguistData[0].data}
                                                    />
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        ) : (
                            <LinguistLanguageTabPreferences
                                linguistCode={props.linguistCode}
                                language={props.language}
                                bufferedData={bufferedValues[0].languagePreferencesData}
                                defaultLanguageData={props.defaultLanguageData}
                                setLanguagePreferencesAcceptValue={setLanguagePreferencesAcceptValue}
                                acceptedCheckedInfo={acceptedValues[0].languagePreferencesData}
                                linguistLinks={props.linguistLinks}
                            />
                        )
                    }
                </div>
            </div>
            <br />
            <br />
            <div className="row">
                <div className="col-sm-12 center">
                    <Button variant="primary" className="btn btn-blue" onClick={(e) => submitAcceptedData()}>
                        Принять изменения языка {props.language.title} [code: {props.language.code}]
                    </Button>
                </div>
            </div>
        </div>
    )
}