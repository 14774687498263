import React, {useState} from "react";
import {Accordion, Form, Button, FormCheck, FormControl, OverlayTrigger} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faKeyboard, faTimes} from "@fortawesome/free-solid-svg-icons";
import FilterNotShowAll from "../view/FilterNotShowAll";
import TemplatesFilterButtons from "./TemplatesFilterButtons";
import Tooltip from "react-bootstrap/Tooltip";

const styles = {
    blockView: {
        border: '1px solid #ccc',
        padding: '1rem',
        borderRadius: '5px',
        margin: '.5rem'
    },

    templateFilterBlock: {
        borderLeft: '1px solid #ccc'
    },

    accordeonTemplatesFilter: {
        marginTop: '0.5rem'
    }
}

export default function Filter(props) {
    function changeSelectedFilter(id) {
        if (id) {
            for (let keyType in props.templatesFilter[0].filters) {
                let keyValue = props.templatesFilter[0].filters[keyType]

                for (let key in keyValue) {
                    let value = keyValue[key]
                    let filterId = value["id"]

                    if (filterId === +id) {
                        props.selectTemplateFilter(filterId, keyType, value["filter"])
                    }
                }
            }
        } else {
            removeSelectedFilter()
        }
    }

    let getTemplatesFilter = data => {
        let content = []

        for (let keyType in data.filters) {
            let keyValue = data.filters[keyType]

            for (let key in keyValue) {
                let value = keyValue[key]
                let filterId = value["id"]
                let filterName = value["filter_name"]
                let isOn = data.isOn

                if (isOn.value && isOn.id === filterId) {
                    content.push(
                        <option value={filterId} selected>{filterName}</option>
                    )
                } else {
                    content.push(
                        <option value={filterId}>{filterName}</option>
                    )
                }
            }
        }

        return content
    };

    function removeSelectedFilter() {
        console.log("remove filter before select new")

        props.removeTemplatesFilterIsOn()
    }

    return (
        <div className="row">
            <div className="col-sm-9">
                <div className="row">
                    {
                        props.columns.map(value => {
                            if (value.type === "tags_list") {
                                return (
                                    <div key={value.code} className="col-sm-8">
                                        <div style={styles.blockView}>
                                            <FormCheck checked={value.filter.show} label={value.name}
                                                       onChange={(e) => props.changeFilter(value.code, value.type, "show", e)}
                                            />
                                            {
                                                value.filter.show &&
                                                <div>
                                                    <hr/>
                                                    <FormCheck checked={value.filter.showAll.value}
                                                               label='Все поля'
                                                               onChange={(e) => props.changeFilter(value.code, value.type, "showAll", e)}
                                                    />
                                                    {!value.filter.showAll.value &&
                                                    <FilterNotShowAll column={value} changeFilter={props.changeFilter}
                                                                      selectedDataMultiselect={props.selectedDataMultiselect}/>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={value.code} className="col-sm-4">
                                        <div style={styles.blockView}>
                                            <FormCheck checked={value.filter.show} label={value.name}
                                                       onChange={(e) => props.changeFilter(value.code, value.type, "show", e)}
                                            />
                                            {value.filter.show &&
                                            <div>
                                                <hr/>
                                                <FormCheck checked={value.filter.showAll.value}
                                                           label='Все поля'
                                                           onChange={(e) => props.changeFilter(value.code, value.type, "showAll", e)}
                                                />
                                                {!value.filter.showAll.value &&
                                                <FilterNotShowAll column={value} changeFilter={props.changeFilter}
                                                                  selectedDataMultiselect={props.selectedDataMultiselect}/>
                                                }
                                            </div>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
                <br/>
                <div className="row">
                    <div className="col-sm-12 center">
                        <Button variant="primary" className="btn btn-blue" onClick={(e) => props.filterTable()}>
                            Отфильтровать таблицу&nbsp;&nbsp;<FontAwesomeIcon icon={faFilter}/>
                        </Button>
                        &nbsp;
                        <Button variant="secondary" onClick={(e) => props.resetFilter()}>
                            Сбросить фильтр&nbsp;&nbsp;<FontAwesomeIcon icon={faTimes}/>
                        </Button>
                    </div>
                </div>
            </div>
            <div className="col-sm-3" style={styles.templateFilterBlock}>
                <div className="row">
                    <div className="col-sm-12" style={styles.accordeonTemplatesFilter}>
                        <div className="center">
                            <h5>Templates filter</h5>
                        </div>
                        <br />
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicProject">
                                <Form.Label>Templates:</Form.Label>
                                <Form.Select onChange={(e) =>
                                    changeSelectedFilter(e.target.value)}>
                                    {
                                        props.templatesFilter[0].isOn.value ? (
                                            <option value="">Open this select menu</option>
                                        ) : (
                                            <option value="" selected>Open this select menu</option>
                                        )
                                    }
                                    { getTemplatesFilter(props.templatesFilter[0]) }
                                </Form.Select>
                            </Form.Group>
                        </Form>
                        <br />
                        <TemplatesFilterButtons
                            isOn={props.templatesFilter[0].isOn}
                            openModalToSaveNewTemplateFilter={props.openModalToSaveNewTemplateFilter}
                            openModalToRenameTemplateFilter={props.openModalToRenameTemplateFilter}
                            deleteTemplateFilter={props.deleteTemplateFilter}
                            updateTemplateFilter={props.updateTemplateFilter}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}