import "../css/modal.css"
import {Button, Col, Form, FormCheck, FormControl, FormLabel, Image, Row, Table, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from "react";

export default function ModalCellDataHistoryList(props) {
    // console.log("ModalCellDataHistoryList", props)

    /*function chooseOtherValue(key, isСhecked, value) {
        if (isСhecked) {
            props.setIsNewValueChecked(true)
            props.setNewValueIndex(key)
            props.setNewValue(value)
        } else {
            props.setIsNewValueChecked(false)
        }
    }*/

    return(
        <>
            {/*{
                getData(props.data)
            }*/}
            {
                props.data.map((value, key) => {
                    let tstampArray = value.tstamp.split("T")
                    let tstamp = tstampArray[0] + " " + tstampArray[1].substring(0, 8)

                    console.log("rerender will be there")

                    if (value["data_type"] === "string" || value["data_type"] === "date") {
                        console.log("string/date value", value, value.value, "isOtherValueChecked", value.isOtherValueChecked)

                        return (
                            <tr key={key}>
                                <td contentEditable={false} className="center align-middle" style={{width: '5%'}}>
                                    <FormCheck checked={value.isOtherValueChecked}
                                               onChange={(e) =>
                                                   props.selectNewValue(+key, e.target.checked, value.value[0])}/>
                                </td>
                                <td className="center align-middle" contentEditable={false} style={{width: '15%'}}>
                                    <a href={"https://b24.allcorrectgames.com/company/personal/user/" + value["changed_by"].id + "/"}
                                       title={value["changed_by"].name} target="_blank">
                                        🦄
                                    </a>
                                </td>
                                <td className="whiteSpacePreLine" contentEditable={false} style={{width: '60%'}}
                                    dangerouslySetInnerHTML={{__html: value.value[0]}}></td>
                                <td contentEditable={false} style={{width: '20%'}}>{tstamp}</td>
                            </tr>
                        )
                    } else if (value["data_type"] === "bool") {
                        console.log("bool value", value, value.value)

                        return (
                            <tr key={key}>
                                <td contentEditable={false} className="center align-middle" style={{width: '5%'}}>
                                    <FormCheck checked={value.isOtherValueChecked}
                                               onChange={(e) =>
                                                   props.selectNewValue(+key, e.target.checked, value.value[0])}/>
                                </td>
                                <td className="center align-middle" contentEditable={false} style={{width: '15%'}}>
                                    <a href={"https://b24.allcorrectgames.com/company/personal/user/" + value["changed_by"].id + "/"}
                                       title={value["changed_by"].name} target="_blank">
                                        🦄
                                    </a>
                                </td>
                                <td className="center align-middle whiteSpacePreLine" contentEditable={false}
                                    style={{width: '35%'}}
                                    dangerouslySetInnerHTML={{__html: value.value[0] ? "✅" : "❌"}}></td>
                                <td contentEditable={false} style={{width: '45%'}}>{tstamp}</td>
                            </tr>
                        )
                    }
                })
            }
        </>
    )

    /*const getData = data => {
        let content = []

        for (let key in data) {
            let value = data[key]
            let tstampArray = value.tstamp.split("T")
            let tstamp = tstampArray[0] + " " + tstampArray[1].substring(0, 8)
            let checkedValue = false

            console.log("rerender will be there")

            if (props.isNewValueChecked && props.newValueIndex === +key) {
                console.log("rerender will be there true check", key)

                checkedValue = true
            }

            if (value["data_type"] === "string" || value["data_type"] === "date") {
                console.log("string/date value", value, value.value)

                content.push(
                    <tr key={key}>
                        <td contentEditable={false} className="center align-middle" style={{width: '5%'}}>
                            <FormCheck defaultChecked={checkedValue}
                                       onInput={(e) =>
                                           props.selectNewValue(+key, e.target.checked, value.value[0])} />
                        </td>
                        <td className="center align-middle" contentEditable={false} style={{width: '15%'}}>
                            <a href={"https://b24.allcorrectgames.com/company/personal/user/" + value["changed_by"].id + "/"}
                               title={value["changed_by"].name} target="_blank" >
                                🦄
                            </a>
                        </td>
                        <td className="whiteSpacePreLine" contentEditable={false} style={{width: '60%'}}
                            dangerouslySetInnerHTML={{__html: value.value[0]}}></td>
                        <td contentEditable={false} style={{width: '20%'}}>{tstamp}</td>
                    </tr>
                )
            } else if (value["data_type"] === "bool") {
                console.log("bool value", value, value.value)

                content.push(
                    <tr key={key}>
                        <td contentEditable={false} className="center align-middle" style={{width: '5%'}}>
                            <FormCheck defaultChecked={checkedValue}
                                       onInput={(e) =>
                                           props.selectNewValue(+key, e.target.checked, value.value[0])} />
                        </td>
                        <td className="center align-middle" contentEditable={false} style={{width: '15%'}}>
                            <a href={"https://b24.allcorrectgames.com/company/personal/user/" + value["changed_by"].id + "/"}
                               title={value["changed_by"].name} target="_blank">
                                🦄
                            </a>
                        </td>
                        <td className="center align-middle whiteSpacePreLine" contentEditable={false} style={{width: '35%'}}
                            dangerouslySetInnerHTML={{__html: value.value[0] ? "✅" : "❌"}}></td>
                        <td contentEditable={false} style={{width: '45%'}}>{tstamp}</td>
                    </tr>
                )
            }
        }

        return content
    }*/
}