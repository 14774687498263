import React, {useEffect, useState} from "react";
import queryString from "query-string";
import "../css/ps.css";
import TagModal from "./TagModal";
import ResponsibleModal from "./ResponsibleModal";
import AllTechProcess from "./AllTechProcess";
import {Alert, Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";

const styles = {
    alertMargin: {
        marginLeft: '.5rem',
        marginRight: '.5rem',
    }
}

export default function View(props) {
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [templateData, setTemplateData] = useState([{
        techProcessSequence: [],
        blockSequence: [],
        rowsSequence: [],
        columnSequence: [],
        responsibleSequence: [],
        data: []
    }])
    const [showAlert, setShowAlert] = useState(true)
    const [tagModalActive, setTagModalActive] = useState(false);
    const [tagModalData, setTagModalData] = useState([{
        allTags: new Map(),
        associativeArrayOfTagsById: {},
        selectedTags: [],
        techProcess: 0,
        block: 0,
        row: 0
    }])
    const [responsibleModalActive, setResponsibleModalActive] = useState(false);
    const [responsibleModalData, setResponsibleModalData] = useState([{
        allResponsible: [],
        selectedResponsible: [],
        techProcess: 0,
        block: 0,
        row: 0
    }])

    const authorizationCookieName = "b24_authorization_log"
    const authorizationCookieMatches = document.cookie.match(new RegExp(
        "(?:^|; )" + authorizationCookieName.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    const employeeId = authorizationCookieMatches ?
        +JSON.parse(window.atob(decodeURIComponent(authorizationCookieMatches[1]))).userId :
        window.location.href.split("/")[2] === "localhost:3000" ? 191 : 0

    useEffect(async () => {
        if (checkCookie()) {
            deleteRedirectCookie()

            await getTechProcessList()
            await getBlocksList()
            await getColumnsList()
            await getResponsiblesList()
            await getRowsList()
            await getDataList()
            await getTagsList()

            setIsLoaded(true)

            console.log(templateData)
        } else {
            setRedirectCookie()
            window.location.replace('https://b24.allcorrectgames.com');
        }
    },[])

    function checkCookie() {
        let name = "testCookie"

        document.cookie = "testCookie=test; max-age=3600";

        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));

        if (!matches) {
            alert("Allow cookies in your browser settings for correct work.")

            return false
        } else {
            return employeeId > 0
        }
    }

    function setRedirectCookie() {
        let cookieName =  'apps_allcorrect_redirect'
        let cookieValue = window.btoa(window.location.href)
        let date = new Date(Date.now() + 1800e3);
        let options = {
            path: '/',
            domain: 'allcorrectgames.com',
            expires: date.toUTCString(),
            samesite: 'lax'
        };
        let updatedCookie = cookieName + "=" + cookieValue;

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;

            let optionValue = options[optionKey];

            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    function deleteRedirectCookie() {
        let cookieName =  'apps_allcorrect_redirect'
        let cookieValue = 'val'
        let date = new Date();

        date.setMonth(date.getMonth() - 1);

        let options = {
            path: '/',
            domain: 'allcorrectgames.com',
            expires: date.toUTCString(),
            samesite: 'lax'
        };
        let updatedCookie = cookieName + "=" + cookieValue;

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;

            let optionValue = options[optionKey];

            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    async function getTechProcessList() {
        await fetch("/proxy/project_bible_template/projectStagesTemplateTechProcessAll", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            for (let i = 0; i < result.length; i++) {
                                let techProcessCode = result[i].code
                                let techProcessName1C = result[i]["name_1c"]
                                let sequence = result[i].num
                                let techProcessName = result[i]["tab_name"]

                                info.techProcessSequence.push({
                                    sequence: sequence,
                                    code: techProcessCode
                                })

                                info.blockSequence[techProcessCode] = []
                                info.columnSequence[techProcessCode] = []
                                info.responsibleSequence[techProcessCode] = []
                                info.data[techProcessCode] = {
                                    techProcessName1C: techProcessName1C,
                                    techProcessName: techProcessName,
                                    blocks: [],
                                    columns: [],
                                    responsible: []
                                }
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    setIsLoaded(true);
                    setError({
                        message: "Error on load data. Please refresh page."
                    });
                }
            )
    }

    async function getBlocksList() {
        await fetch("/proxy/project_bible_template/projectStagesTemplateBlocksAll", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            for (let i = 0; i < result.length; i++) {
                                let techProcessCode = result[i]["tech_process"]
                                let blockCode = result[i].code
                                let sequence = result[i].num
                                let blockName = result[i].name

                                if (techProcessCode in info.data) {
                                    info.blockSequence[techProcessCode].push({
                                        sequence: sequence,
                                        code: blockCode
                                    })
                                    info.rowsSequence[blockCode] = []
                                    info.data[techProcessCode].blocks[blockCode] = {
                                        blockName: blockName,
                                        rows: []
                                    }
                                }
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    setIsLoaded(true);
                    setError({
                        message: "Error on load data. Please refresh page."
                    });
                }
            )
    }

    async function getColumnsList() {
        await fetch("/proxy/project_bible_template/projectStagesTemplateColumnsAll", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            for (let i = 0; i < result.length; i++) {
                                let techProcessCode = result[i]["tech_process"]
                                let columnCode = result[i].code
                                let sequence = result[i].num
                                let columnName = result[i].name
                                let columnType = result[i].type

                                if (techProcessCode in info.data) {
                                    info.columnSequence[techProcessCode].push({
                                        sequence: sequence,
                                        code: columnCode
                                    })
                                    info.data[techProcessCode].columns[columnCode] = {
                                        columnName: columnName,
                                        columnType: columnType
                                    }
                                }
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    setIsLoaded(true);
                    setError({
                        message: "Error on load data. Please refresh page."
                    });
                }
            )
    }

    async function getResponsiblesList() {
        await fetch("/proxy/project_bible_template/projectStagesTemplateResponsiblesAll", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let responsibleList = result.sort(function(a, b) {
                                return a['title'] - b['title'];
                            });

                            for (let i = 0; i < responsibleList.length; i++) {
                                let techProcessCode = responsibleList[i]["tech_process"]
                                let responsibleCode = responsibleList[i].code
                                let responsibleName = responsibleList[i].title

                                if (techProcessCode in info.data) {
                                    info.responsibleSequence[techProcessCode].push({
                                        responsibleName: responsibleName,
                                        code: responsibleCode
                                    })
                                    info.data[techProcessCode].responsible[responsibleCode] = {
                                        responsibleName: responsibleName
                                    }
                                }
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    setIsLoaded(true);
                    setError({
                        message: "Error on load data. Please refresh page."
                    });
                }
            )
    }

    async function getRowsList() {
        await fetch("/proxy/project_bible_template/projectStagesTemplateRowsAll", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            for (let i = 0; i < result.length; i++) {
                                let techProcessCode = result[i]["tech_process"]
                                let blockCode = result[i].block
                                let rowCode = result[i].code
                                let sequence = result[i].num
                                let rowTags = result[i].tags
                                let rowResponsibles = result[i].responsible

                                if (techProcessCode in info.data &&
                                    blockCode in info.data[techProcessCode].blocks) {
                                    info.rowsSequence[blockCode].push({
                                        sequence: sequence,
                                        code: rowCode
                                    })
                                    info.data[techProcessCode].blocks[blockCode].rows[rowCode] = {
                                        tags: rowTags,
                                        responsible: rowResponsibles,
                                        columns: []
                                    }
                                }
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    setIsLoaded(true);
                    setError({
                        message: "Error on load data. Please refresh page."
                    });
                }
            )
    }

    async function getDataList() {
        await fetch("/proxy/project_bible_template/projectStagesTemplateDataAll", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    fillDataList(result.text)
                    fillDataList(result.bool)
                },
                (error) => {
                    setIsLoaded(true);
                    setError({
                        message: "Error on load data. Please refresh page."
                    });
                }
            )
    }

    function fillDataList(valuesList) {
        setTemplateData(
            templateData.map(info => {
                for (let i = 0; i < valuesList.length; i++) {
                    let techProcessCode = valuesList[i]["tech_process"]
                    let blockCode = valuesList[i].block
                    let rowCode = valuesList[i]["row_code"]
                    let colCode = valuesList[i]["col_code"]
                    let value = valuesList[i].value

                    if (techProcessCode in info.data &&
                        blockCode in info.data[techProcessCode].blocks &&
                        rowCode in info.data[techProcessCode].blocks[blockCode].rows &&
                        colCode in info.data[techProcessCode].columns) {
                        info.data[techProcessCode].blocks[blockCode].rows[rowCode].columns[colCode] = {
                            value: value
                        }
                    }
                }

                return info
            })
        )
    }

    async function getTagsList() {
        const response = await fetch('/proxy/project_bible_tags', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}
        });
        let tagsList = await response.json();

        if ('response' in tagsList) {
            setTagModalData(
                tagModalData.map(info => {
                    let tags = new Map()

                    let tagGroupsArray = tagsList.response.TechProcess.sort(function(a, b) {
                        return decodeURIComponent(a['Group_tags']) - decodeURIComponent(b['Group_tags']);
                    });

                    for (let i = 0; i < tagGroupsArray.length; i++) {
                        let value = []
                        let tagsArray = tagGroupsArray[i]['Tags'].sort(function(a, b) {
                            return decodeURIComponent(a.tag) - decodeURIComponent(b.tag);
                        });

                        for (let j = 0; j < tagsArray.length; j++) {
                            // value.push(decodeURIComponent(tagsArray[j].tag))
                            info.associativeArrayOfTagsById[tagsArray[j].ID] = decodeURIComponent(tagsArray[j].tag)

                            value.push({
                                tag: decodeURIComponent(tagsArray[j].tag),
                                id: tagsArray[j].ID,
                                service: tagsArray[j].Services
                            })
                        }

                        tags.set(decodeURIComponent(tagGroupsArray[i]['Group_tags']), value)
                    }

                    info.allTags = tags

                    return info
                })
            )
        } else {
            setIsLoaded(true)
            setError({
                message: "Error on load data. Please refresh page."
            })
        }
    }

    async function openModalForTags(val, techProcess, block, row, selectedTags) {
        setTagModalData(
            tagModalData.map(info => {
                info.techProcess = techProcess
                info.block = block
                info.row = row
                info.selectedTags = selectedTags

                return info
            })
        )

        setTagModalActive(val)
    }

    async function openModalForResponsibles(val, techProcess, block, row, selectedResponsible) {
        setResponsibleModalData(
            responsibleModalData.map(info => {
                info.techProcess = techProcess
                info.block = block
                info.row = row
                info.allResponsible = templateData[0].responsibleSequence[techProcess]
                info.selectedResponsible = selectedResponsible

                return info
            })
        )

        setResponsibleModalActive(val)
    }

    async function saveTagCell(techProcess, block, row, data) {
        console.log("saveTagCell", techProcess, block, row, data)

        await fetch('/proxy/project_bible_template/projectStagesTemplateRowsSetTags', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "block": block,
                "row": row,
                "value": data
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            info.data[techProcess].blocks[block].rows[row].tags = data

                            return info
                        })
                    )

                    setTagModalActive(false)
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function saveResponsibleCell(techProcess, block, row, data) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateRowsSetResponsible', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "block": block,
                "row": row,
                "value": data
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            info.data[techProcess].blocks[block].rows[row].responsible = data

                            return info
                        })
                    )

                    setResponsibleModalActive(false)
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function updateBlockRowColumnValue(techProcess, block, row, column, type, e) {
        console.log("updateBlockRowColumnValue", techProcess, block, row, column, e)

        await fetch('/proxy/project_bible_template/projectStagesTemplateDataExist', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "block": block,
                "row": row,
                "column": column,
                "type": type
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    let link = '/proxy/project_bible_template/'
                    let value

                    if (type === "input") {
                        value = e.target.innerText
                    } else if (type === "checkbox") {
                        value = e.target.checked
                    }

                    console.log("updateBlockRowColumnValue exist", result)

                    if (result.length) {
                        link += 'projectStagesTemplateDataUpdate'
                    } else {
                        link += 'projectStagesTemplateDataSet'
                    }

                    await fetch(link, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "techProcess": techProcess,
                            "block": block,
                            "row": row,
                            "column": column,
                            "type": type,
                            "value": value
                        })
                    })
                        .then(res => res.json())
                        .then(
                            async (result) => {
                                /*setTemplateData(
                                    templateData.map(info => {
                                        info.data[techProcess].blocks[block].rows[row].columns[column].value = value

                                        return info
                                    })
                                )*/
                            },
                            (error) => {
                                alert("Error on save data. Try again.")
                            }
                        )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function deleteRow(techProcess, block, row) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateRowsRemove', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "block": block,
                "row": row
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let rows = info.rowsSequence[block]

                            for (let i = 0; i < rows.length; i++) {
                                if (rows[i].code === row) {
                                    rows.splice(i, 1)
                                }
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function moveUpRow(techProcess, block, row) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateRowsMoveUp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "block": block,
                "row": row
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let rows = info.rowsSequence[block]

                            for (let i = 0; i < rows.length; i++) {
                                if (rows[i].code === row) {
                                    let value = rows[i]

                                    rows.splice(i, 1)
                                    rows.splice(i - 1, 0, value)
                                }
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function moveDownRow(techProcess, block, row) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateRowsMoveDown', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "block": block,
                "row": row
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let rows = info.rowsSequence[block]
                            let nextIndex = searchNextRow(block, row)
                            let value = rows[nextIndex]

                            rows.splice(nextIndex, 1)
                            rows.splice(nextIndex - 1, 0, value)

                            /*for (let i = 0; i < rows.length; i++) {
                                if (rows[i].code === row) {
                                    let value = rows[i]

                                    console.log("i 1", i)

                                    rows.splice(i, 1)
                                    // rows.splice(i + 1, 0, value)

                                    break;
                                }
                            }*/

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function moveRow(techProcess, block, row, oldIndex, newIndex) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateRowsMove', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "block": block,
                "row": row,
                "oldIndex": oldIndex,
                "newIndex": newIndex
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let rows = info.rowsSequence[block]
                            let newRows = []

                            if (newIndex > oldIndex) {
                                for (let i = 0; i < rows.length; i++) {
                                    let sequence = rows[i].sequence

                                    if (sequence < oldIndex || sequence > newIndex) {
                                        newRows.push(rows[i])
                                    } else if (sequence > oldIndex && sequence <= newIndex) {
                                        newRows.push({
                                            sequence: sequence - 1,
                                            code: rows[i].code
                                        })

                                        if (sequence === newIndex) {
                                            newRows.push({
                                                sequence: newIndex,
                                                code: row
                                            })
                                        }
                                    }
                                }
                            } else {
                                for (let i = 0; i < rows.length; i++) {
                                    let sequence = rows[i].sequence

                                    if (sequence < newIndex || sequence > oldIndex) {
                                        newRows.push(rows[i])
                                    } else if (sequence >= newIndex && sequence < oldIndex) {
                                        if (sequence === newIndex) {
                                            newRows.push({
                                                sequence: newIndex,
                                                code: row
                                            })
                                        }

                                        newRows.push({
                                            sequence: sequence + 1,
                                            code: rows[i].code
                                        })
                                    }
                                }
                            }

                            info.rowsSequence[block] = newRows

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    function searchNextRow(block, row) {
        let nextIndex = 0

        templateData.map(info => {
            let rows = info.rowsSequence[block]

            for (let i = 0; i < rows.length; i++) {
                if (rows[i].code === row) {

                    nextIndex = i + 1
                }
            }

            return info
        })

        return nextIndex
    }

    async function addRow(techProcess, block, row) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateRowsAdd', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "block": block,
                "row": row
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let rows = info.rowsSequence[block]
                            let nextIndex = searchNextRow(block, row)
                            let value = {
                                sequence: 0,
                                code: result
                            }

                            rows.splice(nextIndex, 0, value)
                            info.data[techProcess].blocks[block].rows[result] = {
                                columns: [],
                                responsible: [],
                                tags: []
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function addRowToTheEnd(techProcess, block) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateRowsAddToTheEnd', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "block": block
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let rows = info.rowsSequence[block]

                            rows.push({
                                sequence: rows.length + 1,
                                code: result
                            })

                            info.data[techProcess].blocks[block].rows[result] = {
                                columns: [],
                                responsible: [],
                                tags: []
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function addResponsible(techProcess) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateResponsibleAdd', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let responsibles = info.responsibleSequence[techProcess]

                            responsibles.push({
                                responsibleName: "",
                                code: result
                            })

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function editResponsible(techProcess, code, e) {
        let value = e.target.innerText

        await fetch('/proxy/project_bible_template/projectStagesTemplateResponsibleUpdate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "responsibleCode": code,
                "value": value
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let responsibles = info.responsibleSequence[techProcess]

                            for (let i = 0; i < responsibles.length; i++) {
                                if (responsibles[i].code === code) {
                                    responsibles[i].responsibleName = value
                                }
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function deleteResponsible(techProcess, code) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateResponsibleRemove', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "responsibleCode": code
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let responsibles = info.responsibleSequence[techProcess]

                            for (let i = 0; i < responsibles.length; i++) {
                                if (responsibles[i].code === code) {
                                    responsibles.splice(i, 1)
                                }
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function editBlockTitle(techProcess, block, e) {
        let value = e.target.innerText

        await fetch('/proxy/project_bible_template/projectStagesTemplateBlocksUpdate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "block": block,
                "value": value
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            info.data[techProcess].blocks[block].blockName = value

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    function searchNextBlock(techProcess, block) {
        let nextIndex = 0

        templateData.map(info => {
            let blocks = info.blockSequence[techProcess]

            for (let i = 0; i < blocks.length; i++) {
                if (blocks[i].code === block) {

                    nextIndex = i + 1
                }
            }

            return info
        })

        return nextIndex
    }

    async function addBlock(techProcess, block) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateBlocksAdd', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "block": block
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let blocks = info.blockSequence[techProcess]
                            let nextIndex = searchNextBlock(techProcess, block)
                            let value = {
                                sequence: 0,
                                code: result
                            }

                            blocks.splice(nextIndex, 0, value)
                            info.rowsSequence[result] = []
                            info.data[techProcess].blocks[result] = {
                                blockName: "",
                                rows: []
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function addBlockToTheEnd(techProcess) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateBlocksAddToTheEnd', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let blocks = info.blockSequence[techProcess]

                            blocks.push({
                                sequence: blocks.length + 1,
                                code: result
                            })

                            info.rowsSequence[result] = []
                            info.data[techProcess].blocks[result] = {
                                blockName: "",
                                rows: []
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function deleteBlock(techProcess, block) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateBlocksRemove', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "block": block
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let blocks = info.blockSequence[techProcess]

                            for (let i = 0; i < blocks.length; i++) {
                                if (blocks[i].code === block) {
                                    blocks.splice(i, 1)
                                }
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function moveUpBlock(techProcess, block) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateBlocksMoveUp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "block": block
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let blocks = info.blockSequence[techProcess]

                            for (let i = 0; i < blocks.length; i++) {
                                if (blocks[i].code === block) {
                                    let value = blocks[i]

                                    blocks.splice(i, 1)
                                    blocks.splice(i - 1, 0, value)
                                }
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function moveDownBlock(techProcess, block) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateBlocksMoveDown', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "block": block
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let blocks = info.blockSequence[techProcess]
                            let nextIndex = searchNextBlock(techProcess, block)
                            let value = blocks[nextIndex]

                            blocks.splice(nextIndex, 1)
                            blocks.splice(nextIndex - 1, 0, value)

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function editColumnTitle(techProcess, column, e) {
        let value = e.target.innerText

        await fetch('/proxy/project_bible_template/projectStagesTemplateColumnsUpdate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "column": column,
                "value": value
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            info.data[techProcess].columns[column].columnName = value

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    function searchNextColumn(techProcess, column) {
        let nextIndex = 0

        templateData.map(info => {
            let columns = info.columnSequence[techProcess]

            for (let i = 0; i < columns.length; i++) {
                if (columns[i].code === column) {

                    nextIndex = i + 1
                }
            }

            return info
        })

        return nextIndex
    }

    async function addColumn(techProcess, column) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateColumnsAdd', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "column": column,
                "type": "input"
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let columns = info.columnSequence[techProcess]
                            let nextIndex = searchNextColumn(techProcess, column)
                            let value = {
                                sequence: 0,
                                code: result
                            }

                            columns.splice(nextIndex, 0, value)
                            info.data[techProcess].columns[result] = {
                                columnName: "",
                                columnType: "input"
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function addColumnToTheEnd(techProcess) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateColumnsAddToTheEnd', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "type": "input"
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let columns = info.columnSequence[techProcess]

                            columns.push({
                                sequence: columns.length + 1,
                                code: result
                            })

                            info.data[techProcess].columns[result] = {
                                columnName: "",
                                columnType: "input"
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function deleteColumn(techProcess, column) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateColumnsRemove', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "column": column
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let columns = info.columnSequence[techProcess]

                            for (let i = 0; i < columns.length; i++) {
                                if (columns[i].code === column) {
                                    columns.splice(i, 1)
                                }
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function moveDownColumn(techProcess, column) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateColumnsMoveDown', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "column": column
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let columns = info.columnSequence[techProcess]
                            let nextIndex = searchNextColumn(techProcess, column)
                            let value = columns[nextIndex]

                            columns.splice(nextIndex, 1)
                            columns.splice(nextIndex - 1, 0, value)

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    async function moveUpColumn(techProcess, column) {
        await fetch('/proxy/project_bible_template/projectStagesTemplateColumnsMoveUp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "techProcess": techProcess,
                "column": column
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setTemplateData(
                        templateData.map(info => {
                            let columns = info.columnSequence[techProcess]

                            for (let i = 0; i < columns.length; i++) {
                                if (columns[i].code === column) {
                                    let value = columns[i]

                                    columns.splice(i, 1)
                                    columns.splice(i - 1, 0, value)
                                }
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    alert("Error on save data. Try again.")
                }
            )
    }

    if (error) {
        return (
            <div className="row">
                <div className="col-sm-12 center">
                    <br />
                    <h3>Error: {error.message}</h3>
                </div>
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div className="row">
                <div className="col-sm-12 center">
                    <br />
                    <h3>Loading...</h3>
                </div>
            </div>
        )
    } else {
        return(
            <div className="main-block-div-dark">
                {
                    showAlert &&
                    <div style={styles.alertMargin}>
                        <br />
                        <Alert variant="primary"
                               onClose={() => setShowAlert(false)}
                               dismissible>
                            <Alert.Heading>Памятка по редактированию строк с шаблоном:</Alert.Heading>
                            <span>

                            </span>
                        </Alert>
                    </div>
                }
                <br/>
                    <div className="row">
                        <div className="col-sm-12 center">
                            <AllTechProcess
                                templateData={templateData[0]}
                                addResponsible={addResponsible}
                                editResponsible={editResponsible}
                                deleteResponsible={deleteResponsible}
                                editBlockTitle={editBlockTitle}
                                addBlock={addBlock}
                                addBlockToTheEnd={addBlockToTheEnd}
                                deleteBlock={deleteBlock}
                                moveDownBlock={moveDownBlock}
                                moveUpBlock={moveUpBlock}
                                openModalForTags={openModalForTags}
                                openModalForResponsibles={openModalForResponsibles}
                                updateBlockRowColumnValue={updateBlockRowColumnValue}
                                deleteRow={deleteRow}
                                addRow={addRow}
                                addRowToTheEnd={addRowToTheEnd}
                                moveUpRow={moveUpRow}
                                moveDownRow={moveDownRow}
                                moveRow={moveRow}
                                editColumnTitle={editColumnTitle}
                                addColumn={addColumn}
                                addColumnToTheEnd={addColumnToTheEnd}
                                deleteColumn={deleteColumn}
                                moveDownColumn={moveDownColumn}
                                moveUpColumn={moveUpColumn}
                                associativeArrayOfTagsById={tagModalData[0].associativeArrayOfTagsById}
                            />
                        </div>
                    </div>
                <TagModal
                    tagModalActive={tagModalActive}
                    setTagModalActive={setTagModalActive}
                    tagModalData={tagModalData}
                    setTagModalData={setTagModalData}
                    techProcess={templateData[0].data}
                    saveTagCell={saveTagCell}
                />
                <ResponsibleModal
                    responsibleModalActive={responsibleModalActive}
                    setResponsibleModalActive={setResponsibleModalActive}
                    responsibleModalData={responsibleModalData}
                    setResponsibleModalData={setResponsibleModalData}
                    saveResponsibleCell={saveResponsibleCell}
                />
            </div>
        )
    }
}