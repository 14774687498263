import React, {useEffect, useState} from "react";
import {Button, Tab, Table, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";

const styles = {
    tableHeaderRow: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    noEditableCell: {
        backgroundColor: '#fafafa',
    },

    editableCell: {
        backgroundColor: '#fff',
    },

    nowrapCell: {
        backgroundColor: '#fafafa',
        whiteSpace: 'nowrap'
    },
}

export default function ResponsibleTable(props) {
    // console.log("ResponsibleTable", props)

    return(
        <div className="row">
            <div className="col-sm-4 table-blue-thead-not-fixed">
                <Table responsive bordered>
                    <thead>
                    <tr style={styles.tableHeaderRow}>
                        <th className="center" style={styles.tableHeaderRow}>№</th>
                        <th className="center" style={styles.tableHeaderRow}>Responsible type</th>
                        <th className="center" style={styles.tableHeaderRow}>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.responsibleSequence.map((info, index) => {
                            let responsibleCode = info.code
                            let responsibleTitle = info.responsibleName

                            return(
                                <tr key={responsibleCode}>
                                    <td contentEditable={false} style={styles.noEditableCell}>
                                        {index + 1}
                                    </td>
                                    <td className="whiteSpacePreLine"
                                        contentEditable={true}
                                        suppressContentEditableWarning={true}
                                        style={styles.editableCell}
                                        dangerouslySetInnerHTML={{__html: responsibleTitle}}
                                        onBlur={(e) =>
                                            props.editResponsible(props.techProcessCode, responsibleCode, e)}>
                                    </td>
                                    <td contentEditable={false}
                                        style={styles.nowrapCell}>
                                        <Button
                                            size="sm"
                                            variant="danger"
                                            onClick={(e) =>
                                                props.deleteResponsible(props.techProcessCode, responsibleCode)}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
                <br/>
                <Button
                    variant="primary" className="btn btn-blue"
                    onClick={(e) =>
                        props.addResponsible(props.techProcessCode)}>
                    <FontAwesomeIcon icon={faPlus} /> Add
                </Button>
            </div>
        </div>
    )
}