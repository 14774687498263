import {
    Accordion,
    Badge,
    Button,
    CardGroup,
    Form,
    FormCheck,
    FormControl,
    ProgressBar,
    Table,
    Tabs
} from "react-bootstrap";
import React from "react";
import {Tab} from "bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";
import DatePicker from "react-widgets/DatePicker";
import "../css/ps.css";

const styles = {
    blockCenter: {
        textAlign: 'center'
    },

    blockView: {
        border: '1px solid #ccc',
        padding: '.5rem 1rem',
        borderRadius: '5px',
        margin: '.5rem'
    },

    defaultCell: {
        backgroundColor: '#fff',
    },

    defaultDateCell: {
        backgroundColor: '#fff',
        minWidth: '200px'
    },

    noEditableCell: {
        backgroundColor: '#fafafa',
        whiteSpace: 'pre-line',
    },

    filledCell: {
        backgroundColor: '#dffdde',
        whiteSpace: 'pre-line',
    },

    changedCheckbox: {
        backgroundColor: '#fff8e1',
    },

    datePicker: {
        fontSize: '11px'
    },

    progressBarIntroWidth: {
        width: '88%'
    },

    progressBarLabelWidth: {
        width: '10%'
    }
}

export default function ProjectStagesTabs(props) {
    const queryStringParams = queryString.parse(window.location.search)
    const project = queryStringParams.project
    const order = queryStringParams.order
    const service = queryStringParams.service

    function captureKeyCombination(modalTitle, tableName, saveMethod, identityData) {
        let pressed = new Set()

        document.addEventListener('keydown', function(event) {
            pressed.add(event.code)

            if (pressed.has("ControlLeft") && pressed.has("AltLeft") && pressed.has("KeyH")) {
                props.openCellDataHistory(modalTitle, tableName, saveMethod, identityData)

                // return
            } else {
                return
            }

            pressed.clear()
        });

        document.addEventListener('mouseout', function(event) {
            pressed.delete(event.code)
            // pressed.clear()
        });
    }

    const getBlocks = techProcess => {
        let content = []

        for (let key in techProcess.blocks) {
            let value = techProcess.blocks[key]

            if (Object.keys(value.rows).length && value.name !== "Правки") {
                let acceptedRowsCount = countAcceptedRows(value.rows)
                let allRowsCount = Object.keys(value.rows).length
                let progressPercent = acceptedRowsCount / allRowsCount * 100

                content.push(
                    <div className="col-sm-12" key={key}>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <div style={styles.progressBarIntroWidth}>
                                        {value.name}
                                        &nbsp;
                                        (Progress: {acceptedRowsCount}/{allRowsCount})
                                    </div>
                                    <ProgressBar
                                        now={progressPercent}
                                        style={styles.progressBarLabelWidth}
                                    />
                                </Accordion.Header>
                                <Accordion.Body>
                                    {
                                        +key !== 0 &&
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <Form>
                                                    <Form.Group className="mb-3" controlId="formBasicAdditionalComment">
                                                        <Form.Label>Additional comment</Form.Label>
                                                        <Form.Control as="textarea" rows={3}
                                                                      defaultValue={value.comment}
                                                                      onBlur={(e) =>
                                                                          props.oninputBlockAdditionalComment(techProcess.code, key, "input", /*e*/e.target.value/*, value.comment*/)}
                                                                      onMouseOver={(e) =>
                                                                          captureKeyCombination(
                                                                              "History of changes in \"Additional comment\" field",
                                                                              "project_stages_block_data",
                                                                              "oninputBlockAdditionalComment", {
                                                                                  techProcess: techProcess.code,
                                                                                  block: key,
                                                                                  type: "input",
                                                                                  value: /*e*/e.target.value
                                                                              }
                                                                          )}
                                                        />
                                                    </Form.Group>
                                                </Form>
                                            </div>
                                        </div>
                                    }
                                    <br/>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Table responsive bordered>
                                                <thead>
                                                <tr>
                                                    <th></th>
                                                    {
                                                        +key !== 0 &&
                                                        <th>Ответственный за этап</th>
                                                    }
                                                    {
                                                        techProcess.columns.map(column => {
                                                            if (+key === 0) {
                                                                if (column.col_special_requirements && column.type === "input") {
                                                                    return (
                                                                        <th key={column.code}>{column.name}</th>
                                                                    )
                                                                }
                                                            } else {
                                                                return (
                                                                    <th key={column.code}>{column.name}</th>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    {
                                                        +key !== 0 &&
                                                        <th>Comment</th>
                                                    }
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {getRows({"techProcess": techProcess.code, "block": key, "columnsOrder": techProcess.columns, "rows": value.rows})}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <br />
                    </div>
                )
            }
        }

        return content
    };

    function countAcceptedRows(data) {
        let count = 0

        for (let key in data) {
            let value = data[key]

            if (value.accept) {
                count++
            }
        }

        return count
    }

    const getRows = data => {
        let content = []
        let sortedData = [];

        for (let key in data.rows) {
            let value = data.rows[key]
            let valueToPush = {
                key: key,
                columnsOrder: data.columnsOrder,
                columns: value.columns,
                checkRow: value.accept
            }

            if (+data.block === 0) {
                valueToPush["num"] = value.num
            } else {
                valueToPush["comment"] = value.comment
                valueToPush["responsible"] = value.responsible
                valueToPush["checkedSpecialRequirements"] = value.checkedSpecialRequirements
                valueToPush["num"] = value.num
            }

            sortedData.push(valueToPush);
        }

        sortedData.sort(function(a, b) {
            return a.num - b.num;
        });

        if (+data.block !== 0) {
            sortedData.sort(function(a, b) {
                return (a.checkRow === b.checkRow) ? 0 : a.checkRow ? 1 : -1;
            });
        }

        for (let i = 0; i < sortedData.length; i++) {
            let key = sortedData[i].key
            let columnsOrder = sortedData[i].columnsOrder
            let columns = sortedData[i].columns

            if (Object.keys(columns).length) {
                let rowCheckboxValue = sortedData[i].checkRow
                let rowSpecialRequirementsCheckedValue = false
                let rowCommentValue = ""
                let rowResponsibleValue = ""

                if (+data.block !== 0) {
                    rowCommentValue = sortedData[i].comment
                    rowResponsibleValue = sortedData[i].responsible

                    rowSpecialRequirementsCheckedValue = sortedData[i].checkedSpecialRequirements
                } else {
                    rowSpecialRequirementsCheckedValue = true
                }

                content.push(
                    <tr key={key}>
                        <td contentEditable={false} className="center align-middle"
                            >
                            <FormCheck defaultChecked={rowCheckboxValue}
                                       onInput={(e) =>
                                           props.oninputBlockRowData(data.techProcess, data.block, key, "rowCheckbox", "checkbox", e.target.checked)}
                                       onMouseOver={(e) =>
                                           captureKeyCombination(
                                               "History of changes in \"Accept\" column",
                                               "project_stages_block_row_data_accept",
                                               "oninputBlockRowData", {
                                                   techProcess: data.techProcess,
                                                   block: data.block,
                                                   row: key,
                                                   column: "rowCheckbox",
                                                   type: "checkbox",
                                                   value: e.target.checked
                                               }
                                           )}
                            />
                        </td>
                        {
                            +data.block !== 0 &&
                            <td key={key}>
                                {getResponsibleTypes({
                                    "techProcess": data.techProcess,
                                    "block": data.block,
                                    "row": key,
                                    "responsibleTypes": rowResponsibleValue
                                })}
                            </td>
                        }
                        {getColumns({
                            "techProcess": data.techProcess,
                            "block": data.block,
                            "row": key,
                            "columnsOrder": columnsOrder,
                            "checkedSpecialRequirements": rowSpecialRequirementsCheckedValue,
                            "columns": columns
                        })}
                        {
                            +data.block !== 0 &&
                            <td className="whiteSpacePreLine" contentEditable={true}
                                suppressContentEditableWarning={true}
                                dangerouslySetInnerHTML={{__html: rowCommentValue}}
                                onBlur={(e) =>
                                    props.oninputBlockRowData(data.techProcess, data.block, key, "rowComment", "input", e.target.innerText)}
                                onMouseOver={(e) =>
                                    captureKeyCombination(
                                        "History of changes in \"Comment\" column",
                                        "project_stages_block_row_data_comment",
                                        "oninputBlockRowData", {
                                            techProcess: data.techProcess,
                                            block: data.block,
                                            row: key,
                                            column: "rowComment",
                                            type: "input",
                                            value: e.target.innerText
                                        }
                                    )}
                            ></td>
                        }
                    </tr>
                )
            }
        }

        return content
    }

    const getColumns = data => {
        let content = []

        for (let i = 0; i < data.columnsOrder.length; i++) {
            let key = data.columnsOrder[i].code
            let type = data.columnsOrder[i].type
            let isSpecialRequirements = data.columnsOrder[i].col_special_requirements
            let dataColumns = data.columns[key]

            if (type === "input") {
                if (+data.block === 0) {
                    if (isSpecialRequirements) {
                        content.push(
                            <td key={key} className="whiteSpacePreLine"
                                contentEditable={false} style={styles.noEditableCell}
                                dangerouslySetInnerHTML={{__html: dataColumns.value}}>
                            </td>
                        )
                    }
                } else {
                    content.push(
                        <td key={key} className="whiteSpacePreLine"
                            contentEditable={false} style={styles.noEditableCell}
                            dangerouslySetInnerHTML={{__html: dataColumns.value}}>
                        </td>
                    )
                }
            } else if (type === "checkbox") {
                if (+data.block !== 0) {
                    if (isSpecialRequirements) {
                        if (dataColumns.defaultCheckSpecialRequirements) {
                            content.push(
                                <td key={key} contentEditable={false} className="center align-middle"
                                    style={styles.noEditableCell} >
                                    <FormCheck checked disabled />
                                </td>
                            )
                        } else {
                            content.push(
                                <td key={key} contentEditable={false}
                                    className="center align-middle"
                                    style={dataColumns.value ? styles.changedCheckbox : styles.defaultCell}
                                >
                                    <FormCheck defaultChecked={dataColumns.value}
                                               onInput={(e) =>
                                                   props.setColumnValue(data.techProcess, data.block, data.row, key, type, /*e*/e.target.checked/*, isSpecialRequirements*/)}
                                               onMouseOver={(e) =>
                                                   captureKeyCombination(
                                                       "History of changes in checkbox column",
                                                       "project_stages_block_row_data_bool",
                                                       "setColumnValue", {
                                                           techProcess: data.techProcess,
                                                           block: data.block,
                                                           row: data.row,
                                                           column: key,
                                                           type: type,
                                                           value: e.target.checked
                                                       }
                                                   )}
                                    />
                                </td>
                            )
                        }
                    }
                }
            } else if (type === "date") {
                if (+data.block !== 0) {
                    if (isSpecialRequirements) {
                        if (data.checkedSpecialRequirements) {
                            let dateValue = dataColumns.value || null
                            let time = "00:00"

                            if (dataColumns.value) {
                                let hours = dataColumns.value.getHours()
                                let minutes = dataColumns.value.getMinutes()

                                time = [(hours>9 ? '' : '0') + hours, ':', (minutes>9 ? '' : '0') + minutes].join('');
                            }

                            content.push(
                                <td key={key} className="whiteSpacePreLine align-middle center"
                                    contentEditable={false}
                                    style={styles.defaultDateCell}
                                >
                                    <DatePicker
                                        includeTime
                                        value={dateValue}
                                        style={styles.datePicker}
                                        valueFormat={{dateStyle: "medium", timeStyle: "short"}}
                                        onChange={(e) =>
                                            props.setColumnValue(data.techProcess, data.block, data.row, key, type, e/*, isSpecialRequirements*/)}
                                        /*onMouseOver={(e) =>
                                            captureKeyCombination(
                                                "History of changes in date column",
                                                "project_stages_block_row_data_date",
                                                "setColumnValue", {
                                                    techProcess: data.techProcess,
                                                    block: data.block,
                                                    row: data.row,
                                                    column: key,
                                                    type: type,
                                                    value: e
                                                }
                                            )}*/
                                    />
                                    {
                                        dateValue &&
                                        <Badge bg="primary" text="light">
                                            {time}
                                        </Badge>
                                    }
                                </td>
                            )
                        } else {
                            content.push(
                                <td key={key} className="whiteSpacePreLine"
                                    contentEditable={false}
                                    style={styles.noEditableCell}>
                                </td>
                            )
                        }
                    }
                }
            }
        }

        return content
    }

    const getResponsibleTypes = data => {
        let content = []

        for (let key in data.responsibleTypes) {
            let value = data.responsibleTypes[key]
            let title = value.title

            content.push(
                <div key={key}>
                    <strong>{title}: </strong>
                    <Form.Select defaultValue={value.selectedValue}
                                 onChange={(e) =>
                                     props.changeResponsible(data.techProcess, data.block, data.row, key, /*e*/e.target.value)}
                                 onMouseOver={(e) =>
                                     captureKeyCombination(
                                         "History of changes in \"Responsible " + title + "\" field",
                                         "project_stages_block_row_responsible",
                                         "changeResponsible", {
                                             techProcess: data.techProcess,
                                             block: data.block,
                                             row: data.row,
                                             responsibleCode: key,
                                             value: /*e*/e.target.value
                                         }
                                     )} >
                        {getResponsibles(value.additionalResponsible)}
                    </Form.Select>
                </div>
            )
        }

        return content
    }

    const getResponsibles = responsibles => {
        let content = []

        for (let i = 0; i < responsibles.length; i++) {
            content.push(
                <option key={responsibles[i]}>{responsibles[i]}</option>
            )
        }

        return content
    }

    const getCharter = techProcess => {
        let content = []
        let rowsToShow = {}
        let rowToShowNotAssociative = []

        for (let blockKey in techProcess.blocks) {
            let blockData = techProcess.blocks[blockKey]

            if (blockKey !== 0) {
                if (Object.keys(blockData.rows).length && blockData.name !== "Правки") {
                    let sortedData = [];

                    for (let rowKey in blockData.rows) {
                        let rowData = blockData.rows[rowKey]
                        let valueToPush = {
                            key: rowKey,
                            columnsOrder: techProcess.columns,
                            columns: rowData.columns,
                            checkRow: rowData.accept
                        }

                        valueToPush["checkedSpecialRequirements"] = rowData.checkedSpecialRequirements
                        valueToPush["num"] = rowData.num

                        sortedData.push(valueToPush);
                    }

                    sortedData.sort(function(a, b) {
                        return a.num - b.num;
                    });
                    sortedData.sort(function(a, b) {
                        return (a.checkRow === b.checkRow) ? 0 : a.checkRow ? 1 : -1;
                    });

                    for (let i= 0; i < sortedData.length; i++) {
                        let key = sortedData[i].key
                        let columnsOrder = sortedData[i].columnsOrder
                        let columns = sortedData[i].columns
                        let checkedSpecialRequirements = sortedData[i].checkedSpecialRequirements

                        if (Object.keys(columns).length) {
                            let accept = sortedData[i].checkRow
                            let textValue = ""
                            let date = null

                            for (let i = 0; i < columnsOrder.length; i++) {
                                let columnKey = columnsOrder[i].code
                                let type = columnsOrder[i].type
                                let isSpecialRequirements = columnsOrder[i].col_special_requirements
                                let isTaskColumn = columnsOrder[i].col_task
                                let dataColumns = columns[columnKey]

                                if (type === "input" && isTaskColumn) {
                                    textValue = dataColumns.value
                                } else if (type === "date") {
                                    if (isSpecialRequirements && checkedSpecialRequirements) {
                                        date = dataColumns.value || null
                                    }
                                }
                            }

                            if (date !== null) {
                                let dateFormatISO = date.toISOString()
                                let dateFormatISOSubstring = dateFormatISO.substring(0, 10)
                                let dateWithNoTime = new Date(dateFormatISOSubstring)
                                let dateNumFormat = dateWithNoTime.getTime()
                                let dateToGetTime = new Date(dateFormatISO)
                                let dateTimeNumFormat = dateToGetTime.getTime()
                                let hours = dateToGetTime.getHours()
                                let minutes = dateToGetTime.getMinutes()
                                let timeValue = [(hours > 9 ? '' : '0') + hours, ':', (minutes > 9 ? '' : '0') + minutes].join('');

                                if (!(dateNumFormat in rowsToShow)) {
                                    rowsToShow[dateNumFormat] = {
                                        dateNumFormat: dateNumFormat,
                                        date: date,
                                        rows: []
                                    }
                                }

                                rowsToShow[dateNumFormat].rows.push({
                                    time: timeValue,
                                    dateTimeNumFormat: dateTimeNumFormat,
                                    accept: accept,
                                    textValue: textValue,
                                    techProcess: techProcess.code,
                                    block: blockKey,
                                    row: key
                                })
                            }
                        }
                    }
                }
            }
        }

        for (let date in rowsToShow) {
            rowToShowNotAssociative.push(rowsToShow[date])
        }

        rowToShowNotAssociative.sort(function(a, b) {
            return a.dateNumFormat - b.dateNumFormat;
        });

        for (let i = 0; i < rowToShowNotAssociative.length; i++) {
            rowToShowNotAssociative[i].rows.sort(function(a, b) {
                return ((a.accept === b.accept)? 0 : a.accept ? 1 : -1) || a.dateTimeNumFormat - b.dateTimeNumFormat;
            });
        }

        for (let i = 0; i < rowToShowNotAssociative.length; i++) {
            let data = rowToShowNotAssociative[i]

            content.push(
                // <div key={data.dateNumFormat} className="col-sm-3" style={{display: 'inline-block'}}>
                <>
                    <div key={data.dateNumFormat} className="whiteSpacePreLine charter-card align-top">
                        <Table responsive bordered>
                            <thead>
                            <tr>
                                <th colSpan="3"
                                    className="whiteSpacePreLine align-middle"
                                    contentEditable={false}
                                    style={styles.defaultCell}
                                >
                                    <DatePicker
                                        value={data.date}
                                        style={styles.datePicker}
                                        valueFormat={{dateStyle: "medium"}}
                                        disabled /*includeTime*/
                                    />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {getCharterRows(data.rows)}
                            </tbody>
                        </Table>
                    </div>
                </>
            )
        }

        return content
    }

    const getCharterRows = rows => {
        let content = []

        for (let i = 0; i < rows.length; i++) {
            let time = rows[i].time
            let techProcess = rows[i].techProcess
            let block = rows[i].block
            let row = rows[i].row
            let accept = rows[i].accept
            let textValue = rows[i].textValue

            content.push(
                <tr key={row}>
                    <td contentEditable={false} className="center align-middle"
                        style={accept ? styles.filledCell : styles.defaultCell}>
                        <FormCheck defaultChecked={accept}
                                   onInput={(e) =>
                                       props.oninputBlockRowData(techProcess, block, row, "rowCheckbox", "checkbox", e.target.checked)}
                                   onMouseOver={(e) =>
                                       captureKeyCombination(
                                           "History of changes in \"Accept\" column",
                                           "project_stages_block_row_data_accept",
                                           "oninputBlockRowData", {
                                               techProcess: techProcess,
                                               block: block,
                                               row: row,
                                               column: "rowCheckbox",
                                               type: "checkbox",
                                               value: e.target.checked
                                           }
                                       )}
                        />
                    </td>
                    <td key={i} className="whiteSpacePreLine"
                        contentEditable={false}
                        style={accept ? styles.filledCell : styles.defaultCell}
                        dangerouslySetInnerHTML={{__html: textValue}}>
                    </td>
                    <td contentEditable={false} className="center align-middle"
                        style={accept ? styles.filledCell : styles.defaultCell}>
                        <Badge bg="primary" text="light">
                            {time}
                        </Badge>
                    </td>
                </tr>
            )
        }

        return content
    }

    return (
        <Tabs>
            {
                props.data.map(techProcess => {
                    if (Object.keys(techProcess.blocks).length) {
                        return (
                            <Tab key={techProcess.code} eventKey={techProcess.code} title={techProcess.tabName}>
                                <div className="tab-item-wrapper">
                                    <br/>
                                    <div className="row">
                                        {getBlocks(techProcess)}
                                    </div>
                                </div>
                            </Tab>
                        )
                    }
                })
            }
            <Tab eventKey={"charter"} title={"Chart"}>
                <div className="tab-item-wrapper">
                    <br/>
                    <div className="row">
                        <div className="col-sm-12">
                            <Tabs>
                                {
                                    props.data.map(techProcess => {
                                        if (Object.keys(techProcess.blocks).length) {
                                            return (
                                                <Tab key={"charter_" + techProcess.code}
                                                     eventKey={"charter_" + techProcess.code}
                                                     title={techProcess.tabName}>
                                                    <div className="tab-item-wrapper">
                                                        <br/>
                                                        <div className="charter-scrolling-wrapper">
                                                            {getCharter(techProcess)}
                                                        </div>
                                                    </div>
                                                </Tab>
                                            )
                                        }
                                    })
                                }
                            </Tabs>
                        </div>
                    </div>
                </div>
            </Tab>
        </Tabs>
    )
}