import {Nav, Tabs, Tab, Row, Col} from "react-bootstrap";
import React, {useEffect} from "react";
import ClientViewLanguageTab from "./ClientViewLanguageTab";
import GeneralTab from "./GeneralTab";

export default function ClientViewTabs(props) {
    useEffect(() => {
        props.triggerOpenInModalAddedImage()
    }, []);

    return(
        <Tab.Container defaultActiveKey={"project"}>
            <Nav variant="pills" className="nav-custom-grey">
                <Nav.Item>
                    <Nav.Link eventKey={"project"}>
                        Project
                    </Nav.Link>
                </Nav.Item>
                {
                    props.languages.map(language => {
                        return (
                            <Nav.Item>
                                <Nav.Link key={language.code} eventKey={language.code}>
                                    {language.title}
                                </Nav.Link>
                            </Nav.Item>
                        )
                    })
                }
            </Nav>
            <Tab.Content>
                <Tab.Pane eventKey={"project"}>
                    <div className="tab-item-wrapper">
                        <br/>
                        <div className="row">
                            <div className="col-sm-12 tableFixHead">
                                {
                                    <GeneralTab
                                        viewCode={props.viewCode}
                                        columns={props.columns}
                                        rows={props.rows}
                                        generalTabSaveCellValue={props.generalTabSaveCellValue}
                                        showAcceptChangesBtn={props.showAcceptChangesBtn}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </Tab.Pane>
                {
                    props.languages.map(language => {
                        return (
                            <Tab.Pane key={language.code} eventKey={language.code}>
                                <div className="tab-item-wrapper">
                                    <br/>
                                    <ClientViewLanguageTab
                                        language={language}
                                        characterCommunications={props.characterCommunications}
                                        communicationBetweenCharacters={props.communicationBetweenCharacters}
                                        sgTabSaveCellValue={props.sgTabSaveCellValue}
                                        showAcceptChangesBtn={props.showAcceptChangesBtn}
                                    />
                                </div>
                            </Tab.Pane>
                        )
                    })
                }
            </Tab.Content>
        </Tab.Container>
    )
}