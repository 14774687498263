import React, {useEffect, useState} from "react";
import {Alert, Button, Table} from "react-bootstrap";
import TableInfo from "../template_body/TableInfo";
import TagModal from "../template_body/TagModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";

const styles = {
    alertMargin: {
        marginLeft: '.5rem',
        marginRight: '.5rem',
    },

    smallBtn: {
        height: '25px',
        paddingBottom: '0px',
        paddingTop: '0px'
    }
}

export default function ProjectBibleTemplateHeader(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoadedTagData, setIsLoadedTagData] = useState(false);
    const [columns, setColumns] = useState([{
        data: []
    }])
    const [rows, setRows] = useState([{
        data: []
    }])
    const [showAlert, setShowAlert] = useState(true)
    const [tagModalActive, setTagModalActive] = useState(false);
    const [tagModalData, setTagModalData] = useState([{
        allTags: new Map(),
        selectedTags: {},
        row: '',
        column: ''
    }])

    let cellAllCount = 0
    let cellOnCount = 0

    const authorizationCookieName = "b24_authorization_log"
    const authorizationCookieMatches = document.cookie.match(new RegExp(
        "(?:^|; )" + authorizationCookieName.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    const employeeId = authorizationCookieMatches ?
        +JSON.parse(window.atob(decodeURIComponent(authorizationCookieMatches[1]))).userId :
        window.location.href.split("/")[2] === "localhost:3000" ? 191 : 0

    useEffect(async () => {
        if (checkCookie()) {
            deleteRedirectCookie()

            document.title = "PB (template rows)";

            await fetch("/proxy/project_bible_template/projectBibleTemplateActiveRowsAndColumnsWithTemplate", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    async (resultTemplate) => {
                        cellAllCount = resultTemplate.columns.length * resultTemplate.rows.length

                        resultTemplate.columns.map(value => {
                            setColumns(
                                columns.map(info => {
                                    info.data.push({
                                        "code": value.code,
                                        "name": value.name,
                                        "type": value.type
                                    });

                                    return info
                                })
                            )

                            return value
                        })

                        resultTemplate.rows.map(value => {
                            setRows(
                                rows.map(info => {
                                    info.data.push({
                                        "code": value.code,
                                        "data": []
                                    });

                                    return info
                                })
                            )

                            return value
                        })

                        fillDataForHeaders()
                        fillTagData()
                    },
                    (error) => {
                        setIsLoaded(true);
                        setIsLoadedTagData(true);
                        setError({
                            message: "Не удалось загрузить данные. Пожалуйста перезагрузите страницу."
                        });
                    }
                )
        } else {
            setRedirectCookie()
            window.location.replace('https://b24.allcorrectgames.com');
        }
    }, [])

    function checkCookie() {
        let name = "testCookie"

        document.cookie = "testCookie=test; max-age=3600";

        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));

        if (!matches) {
            alert("Allow cookies in your browser settings for correct work.")

            return false
        } else {
            return employeeId > 0
        }
    }

    function setRedirectCookie() {
        let cookieName =  'apps_allcorrect_redirect'
        let cookieValue = window.btoa(window.location.href)
        let date = new Date(Date.now() + 1800e3);
        let options = {
            path: '/',
            domain: 'allcorrectgames.com',
            expires: date.toUTCString(),
            samesite: 'lax'
        };
        let updatedCookie = cookieName + "=" + cookieValue;

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;

            let optionValue = options[optionKey];

            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    function deleteRedirectCookie() {
        let cookieName =  'apps_allcorrect_redirect'
        let cookieValue = 'val'
        let date = new Date();

        date.setMonth(date.getMonth() - 1);

        let options = {
            path: '/',
            domain: 'allcorrectgames.com',
            expires: date.toUTCString(),
            samesite: 'lax'
        };
        let updatedCookie = cookieName + "=" + cookieValue;

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;

            let optionValue = options[optionKey];

            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    }

    async function addRowToTheEnd() {
        await fetch("/proxy/project_bible_template/projectBibleTemplateGenerateRowIndividualCode", {
            method: 'GET',
        })
            .then(res => res.json())
            .then(
                async (resultGenerate) => {
                    if (!resultGenerate.exist.length) {
                        await fetch("/proxy/project_bible_template/projectBibleTemplateAddRowToTheEnd", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "num": rows[0].data.length + 1,
                                "code": resultGenerate.individualCode,
                                "active": true
                            })
                        })
                            .then(res => res.json())
                            .then(
                                (resultAdd) => {
                                    let value = {
                                        "code": resultGenerate.individualCode,
                                        "data": []
                                    }

                                    columns.map(columnsData => {
                                        columnsData.data.map(column => {
                                            if (column.type === "input") {
                                                value.data[column.code] = ""
                                            } else if (column.type === "checkbox") {
                                                value.data[column.code] = false
                                            } else if (column.type === "tags_list") {
                                                value.data[column.code] = []
                                            }
                                        })
                                    })

                                    setRows(
                                        rows.map(info => {
                                            info.data.push(value);

                                            return info
                                        })
                                    )
                                },
                                (error) => {
                                    //todo придумать какой-то текст ошибки
                                }
                            )
                    } else {
                        addRowToTheEnd()
                    }
                },
                (error) => {
                    //todo придумать какой-то текст ошибки
                }
            )
    }

    async function addRow(num) {
        await fetch("/proxy/project_bible_template/projectBibleTemplateGenerateIndividualCode", {
            method: 'GET',
        })
            .then(res => res.json())
            .then(
                async (resultGenerate) => {
                    if (!resultGenerate.exist.length) {
                        await fetch("/proxy/project_bible_template/projectBibleTemplateIncNumPredAddRow", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "initNum": num
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultPredAdd) => {
                                    await fetch("/proxy/project_bible_template/projectBibleTemplateAddRow", {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            "num": num + 1,
                                            "code": resultGenerate.individualCode,
                                            "active": true
                                        })
                                    })
                                        .then(res => res.json())
                                        .then(
                                            (resultAdd) => {
                                                let value = {
                                                    "code": resultGenerate.individualCode,
                                                    "data": []
                                                }

                                                columns.map(columnsData => {
                                                    columnsData.data.map(column => {
                                                        if (column.type === "input") {
                                                            value.data[column.code] = ""
                                                        } else if (column.type === "checkbox") {
                                                            value.data[column.code] = false
                                                        } else if (column.type === "tags_list") {
                                                            value.data[column.code] = []
                                                        }
                                                    })
                                                })

                                                setRows(
                                                    rows.map(info => {
                                                        info.data.splice(num, 0, value)

                                                        return info
                                                    })
                                                )
                                            },
                                            (error) => {
                                                //todo придумать какой-то текст ошибки
                                            }
                                        )
                                },
                                (error) => {
                                    //todo придумать какой-то текст ошибки
                                }
                            )
                    } else {
                        addRow(num)
                    }
                },
                (error) => {
                    //todo придумать какой-то текст ошибки
                }
            )
    }

    async function deleteRow(code, num) {
        await fetch("/proxy/project_bible_template/projectBibleTemplateDecNumPredDeleteRow", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "initNum": num
            })
        })
            .then(res => res.json())
            .then(
                async (resultPredDelete) => {
                    await fetch("/proxy/project_bible_template/projectBibleTemplateDeleteRow", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "code": code
                        })
                    })
                        .then(res => res.json())
                        .then(
                            (resultDelete) => {
                                setRows(
                                    rows.map(info => {
                                        info.data.splice(num - 1, 1)

                                        return info
                                    })
                                )
                            },
                            (error) => {
                                //todo придумать какой-то текст ошибки
                            }
                        )
                },
                (error) => {
                    //todo придумать какой-то текст ошибки
                }
            )
    }

    async function moveUpRow(code, num) {
        if (num !== 1) {
            await fetch("/proxy/project_bible_template/projectBibleTemplateMoveUpRow", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "code": code,
                    "num": num
                })
            })
                .then(res => res.json())
                .then(
                    (resultMoveUp) => {
                        let value = rows[0].data[num - 1]

                        setRows(
                            rows.map(info => {
                                info.data.splice(num - 1, 1)
                                info.data.splice(num - 2, 0, value)

                                return info
                            })
                        )
                    },
                    (error) => {
                        //todo придумать какой-то текст ошибки
                    }
                )
        }
    }

    async function moveDownRow(code, num) {
        let rowsLength = rows[0].data.length

        if (num !== rowsLength) {
            await fetch("/proxy/project_bible_template/projectBibleTemplateMoveDownRow", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "code": code,
                    "num": num
                })
            })
                .then(res => res.json())
                .then(
                    (resultMoveDown) => {
                        let value = rows[0].data[num - 1]

                        setRows(
                            rows.map(info => {
                                info.data.splice(num - 1, 1)
                                info.data.splice(num, 0, value)

                                return info
                            })
                        )
                    },
                    (error) => {
                        //todo придумать какой-то текст ошибки
                    }
                )
        }
    }

    function fillDataForHeaders() {
        setRows(rows.map(rowsData => {
            rowsData.data.map(async row => {
                columns.map(columnsData => {
                    columnsData.data.map(async column => {
                        let queryLinkTemplate = '/proxy/project_bible_template/'

                        if (column.type === "input") {
                            queryLinkTemplate += 'projectBibleTemplateTextByNameIfExist'
                        } else if (column.type === "checkbox") {
                            queryLinkTemplate += 'projectBibleTemplateBoolByNameIfExist'
                        } else if (column.type === "tags_list") {
                            queryLinkTemplate += 'projectBibleTemplateTagJsonByNameIfExist'
                        }

                        await fetch(queryLinkTemplate, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "rowCode": row.code,
                                "colCode": column.code,
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (result) => {
                                    if (result.length > 0) {
                                        row.data[column.code] = result[0].value
                                    } else {
                                        if (column.type === "input") {
                                            row.data[column.code] = ""
                                        } else if (column.type === "checkbox") {
                                            row.data[column.code] = false
                                        } else if (column.type === "tags_list") {
                                            row.data[column.code] = /*[]*/{}
                                        }
                                    }

                                    cellOnCount++

                                    if (cellOnCount === cellAllCount && !error) {
                                        setIsLoaded(true);
                                    }
                                },
                                (error) => {
                                    setIsLoaded(true);
                                    setIsLoadedTagData(true);
                                    setError(error);
                                }
                            )
                    })

                    return columnsData
                })
            })

            return rowsData
        }))
    }

    async function fillTagData() {
        const response = await fetch('/proxy/project_bible_tags', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}
        });
        let result = await response.json();

        if ('response' in result) {
            setTagModalData(
                tagModalData.map(info => {
                    let tags = new Map()

                    let tagGroupsArray = result.response.TechProcess.sort(function(a, b) {
                        return decodeURIComponent(a['Group_tags']) - decodeURIComponent(b['Group_tags']);
                    });

                    for (let i = 0; i < tagGroupsArray.length; i++) {
                        let value = []
                        let tagsArray = tagGroupsArray[i]['Tags'].sort(function(a, b) {
                            return decodeURIComponent(a.tag) - decodeURIComponent(b.tag);
                        });

                        for (let j = 0; j < tagsArray.length; j++) {
                            value.push(decodeURIComponent(tagsArray[j].tag))
                        }

                        tags.set(decodeURIComponent(tagGroupsArray[i]['Group_tags']), value)
                    }

                    info.allTags = tags
                    setIsLoadedTagData(true)

                    return info
                })
            )
        } else {
            setIsLoaded(true)
            setIsLoadedTagData(true)
            setError({
                message: "Не удалось загрузить данные. Пожалуйста перезагрузите страницу."
            })
        }
    }

    async function setTagValue(val, row, column, selectedTags) {
        setTagModalData(
            tagModalData.map(info => {
                info.row = row
                info.column = column
                info.selectedTags = selectedTags

                return info
            })
        )

        setTagModalActive(val)

        /*await fetch('/proxy/project_bible_template/projectBibleTemplateTagJsonByNameIfExist', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "rowCode": row,
                "colCode": column,
            })
        })
            .then(res => res.json())
            .then(
                async (resultSelectedTags) => {
                    setTagModalActive(val)

                    setTagModalData(
                        tagModalData.map(info => {
                            info.row = row
                            info.column = column

                            if (resultSelectedTags.length > 0) {
                                resultSelectedTags[0].value.map(selectTagGroup => {
                                    info.selectedTags[selectTagGroup.code] = {
                                        "code": selectTagGroup.code,
                                        "title": selectTagGroup.title,
                                        "data": []
                                    }

                                    selectTagGroup.data.map(selectTag => {
                                        info.selectedTags[selectTagGroup.code].data[selectTag.code] = selectTag.title

                                        return selectTag
                                    })

                                    return selectTagGroup
                                })
                            } else {
                                info.selectedTags = []
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )*/
    }

    async function saveTagCell(row, column, selectedTags) {
        await fetch('/proxy/project_bible_template/projectBibleTemplateTagJsonByNameIfExist', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "colCode": column,
                "rowCode": row
            })
        })
            .then(res => res.json())
            .then(
                async (resultExist) => {
                    if (resultExist.length) {
                        await fetch('/proxy/project_bible_template/projectBibleTemplateUpdateTagJsonCell', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "colCode": column,
                                "rowCode": row,
                                "value": selectedTags
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultUpdate) => {
                                    setRows(
                                        rows.map(info => {
                                            info.data.map(rowValue => {
                                                if (rowValue.code === row) {
                                                    rowValue.data[column] = selectedTags
                                                }

                                                return rowValue
                                            })

                                            return info
                                        })
                                    )

                                    setTagModalData(
                                        tagModalData.map(info => {
                                            info.row = ''
                                            info.column = ''
                                            info.selectedTags = {}

                                            return info
                                        })
                                    )

                                    console.log("NEW TAG MODAL DATA", tagModalData)

                                    setTagModalActive(false)
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки.")
                                }
                            )
                    } else {
                        await fetch('/proxy/project_bible_template/projectBibleTemplateInsertTagJsonCell', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "colCode": column,
                                "rowCode": row,
                                "value": selectedTags
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultInsert) => {
                                    setRows(
                                        rows.map(info => {
                                            info.data.map(rowValue => {
                                                if (rowValue.code === row) {
                                                    rowValue.data[column] = selectedTags
                                                }

                                                return rowValue
                                            })

                                            return info
                                        })
                                    )

                                    setTagModalData(
                                        tagModalData.map(info => {
                                            info.row = ''
                                            info.column = ''
                                            info.selectedTags = {}

                                            return info
                                        })
                                    )

                                    console.log("NEW TAG MODAL DATA", tagModalData)

                                    setTagModalActive(false)
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки.")
                                }
                            )
                    }
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки.")
                }
            )
    }

    async function oninputCell(column, row, type, e) {
        let queryLinkExist = '/proxy/project_bible_template/'
        let queryUpdateCell = '/proxy/project_bible_template/'
        let queryInsertCell = '/proxy/project_bible_template/'
        let value

        if (type === "input") {
            queryLinkExist += 'projectBibleTemplateTextByNameIfExist'
            queryUpdateCell += 'projectBibleTemplateOninputUpdateTextCell'
            queryInsertCell += 'projectBibleTemplateOninputInsertTextCell'
            value = e.target.innerText
        } else if (type === "checkbox") {
            queryLinkExist += 'projectBibleTemplateBoolByNameIfExist'
            queryUpdateCell += 'projectBibleTemplateOninputUpdateBoolCell'
            queryInsertCell += 'projectBibleTemplateOninputInsertBoolCell'
            value = e.target.checked
        }

        await fetch(queryLinkExist, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "colCode": column,
                "rowCode": row
            })
        })
            .then(res => res.json())
            .then(
                async (resultExist) => {
                    if (resultExist.length) {
                        await fetch(queryUpdateCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "colCode": column,
                                "rowCode": row,
                                "value": value
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultUpdate) => {
                                    setRows(
                                        rows.map(info => {
                                            info.data.map(rowValue => {
                                                if (rowValue.code === row) {
                                                    rowValue.data[column] = value
                                                }

                                                return rowValue
                                            })

                                            return info
                                        })
                                    )
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    } else {
                        await fetch(queryInsertCell, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "colCode": column,
                                "rowCode": row,
                                "value": value
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultInsert) => {
                                    setRows(
                                        rows.map(info => {
                                            info.data.map(rowValue => {
                                                if (rowValue.code === row) {
                                                    rowValue.data[column] = value
                                                }

                                                return rowValue
                                            })

                                            return info
                                        })
                                    )
                                },
                                (error) => {
                                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                        "верное значение")
                                }
                            )
                    }
                },
                (error) => {
                    alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                        "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                        "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                        "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                        "верное значение")
                }
            )
    }

    if (error) {
        return (
            <div className="row">
                <div className="col-sm-12 center">
                    <br />
                    <h3>Ошибка: {error.message}</h3>
                </div>
            </div>
        )
    } else if (!isLoaded || !isLoadedTagData) {
        return (
            <div className="row">
                <div className="col-sm-12 center">
                    <br />
                    <h3>Загрузка...</h3>
                </div>
            </div>
        )
    } else {
        return(
            <div>
                {
                    showAlert &&
                    <div style={styles.alertMargin}>
                        <br />
                        <Alert variant="primary" onClose={() => setShowAlert(false)} dismissible>
                            <Alert.Heading>Памятка по редактированию строк с шаблоном:</Alert.Heading>
                            <span>
                                Каждая строка имеет свой индивидуальный код, в связи с этим:
                                <br/>
                                <br/>
                                1. Если вы хотите изменить существующую строку, потому что она стала не нужна, и хотите
                                добавить новую, то удалите ненужную и с нуля добавьте новую.
                                <br/>
                                2. Перемещение строк (кнопки: <Button size="sm" variant="primary" className="btn btn-blue"
                                                                        style={styles.smallBtn}>
                                <FontAwesomeIcon icon={faChevronUp}/></Button>&nbsp;<Button
                                size="sm" variant="primary" className="btn btn-blue" style={styles.smallBtn}><FontAwesomeIcon
                                icon={faChevronDown}/></Button>) меняет порядок следования строк.
                                <br/>
                                4. Добавление строки (кнопка: <Button size="sm" variant="success"
                                                                      style={styles.smallBtn}><FontAwesomeIcon icon={faPlus}/></Button>) добавит ее после
                                строки, в которой была нажата кнопка.
                                <br/>
                                5. Удаление строки (кнопка: <Button size="sm" variant="danger" style={styles.smallBtn}>
                                <FontAwesomeIcon icon={faTimes}/></Button>) не удаляет ее из базы данных, а делает ее
                                неактивной - так "удаленные" строки могут продолжать отображаться в отчетах, на момент
                                заполнения которых эта строка еще существовала.
                                <br/>
                                А также для того, чтобы при создании новых строк не возникло ситуации с повторяющимся
                                кодом, который уже ранее генерировался для другой строки.
                                <br/>
                                6. Добавление строки (кнопка: <Button size="sm" variant="primary" className="btn btn-blue"
                                                                      style={styles.smallBtn}>Добавить&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/></Button>)
                                добавит ее в конец.
                                <br />
                                7. Изменения в ячейках с галочками сохраняются по нажатию, текстовые изменения
                                сохраняются как только вы переставляете курсор из редактируемой ячейки в любое другое
                                место.
                            </span>
                        </Alert>
                    </div>
                }
                <br />
                <div className="main-block-div-dark">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableInfo columns={columns[0].data} rows={rows[0].data}
                                       addRow={addRow} deleteRow={deleteRow} moveUpRow={moveUpRow}
                                       moveDownRow={moveDownRow} setTagValue={setTagValue} oninputCell={oninputCell} />
                            <div className="center">
                                <Button variant="primary" className="btn btn-blue" onClick={(e) =>
                                    addRowToTheEnd()}>Добавить&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <TagModal tagModalActive={tagModalActive} setTagModalActive={setTagModalActive}
                              tagModalData={tagModalData} setTagModalData={setTagModalData} saveTagCell={saveTagCell} />
                </div>
            </div>
        )
    }
}