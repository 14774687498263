import {Button, Table} from "react-bootstrap";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faMagic, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";

const styles = {
    blockHeader: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    editableCell: {
        backgroundColor: '#fff',
        width: '50%'
    },

    noEditableCell: {
        backgroundColor: '#f6f6f6',
        width: '50%'
    },
}

export default function LinguistLanguageTabPreferences(props) {
    const queryStringParams = queryString.parse(window.location.search)
    const code = queryStringParams.code
    console.log("ClientViewLanguageTab", props.language)

    async function oninputCell(block, row, type, e, oldValue) {
        let queryLinkExist = '/proxy/project_bible_template/'
        let queryUpdateCell = '/proxy/project_bible_template/'
        let queryInsertCell = '/proxy/project_bible_template/'
        let value

        if (type === "input") {
            queryLinkExist += 'clientViewLanguagesFilledCellTextByCode'
            queryUpdateCell += 'clientViewLanguagesOninputUpdateTextCell'
            queryInsertCell += 'clientViewLanguagesOninputInsertTextCell'
            value = e.target.innerText
        }

        // if (value !== oldValue) {
            await fetch(queryLinkExist, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "code": code,
                    "languageCode": props.language.code,
                    "block": block,
                    "rowCode": row
                })
            })
                .then(res => res.json())
                .then(
                    async (resultEditable) => {
                        if (resultEditable.length) {
                            await fetch(queryUpdateCell, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "code": code,
                                    "languageCode": props.language.code,
                                    "block": block,
                                    "rowCode": row,
                                    "value": value
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (resultUpdate) => {
                                        if (props.submit) {
                                            props.setFormUpdateValue()
                                        }
                                    },
                                    (error) => {
                                        alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                            "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                            "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                            "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                            "верное значение")
                                    }
                                )
                        } else {
                            await fetch(queryInsertCell, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "code": code,
                                    "languageCode": props.language.code,
                                    "block": block,
                                    "rowCode": row,
                                    "value": value
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (resultInsert) => {
                                        if (props.submit) {
                                            props.setFormUpdateValue()
                                        }
                                    },
                                    (error) => {
                                        alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                                            "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                                            "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                                            "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                                            "верное значение")
                                    }
                                )
                        }
                    },
                    (error) => {
                        alert("Ошибка при сохранении значения ячейки. Если это была ячейка для ввода " +
                            "текста, то попробуйте поставить указатель обратно в эту ячейку, а затем убрать " +
                            "- повторится процедура сохранения. Если это чекбокс - снимите/поставьте " +
                            "галочку и после этого повторите последнее действие еще раз, чтобы сохранилось " +
                            "верное значение")
                    }
                )
        // }
    }

    return(
        <div key={props.language.code} className="row">
            <div className="col-sm-12 table-blue-thead-not-fixed">
                <Table responsive bordered>
                    <thead>
                    <tr style={styles.blockHeader}>
                        <th className="center" colSpan={2}
                            style={styles.blockHeader}>
                            {props.language.data[0].base_info.title}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.language.data[0].base_info.data.map(data => {
                            return (
                                <tr key={data.code}>
                                    {
                                        data.row.map(rowData => {
                                            //todo это не работает
                                            if (/*props.language.data[0].base_info.clientEditable && */rowData.code === "comment") {
                                                /*if (props.submit) {
                                                    return(
                                                        <td key={rowData.code} className="whiteSpacePreLine"
                                                            contentEditable={false}
                                                            style={styles.noEditableCell}
                                                            dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                        </td>
                                                    )
                                                } else {*/
                                                    return(
                                                        <td key={rowData.code} className="whiteSpacePreLine"
                                                            contentEditable={true}
                                                            suppressContentEditableWarning={true}
                                                            style={styles.editableCell}
                                                            dangerouslySetInnerHTML={{__html: rowData.text}}
                                                            onBlur={(e) =>
                                                                oninputCell("base_info", data.code, "input", e, rowData.text)}>
                                                        </td>
                                                    )
                                                /*}*/
                                            } else {
                                                return(
                                                    <td key={rowData.code} className="whiteSpacePreLine"
                                                        colSpan={data.colspan ? 2 : 1}
                                                        contentEditable={false}
                                                        style={styles.noEditableCell}
                                                        dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                    </td>
                                                )
                                            }
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
                <br />
                <Table responsive bordered>
                    <thead>
                    <tr style={styles.blockHeader}>
                        <th className="center" colSpan={2} style={styles.blockHeader}>
                            {props.language.data[0].punctuation_marks.title}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.language.data[0].punctuation_marks.data.map(data => {
                            return (
                                <tr key={data.code}>
                                    {
                                        data.row.map(rowData => {
                                            if (/*props.language.data[0].punctuation_marks.clientEditable && */rowData.code === "comment") {
                                                /*if (props.submit) {
                                                    return(
                                                        <td key={rowData.code} contentEditable={false}
                                                            style={styles.noEditableCell}
                                                            dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                        </td>
                                                    )
                                                } else {*/
                                                    return(
                                                        <td key={rowData.code} contentEditable={true}
                                                            suppressContentEditableWarning={true}
                                                            style={styles.editableCell}
                                                            dangerouslySetInnerHTML={{__html: rowData.text}}
                                                            onBlur={(e) =>
                                                                oninputCell("punctuation_marks", data.code, "input", e, rowData.text)}>
                                                        </td>
                                                    )
                                                /*}*/
                                            } else {
                                                return(
                                                    <td key={rowData.code} colSpan={data.colspan ? 2 : 1}
                                                        contentEditable={false}
                                                        style={styles.noEditableCell}
                                                        dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                    </td>
                                                )
                                            }
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
                <br />
                <Table responsive bordered>
                    <thead>
                    <tr style={styles.blockHeader}>
                        <th className="center" colSpan={2} style={styles.blockHeader}>
                            {props.language.data[0].capital_letters.title}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.language.data[0].capital_letters.data.map(data => {
                            return (
                                <tr key={data.code}>
                                    {
                                        data.row.map(rowData => {
                                            if (/*props.language.data[0].capital_letters.clientEditable && */rowData.code === "comment") {
                                                /*if (props.submit) {
                                                    return(
                                                        <td key={rowData.code} contentEditable={false}
                                                            style={styles.noEditableCell}
                                                            dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                        </td>
                                                    )
                                                } else {*/
                                                    return(
                                                        <td key={rowData.code} contentEditable={true}
                                                            suppressContentEditableWarning={true}
                                                            style={styles.editableCell}
                                                            dangerouslySetInnerHTML={{__html: rowData.text}}
                                                            onBlur={(e) =>
                                                                oninputCell("capital_letters", data.code, "input", e, rowData.text)}>
                                                        </td>
                                                    )
                                                /*}*/
                                            } else {
                                                return(
                                                    <td key={rowData.code} colSpan={data.colspan ? 2 : 1}
                                                        contentEditable={false}
                                                        style={styles.noEditableCell}
                                                        dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                    </td>
                                                )
                                            }
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
                <br />
                <Table responsive bordered>
                    <thead>
                    <tr style={styles.blockHeader}>
                        <th className="center" colSpan={2} style={styles.blockHeader}>
                            {props.language.data[0].target_language_features.title}
                        </th>
                        {
                            props.language.data[0].target_language_features.clientEditable &&
                            <th className="center" style={styles.blockHeader}>
                                Comment
                            </th>
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.language.data[0].target_language_features.data.map(data => {
                            return (
                                <tr key={data.code}>
                                    {
                                        data.row.map(rowData => {
                                            if (/*props.language.data[0].target_language_features.clientEditable && */rowData.code === "comment") {
                                                /*if (props.submit) {
                                                    return(
                                                        <td key={rowData.code} contentEditable={false}
                                                            style={styles.noEditableCell}
                                                            dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                        </td>
                                                    )
                                                } else {*/
                                                    return(
                                                        <td key={rowData.code} contentEditable={true}
                                                            suppressContentEditableWarning={true}
                                                            style={styles.editableCell}
                                                            dangerouslySetInnerHTML={{__html: rowData.text}}
                                                            onBlur={(e) =>
                                                                oninputCell("target_language_features", data.code, "input", e, rowData.text)}>
                                                        </td>
                                                    )
                                                /*}*/
                                            } else {
                                                return(
                                                    <td key={rowData.code} colSpan={data.colspan ? 2 : 1}
                                                        contentEditable={false}
                                                        style={styles.noEditableCell}
                                                        dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                    </td>
                                                )
                                            }
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
                <br />
                <Table responsive bordered>
                    <thead>
                    <tr style={styles.blockHeader}>
                        <th className="center" colSpan={2} style={styles.blockHeader}>
                            {props.language.data[0].inclusive_language_guidelines.title}
                        </th>
                        {
                            props.language.data[0].inclusive_language_guidelines.clientEditable &&
                            <th className="center" style={styles.blockHeader}>
                                Comment
                            </th>
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.language.data[0].inclusive_language_guidelines.data.map(data => {
                            return (
                                <tr key={data.code}>
                                    {
                                        data.row.map(rowData => {
                                            if (/*props.language.data[0].inclusive_language_guidelines.clientEditable && */rowData.code === "comment") {
                                                /*if (props.submit) {
                                                    return(
                                                        <td key={rowData.code} contentEditable={false}
                                                            style={styles.noEditableCell}
                                                            dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                        </td>
                                                    )
                                                } else {*/
                                                    return(
                                                        <td key={rowData.code} contentEditable={true}
                                                            suppressContentEditableWarning={true}
                                                            style={styles.editableCell}
                                                            dangerouslySetInnerHTML={{__html: rowData.text}}
                                                            onBlur={(e) =>
                                                                oninputCell("inclusive_language_guidelines", data.code, "input", e, rowData.text)}>
                                                        </td>
                                                    )
                                                /*}*/
                                            } else {
                                                return(
                                                    <td key={rowData.code} colSpan={data.colspan ? 2 : 1}
                                                        contentEditable={false}
                                                        style={styles.noEditableCell}
                                                        dangerouslySetInnerHTML={{__html: rowData.text}}>
                                                    </td>
                                                )
                                            }
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}