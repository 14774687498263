import {Button, Nav, Table, Tabs, Tab, Row, Col} from "react-bootstrap";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faMagic, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";
import LanguageTabPreferences from "../client_view/ClientViewLanguageTabPreferences";
import LanguageTabCharacterCommunication from "../client_view/ClientViewLanguageTabCharacterCommunication";

const styles = {
    blockHeader: {
        backgroundColor: '#e0f0ff',
    },

    editableCell: {
        width: '33%'
    },

    noEditableCell: {
        backgroundColor: '#f6f6f6',
        width: '33%'
    },
}

export default function ClientViewLanguageTab(props) {
    return (
        <div>
            {
                props.communicationBetweenCharacters ? (
                    <Tab.Container defaultActiveKey={"languagePreferences"}>
                        <Row>
                            <Col sm={12}>
                                <Nav variant="pills" className="nav-custom-grey">
                                    <Nav.Item>
                                        <Nav.Link eventKey={"languagePreferences"}>
                                            Language Preferences
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={"communicationBetweenCharacters"}>
                                            Communication between characters
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey={"languagePreferences"}>
                                        <div className="tab-item-wrapper">
                                            <br/>
                                            <LanguageTabPreferences language={props.language} submit={props.submit}
                                                                    setFormUpdateValue={props.setFormUpdateValue}
                                                                    projectManagementInfo={props.projectManagementInfo}/>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={"communicationBetweenCharacters"}>
                                        <div className="tab-item-wrapper">
                                            <br/>
                                            <LanguageTabCharacterCommunication
                                                language={props.language.code}
                                                characters={props.characterCommunications[0].characters}
                                                templateData={props.characterCommunications[0].templateData}
                                                languageData={props.characterCommunications[0].languagesData[props.language.code].data}
                                                languageDataFromLinguist={props.characterCommunications[0].languagesData[props.language.code].linguistAcceptedData}
                                            />
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                ) : (
                    <LanguageTabPreferences language={props.language} submit={props.submit}
                                            setFormUpdateValue={props.setFormUpdateValue} />
                )
            }
        </div>
    )
}