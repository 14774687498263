import {Table} from "react-bootstrap";
import React, {useState} from "react";
import GeneralTableRow from "./GeneralTableRow";

const styles = {
    noEditableCell: {
        backgroundColor: '#fafafa',
    },

    tableFixHeadTable: {
        borderCollapse: 'collapse',
        width: '100%',
    },

    tableFixHeadTh: {
        position: 'sticky',
        top: -2,
        zIndex: 1,
        whiteSpace: 'pre-line',
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    tableRowHeader: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    tableBodyTopBorder: {
        borderTop: '0px'
    }
}

export default function TableInfo(props) {
    return (
        <div className="div-table-blue">
            <Table responsive bordered>
                <thead>
                <tr style={styles.tableRowHeader}>
                    <th key="№" className="center" style={styles.tableFixHeadTh}>№</th>
                    {
                        props.columns.map(column => {
                            if (column.show) {
                                return (
                                    <th key={column.name}
                                        className="center"
                                        style={styles.tableFixHeadTh}>
                                        {column.name}
                                    </th>
                                )
                            }
                        })
                    }
                </tr>
                </thead>
                <tbody style={styles.tableBodyTopBorder}>
                {
                    props.rows.map((row, rowIndex) => {
                        return (
                            <GeneralTableRow
                                viewCode={props.viewCode}
                                key={rowIndex}
                                row={row}
                                rowIndex={rowIndex}
                                columns={props.columns}
                                generalTabSaveCellValue={props.generalTabSaveCellValue}
                                showAcceptChangesBtn={props.showAcceptChangesBtn}
                            />
                        )
                    })
                }
                </tbody>
            </Table>
        </div>
    )
}