import React, {useEffect, useState} from "react";
import {Button, Nav, Tab, Table, Tabs, Row, Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import Block from "./Block";
import ResponsibleTable from "./ResponsibleTable";
import ColumnsTable from "./ColumnsTable";

const styles = {
    noEditableCell: {
        backgroundColor: '#fafafa',
    },

    nowrapCell: {
        whiteSpace: 'nowrap'
    },
}

export default function TechProcess(props) {
    return(
        <div>
            <Tab.Container defaultActiveKey={"blocks"}>
                <Row>
                    <Col sm={12}>
                        <Nav variant="pills" className="nav-custom-grey">
                            <Nav.Item>
                                <Nav.Link eventKey={"blocks"}>
                                    Blocks
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={"columns"}>
                                    Columns
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={"responsible"}>
                                    Responsibles
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey={"blocks"}>
                                <div className="tab-item-wrapper">
                                    <br/>
                                    <Block
                                        techProcessCode={props.techProcessCode}
                                        data={props.data}
                                        blockSequence={props.blockSequence}
                                        columnSequence={props.columnSequence}
                                        rowsSequence={props.rowsSequence}
                                        editBlockTitle={props.editBlockTitle}
                                        addBlock={props.addBlock}
                                        addBlockToTheEnd={props.addBlockToTheEnd}
                                        deleteBlock={props.deleteBlock}
                                        moveDownBlock={props.moveDownBlock}
                                        moveUpBlock={props.moveUpBlock}
                                        openModalForTags={props.openModalForTags}
                                        openModalForResponsibles={props.openModalForResponsibles}
                                        updateBlockRowColumnValue={props.updateBlockRowColumnValue}
                                        deleteRow={props.deleteRow}
                                        addRow={props.addRow}
                                        addRowToTheEnd={props.addRowToTheEnd}
                                        moveUpRow={props.moveUpRow}
                                        moveDownRow={props.moveDownRow}
                                        moveRow={props.moveRow}
                                        associativeArrayOfTagsById={props.associativeArrayOfTagsById}
                                    />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey={"columns"}>
                                <div className="tab-item-wrapper">
                                    <br/>
                                    <ColumnsTable
                                        techProcessCode={props.techProcessCode}
                                        data={props.data}
                                        columnSequence={props.columnSequence}
                                        editColumnTitle={props.editColumnTitle}
                                        addColumn={props.addColumn}
                                        addColumnToTheEnd={props.addColumnToTheEnd}
                                        deleteColumn={props.deleteColumn}
                                        moveDownColumn={props.moveDownColumn}
                                        moveUpColumn={props.moveUpColumn}
                                    />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey={"responsible"}>
                                <div className="tab-item-wrapper">
                                    <br/>
                                    <ResponsibleTable
                                        techProcessCode={props.techProcessCode}
                                        responsibleSequence={props.responsibleSequence}
                                        addResponsible={props.addResponsible}
                                        editResponsible={props.editResponsible}
                                        deleteResponsible={props.deleteResponsible}
                                    />
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    )
}