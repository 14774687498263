import React, {useEffect, useState} from "react";
import queryString from 'query-string';
import {Button, Form, ProgressBar, Tabs} from "react-bootstrap";
import TableInfo from "./TableInfo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {Tab} from "bootstrap";
import ClientViewTabs from "./ClientViewTabs";
import ClientViewLanguageTab from "./ClientViewLanguageTab";
import AdditionalComment from "./AdditionalComment";
import "../css/tableFixHeader.css"

const styles = {
    progressBar: {
        margin: '10rem 10rem'
    },
}

export default function ProjectBibleClientView(props) {
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isLanguageLoaded, setIsLanguageLoaded] = useState(false)
    const [isCharacterCommunicationLoaded, setIsCharacterCommunicationLoaded] = useState(false)
    const [progressPercent, setProgressPercent] = useState(0)
    const [communicationBetweenCharacters, setCommunicationBetweenCharacters] = useState(false)
    // const [projectManagementInfo, setProjectManagementInfo] = useState(false)

    const queryStringParams = queryString.parse(window.location.search)
    const clientName = queryStringParams.client_name
    const projectName = queryStringParams.project_name
    const projectCode = queryStringParams.project_code
    const code = queryStringParams.code

    const [columns, setColumns] = useState([{
        data: []
    }])
    const [rows, setRows] = useState([{
        data: []
    }])
    const [languages, setLanguages] = useState([{
        data: []
    }])
    const [characterCommunications, setCharacterCommunications] = useState([{
        characters: [],
        templateData: [],
        languagesData: []
    }])
    const [linguistLinks, setLinguistLinks] = useState([{
        data: []
    }])
    const [isLinguistDataLoaded, setIsLinguistDataLoaded] = useState(false)
    const [rowsPdf, setRowsPdf] = useState([{
        data: []
    }])
    const [additionalComment, setAdditionalComment] = useState("")
    const [submit, setSubmit] = useState(false)

    let cellAllCount = 0
    let cellOnCount = 0
    let languageCount = 0
    let charactersDataCount = 0
    let linguistDataCount = 0
    let linguistLanguagesDataCount = []

    const [clientColumnCount, setClientColumnCount] = useState(0)
    const [isFormUpdateValueSet, setIsFormUpdateValue] = useState(false)
    const [isHideSubmitBtn, setIsHideSubmitBtn] = useState(false)

    useEffect(async () => {
        document.title = projectName + " (PB)";

        await fetch("/proxy/project_bible_template/projectBibleClientViewByCode", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "code": code
            }),
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    if (result.length) {
                        await fetch("/proxy/project_bible_template/projectBibleColumnsForClientTemplate", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                            .then(res => res.json())
                            .then(
                                async (resultColumnsForClient) => {
                                    await fetch("/proxy/project_bible_template/projectBibleStyleGuide", {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            "clientName": clientName,
                                            "projectName": projectName,
                                            "projectCode": projectCode
                                        }),
                                    })
                                        .then(res => res.json())
                                        .then(
                                            async (styleGuideresult) => {
                                                await fetch("/proxy/project_bible_template/clientViewGetAdditionalComment", {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({
                                                        "code": code
                                                    })
                                                })
                                                    .then(res => res.json())
                                                    .then(
                                                        async (resultAdditionalComment) => {
                                                            await fetch("/proxy/project_bible_template/styleGuideGetCharacters", {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-Type': 'application/json'
                                                                },
                                                                body: JSON.stringify({
                                                                    "clientName": clientName,
                                                                    "projectName": projectName,
                                                                    "projectCode": projectCode
                                                                }),
                                                            })
                                                                .then(res => res.json())
                                                                .then(
                                                                    async (resultCharacters) => {
                                                                        await fetch("/proxy/project_bible_template/styleGuideGetLanguagesList", {
                                                                            method: 'GET',
                                                                            headers: {
                                                                                'Content-Type': 'application/json'
                                                                            },
                                                                        })
                                                                            .then(res => res.json())
                                                                            .then(
                                                                                async (resultStyleGuideLanguages) => {
                                                                                    await fetch("/proxy/project_bible_template/styleGuideLinguistAll", {
                                                                                        method: 'POST',
                                                                                        headers: {
                                                                                            'Content-Type': 'application/json'
                                                                                        },
                                                                                        body: JSON.stringify({
                                                                                            "clientName": clientName,
                                                                                            "projectName": projectName,
                                                                                            "projectCode": projectCode
                                                                                        }),
                                                                                    })
                                                                                        .then(res => res.json())
                                                                                        .then(
                                                                                            async (resultStyleGuideForLinguists) => {
                                                                                                await fetch("/proxy/project_bible_template/getStyleGuideLinguistAcceptChanges", {
                                                                                                    method: 'POST',
                                                                                                    headers: {
                                                                                                        'Content-Type': 'application/json'
                                                                                                    },
                                                                                                    body: JSON.stringify({
                                                                                                        "clientName": clientName,
                                                                                                        "projectName": projectName,
                                                                                                        "projectCode": projectCode
                                                                                                    }),
                                                                                                })
                                                                                                    .then(res => res.json())
                                                                                                    .then(
                                                                                                        async (resultStyleGuideForLinguistsAcceptedChangesData) => {
                                                                                                            if (resultColumnsForClient.columns.length) {
                                                                                                                cellAllCount = (result[0].columns.length +
                                                                                                                    resultColumnsForClient.columns.length) * result[0].rows.length
                                                                                                                setClientColumnCount(resultColumnsForClient.columns.length)

                                                                                                                if (result[0].submit) {
                                                                                                                    setSubmit(true)
                                                                                                                }

                                                                                                                console.log("resultAdditionalComment", resultAdditionalComment)

                                                                                                                if (resultAdditionalComment.length) {
                                                                                                                    setAdditionalComment(resultAdditionalComment[0].comment)
                                                                                                                }

                                                                                                                setCommunicationBetweenCharacters(styleGuideresult[0]["communication_between_characters"])
                                                                                                                // setProjectManagementInfo(styleGuideresult[0]["project_management_info"])

                                                                                                                console.log("styleGuideresult[0].languages", styleGuideresult[0].languages)

                                                                                                                setLanguages(
                                                                                                                    languages.map(value => {
                                                                                                                        for (let i = 0; i < resultStyleGuideLanguages.languages.length; i++) {
                                                                                                                            value.data.push({
                                                                                                                                title: resultStyleGuideLanguages.languages[i].language,
                                                                                                                                code: resultStyleGuideLanguages.languages[i].language_code,
                                                                                                                                value: styleGuideresult[0].languages.includes(resultStyleGuideLanguages.languages[i].language_code),
                                                                                                                                data: [],
                                                                                                                                accept: {
                                                                                                                                    linguistId: "",
                                                                                                                                    value: []
                                                                                                                                }
                                                                                                                            })

                                                                                                                            if (styleGuideresult[0].languages.includes(resultStyleGuideLanguages.languages[i].language_code)) {
                                                                                                                                setCharacterCommunications(
                                                                                                                                    characterCommunications.map(info => {
                                                                                                                                        /*info.languagesData[resultStyleGuideLanguages.languages[i].language_code] = {
                                                                                                                                            // title: value.title,
                                                                                                                                            data: [],
                                                                                                                                            accept: {
                                                                                                                                                linguistId: "",
                                                                                                                                                value: false
                                                                                                                                            }
                                                                                                                                        }*/

                                                                                                                                        info.languagesData[resultStyleGuideLanguages.languages[i].language_code] = {
                                                                                                                                            // title: value.title,
                                                                                                                                            data: [],
                                                                                                                                            linguistAcceptedData: []
                                                                                                                                        }

                                                                                                                                        return info
                                                                                                                                    })
                                                                                                                                )
                                                                                                                            }
                                                                                                                        }

                                                                                                                        return value
                                                                                                                    })
                                                                                                                )

                                                                                                                for (let i = 0; i < resultCharacters.length; i++) {
                                                                                                                    // console.log("FOR resultCharacters", resultCharacters)
                                                                                                                    let characterId = +resultCharacters[i].id
                                                                                                                    let characterNum = resultCharacters[i].num
                                                                                                                    let characterName = resultCharacters[i].name

                                                                                                                    setCharacterCommunications(
                                                                                                                        characterCommunications.map(info => {
                                                                                                                            info.characters[characterId] = {
                                                                                                                                "id": characterId,
                                                                                                                                "num": characterNum,
                                                                                                                                "name": characterName
                                                                                                                            }

                                                                                                                            info.templateData[characterNum - 1] = {
                                                                                                                                "id": characterId,
                                                                                                                                "name": characterName,
                                                                                                                                "data": []
                                                                                                                            }

                                                                                                                            return info
                                                                                                                        })
                                                                                                                    )

                                                                                                                    /*setCharacterCommunications(
                                                                                                                        characterCommunications.map(info => {
                                                                                                                            info.templateData[i] = {
                                                                                                                                "name": resultCharacters[i].name,
                                                                                                                                "data": []
                                                                                                                            }

                                                                                                                            return info
                                                                                                                        })
                                                                                                                    )*/
                                                                                                                }

                                                                                                                resultStyleGuideForLinguists.map(value => {
                                                                                                                    setLinguistLinks(
                                                                                                                        linguistLinks.map(info => {
                                                                                                                            info.data[value.linguist_id] = {
                                                                                                                                code: value.linguist_id,
                                                                                                                                languages: value.languages,
                                                                                                                                languagesNames: [],
                                                                                                                                submit: value.submit,
                                                                                                                                data: {
                                                                                                                                    languagePreferences: [],
                                                                                                                                    characterCommunications: {
                                                                                                                                        value: styleGuideresult[0]["communication_between_characters"],
                                                                                                                                        characters: [],
                                                                                                                                        baseStyleGuideData: [],
                                                                                                                                        templateData: [],
                                                                                                                                        languagesData: []
                                                                                                                                    }
                                                                                                                                },
                                                                                                                                acceptData: [],
                                                                                                                                bufferedData: []
                                                                                                                            }

                                                                                                                            return info
                                                                                                                        })
                                                                                                                    )

                                                                                                                    return value
                                                                                                                })

                                                                                                                resultStyleGuideForLinguistsAcceptedChangesData.map(value => {
                                                                                                                    setLinguistLinks(
                                                                                                                        linguistLinks.map(info => {
                                                                                                                            let linguistId = value.linguist_id
                                                                                                                            let languageCode = value.language_code
                                                                                                                            let acceptLanguagePreferences = value.accept_language_preferences
                                                                                                                            let acceptCommunicationBetweenCharacters = value.accept_communication_between_characters
                                                                                                                            let bufferedChangedLanguagePreferences = value.buffered_changed_language_preferences
                                                                                                                            let bufferedChangedCommunicationBetweenCharacters = value.buffered_changed_communication_between_characters

                                                                                                                            info.data[linguistId].acceptData[languageCode] = {
                                                                                                                                languagePreferences: {
                                                                                                                                    value: acceptLanguagePreferences
                                                                                                                                },
                                                                                                                                communicationBetweenCharacters: {
                                                                                                                                    value: acceptCommunicationBetweenCharacters
                                                                                                                                }
                                                                                                                            }

                                                                                                                            info.data[linguistId].bufferedData[languageCode] = {
                                                                                                                                languagePreferences: {
                                                                                                                                    value: bufferedChangedLanguagePreferences
                                                                                                                                },
                                                                                                                                communicationBetweenCharacters: {
                                                                                                                                    value: bufferedChangedCommunicationBetweenCharacters
                                                                                                                                }
                                                                                                                            }

                                                                                                                            if (info.data[linguistId].submit) {
                                                                                                                                if (acceptLanguagePreferences) {
                                                                                                                                    setLanguagePreferencesAcceptData(linguistId, languageCode, acceptLanguagePreferences)
                                                                                                                                }

                                                                                                                                /*if (acceptCommunicationBetweenCharacters) {
                                                                                                                                    setCharacterCommunicationsAcceptData(linguistId, languageCode, acceptCommunicationBetweenCharacters)
                                                                                                                                }*/
                                                                                                                            }

                                                                                                                            return info
                                                                                                                        })
                                                                                                                    )

                                                                                                                    return value
                                                                                                                })

                                                                                                                result[0].columns.map(value => {
                                                                                                                    setColumns(
                                                                                                                        columns.map(info => {
                                                                                                                            info.data.push({
                                                                                                                                "code": value.code,
                                                                                                                                "name": value.name,
                                                                                                                                "type": value.type,
                                                                                                                                "editable": value.editable,
                                                                                                                                "template": value.template,
                                                                                                                                "clientColumn": false
                                                                                                                            });

                                                                                                                            return info
                                                                                                                        })
                                                                                                                    )

                                                                                                                    return value
                                                                                                                })

                                                                                                                resultColumnsForClient.columns.map(value => {
                                                                                                                    setColumns(
                                                                                                                        columns.map(info => {
                                                                                                                            info.data.push({
                                                                                                                                "code": value.code,
                                                                                                                                "name": value.name,
                                                                                                                                "type": value.type,
                                                                                                                                "clientColumn": true
                                                                                                                            });

                                                                                                                            return info
                                                                                                                        })
                                                                                                                    )

                                                                                                                    return value
                                                                                                                })

                                                                                                                result[0].rows.map(value => {
                                                                                                                    setRows(
                                                                                                                        rows.map(info => {
                                                                                                                            info.data.push({
                                                                                                                                "code": value.code,
                                                                                                                                "data": []
                                                                                                                            });

                                                                                                                            return info
                                                                                                                        })
                                                                                                                    )

                                                                                                                    return value
                                                                                                                })

                                                                                                                fillDataForLinguistStyleGuide(styleGuideresult, resultCharacters, resultStyleGuideLanguages)

                                                                                                                fillDataForHeaders()
                                                                                                                // fillDataForLanguages()
                                                                                                                // fillCharacterCommunication()

                                                                                                                if (styleGuideresult[0]["communication_between_characters"]) {
                                                                                                                    await fillCharacterCommunication()
                                                                                                                    await fillCharacterCommunicationFromLinguists()
                                                                                                                }
                                                                                                            }
                                                                                                        },
                                                                                                        (error) => {
                                                                                                            setIsLoaded(true);
                                                                                                            setError(error);
                                                                                                        }
                                                                                                    )
                                                                                            },
                                                                                            (error) => {
                                                                                                setIsLoaded(true);
                                                                                                setError(error);
                                                                                            }
                                                                                        )
                                                                                },
                                                                                (error) => {
                                                                                    setIsLoaded(true);
                                                                                    setError({
                                                                                        message: "Не удалось получить данные о языках стайлгайда. Пожалуйста перезагрузите страницу, чтобы запустить процесс снова"
                                                                                    });
                                                                                }
                                                                            )
                                                                    },
                                                                    (error) => {
                                                                        setIsLoaded(true);
                                                                        setError(error);
                                                                    }
                                                                )
                                                        },
                                                        (error) => {
                                                            setIsLoaded(true);
                                                            setError(error);
                                                        }
                                                    )
                                            },
                                            (error) => {
                                                setIsLoaded(true);
                                                setError(error);
                                            }
                                        )
                                },
                                (error) => {
                                    //todo придумать какой-то текст ошибки
                                }
                            )
                    } else {
                        setIsLoaded(true);
                        setError({
                            message: "This page doesn't exist"
                        });
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    function setLanguagePreferencesAcceptData(linguistId, languageCode, value) {
        setLanguages(
            languages.map(info => {
                info.data.map(language => {
                    if (language.code === languageCode) {
                        language.accept.linguistId = linguistId
                        language.accept.value = value
                    }

                    return language
                })

                return info
            })
        )
    }

    /*function setCharacterCommunicationsAcceptData(linguistId, languageCode, value) {
        setCharacterCommunications(
            characterCommunications.map(info => {
                if (languageCode in info.languagesData) {
                    info.languagesData[languageCode].accept.linguistId = linguistId
                    info.languagesData[languageCode].accept.value = value
                }

                return info
            })
        )
    }*/

    function fillDataForLinguistStyleGuide(styleGuideresult, resultCharacters, allStyleGuideLanguages) {
        let styleGuideLanguages = styleGuideresult[0]["languages"]

        console.log("LALALA", linguistLinks)

        setLinguistLinks(
            linguistLinks.map(info => {
                let length = Object.keys(info.data).length

                if (length > 0) {
                    for (let key in info.data) {
                        let value = info.data[key]
                        let linguistId = value.code

                        if (value.submit) {
                            let linguistLanguages = value.languages
                            let languagesAllCount = countCorrectLinguistLanguages(linguistLanguages, styleGuideLanguages, allStyleGuideLanguages)

                            linguistLanguagesDataCount[linguistId] = {
                                languagePreferences: {
                                    load: false,
                                    onCountLanguage: 0,
                                    languagesAllCount: languagesAllCount
                                },
                                characterCommunications: {
                                    load: false,
                                    loadCharacterCommunication: false,
                                    loadLinguistCharacterCommunication: false,
                                    charactersDataCount: 0,
                                    linguistCharactersDataCount: 0
                                }
                            }

                            for (let i = 0; i < resultCharacters.length; i++) {
                                value.data.characterCommunications.characters[i] = {
                                    "name": resultCharacters[i].name
                                }
                            }

                            for (let i = 0; i < allStyleGuideLanguages.languages.length; i++) {
                                let languageCode = allStyleGuideLanguages.languages[i].language_code
                                let languageName = allStyleGuideLanguages.languages[i].language

                                if (styleGuideLanguages.includes(languageCode) &&
                                    linguistLanguages.includes(languageCode)) {
                                    value.languagesNames.push({
                                        code: languageCode,
                                        name: languageName
                                    })

                                    value.data.languagePreferences.push({
                                        title: allStyleGuideLanguages.languages[i].language,
                                        code: languageCode,
                                        data: []
                                    })

                                    value.data.characterCommunications.templateData[languageCode] = {
                                        data: []
                                    }

                                    value.data.characterCommunications.languagesData[languageCode] = {
                                        data: []
                                    }

                                    fillLanguageDataForLinguistStyleGuide(linguistId, languageCode, length)

                                    if (styleGuideresult[0]["communication_between_characters"]) {
                                        fillCharacterCommunicationDataForLinguistStyleGuide(linguistId, length)
                                    } else {
                                        linguistLanguagesDataCount[linguistId].characterCommunications.load = true

                                        if (linguistLanguagesDataCount[linguistId].languagePreferences.load) {
                                            linguistDataCount++

                                            //todo
                                            if (linguistDataCount === length) {
                                                setIsLinguistDataLoaded(true);

                                                fillDataForLanguages()
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            linguistDataCount++
                        }

                        if (linguistDataCount === length) {
                            setIsLinguistDataLoaded(true)

                            fillDataForLanguages()
                        }
                    }
                } else {
                    setIsLinguistDataLoaded(true)

                    fillDataForLanguages()
                }

                return info
            })
        )
    }

    function countCorrectLinguistLanguages(linguistLanguages, styleGuideLanguages, allStyleGuideLanguages) {
        let count = 0

        for (let i = 0; i < allStyleGuideLanguages.languages.length; i++) {
            let languageCode = allStyleGuideLanguages.languages[i].language_code

            if (styleGuideLanguages.includes(languageCode) &&
                linguistLanguages.includes(languageCode)) {
                count++
            }
        }

        return count
    }

    async function fillLanguageDataForLinguistStyleGuide(linguistId, languageCode, length) {
        await fetch("/proxy/project_bible_template/styleGuideGetLanguagesListByCode", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "code": languageCode,
                "projectManagementInfo": false
            })
        })
            .then(res => res.json())
            .then(
                async (resultLanguageTemplate) => {
                    await fetch("/proxy/project_bible_template/styleGuideGetLanguagesExtraRows", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "clientName": clientName,
                            "projectName": projectName,
                            "projectCode": projectCode,
                            "languageCode": languageCode
                        })
                    })
                        .then(res => res.json())
                        .then(
                            async (resultLanguageExtraRows) => {
                                await fetch("/proxy/project_bible_template/styleGuideLinguistGetLanguagesExtraRows", {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        "clientName": clientName,
                                        "projectName": projectName,
                                        "projectCode": projectCode,
                                        "id": linguistId,
                                        "languageCode": languageCode
                                    })
                                })
                                    .then(res => res.json())
                                    .then(
                                        async (resultLinguistLanguageExtraRows) => {
                                            await fetch("/proxy/project_bible_template/styleGuideGetLanguagesFilledData", {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    "clientName": clientName,
                                                    "projectName": projectName,
                                                    "projectCode": projectCode,
                                                    "languageCode": languageCode
                                                })
                                            })
                                                .then(res => res.json())
                                                .then(
                                                    async (resultLanguageFilledData) => {
                                                        await fetch("/proxy/project_bible_template/styleGuideLinguistGetLanguagesFilledData", {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-Type': 'application/json'
                                                            },
                                                            body: JSON.stringify({
                                                                "clientName": clientName,
                                                                "projectName": projectName,
                                                                "projectCode": projectCode,
                                                                "id": linguistId,
                                                                "languageCode": languageCode
                                                            })
                                                        })
                                                            .then(res => res.json())
                                                            .then(
                                                                async (resultLinguistLanguageFilledData) => {
                                                                    setLinguistLinks(
                                                                        linguistLinks.map(info => {
                                                                            for (let key in info.data) {
                                                                                let value = info.data[key]

                                                                                if (value.code === linguistId) {
                                                                                    for (let i = 0; i < resultLanguageExtraRows.length; i++) {
                                                                                        if (resultLanguageExtraRows[i].block in resultLanguageTemplate[0]) {
                                                                                            let rowToPush = []

                                                                                            for (let j = 0; j < resultLanguageExtraRows[i].columns.length; j++) {
                                                                                                rowToPush.push({
                                                                                                    "code": resultLanguageExtraRows[i].columns[j],
                                                                                                    "text": "",
                                                                                                    "styleGuideEditable": true,
                                                                                                    "linguistEditable": resultLanguageExtraRows[i].linguist_editable
                                                                                                })
                                                                                            }

                                                                                            resultLanguageTemplate[0][resultLanguageExtraRows[i].block].data.push({
                                                                                                "row": rowToPush,
                                                                                                "code": resultLanguageExtraRows[i]["row_code"],
                                                                                                "colspan": resultLanguageExtraRows[i].colspan
                                                                                            })
                                                                                        }
                                                                                    }

                                                                                    for (let i = 0; i < resultLinguistLanguageExtraRows.length; i++) {
                                                                                        let rowToPush = []

                                                                                        for (let j = 0; j < resultLinguistLanguageExtraRows[i].columns.length; j++) {
                                                                                            rowToPush.push({
                                                                                                "code": resultLinguistLanguageExtraRows[i].columns[j],
                                                                                                "text": "",
                                                                                                "styleGuideEditable": true,
                                                                                                "linguistEditable": resultLinguistLanguageExtraRows[i].linguist_editable
                                                                                            })
                                                                                        }

                                                                                        resultLanguageTemplate[0][resultLinguistLanguageExtraRows[i].block].data.push({
                                                                                            "row": rowToPush,
                                                                                            "code": resultLinguistLanguageExtraRows[i]["row_code"],
                                                                                            "colspan": resultLinguistLanguageExtraRows[i].colspan,
                                                                                            "linguistExtraRow": true
                                                                                        })
                                                                                    }

                                                                                    for (let i = 0; i < resultLanguageFilledData.length; i++) {
                                                                                        if (resultLanguageFilledData[i].block in  resultLanguageTemplate[0]) {
                                                                                            let elData = resultLanguageTemplate[0][resultLanguageFilledData[i].block].data

                                                                                            for (let j = 0; j < elData.length; j++) {
                                                                                                if (elData[j].code === resultLanguageFilledData[i].row_code) {
                                                                                                    for (let k = 0; k < elData[j].row.length; k++) {
                                                                                                        if (elData[j].row[k].code === resultLanguageFilledData[i].col_code) {
                                                                                                            elData[j].row[k].text = resultLanguageFilledData[i].value
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }

                                                                                            resultLanguageTemplate[0][resultLanguageFilledData[i].block].data = elData
                                                                                        }
                                                                                    }

                                                                                    for (let i = 0; i < resultLinguistLanguageFilledData.length; i++) {
                                                                                        let elData = resultLanguageTemplate[0][resultLinguistLanguageFilledData[i].block].data

                                                                                        for (let j = 0; j < elData.length; j++) {
                                                                                            if (elData[j].code === resultLinguistLanguageFilledData[i].row_code) {
                                                                                                for (let k = 0; k < elData[j].row.length; k++) {
                                                                                                    if (elData[j].row[k].code === resultLinguistLanguageFilledData[i].col_code) {
                                                                                                        elData[j].row[k].text = resultLinguistLanguageFilledData[i].value
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        resultLanguageTemplate[0][resultLinguistLanguageFilledData[i].block].data = elData
                                                                                    }

                                                                                    value.data.languagePreferences.map(languagePreferencesLanguage => {
                                                                                        if (languagePreferencesLanguage.code === languageCode) {
                                                                                            languagePreferencesLanguage.data = resultLanguageTemplate
                                                                                        }

                                                                                        return languagePreferencesLanguage
                                                                                    })

                                                                                    linguistLanguagesDataCount[linguistId].languagePreferences.onCountLanguage = linguistLanguagesDataCount[linguistId].languagePreferences.onCountLanguage + 1

                                                                                    if (linguistLanguagesDataCount[linguistId].languagePreferences.onCountLanguage === linguistLanguagesDataCount[linguistId].languagePreferences.languagesAllCount) {
                                                                                        linguistLanguagesDataCount[linguistId].languagePreferences.load = true

                                                                                        if (linguistLanguagesDataCount[linguistId].characterCommunications.load) {
                                                                                            linguistDataCount++

                                                                                            //todo
                                                                                            if (linguistDataCount === length) {
                                                                                                setIsLinguistDataLoaded(true);

                                                                                                fillDataForLanguages()
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            return info
                                                                        })
                                                                    )
                                                                },
                                                                (error) => {
                                                                    setIsLinguistDataLoaded(true);
                                                                    setError(error);
                                                                }
                                                            )
                                                    },
                                                    (error) => {
                                                        setIsLinguistDataLoaded(true);
                                                        setError(error);
                                                    }
                                                )
                                        },
                                        (error) => {
                                            setIsLinguistDataLoaded(true);
                                            setError(error);
                                        }
                                    )
                            },
                            (error) => {
                                setIsLinguistDataLoaded(true);
                                setError(error);
                            }
                        )
                },
                (error) => {
                    setIsLinguistDataLoaded(true);
                    setError(error);
                }
            )
    }

    async function fillCharacterCommunicationDataForLinguistStyleGuide(linguistId, length) {
        await fetch("/proxy/project_bible_template/styleGuideGetCharactersData", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    await fetch("/proxy/project_bible_template/styleGuideLinguistGetCharactersData", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "clientName": clientName,
                            "projectName": projectName,
                            "projectCode": projectCode,
                            "linguistId": linguistId
                        })
                    })
                        .then(res => res.json())
                        .then(
                            async (resultLinguist) => {
                                setLinguistLinks(
                                    linguistLinks.map(info => {
                                        for (let key in info.data) {
                                            let value = info.data[key]

                                            if (value.code === linguistId) {
                                                if (result.length) {
                                                    for (let i = 0; i < result.length; i++) {
                                                        if (result[i].language_code !== 0) {
                                                            if (result[i].language_code in value.data.characterCommunications.templateData) {
                                                                if (!(result[i].character_first - 1 in value.data.characterCommunications.templateData[result[i].language_code].data)) {
                                                                    value.data.characterCommunications.templateData[result[i].language_code].data[result[i].character_first - 1] = []
                                                                }

                                                                value.data.characterCommunications.templateData[result[i].language_code].data[result[i].character_first - 1][result[i].character_second - 1] = result[i].value
                                                            }
                                                        } else {
                                                            if (!(result[i].character_first - 1 in value.data.characterCommunications.baseStyleGuideData)) {
                                                                value.data.characterCommunications.baseStyleGuideData[result[i].character_first - 1] = []
                                                            }

                                                            value.data.characterCommunications.baseStyleGuideData[result[i].character_first - 1][result[i].character_second - 1] = result[i].value
                                                        }

                                                        linguistLanguagesDataCount[linguistId].characterCommunications.charactersDataCount = linguistLanguagesDataCount[linguistId].characterCommunications.charactersDataCount + 1

                                                        if (linguistLanguagesDataCount[linguistId].characterCommunications.charactersDataCount === result.length) {
                                                            linguistLanguagesDataCount[linguistId].characterCommunications.loadCharacterCommunication = true

                                                            if (linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication) {
                                                                linguistLanguagesDataCount[linguistId].characterCommunications.load = true

                                                                if (linguistLanguagesDataCount[linguistId].languagePreferences.load) {
                                                                    linguistDataCount++

                                                                    //todo
                                                                    if (linguistDataCount === length) {
                                                                        setIsLinguistDataLoaded(true);

                                                                        fillDataForLanguages()
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    linguistLanguagesDataCount[linguistId].characterCommunications.loadCharacterCommunication = true

                                                    if (linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication) {
                                                        linguistLanguagesDataCount[linguistId].characterCommunications.load = true

                                                        if (linguistLanguagesDataCount[linguistId].languagePreferences.load) {
                                                            linguistDataCount++

                                                            //todo
                                                            if (linguistDataCount === length) {
                                                                setIsLinguistDataLoaded(true);

                                                                fillDataForLanguages()
                                                            }
                                                        }
                                                    }
                                                }

                                                if (resultLinguist.length) {
                                                    for (let i = 0; i < resultLinguist.length; i++) {
                                                        if (resultLinguist[i].language_code !== 0) {
                                                            if (resultLinguist[i].language_code in value.data.characterCommunications.languagesData) {
                                                                if (!(resultLinguist[i].character_first - 1 in value.data.characterCommunications.languagesData[resultLinguist[i].language_code].data)) {
                                                                    value.data.characterCommunications.languagesData[resultLinguist[i].language_code].data[resultLinguist[i].character_first - 1] = []
                                                                }

                                                                value.data.characterCommunications.languagesData[resultLinguist[i].language_code].data[resultLinguist[i].character_first - 1][resultLinguist[i].character_second - 1] = resultLinguist[i].value
                                                            }
                                                        }

                                                        linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication = linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication + 1

                                                        if (linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication === resultLinguist.length) {
                                                            linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication = true

                                                            if (linguistLanguagesDataCount[linguistId].characterCommunications.loadCharacterCommunication) {
                                                                linguistLanguagesDataCount[linguistId].characterCommunications.load = true

                                                                if (linguistLanguagesDataCount[linguistId].languagePreferences.load) {
                                                                    linguistDataCount++

                                                                    //todo
                                                                    if (linguistDataCount === length) {
                                                                        setIsLinguistDataLoaded(true);

                                                                        fillDataForLanguages()
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    linguistLanguagesDataCount[linguistId].characterCommunications.loadLinguistCharacterCommunication = true

                                                    if (linguistLanguagesDataCount[linguistId].characterCommunications.loadCharacterCommunication) {
                                                        linguistLanguagesDataCount[linguistId].characterCommunications.load = true

                                                        if (linguistLanguagesDataCount[linguistId].languagePreferences.load) {
                                                            linguistDataCount++

                                                            //todo
                                                            if (linguistDataCount === length) {
                                                                setIsLinguistDataLoaded(true);

                                                                fillDataForLanguages()
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        return info
                                    })
                                )
                            },
                            (error) => {
                                setIsLinguistDataLoaded(true)
                                setError(error);
                            }
                        )
                },
                (error) => {
                    setIsLinguistDataLoaded(true)
                    setError(error);
                }
            )
    }

    function fillDataForHeaders() {
        setRows(rows.map(rowsData => {
            rowsData.data.map(async row => {
                columns.map(columnsData => {
                    columnsData.data.map(async column => {
                        if (column.clientColumn) {
                            let queryLinkClient = '/proxy/project_bible_template/'

                            if (column.type === "input") {
                                queryLinkClient += 'projectBibleClientViewFilledCellTextByName'
                            } else if (column.type === "checkbox") {
                                queryLinkClient += 'projectBibleClientViewFilledCellBoolByName'
                            }

                            await fetch(queryLinkClient, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "code": code,
                                    "rowCode": row.code,
                                    "colCode": column.code,
                                })
                            })
                                .then(res => res.json())
                                .then(
                                    async (result) => {
                                        if (result.length) {
                                            row.data[column.code] = result[0].value
                                        } else {
                                            if (column.type === "input") {
                                                row.data[column.code] = ""
                                            } else if (column.type === "checkbox") {
                                                row.data[column.code] = false
                                            }
                                        }

                                        cellOnCount++
                                        console.log("cellOnCount, cellAllCount", cellOnCount, cellAllCount)
                                        setProgressPercent(Math.round(cellOnCount / cellAllCount * 100))

                                        if (cellOnCount === cellAllCount && !error) {
                                            setIsLoaded(true);
                                        }
                                    },
                                    (error) => {
                                        setIsLoaded(true);
                                        setError(error);
                                    }
                                )
                        } else {
                            let queryLinkTemplate = '/proxy/project_bible_template/'
                            let queryLinkEditable = '/proxy/project_bible_template/'

                            if (column.type === "input") {
                                queryLinkTemplate += 'projectBibleTemplateTextByNameIfExist'
                                queryLinkEditable += 'projectBibleFilledCellTextByName'
                            } else if (column.type === "checkbox") {
                                queryLinkTemplate += 'projectBibleTemplateBoolByNameIfExist'
                                queryLinkEditable += 'projectBibleFilledCellBoolByName'
                            } else if (column.type === "tags_list") {
                                queryLinkTemplate += 'projectBibleTemplateTagJsonByNameIfExist'
                                queryLinkEditable += 'projectBibleFilledCellBoolByName'
                            }

                            if (column.template && column.editable) {
                                await fetch(queryLinkTemplate, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        "rowCode": row.code,
                                        "colCode": column.code,
                                    })
                                })
                                    .then(res => res.json())
                                    .then(
                                        async (resultTemplate) => {
                                            await fetch(queryLinkEditable, {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    "clientName": clientName,
                                                    "projectName": projectName,
                                                    "projectCode": projectCode,
                                                    "rowCode": row.code,
                                                    "colCode": column.code,
                                                })
                                            })
                                                .then(res => res.json())
                                                .then(
                                                    async (resultEditable) => {
                                                        if (resultEditable.length) {
                                                            row.data[column.code] = resultEditable[0].value
                                                        } else {
                                                            if (resultTemplate.length) {
                                                                row.data[column.code] = resultTemplate[0].value
                                                            } else if (column.type === "input") {
                                                                row.data[column.code] = ""
                                                            } else if (column.type === "checkbox") {
                                                                row.data[column.code] = false
                                                            }
                                                        }

                                                        cellOnCount++
                                                        console.log("cellOnCount, cellAllCount", cellOnCount, cellAllCount)
                                                        setProgressPercent(Math.round(cellOnCount / cellAllCount * 100))

                                                        if (cellOnCount === cellAllCount && !error) {
                                                            setIsLoaded(true);
                                                        }
                                                    },
                                                    (error) => {
                                                        setIsLoaded(true);
                                                        setError(error);
                                                    }
                                                )
                                        },
                                        (error) => {
                                            setIsLoaded(true);
                                            setError(error);
                                        }
                                    )
                            } else if (column.template) {
                                await fetch(queryLinkTemplate, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        "rowCode": row.code,
                                        "colCode": column.code,
                                    })
                                })
                                    .then(res => res.json())
                                    .then(
                                        async (result) => {
                                            if (result.length) {
                                                row.data[column.code] = result[0].value
                                            } else if (column.type === "input") {
                                                row.data[column.code] = ""
                                            } else if (column.type === "checkbox") {
                                                row.data[column.code] = false
                                            } else if (column.type === "tags_list") {
                                                row.data[column.code] = []
                                            }

                                            cellOnCount++
                                            console.log("cellOnCount, cellAllCount", cellOnCount, cellAllCount)
                                            setProgressPercent(Math.round(cellOnCount / cellAllCount * 100))

                                            if (cellOnCount === cellAllCount && !error) {
                                                setIsLoaded(true);
                                            }
                                        },
                                        (error) => {
                                            setIsLoaded(true);
                                            setError(error);
                                        }
                                    )
                            } else if (column.editable) {
                                await fetch(queryLinkEditable, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        "clientName": clientName,
                                        "projectName": projectName,
                                        "projectCode": projectCode,
                                        "rowCode": row.code,
                                        "colCode": column.code,
                                    })
                                })
                                    .then(res => res.json())
                                    .then(
                                        async (result) => {
                                            if (result.length) {
                                                row.data[column.code] = result[0].value
                                            } else {
                                                if (column.type === "input") {
                                                    row.data[column.code] = ""
                                                } else if (column.type === "checkbox") {
                                                    row.data[column.code] = false
                                                } else if (column.type === "tags_list") {
                                                    row.data[column.code] = []
                                                }
                                            }

                                            cellOnCount++
                                            console.log("cellOnCount, cellAllCount", cellOnCount, cellAllCount)
                                            setProgressPercent(Math.round(cellOnCount / cellAllCount * 100))

                                            if (cellOnCount === cellAllCount && !error) {
                                                setIsLoaded(true);
                                            }
                                        },
                                        (error) => {
                                            setIsLoaded(true);
                                            setError(error);
                                        }
                                    )
                            }
                        }
                    })

                    return columnsData
                })
            })

            return rowsData
        }))
    }

    async function fillDataForLanguages() {
        setLanguages(
            languages.map(info => {
                info.data.map(async language => {
                    if (language.value) {
                        await fetch("/proxy/project_bible_template/styleGuideGetLanguagesListByCode", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "code": language.code,
                                "projectManagementInfo": false
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultLanguageTemplate) => {
                                    await fetch("/proxy/project_bible_template/styleGuideGetLanguagesExtraRows", {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            "clientName": clientName,
                                            "projectName": projectName,
                                            "projectCode": projectCode,
                                            "languageCode": language.code
                                        })
                                    })
                                        .then(res => res.json())
                                        .then(
                                            async (resultLanguageExtraRows) => {
                                                await fetch("/proxy/project_bible_template/styleGuideGetLanguagesFilledData", {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({
                                                        "clientName": clientName,
                                                        "projectName": projectName,
                                                        "projectCode": projectCode,
                                                        "languageCode": language.code
                                                    })
                                                })
                                                    .then(res => res.json())
                                                    .then(
                                                        async (resultLanguageFilledData) => {
                                                            let languageAcceptData = language.accept
                                                            let acceptData = {}

                                                            language.data = fillResultLanguageTemplateWithOtherData(language.code, resultLanguageTemplate, resultLanguageExtraRows, resultLanguageFilledData, languageAcceptData)

                                                            /*for (let i = 0; i < resultLanguageExtraRows.length; i++) {
                                                                let rowToPush = []

                                                                for (let j = 0; j < resultLanguageExtraRows[i].columns.length; j++) {
                                                                    rowToPush.push({
                                                                        "code": resultLanguageExtraRows[i].columns[j],
                                                                        "text": "",
                                                                        "styleGuideEditable": true
                                                                    })
                                                                }

                                                                resultLanguageTemplate[0][resultLanguageExtraRows[i].block].data.push({
                                                                    "row": rowToPush,
                                                                    "code": resultLanguageExtraRows[i]["row_code"],
                                                                    "colspan": resultLanguageExtraRows[i].colspan,
                                                                    "extraRow": true
                                                                })
                                                            }

                                                            for (let i = 0; i < resultLanguageFilledData.length; i++) {
                                                                let elData = resultLanguageTemplate[0][resultLanguageFilledData[i].block].data

                                                                for (let j = 0; j < elData.length; j++) {
                                                                    if (elData[j].code === resultLanguageFilledData[i].row_code) {
                                                                        for (let k = 0; k < elData[j].row.length; k++) {
                                                                            if (elData[j].row[k].code === resultLanguageFilledData[i].col_code) {
                                                                                elData[j].row[k].text = resultLanguageFilledData[i].value
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                resultLanguageTemplate[0][resultLanguageFilledData[i].block].data = elData
                                                            }

                                                            if (Object.keys(languageAcceptData.value).length > 0) {
                                                                linguistLinks[0].data[languageAcceptData.linguistId].data.languagePreferences.map(infoLinguistLinks => {
                                                                    if (infoLinguistLinks.code === language.code) {
                                                                        resultLanguageTemplate.map(languageDataInfo => {
                                                                            for (let blockName in languageAcceptData.value) {
                                                                                languageDataInfo[blockName].data.map(languageBlockData => {
                                                                                    let rowCode = languageBlockData.code

                                                                                    if (languageAcceptData.value[blockName].default.includes(rowCode)) {
                                                                                        infoLinguistLinks.data[0][blockName].data.map(infoLinguistLinkKeyBlock => {
                                                                                            if (infoLinguistLinkKeyBlock.code === rowCode) {
                                                                                                if (!(blockName in acceptData)) {
                                                                                                    acceptData[blockName] = {
                                                                                                        "default": [],
                                                                                                        "extra": []
                                                                                                    }
                                                                                                }

                                                                                                acceptData[blockName].default[rowCode] = infoLinguistLinkKeyBlock.row
                                                                                            }

                                                                                            return infoLinguistLinkKeyBlock
                                                                                        })
                                                                                    }

                                                                                    return languageBlockData
                                                                                })

                                                                                for (let i = 0; i < languageAcceptData.value[blockName].extra.length; i++) {
                                                                                    infoLinguistLinks.data[0][blockName].data.map(infoLinguistLinkKeyBlock => {
                                                                                        if (infoLinguistLinkKeyBlock.code === languageAcceptData.value[blockName].extra[i]) {
                                                                                            if (!(blockName in acceptData)) {
                                                                                                acceptData[blockName] = {
                                                                                                    "default": [],
                                                                                                    "extra": []
                                                                                                }
                                                                                            }

                                                                                            acceptData[blockName].extra.push(infoLinguistLinkKeyBlock)
                                                                                        }

                                                                                        return infoLinguistLinkKeyBlock
                                                                                    })
                                                                                }
                                                                            }

                                                                            return languageDataInfo
                                                                        })
                                                                    }

                                                                    return infoLinguistLinks
                                                                })
                                                            }

                                                            language.data = resultLanguageTemplate*/

                                                            languageCount++

                                                            if (languageCount === languages[0].data.length) {
                                                                setIsLanguageLoaded(true);
                                                            }
                                                        },
                                                        (error) => {
                                                            setIsLanguageLoaded(true);
                                                            setError(error);
                                                        }
                                                    )
                                            },
                                            (error) => {
                                                setIsLanguageLoaded(true);
                                                setError(error);
                                            }
                                        )
                                },
                                (error) => {
                                    setIsLanguageLoaded(true);
                                    setError(error);
                                }
                            )
                    } else {
                        languageCount++

                        if (languageCount === languages[0].data.length) {
                            setIsLanguageLoaded(true);
                        }
                    }

                    return language
                })

                return info
            })
        )
    }

    function fillResultLanguageTemplateWithOtherData(languageCode, data, extraRowsData, filledData, languageAcceptData) {
        console.log("fillResultLanguageTemplateWithOtherData LANGUAGE", languageCode, data, extraRowsData, filledData, languageAcceptData)
        let acceptData = {}

        for (let i = 0; i < extraRowsData.length; i++) {
            let rowToPush = []

            for (let j = 0; j < extraRowsData[i].columns.length; j++) {
                rowToPush.push({
                    "code": extraRowsData[i].columns[j],
                    "text": "",
                    "styleGuideEditable": true
                })
            }

            if (extraRowsData[i].block in data[0]) {
                data[0][extraRowsData[i].block].data.push({
                    "row": rowToPush,
                    "code": extraRowsData[i]["row_code"],
                    "colspan": extraRowsData[i].colspan,
                    "extraRow": true
                })
            }
        }

        for (let i = 0; i < filledData.length; i++) {
            if (filledData[i].block in data[0]) {
                let elData = data[0][filledData[i].block].data

                for (let j = 0; j < elData.length; j++) {
                    if (elData[j].code === filledData[i].row_code) {
                        for (let k = 0; k < elData[j].row.length; k++) {
                            if (elData[j].row[k].code === filledData[i].col_code) {
                                elData[j].row[k].text = filledData[i].value
                            }
                        }
                    }
                }

                data[0][filledData[i].block].data = elData
            }
        }

        if (Object.keys(languageAcceptData.value).length > 0) {
            linguistLinks[0].data[languageAcceptData.linguistId].data.languagePreferences.map(infoLinguistLinks => {
                if (infoLinguistLinks.code === languageCode) {
                    console.log(languageCode, infoLinguistLinks, data)

                    data.map(languageDataInfo => {
                        console.log(languageCode, languageDataInfo)

                        for (let blockName in languageAcceptData.value) {
                            languageDataInfo[blockName].data.map(languageBlockData => {
                                let rowCode = languageBlockData.code

                                if (languageAcceptData.value[blockName].default.includes(rowCode)) {
                                    infoLinguistLinks.data[0][blockName].data.map(infoLinguistLinkKeyBlock => {
                                        if (infoLinguistLinkKeyBlock.code === rowCode) {
                                            if (!(blockName in acceptData)) {
                                                acceptData[blockName] = {
                                                    "default": [],
                                                    "extra": []
                                                }
                                            }

                                            acceptData[blockName].default[rowCode] = infoLinguistLinkKeyBlock.row

                                            languageBlockData.row = infoLinguistLinkKeyBlock.row
                                        }

                                        return infoLinguistLinkKeyBlock
                                    })
                                }

                                return languageBlockData
                            })

                            for (let i = 0; i < languageAcceptData.value[blockName].extra.length; i++) {
                                infoLinguistLinks.data[0][blockName].data.map(infoLinguistLinkKeyBlock => {
                                    if (infoLinguistLinkKeyBlock.code === languageAcceptData.value[blockName].extra[i]) {
                                        if (!(blockName in acceptData)) {
                                            acceptData[blockName] = {
                                                "default": [],
                                                "extra": []
                                            }
                                        }

                                        acceptData[blockName].extra.push(infoLinguistLinkKeyBlock)

                                        languageDataInfo[blockName].data.push(infoLinguistLinkKeyBlock)
                                    }

                                    return infoLinguistLinkKeyBlock
                                })
                            }
                        }

                        return languageDataInfo
                    })
                }

                return infoLinguistLinks
            })
        }

        console.log("fillResultLanguageTemplateWithOtherData DATA", languageCode, data, acceptData)

        return data
    }

    /*function fillDataForLanguages() {
        console.log("fillDataForLanguages", languages)

        setLanguages(
            languages.map(info => {
                info.data.map(async language => {
                    if (language.value) {
                        await fetch("/proxy/project_bible_template/styleGuideGetLanguagesListByCode", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "code": language.code
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (resultLanguageTemplate) => {
                                    await fetch("/proxy/project_bible_template/styleGuideGetLanguagesExtraRows", {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            "clientName": clientName,
                                            "projectName": projectName,
                                            "projectCode": projectCode,
                                            "languageCode": language.code
                                        })
                                    })
                                        .then(res => res.json())
                                        .then(
                                            async (resultLanguageExtraRows) => {
                                                await fetch("/proxy/project_bible_template/styleGuideGetLanguagesFilledData", {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({
                                                        "clientName": clientName,
                                                        "projectName": projectName,
                                                        "projectCode": projectCode,
                                                        "languageCode": language.code
                                                    })
                                                })
                                                    .then(res => res.json())
                                                    .then(
                                                        async (resultLanguageFilledData) => {
                                                            await fetch("/proxy/project_bible_template/clientViewGetLanguagesFilledData", {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-Type': 'application/json'
                                                                },
                                                                body: JSON.stringify({
                                                                    "code": code,
                                                                    "languageCode": language.code
                                                                })
                                                            })
                                                                .then(res => res.json())
                                                                .then(
                                                                    async (resultClientViewLanguageFilledData) => {
                                                                        console.log("fillDataForLanguages", resultLanguageTemplate, resultLanguageFilledData)

                                                                        for (let i = 0; i < resultLanguageExtraRows.length; i++) {
                                                                            let rowToPush = []

                                                                            for (let j = 0; j < resultLanguageExtraRows[i].columns.length; j++) {
                                                                                rowToPush.push({
                                                                                    "code": resultLanguageExtraRows[i].columns[j],
                                                                                    "text": "",
                                                                                    "styleGuideEditable": true
                                                                                })
                                                                            }

                                                                            resultLanguageTemplate[0][resultLanguageExtraRows[i].block].data.push({
                                                                                "row": rowToPush,
                                                                                "code": resultLanguageExtraRows[i]["row_code"],
                                                                                "colspan": resultLanguageExtraRows[i].colspan
                                                                            })
                                                                        }

                                                                        for (let i = 0; i < resultLanguageFilledData.length; i++) {
                                                                            let elData = resultLanguageTemplate[0][resultLanguageFilledData[i].block].data

                                                                            for (let j = 0; j < elData.length; j++) {
                                                                                if (elData[j].code === resultLanguageFilledData[i].row_code) {
                                                                                    for (let k = 0; k < elData[j].row.length; k++) {
                                                                                        if (elData[j].row[k].code === resultLanguageFilledData[i].col_code) {
                                                                                            elData[j].row[k].text = resultLanguageFilledData[i].value
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            resultLanguageTemplate[0][resultLanguageFilledData[i].block].data = elData
                                                                        }

                                                                        for (let block in resultLanguageTemplate[0]) {
                                                                            if (resultLanguageTemplate[0][block].clientEditable) {
                                                                                for (let i = 0; i < resultLanguageTemplate[0][block].data.length; i++) {
                                                                                    resultLanguageTemplate[0][block].data[i].row.push({
                                                                                        "code": "comment",
                                                                                        "text": ""
                                                                                    })
                                                                                }
                                                                            }
                                                                        }

                                                                        for (let i = 0; i < resultClientViewLanguageFilledData.length; i++) {
                                                                            let elData = resultLanguageTemplate[0][resultClientViewLanguageFilledData[i].block].data

                                                                            for (let j = 0; j < elData.length; j++) {
                                                                                if (elData[j].code === resultClientViewLanguageFilledData[i].row_code) {
                                                                                    for (let k = 0; k < elData[j].row.length; k++) {
                                                                                        if (elData[j].row[k].code === "comment") {
                                                                                            elData[j].row[k].text = resultClientViewLanguageFilledData[i].value
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            resultLanguageTemplate[0][resultClientViewLanguageFilledData[i].block].data = elData
                                                                        }

                                                                        // console.log("resultLanguageTemplate", resultLanguageTemplate)
                                                                        // console.log("resultLanguageExtraRows", resultLanguageExtraRows)

                                                                        language.data = resultLanguageTemplate

                                                                        // console.log("language", language)
                                                                        // console.log("Languages", languages)

                                                                        languageCount++

                                                                        if (languageCount === languages[0].data.length) {
                                                                            setIsLanguageLoaded(true);
                                                                        }
                                                                    },
                                                                    (error) => {
                                                                        setIsLanguageLoaded(true);
                                                                        setError(error);
                                                                    }
                                                                )
                                                        },
                                                        (error) => {
                                                            setIsLanguageLoaded(true);
                                                            setError(error);
                                                        }
                                                    )
                                            },
                                            (error) => {
                                                setIsLanguageLoaded(true);
                                                setError(error);
                                            }
                                        )
                                },
                                (error) => {
                                    setIsLanguageLoaded(true);
                                    setError(error);
                                }
                            )
                    } else {
                        languageCount++

                        if (languageCount === languages[0].data.length) {
                            setIsLanguageLoaded(true);
                        }
                    }

                    return language
                })

                return info
            })
        )
    }*/

    async function fillCharacterCommunication() {
        await fetch("/proxy/project_bible_template/styleGuideGetCharactersData", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    if (result.length) {
                        for (let i = 0; i < result.length; i++) {
                            let languageCode = result[i].language_code
                            let firstCharacter = result[i].character_first
                            let secondCharacter = result[i].character_second
                            let firstCharacterSequence = getCharacterSequence(firstCharacter) - 1
                            let secondCharacterSequence = getCharacterSequence(secondCharacter) - 1

                            setCharacterCommunications(
                                characterCommunications.map(info => {
                                    if (languageCode === 0) {
                                        info.templateData[firstCharacterSequence].data[secondCharacterSequence] = result[i].value
                                    } else {
                                        if (languageCode in info.languagesData) {
                                            if (!(firstCharacterSequence in info.languagesData[languageCode].data)) {
                                                info.languagesData[languageCode].data[firstCharacterSequence] = []
                                            }

                                            info.languagesData[languageCode].data[firstCharacterSequence][secondCharacterSequence] = result[i].value
                                        }
                                    }

                                    return info
                                })
                            )

                            charactersDataCount++

                            if (charactersDataCount === result.length) {
                                setIsCharacterCommunicationLoaded(true);
                            }
                        }
                    } else {
                        setIsCharacterCommunicationLoaded(true);
                    }

                    /*if (result.length) {
                        console.log("fillCharacterCommunication", characterCommunications, result)

                        for (let i = 0; i < result.length; i++) {
                            setCharacterCommunications(
                                characterCommunications.map(info => {
                                    if (result[i].language_code === 0) {
                                        info.templateData[result[i].character_first - 1].data[result[i].character_second - 1] = result[i].value

                                        /!*if (result[i].character_first - 1 in info.templateData.data) {
                                            if (result[i].character_second - 1 in info.templateData.data[result[i].character_first - 1]) {
                                                info.templateData.data[result[i].character_first - 1][result[i].character_second - 1] = result[i].value
                                            } else {
                                                info.templateData.data[result[i].character_first - 1][result[i].character_second - 1] = []
                                            }
                                        } else {
                                            info.templateData.data[result[i].character_first - 1] = []
                                        }*!/
                                    } else {
                                        if (result[i].language_code in info.languagesData) {
                                            let languageCodeValue = result[i].language_code

                                            if (!info.languagesData[languageCodeValue].accept.value) {
                                                if (!(result[i].character_first - 1 in info.languagesData[languageCodeValue].data)) {
                                                    info.languagesData[languageCodeValue].data[result[i].character_first - 1] = []
                                                }

                                                info.languagesData[languageCodeValue].data[result[i].character_first - 1][result[i].character_second - 1] = result[i].value
                                            }

                                            /!*if (info.languagesData[languageCodeValue].accept.value) {
                                                let linguistId = info.languagesData[languageCodeValue].accept.linguistId

                                                info.languagesData[languageCodeValue].data = linguistLinks[0].data[linguistId].data.characterCommunications.languagesData[languageCodeValue].data
                                            } else {
                                                if (!(result[i].character_first - 1 in info.languagesData[languageCodeValue].data)) {
                                                    info.languagesData[languageCodeValue].data[result[i].character_first - 1] = []
                                                }

                                                info.languagesData[languageCodeValue].data[result[i].character_first - 1][result[i].character_second - 1] = result[i].value
                                            }*!/
                                        }
                                    }

                                    return info
                                })
                            )

                            charactersDataCount++

                            if (charactersDataCount === result.length) {
                                setIsCharacterCommunicationLoaded(true);
                            }
                        }

                        setCharacterCommunications(
                            characterCommunications.map(info => {
                                for (let key in info.languagesData) {
                                    let value = info.languagesData[key]

                                    if (value.accept.value) {
                                        let linguistId = value.accept.linguistId

                                        value.data = linguistLinks[0].data[linguistId].data.characterCommunications.languagesData[key].data
                                    }
                                }

                                return info
                            })
                        )
                    } else {
                        setIsCharacterCommunicationLoaded(true);
                    }*/
                },
                (error) => {
                    setIsCharacterCommunicationLoaded(true);
                    setError(error);
                }
            )
    }

    async function fillCharacterCommunicationFromLinguists() {
        await fetch("/proxy/project_bible_template/styleGuideAllLinguistGetAcceptedCharactersData", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setCharacterCommunications(
                        characterCommunications.map(info => {
                            for (let i = 0; i < result.length; i++) {
                                let languageCode = result[i]["language_code"]
                                let characterFirst = result[i]["character_first"]
                                let characterSecond = result[i]["character_second"]
                                let acceptedValue = result[i]["accepted_value"]
                                let firstCharacterSequence = getCharacterSequence(characterFirst) - 1
                                let secondCharacterSequence = getCharacterSequence(characterSecond) - 1

                                if (languageCode in info.languagesData) {
                                    if (!(firstCharacterSequence in info.languagesData[languageCode].linguistAcceptedData)) {
                                        info.languagesData[languageCode].linguistAcceptedData[firstCharacterSequence] = []
                                    }

                                    info.languagesData[languageCode].linguistAcceptedData[firstCharacterSequence][secondCharacterSequence] = {
                                        value: acceptedValue
                                    }
                                }
                            }

                            return info
                        })
                    )
                },
                (error) => {
                    setError(error);
                }
            )
    }

    function getCharacterSequence(id) {
        let num = 0

        characterCommunications.map(info => {
            num = info.characters[id].num

            return info
        })

        return num
    }

    /*async function fillCharacterCommunication() {
        console.log("fillCharacterCommunication", characterCommunications)

        await fetch("/proxy/project_bible_template/styleGuideGetCharactersData", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    if (result.length) {
                        for (let i = 0; i < result.length; i++) {
                            setCharacterCommunications(
                                characterCommunications.map(info => {
                                    //todo
                                    if (result[i].language_code === 0) {
                                        console.log("FILL CHARACTER", info.templateData)
                                        info.templateData[result[i].character_first - 1].data[result[i].character_second - 1] = result[i].value
                                    } else if (result[i].language_code in info.languagesData) {
                                        info.languagesData[result[i].language_code].data[result[i].character_first - 1] = []
                                        info.languagesData[result[i].language_code].data[result[i].character_first - 1][result[i].character_second - 1] = result[i].value
                                    }

                                    return info
                                })
                            )

                            charactersDataCount++

                            console.log("charactersDataCount", charactersDataCount)

                            if (charactersDataCount === result.length) {
                                setIsCharacterCommunicationLoaded(true);
                            }
                        }
                    } else {
                        setIsCharacterCommunicationLoaded(true);
                    }
                },
                (error) => {
                    setIsCharacterCommunicationLoaded(true);
                    setError(error);
                }
            )
    }*/

    function createPdf(code, countClientColumns) {
        let rowsPdf = rows[0].data
        let clientCellOncount = 0

        rowsPdf.map(row => {
            columns.map(columnsData => {
                columnsData.data.map(async column => {
                    if (column.clientColumn) {
                        let queryLinkClient = '/proxy/project_bible_template/'

                        if (column.type === "input") {
                            queryLinkClient += 'projectBibleClientViewFilledCellTextByName'
                        } else if (column.type === "checkbox") {
                            queryLinkClient += 'projectBibleClientViewFilledCellBoolByName'
                        }

                        await fetch(queryLinkClient, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                "code": code,
                                "rowCode": row.code,
                                "colCode": column.code,
                            })
                        })
                            .then(res => res.json())
                            .then(
                                async (result) => {
                                    if (result.length) {
                                        row.data[column.code] = result[0].value.replace(/<br>/g, " ")
                                    } else {
                                        if (column.type === "input") {
                                            row.data[column.code] = ""
                                        } else if (column.type === "checkbox") {
                                            row.data[column.code] = false
                                        }
                                    }

                                    clientCellOncount++

                                    if (clientCellOncount === countClientColumns * rowsPdf.length) {
                                        pdfMake.vfs = pdfFonts.pdfMake.vfs;

                                        let docDefinition = {
                                            pageOrientation: 'landscape',
                                            content: [
                                                {text: 'Project Bible\n\n\n', style: 'header', alignment: 'center', fontSize: 16},
                                                {
                                                    style: 'tableExample',
                                                    fontSize: 10,
                                                    table: {
                                                        headerRows: 1,
                                                        dontBreakRows: true,
                                                        body: rowsForDownloadPdf(columns[0].data, rowsPdf)
                                                    }
                                                },
                                            ]
                                        }

                                        pdfMake.createPdf(docDefinition).download();
                                    }
                                },
                                (error) => {

                                }
                            )
                    }

                    return column
                })

                return columnsData
            })

            return row
        })
    }

    function rowsForDownloadPdf(columns, rows) {
        let data = []
        let headers = []

        headers.push({
            text: '№',
            style: 'tableHeader',
            fontSize: 10,
            bold: true
        })

        columns.map(column => {
            headers.push({
                text: column.name,
                style: 'tableHeader',
                fontSize: 10,
                bold: true
            });

            return column
        })

        data.push(headers)

        rows.map((row, index) => {
            let value = []

            value.push({
                text: String(index + 1),
                style: 'tableHeader',
                fontSize: 10
            });

            columns.map(column => {
                if (column.type === "input") {
                    value.push({
                        text: String(row.data[column.code].replace(/<br>/g, " ")),
                        style: 'tableHeader',
                        fontSize: 10
                    });
                } else if (column.type === "checkbox") {
                    value.push({
                        text: String(row.data[column.code] ? "YES" : ""),
                        style: 'tableHeader',
                        fontSize: 10
                    });
                }
            })

            data.push(value)
        })

        return data
    }

    async function submitClientView() {
        await fetch("/proxy/project_bible_template/projectBibleClientViewSubmit", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "code": code,
                "value": true
            }),
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setSubmit(true)
                    setIsHideSubmitBtn(true)
                },
                (error) => {

                }
            )
    }

    async function setFormUpdateValue() {
        if (!isFormUpdateValueSet) {
            await fetch("/proxy/project_bible_template/projectBibleClientViewSetUpdateValue", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "code": code,
                    "value": true
                }),
            })
                .then(res => res.json())
                .then(
                    async (result) => {
                        setIsFormUpdateValue(true)
                    },
                    (error) => {

                    }
                )
        }
    }

    if (error) {
        return (
            <div className="row">
                <div className="col-sm-12 center">
                    <br />
                    <h3>Error: {error.message}</h3>
                </div>
            </div>
        )
    } else if (!isLoaded || !isLanguageLoaded || (communicationBetweenCharacters && !isCharacterCommunicationLoaded) ||
        !isLinguistDataLoaded) {
        return (
            <div className="row" style={styles.progressBar}>
                <div className="col-sm-4">

                </div>
                <div className="col-sm-4 center">
                    <h4>Loading...</h4>
                    <ProgressBar now={progressPercent} label={`${progressPercent}%`} />
                </div>
                <div className="col-sm-4">

                </div>
            </div>
        )
    } else {
        return(
            <div className="main-block-div-dark">
                <div className="row">
                    <div className="col-sm-4">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicClientName">
                                <Form.Label>Client</Form.Label>
                                <Form.Control type="clientName" disabled
                                              placeholder={queryStringParams.client_name} />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="col-sm-4">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicProjectName">
                                <Form.Label>Project name</Form.Label>
                                <Form.Control type="projectName" disabled
                                              placeholder={queryStringParams.project_name} />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="col-sm-4 center">
                        <br />
                        <Button variant="primary" className="btn btn-blue" onClick={(e) => createPdf(code, clientColumnCount)}>
                            Download pdf&nbsp;&nbsp;<FontAwesomeIcon icon={faFilePdf}/>
                        </Button>
                    </div>
                </div>
                <br />
                <AdditionalComment submit={submit} additionalComment={additionalComment} setFormUpdateValue={setFormUpdateValue} />
                <br />
                <ClientViewTabs columns={columns[0].data} rows={rows[0].data} submit={submit}
                                languages={languages[0].data} characterCommunications={characterCommunications}
                                communicationBetweenCharacters={communicationBetweenCharacters}
                                linguistLinks={linguistLinks[0].data} setFormUpdateValue={setFormUpdateValue} />
                <br />
                <br />
                {
                    submit ? (
                        <>
                            {
                                !isHideSubmitBtn &&
                                <div className="row">
                                    <div className="col-sm-12 center">
                                        <Button variant="primary" className="btn btn-blue" onClick={(e) => setIsHideSubmitBtn(true)}>
                                            Submit comments
                                        </Button>
                                    </div>
                                </div>
                            }
                        </>
                    ) : (
                        <div className="row">
                            <div className="col-sm-12 center">
                                <Button variant="primary" className="btn btn-blue" onClick={(e) => submitClientView()}>
                                    Submit comments
                                </Button>
                            </div>
                        </div>
                    )
                }
                <br />
            </div>
        )
    }
}