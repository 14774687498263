import {Button, Form, Table} from "react-bootstrap";
import React, {useState} from "react";
import Tooltip from 'react-bootstrap/Tooltip';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagic} from "@fortawesome/free-solid-svg-icons";

const styles = {
    noEditableCell: {
        backgroundColor: '#f6f6f6'
    },

    disabledInput: {
        backgroundColor: 'white'
    },

    imgArrowPercentSize: {
        maxHeight: '40px',
        maxWidth: '40px'
    },

    firstColumn: {
        backgroundColor: '#e8f6e9', //light green
        minWidth: '200px'
    },

    firstRow: {
        backgroundColor: '#FFE5E5', //pink
        minWidth: '200px'
    },

    editableCell: {
        backgroundColor: '#fff'
    }
}

export default function LanguageTabCharacterCommunication(props) {
    const [templateTooltipText, setTemplateTooltipText] = useState("")
    const [languageTemplateTooltipText, setLanguageTemplateTooltipText] = useState("")
    const [acceptedFromLinguistTooltipText, setAcceptedFromLinguistTooltipText] = useState("")

    function setTooltip(templateValue, languageTemplateValue, linguist) {
        setTemplateTooltipText(templateValue)
        setLanguageTemplateTooltipText(languageTemplateValue)
        setAcceptedFromLinguistTooltipText(linguist)
    }

    console.log("LanguageTabCharacterCommunication", props)

    return (
        <div>
            <div className="row">
                <div className="col-sm-12">
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicTemplateValue">
                            <Form.Label>You can see a hint about the characters' relationships in the box below. Put in
                                the table how a character from the first column addresses characters from the first
                                row.</Form.Label>
                            <Form.Control as="textarea" rows={2}
                                          value={templateTooltipText} disabled style={styles.disabledInput} placeholder="Template value" />
                            <br/>
                            <Form.Control as="textarea" rows={2}
                                          value={languageTemplateTooltipText} disabled style={styles.disabledInput} placeholder="Language template value" />
                            <br/>
                            <Form.Control as="textarea" rows={1}
                                          value={acceptedFromLinguistTooltipText} disabled style={styles.disabledInput} placeholder="Linguist value" />
                        </Form.Group>
                    </Form>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    {
                        props.templateData.length > 0 &&
                        <div className="table-responsive character-communication-container">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th className="center align-middle" style={{minWidth: '200px'}}>
                                        <img alt="logo" src={require(`../img/right-arrow.png`).default}
                                             data-pr-tooltip="PrimeReact-Logo" style={styles.imgArrowPercentSize}
                                             onError={(e) =>
                                                 e.target.src = require(`../img/error.jpg`).default}/>
                                    </th>
                                    {
                                        props.templateData.map((value, valueIndex) => {
                                            return (
                                                <th key={valueIndex} className="center" contentEditable={false}
                                                    style={styles.firstRow}
                                                    dangerouslySetInnerHTML={{__html: value.name}}>
                                                </th>
                                            )
                                        })
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    props.templateData.map((value, valueIndex) => {
                                        const rowCells = [];

                                        for (let i = 0; i < Object.keys(props.templateData).length; i++) {
                                            let actualValueForTable = ""
                                            let defaultCellValue = ""
                                            let languageCellValue = ""
                                            let acceptFromLinguist = "-"

                                            if (valueIndex in props.templateData && i in props.templateData[valueIndex].data) {
                                                defaultCellValue = props.templateData[valueIndex].data[i]
                                                // actualValueForTable = defaultCellValue
                                            }

                                            if (valueIndex in props.languageData && i in props.languageData[valueIndex]) {
                                                languageCellValue = props.languageData[valueIndex][i]
                                                actualValueForTable = languageCellValue
                                            }

                                            if (valueIndex in props.languageDataFromLinguist && i in props.languageDataFromLinguist[valueIndex]) {
                                                let acceptedFromLinguistData = props.languageDataFromLinguist[valueIndex][i]

                                                actualValueForTable = acceptedFromLinguistData.value
                                                acceptFromLinguist = acceptedFromLinguistData.linguist
                                            }

                                            if (valueIndex !== i) {
                                                rowCells.push(
                                                    <td key={i} className="whiteSpacePreLine"
                                                        contentEditable={true}
                                                        style={styles.editableCell}
                                                        suppressContentEditableWarning={true}
                                                        dangerouslySetInnerHTML={{__html: actualValueForTable}}
                                                        onBlur={(e) =>
                                                            props.oninputCharacterCommunicationCell(props.language,
                                                                value.id, valueIndex + 1, i + 1, "input", e)}
                                                        onMouseOver={(e) => setTooltip(defaultCellValue, languageCellValue, acceptFromLinguist)}
                                                        onMouseOut={(e) => setTooltip("", "", "")}>
                                                    </td>
                                                );

                                                /*if (props.languageAccept.value) {
                                                    rowCells.push(
                                                        <td key={i} className="whiteSpacePreLine"
                                                            contentEditable={false}
                                                            style={styles.noEditableCell}
                                                            dangerouslySetInnerHTML={{__html: languageCellValue}}
                                                            onMouseOver={(e) => setTemplateTooltipText(defaultCellValue)}
                                                            onMouseOut={(e) => setTemplateTooltipText("")}>
                                                        </td>
                                                    );
                                                } else {
                                                    rowCells.push(
                                                        <td key={i} className="whiteSpacePreLine"
                                                            contentEditable={true}
                                                            suppressContentEditableWarning={true}
                                                            dangerouslySetInnerHTML={{__html: languageCellValue}}
                                                            onBlur={(e) =>
                                                                props.oninputCharacterCommunicationCell(props.language,
                                                                    valueIndex + 1, i + 1, "input", e, languageCellValue)}
                                                            onMouseOver={(e) => setTemplateTooltipText(defaultCellValue)}
                                                            onMouseOut={(e) => setTemplateTooltipText("")}>
                                                        </td>
                                                    );
                                                }*/
                                            } else {
                                                rowCells.push(
                                                    <td key={i} className="whiteSpacePreLine"
                                                        contentEditable={false}
                                                        style={styles.noEditableCell}
                                                        dangerouslySetInnerHTML={{__html: ""}}>
                                                    </td>
                                                );
                                            }
                                        }

                                        return (
                                            <tr key={valueIndex}>
                                                <th className="center" contentEditable={false}
                                                    style={styles.firstColumn}
                                                    dangerouslySetInnerHTML={{__html: value.name}}>
                                                </th>
                                                {
                                                    rowCells
                                                }
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}