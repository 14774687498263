import "../project_bible/css/modal.css"
import {Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import ModalCellDataHistoryList from "./ModalCellDataHistoryList"

const styles = {
    modalWH: {
        width: '30%',/*,
        height: '40%'*/
        background: '#222'
    },

    btnOk: {
        width: '100px'
    }
}

export default function AlertModal(props) {
    return (
        <div className={props.modalAlertActive ? "modal active" : "modal"}
             onClick={() => props.setModalAlertActive(false)}>
            <div className={props.modalAlertActive ? "modal__content active" : "modal__content"}
                 onClick={e => e.stopPropagation()}
                 style={styles.modalWH}>
                <div className="row">
                    <div className="col-sm-12 center">
                        <h5>{props.alertMessage}</h5>
                    </div>
                </div>
                <br/>
                <br/>
                <div className="row">
                    <div className="col-sm-12 center">
                        <Button variant="primary" className="btn btn-blue" style={styles.btnOk}
                                onClick={() => props.setModalAlertActive(false)}>
                            Ok
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}