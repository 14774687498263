import React, {useEffect, useState} from "react";
import {Button, FormCheck, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";

const styles = {
    tableHeaderRow: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },

    noEditableCell: {
        backgroundColor: '#fafafa',
    },

    editableCell: {
        backgroundColor: '#fff',
    },

    nowrapCell: {
        backgroundColor: '#fafafa',
        whiteSpace: 'nowrap'
    },
}

export default function BlockRows(props) {
    console.log("BlockRows", props)

    function validateInputNum(event) {
        console.log(event.charCode)

        if (/*event.charCode === 46 ||*/ event.charCode === 8 || event.charCode === 9 || event.charCode === 27 ||
            // Разрешаем: Ctrl+A
            (event.charCode === 65 && event.ctrlKey === true) /*||
            // Разрешаем: home, end, влево, вправо
            (event.charCode >= 35 && event.charCode <= 39)*/) {

            // Ничего не делаем
            // return;
        } else {
            // Запрещаем все, кроме цифр на основной клавиатуре, а так же Num-клавиатуре
            if (event.charCode < 48 || event.charCode > 57) {
                event.preventDefault()
            }
        }
    }

    function validateNewRowIndex(techProcess, block, row, oldIndex, newIndex) {
        let rowsSequenceLength = props.rowsSequence.length
        let rowElementId = "block-row-index-" + row

        if (!newIndex.match(/^\d+$/)) {
            alert("Index must be a number!")
            // document.getElementById(rowElementId).focus()
        } else if (+newIndex !== oldIndex) {
            newIndex = +newIndex

            if (newIndex > 0 && newIndex <= rowsSequenceLength) {
                props.moveRow(techProcess,
                    block, row, oldIndex, newIndex)
            } else if (newIndex <= 0) {
                alert("New row index can't be 0 or under the 0")
                // document.getElementById(rowElementId).focus()
            } else if (newIndex > rowsSequenceLength) {
                alert("New row index can't be more than " + rowsSequenceLength)
                // document.getElementById(rowElementId).focus()
            }
        }
    }

    return(
        <div className="row">
            <div className="col-sm-12 table-blue-thead-not-fixed">
                <Table responsive bordered>
                    <thead>
                    <tr style={styles.tableHeaderRow}>
                        <th className="center" style={styles.tableHeaderRow}>№</th>
                        {
                            props.columnSequence.map((info, index) => {
                                let columnCode = info.code
                                let columnName = props.columns[columnCode].columnName

                                if (columnCode !== 1 && columnCode !== 3 && columnCode !== 16 && columnCode !== 17) {
                                    return (
                                        <th key={columnCode} className="center" style={styles.tableHeaderRow}>{columnName}</th>
                                    )
                                }
                            })
                        }
                        <th className="center" style={styles.tableHeaderRow}>Tags</th>
                        <th className="center" style={styles.tableHeaderRow}>Responsible</th>
                        <th className="center" style={styles.tableHeaderRow}>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.rowsSequence.map((info, index) => {
                            let rowCode = info.code
                            let rowData = props.rows[rowCode]

                            return(
                                <tr key={info.code}>
                                    <td contentEditable={true} id={"block-row-index-" + rowCode}
                                        className="whiteSpacePreLine"
                                        suppressContentEditableWarning={true}
                                        dangerouslySetInnerHTML={{__html: index + 1}}
                                        style={styles.editableCell}
                                        onKeyPress={(e) => validateInputNum(e)}
                                        onBlur={(e) =>
                                            validateNewRowIndex(props.techProcessCode,
                                                props.blockCode, rowCode, index + 1, e.target.innerText)}></td>
                                    {
                                        props.columnSequence.map((info, index) => {
                                            let columnCode = info.code
                                            let columnType = props.columns[columnCode].columnType

                                            // console.log("column info.", info, columnType)

                                            if (columnCode !== 1 && columnCode !== 3 && columnCode !== 16 &&
                                                columnCode !== 17) {
                                                if (columnType === "input") {
                                                    let value = columnCode in rowData.columns ?
                                                        rowData.columns[columnCode].value : ""

                                                    return (
                                                        <td key={columnCode}
                                                            className="whiteSpacePreLine"
                                                            contentEditable={true}
                                                            suppressContentEditableWarning={true}
                                                            style={styles.editableCell}
                                                            dangerouslySetInnerHTML={{__html: value}}
                                                            onBlur={(e) =>
                                                                props.updateBlockRowColumnValue(props.techProcessCode,
                                                                    props.blockCode, rowCode, columnCode, "input", e)}>
                                                        </td>
                                                    )
                                                } else if (columnType === "checkbox") {
                                                    let value = columnCode in rowData.columns ?
                                                        rowData.columns[columnCode].value : false

                                                    return(
                                                        <td key={columnCode}
                                                            className="center align-middle"
                                                            contentEditable={false}
                                                            style={styles.noEditableCell}
                                                        >
                                                            <FormCheck
                                                                defaultChecked={value}
                                                                onInput={(e) =>
                                                                    props.updateBlockRowColumnValue(props.techProcessCode,
                                                                        props.blockCode, rowCode, columnCode, "checkbox", e)}
                                                            />
                                                        </td>
                                                    )
                                                } else if (columnType === "date") {
                                                    return (
                                                        <td key={columnCode}
                                                            className="whiteSpacePreLine"
                                                            style={styles.editableCell}
                                                            contentEditable={false}>
                                                        </td>
                                                    )
                                                }
                                            }
                                        })
                                    }
                                    <td className="whiteSpacePreLine"
                                        contentEditable={false}
                                        style={styles.editableCell}
                                        onClick={(e) =>
                                            props.openModalForTags(true, props.techProcessCode, props.blockCode,
                                                rowCode, rowData.tags)}>
                                        {
                                            rowData.tags.map(tagId => {

                                                if (tagId in props.associativeArrayOfTagsById) {
                                                    return(
                                                        <div key={tagId}>{props.associativeArrayOfTagsById[tagId]}</div>
                                                    )
                                                }
                                            })
                                        }
                                    </td>
                                    <td className="whiteSpacePreLine"
                                        contentEditable={false}
                                        style={styles.editableCell}
                                        onClick={(e) =>
                                            props.openModalForResponsibles(true, props.techProcessCode, props.blockCode,
                                                rowCode, rowData.responsible)}>
                                        {
                                            rowData.responsible.map(responsibleId => {
                                                if (responsibleId in props.responsible) {
                                                    return(
                                                        <div key={responsibleId}>{props.responsible[responsibleId].responsibleName}</div>
                                                    )
                                                }
                                            })
                                        }
                                    </td>
                                    <td contentEditable={false}
                                        style={styles.nowrapCell}>
                                        {
                                            index !== 0 &&
                                            <>
                                                <Button
                                                    size="sm"
                                                    variant="primary"
                                                    className="btn btn-blue"
                                                    onClick={(e) =>
                                                        props.moveUpRow(props.techProcessCode, props.blockCode,
                                                            rowCode)}>
                                                    <FontAwesomeIcon icon={faChevronUp} />
                                                </Button>
                                                &nbsp;
                                            </>
                                        }
                                        {
                                            index !== props.rowsSequence.length - 1 &&
                                            <>
                                                <Button
                                                    size="sm"
                                                    variant="primary"
                                                    className="btn btn-blue"
                                                    onClick={(e) =>
                                                        props.moveDownRow(props.techProcessCode, props.blockCode,
                                                            rowCode)}>
                                                    <FontAwesomeIcon icon={faChevronDown} />
                                                </Button>
                                                &nbsp;
                                            </>
                                        }
                                        <Button
                                            size="sm"
                                            variant="success"
                                            onClick={(e) =>
                                                props.addRow(props.techProcessCode, props.blockCode,
                                                    rowCode)}>
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                        &nbsp;
                                        <Button
                                            size="sm"
                                            variant="danger"
                                            onClick={(e) =>
                                                props.deleteRow(props.techProcessCode, props.blockCode,
                                                    rowCode)}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
                <br />
                <Button
                    variant="primary" className="btn btn-blue"
                    onClick={(e) =>
                        props.addRowToTheEnd(props.techProcessCode, props.blockCode)}>
                    <FontAwesomeIcon icon={faPlus} /> Add
                </Button>
            </div>
        </div>
    )
}