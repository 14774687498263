import {FormCheck} from "react-bootstrap";
import React, {useState} from "react";
import queryString from "query-string";
import Editor from "react-medium-editor";
import MediumButton from "medium-button";

const styles = {
    editableCell: {
        backgroundColor: '#ffffff',
        whiteSpace: 'pre-line',
        minWidth: '500px',
        wordBreak: 'break-word'
    },

    noEditableCell: {
        backgroundColor: '#fafafa',
        whiteSpace: 'pre-line',
        minWidth: '100px',
        maxWidth: '400px',
        wordBreak: 'break-word'
    },

    clientNotAcceptedCell: {
        backgroundColor: '#fff8e1',
        whiteSpace: 'pre-line',
        minWidth: '500px',
        wordBreak: 'break-word'
    },

    clientDidNotSeeChanges: {
        backgroundColor: '#c5deff',
        whiteSpace: 'pre-line',
        minWidth: '500px',
        wordBreak: 'break-word'
    }
}

export default function TableCell(props) {
    if (props.column.type === "input") {
        if (props.column.editableByClient) {
            let cellStyle = styles.editableCell
            let changeAcceptedByClient = true

            if ("changedBy" in props.cellData) {
                changeAcceptedByClient = "changeAcceptedByClient" in props.cellData.changedBy ?
                    props.cellData.changedBy.changeAcceptedByClient : false
            }

            if (!props.cellData.isAccepted) {
                cellStyle = styles.clientNotAcceptedCell
            } else if (!changeAcceptedByClient && props.showAcceptChangesBtn) {
                cellStyle = styles.clientDidNotSeeChanges
            }

            return (
                <td className="whiteSpacePreLine"
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    style={cellStyle}
                    onBlur={(e) =>
                        props.generalTabSaveCellValue(props.column.code, props.rowCode, e.target.innerHTML)}
                    dangerouslySetInnerHTML={{__html: props.cellData.value}}>
                </td>
            )
        } else {
            let cellStyle = styles.noEditableCell

            return (
                <td className="whiteSpacePreLine"
                    contentEditable={false}
                    suppressContentEditableWarning={true}
                    style={cellStyle}
                    dangerouslySetInnerHTML={{__html: props.cellData.value}}>
                </td>
            )
        }
    }
}