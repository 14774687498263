import "../css/modal.css"
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faListAlt} from "@fortawesome/free-solid-svg-icons";
import {Button, Form, FormCheck, Table} from "react-bootstrap";

const styles = {
    modalWH: {
        width: '75%',
        height: '85%'
    },

    tableHeaders: {
        background: '#4A8EFF',
        color: '#FFFFFF',
        borderColor: 'transparent'
    },
}

export default function TemplateTextSelectionModal(props) {
    let modalTemplateTextSelectionData = props.modalTemplateTextSelectionData.length ? props.modalTemplateTextSelectionData[0] : []
    const [selectedValue, setSelectedValue] = useState(props.selectedValue)

    console.log("TemplateTextSelectionModal", props.selectedValue, modalTemplateTextSelectionData)

    const getOptions = data => {
        let content = []

        for (let i = 0; i < data.length; i++) {
            console.log("getOptions", selectedValue, i)

            content.push(
                <tr key={i + 1}>
                    <td contentEditable={false}
                        dangerouslySetInnerHTML={{__html: data[i]}}
                        style={{width: "90%"}}>
                    </td>
                    <td className="center align-middle" contentEditable={false}>
                        <FormCheck type="checkbox"
                                   checked={selectedValue === i + 1}
                                   style={{width: "10%"}}
                                   onChange={(e) => {
                                       let newValue = e.target.checked ? i + 1 : 0

                                       props.setModalTemplateTextSelectionDataSelectedValue(newValue)
                                       setSelectedValue(newValue)
                                   }} />
                    </td>
                </tr>
            )
        }

        return content
    }

    return(
        <div className={props.modalTemplateTextSelectionActive ? "modal active" : "modal"} onClick={() => props.setModalTemplateTextSelectionActive(false)} >
            <div className={props.modalTemplateTextSelectionActive ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}
                 style={styles.modalWH} >
                {
                    "templateTextSelection" in modalTemplateTextSelectionData &&
                    <>
                        <div className="row">
                            <div className="col-sm-12 center table-blue-thead-not-fixed">
                                <Table responsive bordered>
                                    <thead>
                                    <tr style={styles.tableHeaders}>
                                        <th style={styles.tableHeaders}>Option</th>
                                        <th style={styles.tableHeaders}>Check</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    { getOptions(modalTemplateTextSelectionData.templateTextSelection) }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="row">
                            <div className="col-sm-12 center">
                                <Button variant="primary" className="btn btn-blue" disabled={selectedValue === 0}
                                        onClick={(e) =>
                                            props.languageTabSavePreferencesCellValue(
                                                modalTemplateTextSelectionData.languageCode,
                                                modalTemplateTextSelectionData.block,
                                                modalTemplateTextSelectionData.column,
                                                modalTemplateTextSelectionData.row,
                                                modalTemplateTextSelectionData.type,
                                                modalTemplateTextSelectionData.templateTextSelection[selectedValue - 1],
                                                modalTemplateTextSelectionData.refillCell
                                            )}>
                                    Choose variant
                                </Button>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}