import "../css/modal.css"
import {Button, Col, Form, FormCheck, FormControl, FormLabel, Nav, ProgressBar, Row, Table, Tabs, Tab} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import StyleGuideModalSettingsCharacterCommunication from "./StyleGuideModalSettingsCharacterCommunication";

const styles = {
    modalWH: {
        width: '85%',
        height: '80%',
        background: '#222' /* dark-theme */
    },

    tableCell: {
        background: '#fff'
    }
}

export default function StyleGuideSettingsModal(props) {
    let languages = props.languages
    let logOfNewBatches = props.logOfNewBatches
    // let communicationBetweenCharacters = props.communicationBetweenCharacters
    const [communicationBetweenCharacters, setCommunicationBetweenCharacters] = useState(props.communicationBetweenCharacters)
    const [projectManagementInfo, setProjectManagementInfo] = useState(props.projectManagementInfo)
    const [editablePreferencesInLanguageBlocks, setEditablePreferencesInLanguageBlocks] = useState([{
        data: [{
            "project_management_info": {
                "title": "Project Management Info",
                "client": true,
                "linguist": false,
                "rowOneCell": [""],
                "rowTwoCells": ["", ""]
            },
            "base_info": {
                "title": "Base Info",
                "client": true,
                "linguist": false,
                "rowOneCell": [""],
                "rowTwoCells": ["", ""]
            },
            "punctuation_marks": {
                "title": "Punctuation Marks",
                "client": true,
                "linguist": false,
                "rowOneCell": [""],
                "rowTwoCells": ["", ""]
            },
            "capital_letters": {
                "title": "Capital Letters",
                "client": true,
                "linguist": true,
                "rowOneCell": [""],
                "rowTwoCells": ["", ""]
            },
            "target_language_features": {
                "title": "Target Language Features",
                "client": false,
                "linguist": true,
                "rowOneCell": [""],
                "rowTwoCells": ["", ""]
            },
            "inclusive_language_guidelines": {
                "title": "Inclusive Language Guidelines",
                "client": false,
                "linguist": true,
                "rowOneCell": [""],
                "rowTwoCells": ["", ""]
            }
        }]
    }])

    console.log("StyleGuideSettingsModal", props)

    function onInputCellForAddingRowsToAllLanguages(block, cellCount, cellNum, e) {
        console.log("editablePreferencesInLanguageBlocks", block, cellCount, cellNum, e.target.innerText, editablePreferencesInLanguageBlocks)

        setEditablePreferencesInLanguageBlocks(
            editablePreferencesInLanguageBlocks.map(info => {
                info.data.map(value => {
                    value[block][cellCount][cellNum] = e.target.innerText

                    return value
                })

                return info
            })
        )

        console.log("NEW BLOCKS ARRAY", editablePreferencesInLanguageBlocks)
    }

    function setLanguage(title, e) {
        languages.forEach(function(language) {
            if (language.title === title) {
                language.value = !language.value
            }
        })

        // console.log("setLanguage", languages)
    }

    let getLanguages = languages => {
        let content = []

        console.log("Object.keys(languages).length", Object.keys(languages).length)

        content.push(
            <div className="row">
                <div className="col-sm-6">
                    {
                        languages.map((value, index) => {
                            if (index <= Object.keys(languages).length / 2) {
                                return(
                                    <FormCheck key={value.title} defaultChecked={value.value} label={value.title}
                                               onChange={(e) =>
                                                   setLanguage(value.title, e)}/>
                                )
                            }

                            // return value
                        })
                    }
                </div>
                <div className="col-sm-6">
                    {
                        languages.map((value, index) => {
                            if (index > Object.keys(languages).length / 2) {
                                return(
                                    <FormCheck key={value.title} defaultChecked={value.value} label={value.title}
                                               onChange={(e) =>
                                                   setLanguage(value.title, e)}/>
                                )
                            }

                            // return value
                        })
                    }
                </div>
            </div>
        )

        /*languages.map((value, index) => {
            // console.log("LANGUAGE KEY", value)

            content.push(
                <div className="row" key={value.title}>
                    <div className="col=sm-6">
                        <FormCheck defaultChecked={value.value} label={value.title} onChange={(e) =>
                            setLanguage(value.title, e)} />
                    </div>
                </div>
            )

            return value
        })*/

        return content
    };

    let getBlocksNavs = () => {
        let content = []

        for (let blockKey in editablePreferencesInLanguageBlocks[0].data[0]) {
            let blockValue = editablePreferencesInLanguageBlocks[0].data[0][blockKey]

            if (blockKey !== "project_management_info" || (blockKey === "project_management_info" && projectManagementInfo)) {
                content.push(
                    <Nav.Item>
                        <Nav.Link eventKey={blockKey}>
                            {blockValue.title}
                        </Nav.Link>
                    </Nav.Item>
                )
            }
        }

        return content
    }

    let getBlocksData = () => {
        let content = []

        for (let blockKey in editablePreferencesInLanguageBlocks[0].data[0]) {
            let blockValue = editablePreferencesInLanguageBlocks[0].data[0][blockKey]

            if (blockKey !== "project_management_info" || (blockKey === "project_management_info" && projectManagementInfo)) {
                content.push(
                    <Tab.Pane eventKey={blockKey}>
                        <div className="tab-item-wrapper">
                            <br/>
                            <div className="center">
                                <Table responsive bordered>
                                    <tbody>
                                    <tr>
                                        <td contentEditable={true} style={styles.tableCell}
                                            onBlur={(e) =>
                                                onInputCellForAddingRowsToAllLanguages(blockKey, "rowOneCell", 0, e)}></td>
                                    </tr>
                                    </tbody>
                                </Table>
                                <Button variant="primary" className="btn btn-blue" onClick={(e) =>
                                    props.addRowToAllStyleGuideLanguageBlock(blockKey,
                                        editablePreferencesInLanguageBlocks[0].data[0][blockKey].client,
                                        editablePreferencesInLanguageBlocks[0].data[0][blockKey].linguist,
                                        editablePreferencesInLanguageBlocks[0].data[0][blockKey].rowOneCell,
                                        1)}>
                                    Add row with 1 cell&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                                </Button>
                                <br/>
                                <br/>
                                <Table responsive bordered>
                                    <tbody>
                                    <tr>
                                        <td contentEditable={true} style={styles.tableCell}
                                            onBlur={(e) =>
                                                onInputCellForAddingRowsToAllLanguages(blockKey, "rowTwoCells", 0, e)}></td>
                                        <td contentEditable={true} style={styles.tableCell}
                                            onBlur={(e) =>
                                                onInputCellForAddingRowsToAllLanguages(blockKey, "rowTwoCells", 1, e)}></td>
                                    </tr>
                                    </tbody>
                                </Table>
                                <Button variant="primary" className="btn btn-blue" onClick={(e) =>
                                    props.addRowToAllStyleGuideLanguageBlock(blockKey,
                                        editablePreferencesInLanguageBlocks[0].data[0][blockKey].client,
                                        editablePreferencesInLanguageBlocks[0].data[0][blockKey].linguist,
                                        editablePreferencesInLanguageBlocks[0].data[0][blockKey].rowTwoCells,
                                        2)}>
                                    Add row with 2 cells&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                                </Button>
                            </div>
                        </div>
                    </Tab.Pane>
                )

                /*content.push(
                    <>
                        <Table responsive bordered>
                            <thead>
                            <tr>
                                <td className="center" style={styles.tableCell}>{blockValue.title}</td>
                            </tr>
                            </thead>
                        </Table>
                        <div className="center">
                            <Table responsive bordered>
                                <tbody>
                                <tr>
                                    <td contentEditable={true} style={styles.tableCell}
                                        onBlur={(e) =>
                                            onInputCellForAddingRowsToAllLanguages(blockKey, "rowOneCell", 0, e)}></td>
                                </tr>
                                </tbody>
                            </Table>
                            <Button variant="primary" className="btn btn-blue" onClick={(e) =>
                                props.addRowToAllStyleGuideLanguageBlock(blockKey,
                                    editablePreferencesInLanguageBlocks[0].data[0][blockKey].client,
                                    editablePreferencesInLanguageBlocks[0].data[0][blockKey].linguist,
                                    editablePreferencesInLanguageBlocks[0].data[0][blockKey].rowOneCell,
                                    1)}>
                                Add row with 1 cell&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                            </Button>
                            <br/>
                            <br/>
                            <Table responsive bordered>
                                <tbody>
                                <tr>
                                    <td contentEditable={true} style={styles.tableCell}
                                        onBlur={(e) =>
                                            onInputCellForAddingRowsToAllLanguages(blockKey, "rowTwoCells", 0, e)}></td>
                                    <td contentEditable={true} style={styles.tableCell}
                                        onBlur={(e) =>
                                            onInputCellForAddingRowsToAllLanguages(blockKey, "rowTwoCells", 1, e)}></td>
                                </tr>
                                </tbody>
                            </Table>
                            <Button variant="primary" className="btn btn-blue" onClick={(e) =>
                                props.addRowToAllStyleGuideLanguageBlock(blockKey,
                                    editablePreferencesInLanguageBlocks[0].data[0][blockKey].client,
                                    editablePreferencesInLanguageBlocks[0].data[0][blockKey].linguist,
                                    editablePreferencesInLanguageBlocks[0].data[0][blockKey].rowTwoCells,
                                    2)}>
                                Add row with 2 cells&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                            </Button>
                        </div>
                        <br />
                    </>
                )*/
            }
        }

        return content
    }

    return(
        <div className={props.styleGuideSettingsModalActive ? "modal active" : "modal"} onClick={() => props.setStyleGuideSettingsModalActive(false)} >
            <div className={props.styleGuideSettingsModalActive ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}
                 style={styles.modalWH} >
                <div className="row">
                    <div className="col-sm-12">
                        <h4>Поставьте необходимые галочки</h4>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-8">
                        <div className="row">
                            <h5>Вкладки языков</h5>
                        </div>
                        { getLanguages(props.languages) }
                    </div>
                    <div className="col-sm-4">
                        <div className="row">
                            <h5>Остальное</h5>
                        </div>
                        <FormCheck defaultChecked={logOfNewBatches} label="Log Of New Batches"
                                   onChange={(e) => {logOfNewBatches = !logOfNewBatches}} />
                        <FormCheck defaultChecked={communicationBetweenCharacters} label="Communication Between Characters"
                                   onChange={(e) => {setCommunicationBetweenCharacters(!communicationBetweenCharacters)}} />
                        <FormCheck defaultChecked={projectManagementInfo} label="Project Management Info"
                                   onChange={(e) => {setProjectManagementInfo(!projectManagementInfo)}} />
                        <br />
                        <br />
                        <div className="row">
                            <div className="col-sm-12 center">
                                <button className="btn btn-blue"
                                        onClick={(e) => props.changeStyleGuideSettings(languages, logOfNewBatches, communicationBetweenCharacters, projectManagementInfo)}>Change styleguide settings</button>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <hr />
                <br />
                <div className="row">
                    <div className="col-sm-12">
                        <h4>Добавьте строки к блокам на всех языковых вкладках</h4>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-12">
                        <Tab.Container defaultActiveKey={projectManagementInfo ? "project_management_info" : "base_info"}>
                            <Row>
                                <Col sm={12}>
                                    <Nav variant="pills" className="nav-custom-grey">
                                        { getBlocksNavs() }
                                    </Nav>
                                    <Tab.Content>
                                        { getBlocksData() }
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                        {/*<Table responsive bordered>
                            <thead>
                            <tr>
                                <td className="center">Base Info</td>
                            </tr>
                            </thead>
                        </Table>
                        <div className="center">
                            <Table responsive bordered>
                                <tbody>
                                <tr>
                                    <td contentEditable={true}
                                        onBlur={(e) =>
                                        onInputCellForAddingRowsToAllLanguages("base_info", "rowOneCell", 0, e)}></td>
                                </tr>
                                </tbody>
                            </Table>
                            <Button variant="primary" onClick={(e) =>
                                props.addRowToAllStyleGuideLanguageBlock("base_info",
                                    editablePreferencesInLanguageBlocks[0].data[0].base_info.client,
                                    editablePreferencesInLanguageBlocks[0].data[0].base_info.linguist,
                                    editablePreferencesInLanguageBlocks[0].data[0].base_info.rowOneCell,
                                    1)}>
                                Add row with 1 cell&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                            </Button>
                            <br />
                            <br />
                            <Table responsive bordered>
                                <tbody>
                                <tr>
                                    <td contentEditable={true}
                                        onBlur={(e) =>
                                        onInputCellForAddingRowsToAllLanguages("base_info", "rowTwoCells", 0, e)}></td>
                                    <td contentEditable={true}
                                        onBlur={(e) =>
                                        onInputCellForAddingRowsToAllLanguages("base_info", "rowTwoCells", 1, e)}></td>
                                </tr>
                                </tbody>
                            </Table>
                            <Button variant="primary" onClick={(e) =>
                                props.addRowToAllStyleGuideLanguageBlock("base_info",
                                    editablePreferencesInLanguageBlocks[0].data[0].base_info.client,
                                    editablePreferencesInLanguageBlocks[0].data[0].base_info.linguist,
                                    editablePreferencesInLanguageBlocks[0].data[0].base_info.rowTwoCells,
                                    2)}>
                                Add row with 2 cells&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                            </Button>
                        </div>
                        <br />
                        <Table responsive bordered>
                            <thead>
                            <tr>
                                <td className="center">Punctuation marks</td>
                            </tr>
                            </thead>
                        </Table>
                        <div className="center">
                            <Table responsive bordered>
                                <tbody>
                                <tr>
                                    <td contentEditable={true}
                                        onBlur={(e) =>
                                            onInputCellForAddingRowsToAllLanguages("punctuation_marks", "rowOneCell", 0, e)}></td>
                                </tr>
                                </tbody>
                            </Table>
                            <Button variant="primary" onClick={(e) =>
                                props.addRowToAllStyleGuideLanguageBlock("punctuation_marks",
                                    editablePreferencesInLanguageBlocks[0].data[0].punctuation_marks.client,
                                    editablePreferencesInLanguageBlocks[0].data[0].punctuation_marks.linguist,
                                    editablePreferencesInLanguageBlocks[0].data[0].punctuation_marks.rowOneCell,
                                    1)}>
                                Add row with 1 cell&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                            </Button>
                            <br />
                            <br />
                            <Table responsive bordered>
                                <tbody>
                                <tr>
                                    <td contentEditable={true}
                                        onBlur={(e) =>
                                            onInputCellForAddingRowsToAllLanguages("punctuation_marks", "rowTwoCells", 0, e)}></td>
                                    <td contentEditable={true}
                                        onBlur={(e) =>
                                            onInputCellForAddingRowsToAllLanguages("punctuation_marks", "rowTwoCells", 1, e)}></td>
                                </tr>
                                </tbody>
                            </Table>
                            <Button variant="primary" onClick={(e) =>
                                props.addRowToAllStyleGuideLanguageBlock("punctuation_marks",
                                    editablePreferencesInLanguageBlocks[0].data[0].punctuation_marks.client,
                                    editablePreferencesInLanguageBlocks[0].data[0].punctuation_marks.linguist,
                                    editablePreferencesInLanguageBlocks[0].data[0].punctuation_marks.rowTwoCells,
                                    2)}>
                                Add row with 2 cells&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                            </Button>
                        </div>
                        <br />
                        <Table responsive bordered>
                            <thead>
                            <tr>
                                <td className="center">Capital letters</td>
                            </tr>
                            </thead>
                        </Table>
                        <div className="center">
                            <Table responsive bordered>
                                <tbody>
                                <tr>
                                    <td contentEditable={true}
                                        onBlur={(e) =>
                                            onInputCellForAddingRowsToAllLanguages("capital_letters", "rowOneCell", 0, e)}></td>
                                </tr>
                                </tbody>
                            </Table>
                            <Button variant="primary" onClick={(e) =>
                                props.addRowToAllStyleGuideLanguageBlock("capital_letters",
                                    editablePreferencesInLanguageBlocks[0].data[0].capital_letters.client,
                                    editablePreferencesInLanguageBlocks[0].data[0].capital_letters.linguist,
                                    editablePreferencesInLanguageBlocks[0].data[0].capital_letters.rowOneCell,
                                    1)}>
                                Add row with 1 cell&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                            </Button>
                            <br />
                            <br />
                            <Table responsive bordered>
                                <tbody>
                                <tr>
                                    <td contentEditable={true}
                                        onBlur={(e) =>
                                            onInputCellForAddingRowsToAllLanguages("capital_letters", "rowTwoCells", 0, e)}></td>
                                    <td contentEditable={true}
                                        onBlur={(e) =>
                                            onInputCellForAddingRowsToAllLanguages("capital_letters", "rowTwoCells", 1, e)}></td>
                                </tr>
                                </tbody>
                            </Table>
                            <Button variant="primary" onClick={(e) =>
                                props.addRowToAllStyleGuideLanguageBlock("capital_letters",
                                    editablePreferencesInLanguageBlocks[0].data[0].capital_letters.client,
                                    editablePreferencesInLanguageBlocks[0].data[0].capital_letters.linguist,
                                    editablePreferencesInLanguageBlocks[0].data[0].capital_letters.rowTwoCells,
                                    2)}>
                                Add row with 2 cells&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                            </Button>
                        </div>
                        <br />
                        <Table responsive bordered>
                            <thead>
                            <tr>
                                <td className="center">Target language features</td>
                            </tr>
                            </thead>
                        </Table>
                        <div className="center">
                            <Table responsive bordered>
                                <tbody>
                                <tr>
                                    <td contentEditable={true}
                                        onBlur={(e) =>
                                            onInputCellForAddingRowsToAllLanguages("target_language_features", "rowOneCell", 0, e)}></td>
                                </tr>
                                </tbody>
                            </Table>
                            <Button variant="primary" onClick={(e) =>
                                props.addRowToAllStyleGuideLanguageBlock("target_language_features",
                                    editablePreferencesInLanguageBlocks[0].data[0].target_language_features.client,
                                    editablePreferencesInLanguageBlocks[0].data[0].target_language_features.linguist,
                                    editablePreferencesInLanguageBlocks[0].data[0].target_language_features.rowOneCell,
                                    1)}>
                                Add row with 1 cell&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                            </Button>
                            <br />
                            <br />
                            <Table responsive bordered>
                                <tbody>
                                <tr>
                                    <td contentEditable={true}
                                        onBlur={(e) =>
                                            onInputCellForAddingRowsToAllLanguages("target_language_features", "rowTwoCells", 0, e)}></td>
                                    <td contentEditable={true}
                                        onBlur={(e) =>
                                            onInputCellForAddingRowsToAllLanguages("target_language_features", "rowTwoCells", 1, e)}></td>
                                </tr>
                                </tbody>
                            </Table>
                            <Button variant="primary" onClick={(e) =>
                                props.addRowToAllStyleGuideLanguageBlock("target_language_features",
                                    editablePreferencesInLanguageBlocks[0].data[0].target_language_features.client,
                                    editablePreferencesInLanguageBlocks[0].data[0].target_language_features.linguist,
                                    editablePreferencesInLanguageBlocks[0].data[0].target_language_features.rowTwoCells,
                                    2)}>
                                Add row with 2 cells&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                            </Button>
                        </div>
                        <br />
                        <Table responsive bordered>
                            <thead>
                            <tr>
                                <td className="center">Inclusive language guidelines</td>
                            </tr>
                            </thead>
                        </Table>
                        <div className="center">
                            <Table responsive bordered>
                                <tbody>
                                <tr>
                                    <td contentEditable={true}
                                        onBlur={(e) =>
                                            onInputCellForAddingRowsToAllLanguages("inclusive_language_guidelines", "rowOneCell", 0, e)}></td>
                                </tr>
                                </tbody>
                            </Table>
                            <Button variant="primary" onClick={(e) =>
                                props.addRowToAllStyleGuideLanguageBlock("inclusive_language_guidelines",
                                    editablePreferencesInLanguageBlocks[0].data[0].inclusive_language_guidelines.client,
                                    editablePreferencesInLanguageBlocks[0].data[0].inclusive_language_guidelines.linguist,
                                    editablePreferencesInLanguageBlocks[0].data[0].inclusive_language_guidelines.rowOneCell,
                                    1)}>
                                Add row with 1 cell&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                            </Button>
                            <br />
                            <br />
                            <Table responsive bordered>
                                <tbody>
                                <tr>
                                    <td contentEditable={true}
                                        onBlur={(e) =>
                                            onInputCellForAddingRowsToAllLanguages("inclusive_language_guidelines", "rowTwoCells", 0, e)}></td>
                                    <td contentEditable={true}
                                        onBlur={(e) =>
                                            onInputCellForAddingRowsToAllLanguages("inclusive_language_guidelines", "rowTwoCells", 1, e)}></td>
                                </tr>
                                </tbody>
                            </Table>
                            <Button variant="primary" onClick={(e) =>
                                props.addRowToAllStyleGuideLanguageBlock("inclusive_language_guidelines",
                                    editablePreferencesInLanguageBlocks[0].data[0].inclusive_language_guidelines.client,
                                    editablePreferencesInLanguageBlocks[0].data[0].inclusive_language_guidelines.linguist,
                                    editablePreferencesInLanguageBlocks[0].data[0].inclusive_language_guidelines.rowTwoCells,
                                    2)}>
                                Add row with 2 cells&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus}/>
                            </Button>
                        </div>
                        <br />*/}
                    </div>
                </div>
                <StyleGuideModalSettingsCharacterCommunication
                    communicationBetweenCharacters={communicationBetweenCharacters}
                    templateData={props.templateData} languagesData={props.languagesData}
                    addCharacter={props.addCharacter} deleteCharacter={props.deleteCharacter}
                    updateCharacterName={props.updateCharacterName}
                    oninputCharacterCommunicationCell={props.oninputCharacterCommunicationCell} />
            </div>
        </div>
    )
}