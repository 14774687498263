import React, {useEffect, useState} from "react";
import queryString from "query-string";
import {Button, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import AdditionalComment from "./AdditionalComment";
import ClientViewTabs from "./ClientViewTabs";
import OpenInTooltipAddedImage from "./OpenInTooltipAddedImage";
import AlertModal from "../AlertModal";
import ClientHintModal from "./ClientHintModal";

export default function View(props) {
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const queryStringParams = queryString.parse(window.location.search)
    const clientName = queryStringParams.client_name
    const projectName = queryStringParams.project_name
    const projectCode = queryStringParams.project_code
    const [viewCode, setViewCode] = useState(+queryStringParams.code)
    // const viewCode = queryStringParams.code
    const [additionalComment, setAdditionalComment] = useState("")
    const [columns, setColumns] = useState([{
        data: [
            {
                "code": "mh8hjffmev2a7jgbjs1ca3ioqxrxk-0t2qrt",
                // "name": "Project Info Type",
                "show": true,
                "showIfAllRowsEmpty": true,
                "editableByClient": false
            },
            {
                "code": "22e0jrtmbwzlhnp1g0ymerm7vilayp2xkump",
                // "name": "Description",
                "show": true,
                "showIfAllRowsEmpty": true,
                "editableByClient": false
            },
            {
                "code": "yqjjii-ksp7jkg5vlue1om0beh5-sdhq3i25",
                // "name": "Basic kick-off question",
                "show": true,
                "showIfAllRowsEmpty": true,
                "editableByClient": false
            },
            {
                "code": "8pz90mzl0yp-fn9q2chl5lxay8x1jbyqf98h",
                // "name": "Special kick-off questions",
                "show": true,
                "showIfAllRowsEmpty": false,
                "editableByClient": false
            },
            {
                "code": "u6ea3ffqrpg054v-1erslv5r-1j8zo288jby",
                // "name": "Info",
                "show": true,
                "showIfAllRowsEmpty": true,
                "editableByClient": true
            }
        ]
    }])
    const [columnClientsApprovalCode, setColumnClientsApprovalCode] = useState("7772fhhbrbcmkcywbbefu19wwuw5rmxnerpc")
    const [rows, setRows] = useState([{
        data: []
    }])
    const [tooltipImageData, setTooltipImageData] = useState([{
        src: '',
        title: '',
        element: null,
        justUploaded: false
    }])
    const [communicationBetweenCharacters, setCommunicationBetweenCharacters] = useState(false)
    const [languages, setLanguages] = useState([{
        data: [],
        listOfCodes: []
    }])
    const [characterCommunications, setCharacterCommunications] = useState([{
        characters: [],
        templateData: [],
        languagesData: []
    }])
    const [linguistLinks, setLinguistLinks] = useState([{
        data: []
    }])
    const [showAcceptChangesBtn, setShowAcceptChangesBtn] = useState(true);
    const [modalAlertActive, setModalAlertActive] = useState(false);
    const [modalAlertMessage, setModalAlertMessage] = useState("");
    const [modalHintActive, setModalHintActive] = useState(false);

    useEffect(async () => {
        document.title = projectName + " (PB)";

        await getInfoAboutView()
        await getAdditionalComment()
        await getGeneralColumns()
        await getGeneralRows()
        await fillGeneralData()
        await getStyleGuideData()

        setIsLoaded(true)
    },[])

    async function getInfoAboutView() {
        await fetch("/proxy/project_bible_template/getPBClientView", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode
            }),
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    if (result.length) {
                        setViewCode(+result[0].code)
                        //todo pb_client_view ссылка с неправильным кодом страницы клиента
                    } else {
                        setError({
                            message: "This page does not exist or the link is incorrect. Please contact your manager."
                        });
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError({
                        message: "An error occurred while loading page data. Please refresh or contact your manager."
                    });
                }
            )
    }

    async function getAdditionalComment() {
        await fetch("/proxy/project_bible_template/getClientViewAdditionalComment", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "code": viewCode
            })
        })
            .then(res => res.json())
            .then(
                async (resultAdditionalComment) => {
                    if (resultAdditionalComment.length) {
                        setAdditionalComment(resultAdditionalComment[0].comment)
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError({
                        message: "An error occurred while loading page data. Please refresh or contact your manager."
                    });
                }
            )
    }

    async function getGeneralColumns() {
        await fetch("/proxy/project_bible_template/getPBColumnsForClientView", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    let associativeColumns = {}

                    for (let i = 0; i < result.length; i++) {
                        associativeColumns[result[i].code] = result[i]
                    }

                    setColumns(
                        columns.map(info => {
                            info.data.map(column => {
                                column["name"] = associativeColumns[column.code].name
                                column["type"] = associativeColumns[column.code].type
                                column["template"] = associativeColumns[column.code].template
                                column["editable"] = associativeColumns[column.code].editable

                                return column
                            })

                            return info
                        })
                    )
                },
                (error) => {
                    setIsLoaded(true);
                    setError({
                        message: "An error occurred while loading page data. Please refresh or contact your manager."
                    });
                }
            )
    }

    async function getGeneralRows() {
        await fetch("/proxy/project_bible_template/pbClientViewRows", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "clientsApprovalColCode": columnClientsApprovalCode
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    for (let i = 0; i < result.rows.length; i++) {
                        setRows(
                            rows.map(info => {
                                info.data.push({
                                    "code": result.rows[i],
                                    "data": []
                                })

                                return info
                            })
                        )
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError({
                        message: "An error occurred while loading page data. Please refresh or contact your manager."
                    });
                }
            )
    }

    async function fillGeneralData() {
        let showIfAllRowsEmpty = false

        await fetch("/proxy/project_bible_template/getPBCellDataForClientViewAll", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                clientName: clientName,
                projectName: projectName,
                projectCode: projectCode,
                viewCode: viewCode
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    let associativeTemplateValues = {}
                    let associativeEditableValues = {}
                    let associativeEditableByClientValues = {}

                    for (let i = 0; i < result.templateValues.length; i++) {
                        let valueParam = result.templateValues[i]

                        if (!(valueParam["row_code"] in associativeTemplateValues)) {
                            associativeTemplateValues[valueParam["row_code"]] = {}
                        }

                        associativeTemplateValues[valueParam["row_code"]][valueParam["col_code"]] = {
                            value: valueParam["value"]
                        }
                    }

                    for (let i = 0; i < result.editableValues.length; i++) {
                        let valueParam = result.editableValues[i]

                        if (!(valueParam["row_code"] in associativeEditableValues)) {
                            associativeEditableValues[valueParam["row_code"]] = {}
                        }

                        associativeEditableValues[valueParam["row_code"]][valueParam["col_code"]] = {
                            value: valueParam["value"],
                            changedBy: valueParam["changed_by"]
                        }
                    }

                    for (let i = 0; i < result.editableByClientValues.length; i++) {
                        let valueParam = result.editableByClientValues[i]

                        if (!(valueParam["row_code"] in associativeEditableByClientValues)) {
                            associativeEditableByClientValues[valueParam["row_code"]] = {}
                        }

                        associativeEditableByClientValues[valueParam["row_code"]][valueParam["col_code"]] = {
                            value: valueParam["value"],
                            isAccepted: /*valueParam["is_accepted"]*/false
                        }
                    }

                    setRows(
                        rows.map(rowsInfo => {
                            rowsInfo.data.map(row => {
                                columns.map(columnsInfo => {
                                    columnsInfo.data.map(column => {
                                        let value = ""
                                        let valueWithoutSpaces = ""
                                        let changedBy = {}
                                        let changedByExist = false
                                        let isAccepted = true

                                        if (row.code in associativeTemplateValues &&
                                            column.code in associativeTemplateValues[row.code]) {
                                            value = associativeTemplateValues[row.code][column.code].value

                                            if (!column.showIfAllRowsEmpty) {
                                                let elem = document.createElement("textarea");

                                                elem.innerHTML = value;

                                                let txtValue = elem.value;

                                                valueWithoutSpaces = txtValue.trim()
                                                    .replace(/^\s+/, '')
                                                    .replace(/\s+$/, '')
                                                    .replace(/[\n\r]+/g, '')
                                                    .replace(/\s{2,}/g,'')
                                                    .replace(/^\s+|\s+$/,'')
                                                    .replace(/&lt;br&gt;/g,"")
                                                    .replace(/\<br\>/g,'');

                                                if (valueWithoutSpaces !== "") {
                                                    showIfAllRowsEmpty = true
                                                }
                                            }
                                        }

                                        if (row.code in associativeEditableValues &&
                                            column.code in associativeEditableValues[row.code]) {
                                            value = associativeEditableValues[row.code][column.code].value
                                            changedBy = associativeEditableValues[row.code][column.code].changedBy
                                            changedByExist = true

                                            if (!column.showIfAllRowsEmpty) {
                                                let elem = document.createElement("textarea");

                                                elem.innerHTML = value;

                                                let txtValue = elem.value;

                                                valueWithoutSpaces = txtValue.trim()
                                                    .replace(/^\s+/, '')
                                                    .replace(/\s+$/, '')
                                                    .replace(/[\n\r]+/g, '')
                                                    .replace(/\s{2,}/g,'')
                                                    .replace(/^\s+|\s+$/,'')
                                                    .replace(/&lt;br&gt;/g," ")
                                                    .replace(/\<br\>/g,' ');

                                                if (valueWithoutSpaces !== "") {
                                                    showIfAllRowsEmpty = true
                                                }
                                            }
                                        }

                                        if (row.code in associativeEditableByClientValues &&
                                            column.code in associativeEditableByClientValues[row.code]) {
                                            let clientValueData = associativeEditableByClientValues[row.code][column.code]

                                            value = clientValueData.value[0]

                                            if (!column.showIfAllRowsEmpty) {
                                                let elem = document.createElement("textarea");

                                                elem.innerHTML = value;

                                                let txtValue = elem.value;

                                                valueWithoutSpaces = txtValue.trim()
                                                    .replace(/^\s+/, '')
                                                    .replace(/\s+$/, '')
                                                    .replace(/[\n\r]+/g, '')
                                                    .replace(/\s{2,}/g,'')
                                                    .replace(/^\s+|\s+$/,'')
                                                    .replace(/&lt;br&gt;/g," ")
                                                    .replace(/\<br\>/g,' ');

                                                if (valueWithoutSpaces !== "") {
                                                    showIfAllRowsEmpty = true
                                                }
                                            }

                                            if (column.editableByClient) {
                                                isAccepted = "isAccepted" in clientValueData ? clientValueData.isAccepted : true
                                            }
                                        }

                                        row.data[column.code] = {
                                            value: value
                                        }

                                        if (column.editableByClient) {
                                            row.data[column.code]["isAccepted"] = isAccepted

                                            if (changedByExist) {
                                                row.data[column.code]["changedBy"] = changedBy
                                            }
                                        }

                                        return column
                                    })

                                    return columnsInfo
                                })

                                return row
                            })

                            return rowsInfo
                        })
                    )
                },
                (error) => {
                    setIsLoaded(true);
                    setError({
                        message: "An error occurred while loading page data. Please refresh or contact your manager."
                    });
                }
            )

        if (!showIfAllRowsEmpty) {
            setColumns(
                columns.map(info => {
                    info.data.map(column => {
                        if (!column.showIfAllRowsEmpty) {
                            column.show = false
                        }
                    })

                    return info
                })
            )
        }
    }

    async function getStyleGuideData() {
        await fetch("/proxy/project_bible_template/getPBClientViewStyleGuideData", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "viewCode": viewCode
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    let isCommunicationBetweenCharactersExist = result.queryPBStyleGuide[0]["communication_between_characters"]

                    setCommunicationBetweenCharacters(isCommunicationBetweenCharactersExist)

                    setLanguages(
                        languages.map(value => {
                            for (let i = 0; i < result.querySGLanguages.length; i++) {
                                if (result.queryPBStyleGuide[0].languages.includes(result.querySGLanguages[i]["language_code"])) {
                                    value.listOfCodes.push(result.querySGLanguages[i]["language_code"])
                                    value.data.push({
                                        title: result.querySGLanguages[i].language,
                                        code: result.querySGLanguages[i]["language_code"],
                                        data: [],
                                        accept: {}
                                    })

                                    if (isCommunicationBetweenCharactersExist) {
                                        setCharacterCommunications(
                                            characterCommunications.map(info => {
                                                info.languagesData[result.querySGLanguages[i]["language_code"]] = {
                                                    data: [],
                                                    linguistAcceptedData: []
                                                }

                                                return info
                                            })
                                        )
                                    }
                                }
                            }

                            return value
                        })
                    )

                    if (isCommunicationBetweenCharactersExist) {
                        for (let i = 0; i < result.querySGCharacters.length; i++) {
                            let characterId = +result.querySGCharacters[i].id
                            let characterNum = result.querySGCharacters[i].num
                            let characterName = result.querySGCharacters[i].name

                            setCharacterCommunications(
                                characterCommunications.map(info => {
                                    info.characters[characterId] = {
                                        "id": characterId,
                                        "num": characterNum,
                                        "name": characterName
                                    }

                                    info.templateData[characterNum - 1] = {
                                        "id": characterId,
                                        "name": characterName,
                                        "data": []
                                    }

                                    return info
                                })
                            )
                        }
                    }

                    result.querySGLinguist.map(value => {
                        setLinguistLinks(
                            linguistLinks.map(info => {
                                info.data[value["linguist_id"]] = {
                                    code: value["linguist_id"],
                                    languages: value.languages,
                                    languagesNames: [],
                                    languagePreferences: {},
                                    acceptData: []
                                }

                                return info
                            })
                        )

                        return value
                    })

                    result.querySGLinguistAcceptChanges.map(value => {
                        setLinguistLinks(
                            linguistLinks.map(info => {
                                let linguistId = value["linguist_id"]
                                let languageCode = value["language_code"]
                                let acceptLanguagePreferences = value["accept_language_preferences"]

                                info.data[linguistId].acceptData[languageCode] = {
                                    languagePreferences: {
                                        value: acceptLanguagePreferences
                                    }
                                }

                                if (acceptLanguagePreferences) {
                                    setLanguagePreferencesAcceptData(linguistId, languageCode, acceptLanguagePreferences)
                                }

                                return info
                            })
                        )

                        return value
                    })

                    fillInLanguageData(result.querySGLanguages, result.querySGLanguageExtraRows,
                        result.querySGLanguageFilledData, result.querySGLinguistLanguageExtraRows,
                        result.querySGLinguistLanguageFilledData, result.queryClientViewSGFilledData,
                        result.queryClientViewSGLinguistRowsFilledData)

                    if (isCommunicationBetweenCharactersExist) {
                        fillInCharacterCommunication(result.querySGCharacters, result.querySGCharactersData,
                            result.querySGLinguistCharactersData)
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError({
                        message: "An error occurred while loading page data. Please refresh or contact your manager."
                    });
                }
            )
    }

    function fillInLanguageData(querySGLanguages, querySGLanguageExtraRows, querySGLanguageFilledData,
                                querySGLinguistLanguageExtraRows, querySGLinguistLanguageFilledData,
                                queryClientViewSGFilledData, queryClientViewSGLinguistRowsFilledData) {
        let sgLanguagesByCode = {}
        let sgExtraRowsByLanguageCode = {}
        let sgFilledDataByLanguageCode = {}
        let sgLinguistExtraRowsByLanguageCode = {}
        let sgLinguistFilledDataByLanguageCode = {}
        let sgClientFilledDataByLanguageCode = {}
        let sgClientFilledLinguistRowsDataByLanguageCode = {}

        for (let i = 0; i < querySGLanguages.length; i++) {
            let languageCode = querySGLanguages[i]["language_code"]

            sgLanguagesByCode[languageCode] = querySGLanguages[i]
        }

        for (let i = 0; i < querySGLanguageExtraRows.length; i++) {
            let languageCode = querySGLanguageExtraRows[i]["language_code"]

            if (!(languageCode in sgExtraRowsByLanguageCode)) {
                sgExtraRowsByLanguageCode[languageCode] = []
            }

            sgExtraRowsByLanguageCode[languageCode].push(querySGLanguageExtraRows[i])
        }

        for (let i = 0; i < querySGLanguageFilledData.length; i++) {
            let languageCode = querySGLanguageFilledData[i]["language_code"]

            if (!(languageCode in sgFilledDataByLanguageCode)) {
                sgFilledDataByLanguageCode[languageCode] = []
            }

            sgFilledDataByLanguageCode[languageCode].push(querySGLanguageFilledData[i])
        }

        for (let i = 0; i < querySGLinguistLanguageExtraRows.length; i++) {
            let linguistId = querySGLinguistLanguageExtraRows[i]["linguist_id"]
            let languageCode = querySGLinguistLanguageExtraRows[i]["language_code"]
            let blockCode = querySGLinguistLanguageExtraRows[i]["block"]
            let rowCode = querySGLinguistLanguageExtraRows[i]["row_code"]

            if (!(linguistId in sgLinguistExtraRowsByLanguageCode)) {
                sgLinguistExtraRowsByLanguageCode[linguistId] = {}
            }

            if (!(languageCode in sgLinguistExtraRowsByLanguageCode[linguistId])) {
                sgLinguistExtraRowsByLanguageCode[linguistId][languageCode] = {}
            }

            if (!(blockCode in sgLinguistExtraRowsByLanguageCode[linguistId][languageCode])) {
                sgLinguistExtraRowsByLanguageCode[linguistId][languageCode][blockCode] = {}
            }

            sgLinguistExtraRowsByLanguageCode[linguistId][languageCode][blockCode][rowCode] = querySGLinguistLanguageExtraRows[i]
        }

        for (let i = 0; i < querySGLinguistLanguageFilledData.length; i++) {
            let linguistId = querySGLinguistLanguageFilledData[i]["linguist_id"]
            let languageCode = querySGLinguistLanguageFilledData[i]["language_code"]
            let blockCode = querySGLinguistLanguageFilledData[i]["block"]
            let rowCode = querySGLinguistLanguageFilledData[i]["row_code"]
            let colCode = querySGLinguistLanguageFilledData[i]["col_code"]
            let value = querySGLinguistLanguageFilledData[i]["value"]

            if (!(linguistId in sgLinguistFilledDataByLanguageCode)) {
                sgLinguistFilledDataByLanguageCode[linguistId] = {}
            }

            if (!(languageCode in sgLinguistFilledDataByLanguageCode[linguistId])) {
                sgLinguistFilledDataByLanguageCode[linguistId][languageCode] = []
            }

            if (!(blockCode in sgLinguistFilledDataByLanguageCode[linguistId][languageCode])) {
                sgLinguistFilledDataByLanguageCode[linguistId][languageCode][blockCode] = {}
            }

            if (!(rowCode in sgLinguistFilledDataByLanguageCode[linguistId][languageCode][blockCode])) {
                sgLinguistFilledDataByLanguageCode[linguistId][languageCode][blockCode][rowCode] = {}
            }

            sgLinguistFilledDataByLanguageCode[linguistId][languageCode][blockCode][rowCode][colCode] = value
        }

        for (let i = 0; i < queryClientViewSGFilledData.length; i++) {
            let languageCode = queryClientViewSGFilledData[i]["language_code"]
            let blockCode = queryClientViewSGFilledData[i]["block"]
            let rowCode = queryClientViewSGFilledData[i]["row_code"]
            let colCode = queryClientViewSGFilledData[i]["col_code"]
            let value = queryClientViewSGFilledData[i]["value"]
            let isAccepted = /*queryClientViewSGFilledData[i]["is_accepted"]*/false

            if (!(languageCode in sgClientFilledDataByLanguageCode)) {
                sgClientFilledDataByLanguageCode[languageCode] = []
            }

            if (!(blockCode in sgClientFilledDataByLanguageCode[languageCode])) {
                sgClientFilledDataByLanguageCode[languageCode][blockCode] = {}
            }

            if (!(rowCode in sgClientFilledDataByLanguageCode[languageCode][blockCode])) {
                sgClientFilledDataByLanguageCode[languageCode][blockCode][rowCode] = {}
            }

            sgClientFilledDataByLanguageCode[languageCode][blockCode][rowCode][colCode] = {
                value: value,
                isAccepted: isAccepted
            }
        }

        for (let i = 0; i < queryClientViewSGLinguistRowsFilledData.length; i++) {
            let linguistId = queryClientViewSGLinguistRowsFilledData[i]["linguist_id"]
            let languageCode = queryClientViewSGLinguistRowsFilledData[i]["language_code"]
            let blockCode = queryClientViewSGLinguistRowsFilledData[i]["block"]
            let rowCode = queryClientViewSGLinguistRowsFilledData[i]["row_code"]
            let colCode = queryClientViewSGLinguistRowsFilledData[i]["col_code"]
            let value = queryClientViewSGLinguistRowsFilledData[i]["value"]
            let isAccepted = /*queryClientViewSGLinguistRowsFilledData[i]["is_accepted"]*/false

            if (!(linguistId in sgClientFilledLinguistRowsDataByLanguageCode)) {
                sgClientFilledLinguistRowsDataByLanguageCode[linguistId] = {}
            }

            if (!(languageCode in sgClientFilledLinguistRowsDataByLanguageCode[linguistId])) {
                sgClientFilledLinguistRowsDataByLanguageCode[linguistId][languageCode] = []
            }

            if (!(blockCode in sgClientFilledLinguistRowsDataByLanguageCode[linguistId][languageCode])) {
                sgClientFilledLinguistRowsDataByLanguageCode[linguistId][languageCode][blockCode] = {}
            }

            if (!(rowCode in sgClientFilledLinguistRowsDataByLanguageCode[linguistId][languageCode][blockCode])) {
                sgClientFilledLinguistRowsDataByLanguageCode[linguistId][languageCode][blockCode][rowCode] = {}
            }

            sgClientFilledLinguistRowsDataByLanguageCode[linguistId][languageCode][blockCode][rowCode][colCode] = {
                value: value,
                isAccepted: isAccepted
            }
        }

        for (let languageCode in sgExtraRowsByLanguageCode) {
            let rows = sgExtraRowsByLanguageCode[languageCode]

            if (languageCode in sgLanguagesByCode) {
                for (let i = 0; i < rows.length; i++) {
                    let rowToPush = []

                    for (let j = 0; j < rows[i].columns.length; j++) {
                        rowToPush.push({
                            "code": rows[i].columns[j],
                            "text": "",
                            "styleGuideEditable": true
                        })
                    }

                    if (rows[i].block in sgLanguagesByCode[languageCode]) {
                        sgLanguagesByCode[languageCode][rows[i].block].data.push({
                            "row": rowToPush,
                            "code": rows[i]["row_code"],
                            "colspan": rows[i].colspan,
                            "extraRow": true
                        })
                    }
                }
            }
        }

        for (let languageCode in sgFilledDataByLanguageCode) {
            let rows = sgFilledDataByLanguageCode[languageCode]

            if (languageCode in sgLanguagesByCode) {
                for (let i = 0; i < rows.length; i++) {
                    if (rows[i].block in sgLanguagesByCode[languageCode]) {
                        let elData = sgLanguagesByCode[languageCode][rows[i].block].data

                        for (let j = 0; j < elData.length; j++) {
                            if (elData[j].code === rows[i].row_code) {
                                for (let k = 0; k < elData[j].row.length; k++) {
                                    if (elData[j].row[k].code === rows[i].col_code) {
                                        elData[j].row[k].text = rows[i].value
                                        elData[j].row[k]["changedBy"] = rows[i]["changed_by"]
                                    }
                                }
                            }
                        }

                        sgLanguagesByCode[languageCode][rows[i].block].data = elData
                    }
                }
            }
        }

        for (let languageCode in sgClientFilledDataByLanguageCode) {
            let blocks = sgClientFilledDataByLanguageCode[languageCode]

            if (languageCode in sgLanguagesByCode) {
                for (let blockCode in blocks) {
                    if (blockCode in sgLanguagesByCode[languageCode]) {
                        let elData = sgLanguagesByCode[languageCode][blockCode].data
                        let rows = blocks[blockCode]

                        for (let i = 0; i < elData.length; i++) {
                            if (elData[i].code in rows) {
                                let columns = rows[elData[i].code]

                                for (let j = 0; j < elData[i].row.length; j++) {
                                    if (elData[i].row[j].code in columns) {
                                        let columnData = columns[elData[i].row[j].code]

                                        elData[i].row[j].text = columnData.value
                                        elData[i].row[j]["isAccepted"] = columnData.isAccepted
                                    }
                                }
                            }
                        }

                        sgLanguagesByCode[languageCode][blockCode].data = elData
                    }
                }
            }
        }

        setLanguages(
            languages.map(info => {
                info.data.map(language => {
                    let languageCode = language.code
                    let languageAcceptData = language.accept
                    let acceptData = {}

                    for (let linguistId in languageAcceptData) {
                        let linguistData = languageAcceptData[linguistId]

                        for (let block in linguistData) {
                            let blockData = linguistData[block]

                            for (let i = 0; i < blockData.default.length; i++) {
                                let rowCode = blockData.default[i]

                                if (languageCode in sgLanguagesByCode &&
                                    block in sgLanguagesByCode[languageCode]) {
                                    sgLanguagesByCode[languageCode][block].data.map(languageDataInfo => {
                                        if (languageDataInfo.code === rowCode) {
                                            for (let j = 0; j < languageDataInfo.row.length; j++) {
                                                let colCode = languageDataInfo.row[j].code

                                                if (!("isAccepted" in languageDataInfo.row[j])) {
                                                    if (linguistId in sgLinguistFilledDataByLanguageCode &&
                                                        languageCode in sgLinguistFilledDataByLanguageCode[linguistId] &&
                                                        block in sgLinguistFilledDataByLanguageCode[linguistId][languageCode] &&
                                                        rowCode in sgLinguistFilledDataByLanguageCode[linguistId][languageCode][block] &&
                                                        colCode in sgLinguistFilledDataByLanguageCode[linguistId][languageCode][block][rowCode]) {
                                                        languageDataInfo.row[j].text =
                                                            sgLinguistFilledDataByLanguageCode[linguistId][languageCode][block][rowCode][colCode]
                                                    }
                                                }
                                            }
                                        }

                                        return languageDataInfo
                                    })
                                }
                            }

                            for (let i = 0; i < blockData.extra.length; i++) {
                                let rowCode = blockData.extra[i]

                                if (linguistId in sgLinguistExtraRowsByLanguageCode &&
                                    languageCode in sgLinguistExtraRowsByLanguageCode[linguistId] &&
                                    block in sgLinguistExtraRowsByLanguageCode[linguistId][languageCode] &&
                                    rowCode in sgLinguistExtraRowsByLanguageCode[linguistId][languageCode][block]) {
                                    let rowData =
                                        sgLinguistExtraRowsByLanguageCode[linguistId][languageCode][block][rowCode]
                                    let rowToPush = []

                                    for (let j = 0; j < rowData.columns.length; j++) {
                                        let colCode = rowData.columns[j]
                                        let text = ""
                                        let isClientChanged = false
                                        let isAccepted = false

                                        if (linguistId in sgLinguistFilledDataByLanguageCode &&
                                            languageCode in sgLinguistFilledDataByLanguageCode[linguistId] &&
                                            block in sgLinguistFilledDataByLanguageCode[linguistId][languageCode] &&
                                            rowCode in sgLinguistFilledDataByLanguageCode[linguistId][languageCode][block] &&
                                            colCode in sgLinguistFilledDataByLanguageCode[linguistId][languageCode][block][rowCode]) {
                                            text =
                                                sgLinguistFilledDataByLanguageCode[linguistId][languageCode][block][rowCode][colCode]
                                        }

                                        if (linguistId in sgClientFilledLinguistRowsDataByLanguageCode &&
                                            languageCode in sgClientFilledLinguistRowsDataByLanguageCode[linguistId] &&
                                            block in sgClientFilledLinguistRowsDataByLanguageCode[linguistId][languageCode] &&
                                            rowCode in sgClientFilledLinguistRowsDataByLanguageCode[linguistId][languageCode][block] &&
                                            colCode in sgClientFilledLinguistRowsDataByLanguageCode[linguistId][languageCode][block][rowCode]) {
                                            let clientValue = sgClientFilledLinguistRowsDataByLanguageCode[linguistId][languageCode][block][rowCode][colCode]

                                            text = clientValue.value
                                            isAccepted = clientValue.isAccepted
                                            isClientChanged = true
                                        }

                                        let valueToPush = {
                                            "code": colCode,
                                            "text": text,
                                            "styleGuideEditable": true,
                                            "linguistEditable": rowData.linguist_editable
                                        }

                                        if (isClientChanged) {
                                            //этот параметр добавляется только если строка менялась клиентом
                                            valueToPush["isAccepted"] = isAccepted
                                        }

                                        rowToPush.push(valueToPush)
                                    }

                                    if (languageCode in sgLanguagesByCode &&
                                        block in sgLanguagesByCode[languageCode]) {
                                        sgLanguagesByCode[languageCode][block].data.push({
                                            "row": rowToPush,
                                            "code": rowCode,
                                            "colspan": rowData.colspan,
                                            "linguistExtraRow": true,
                                            "linguist": linguistId
                                        })
                                    }
                                }
                            }

                            for (let i = 0; i < blockData.otherLinguistExtra.length; i++) {
                                let rowCreatedByLinguist = blockData.otherLinguistExtra[i].linguist
                                let rows = blockData.otherLinguistExtra[i].rows

                                for (let j = 0; j < rows.length; j++) {
                                    let rowCode = rows[j]

                                    if (rowCreatedByLinguist in sgLinguistExtraRowsByLanguageCode &&
                                        languageCode in sgLinguistExtraRowsByLanguageCode[rowCreatedByLinguist] &&
                                        block in sgLinguistExtraRowsByLanguageCode[rowCreatedByLinguist][languageCode] &&
                                        rowCode in sgLinguistExtraRowsByLanguageCode[rowCreatedByLinguist][languageCode][block]) {
                                        let rowData =
                                            sgLinguistExtraRowsByLanguageCode[rowCreatedByLinguist][languageCode][block][rowCode]
                                        let rowToPush = []

                                        for (let j = 0; j < rowData.columns.length; j++) {
                                            let colCode = rowData.columns[j]
                                            let text = ""
                                            let isClientChanged = false
                                            let isAccepted = false

                                            if (rowCreatedByLinguist in sgLinguistFilledDataByLanguageCode &&
                                                languageCode in sgLinguistFilledDataByLanguageCode[rowCreatedByLinguist] &&
                                                block in sgLinguistFilledDataByLanguageCode[rowCreatedByLinguist][languageCode] &&
                                                rowCode in sgLinguistFilledDataByLanguageCode[rowCreatedByLinguist][languageCode][block] &&
                                                colCode in sgLinguistFilledDataByLanguageCode[rowCreatedByLinguist][languageCode][block][rowCode]) {
                                                text =
                                                    sgLinguistFilledDataByLanguageCode[rowCreatedByLinguist][languageCode][block][rowCode][colCode]
                                            }

                                            if (linguistId in sgLinguistFilledDataByLanguageCode &&
                                                languageCode in sgLinguistFilledDataByLanguageCode[linguistId] &&
                                                block in sgLinguistFilledDataByLanguageCode[linguistId][languageCode] &&
                                                rowCode in sgLinguistFilledDataByLanguageCode[linguistId][languageCode][block] &&
                                                colCode in sgLinguistFilledDataByLanguageCode[linguistId][languageCode][block][rowCode]) {
                                                text =
                                                    sgLinguistFilledDataByLanguageCode[linguistId][languageCode][block][rowCode][colCode]
                                            }

                                            if (linguistId in sgClientFilledLinguistRowsDataByLanguageCode &&
                                                languageCode in sgClientFilledLinguistRowsDataByLanguageCode[linguistId] &&
                                                block in sgClientFilledLinguistRowsDataByLanguageCode[linguistId][languageCode] &&
                                                rowCode in sgClientFilledLinguistRowsDataByLanguageCode[linguistId][languageCode][block] &&
                                                colCode in sgClientFilledLinguistRowsDataByLanguageCode[linguistId][languageCode][block][rowCode]) {
                                                let clientValue = sgClientFilledLinguistRowsDataByLanguageCode[linguistId][languageCode][block][rowCode][colCode]

                                                text = clientValue.value
                                                isAccepted = clientValue.isAccepted
                                                isClientChanged = true
                                            }

                                            let valueToPush = {
                                                "code": colCode,
                                                "text": text,
                                                "styleGuideEditable": true,
                                                "linguistEditable": rowData.linguist_editable
                                            }

                                            if (isClientChanged) {
                                                //этот параметр добавляется только если строка менялась клиентом
                                                valueToPush["isAccepted"] = isAccepted
                                            }

                                            rowToPush.push(valueToPush)
                                        }

                                        if (languageCode in sgLanguagesByCode &&
                                            block in sgLanguagesByCode[languageCode]) {
                                            sgLanguagesByCode[languageCode][block].data.push({
                                                "row": rowToPush,
                                                "code": rowCode,
                                                "colspan": rowData.colspan,
                                                "linguistExtraRow": true,
                                                "linguist": linguistId
                                            })
                                        }
                                    }
                                }
                            }
                        }
                    }

                    language.data = sgLanguagesByCode[languageCode]
                })

                return info
            })
        )
    }

    function fillInCharacterCommunication(querySGCharacters, querySGCharactersData, querySGLinguistCharactersData) {
        setCharacterCommunications(
            characterCommunications.map(info => {
                for (let i = 0; i < querySGCharactersData.length; i++) {
                    let languageCode = querySGCharactersData[i]["language_code"]

                    if (languages[0].listOfCodes.includes(languageCode)) {
                        let firstCharacter = querySGCharactersData[i]["character_first"]
                        let secondCharacter = querySGCharactersData[i]["character_second"]
                        let firstCharacterSequence = getCharacterSequence(firstCharacter) - 1
                        let secondCharacterSequence = getCharacterSequence(secondCharacter) - 1

                        if (languageCode === 0) {
                            info.templateData[firstCharacterSequence].data[secondCharacterSequence] = querySGCharactersData[i].value
                        } else {
                            if (languageCode in info.languagesData) {
                                if (!(firstCharacterSequence in info.languagesData[languageCode].data)) {
                                    info.languagesData[languageCode].data[firstCharacterSequence] = []
                                }

                                info.languagesData[languageCode].data[firstCharacterSequence][secondCharacterSequence] = querySGCharactersData[i].value
                            }
                        }
                    }
                }

                for (let i = 0; i < querySGLinguistCharactersData.length; i++) {
                    let languageCode = querySGLinguistCharactersData[i]["language_code"]

                    if (languages[0].listOfCodes.includes(languageCode)) {
                        let characterFirst = querySGLinguistCharactersData[i]["character_first"]
                        let characterSecond = querySGLinguistCharactersData[i]["character_second"]
                        let acceptedValue = querySGLinguistCharactersData[i]["accepted_value"]
                        let firstCharacterSequence = getCharacterSequence(characterFirst) - 1
                        let secondCharacterSequence = getCharacterSequence(characterSecond) - 1

                        if (languageCode in info.languagesData) {
                            if (!(firstCharacterSequence in info.languagesData[languageCode].linguistAcceptedData)) {
                                info.languagesData[languageCode].linguistAcceptedData[firstCharacterSequence] = []
                            }

                            info.languagesData[languageCode].linguistAcceptedData[firstCharacterSequence][secondCharacterSequence] = {
                                value: acceptedValue
                            }
                        }
                    }
                }

                return info
            })
        )
    }

    function setLanguagePreferencesAcceptData(linguistId, languageCode, value) {
        setLanguages(
            languages.map(info => {
                info.data.map(language => {
                    if (language.code === languageCode) {
                        language.accept[linguistId] = value
                    }

                    return language
                })

                return info
            })
        )
    }

    function getCharacterSequence(id) {
        let num = 0

        characterCommunications.map(info => {
            num = info.characters[id].num

            return info
        })

        return num
    }

    function triggerOpenInModalAddedImage() {
        const openInModalAddedImageArray = document.getElementsByClassName("open-in-modal-added-image")

        for (const trigger of openInModalAddedImageArray) {
            trigger.addEventListener('mouseover', (e) => {
                console.log("triggerOpenInModalAddedImage mouseover")

                setTooltipImageData(
                    tooltipImageData.map(data => {
                        data.src = e.target.dataset.src
                        data.title = trigger.innerText
                        data.element = e
                        data.justUploaded = "justUploaded" in e.target.dataset && e.target.dataset.justUploaded == "1";

                        return data
                    })
                )
            });
        }
    }

    async function generalTabSaveCellValue(column, row, value) {
        await fetch('/proxy/project_bible_template/setClientViewGeneralCellData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "code": viewCode,
                "row": row,
                "column": column,
                "value": value
            })
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setRows(
                        rows.map(rowsData => {
                            rowsData.data.map(rowValue => {
                                if (rowValue.code === row) {
                                    rowValue.data[column].isAccepted = false

                                    triggerOpenInModalAddedImage()
                                }

                                return rowValue
                            })

                            return rowsData
                        })
                    )
                },
                (error) => {
                    alert("An error occurred while saving cell data.")
                }
            )
    }

    async function sgTabSaveCellValue(linguist, language, block, row, column, value) {
        let link = '/proxy/project_bible_template/'
        let body = {
            "code": viewCode,
            "language": language,
            "block": block,
            "row": row,
            "column": column,
            "value": value
        }

        if (linguist.isLinguistRow) {
            link += 'setClientViewSGLinguistRowCellData'
            body["linguist"] = linguist.linguistId
        } else {
            link += 'setClientViewSGCellData'
        }

        await fetch(link, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setLanguages(
                        languages.map(info => {
                            info.data.map(languageData => {
                                if (languageData.code === language) {
                                    for (let blockKey in languageData.data) {
                                        if (blockKey === block) {
                                            languageData.data[blockKey].data.map(rowData => {
                                                if (rowData.code === row) {
                                                    if (!linguist.isLinguistRow || (linguist.isLinguistRow &&
                                                        "linguist" in rowData && rowData.linguist === linguist)) {
                                                        rowData.row.map(colData => {
                                                            if (colData.code === column) {
                                                                colData["isAccepted"] = false

                                                                triggerOpenInModalAddedImage()
                                                            }

                                                            return colData
                                                        })
                                                    }
                                                }

                                                return rowData
                                            })
                                        }
                                    }
                                }

                                return languageData
                            })

                            return info
                        })
                    )
                },
                (error) => {
                    alert("An error occurred while saving cell data.")
                }
            )
    }

    async function acceptChanges() {
        setModalAlertMessage("Please wait...") //todo pb_client_view_new
        setModalAlertActive(true)

        await fetch("/proxy/project_bible_template/setClientAcceptChangesFromManagers", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientName": clientName,
                "projectName": projectName,
                "projectCode": projectCode,
                "viewCode": viewCode
            }),
        })
            .then(res => res.json())
            .then(
                async (result) => {
                    setModalAlertMessage("Changes have been successfully accepted!") //todo pb_client_view_new
                    setShowAcceptChangesBtn(false)
                },
                (error) => {
                    setModalAlertMessage("An error occurred while accepting the changes.") //todo pb_client_view_new
                }
            )
    }

    function openHintModal() {
        setModalHintActive(true)
    }

    function createPdf(code, countClientColumns) {

    }

    if (error) {
        return (
            <div className="row">
                <div className="col-sm-12 center">
                    <br />
                    <h3>Error: {error.message}</h3>
                </div>
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div className="row">
                <div className="col-sm-12 center">
                    <br/>
                    <h3>Loading...</h3>
                </div>
            </div>
        )
    } else {
        return(
            <div className="main-block-div-dark">
                <div className="row">
                    <div className="col-sm-4">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicClientName">
                                <Form.Label>Client</Form.Label>
                                <Form.Control type="clientName" disabled
                                              placeholder={clientName} />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="col-sm-4">
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicProjectName">
                                <Form.Label>Project name</Form.Label>
                                <Form.Control type="projectName" disabled
                                              placeholder={projectName} />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="col-sm-4 center">
                        <br/>
                        {
                            showAcceptChangesBtn &&
                            <>
                                <button className="btn btn-yellow"
                                        onClick={(e) => acceptChanges()}>
                                    Accept Changes&nbsp;&nbsp;<FontAwesomeIcon icon={faCheckCircle}/>
                                </button>
                                &nbsp;&nbsp;
                                <button className="btn btn-light"
                                        onClick={(e) => openHintModal()}>
                                    Hint
                                </button>
                            </>
                        }
                    </div>
                    {/*<div className="col-sm-4 center">
                        <br />
                        <Button
                            variant="dark" disabled
                            onClick={(e) => createPdf()}
                        >
                            Download pdf&nbsp;&nbsp;<FontAwesomeIcon icon={faFilePdf}/>
                        </Button>
                    </div>*/}
                </div>
                <br />
                <AdditionalComment
                    viewCode={viewCode}
                    additionalComment={additionalComment}
                />
                <br />
                <ClientViewTabs
                    viewCode={viewCode}
                    triggerOpenInModalAddedImage={triggerOpenInModalAddedImage}
                    columns={columns[0].data}
                    rows={rows[0].data}
                    generalTabSaveCellValue={generalTabSaveCellValue}
                    languages={languages[0].data}
                    communicationBetweenCharacters={communicationBetweenCharacters}
                    characterCommunications={characterCommunications}
                    sgTabSaveCellValue={sgTabSaveCellValue}
                    showAcceptChangesBtn={showAcceptChangesBtn}
                />
                <OpenInTooltipAddedImage
                    tooltipImageSrc={tooltipImageData[0].src}
                    tooltipImageTitle={tooltipImageData[0].title}
                    tooltipImageJustUploaded={tooltipImageData[0].justUploaded}
                />
                <AlertModal modalAlertActive={modalAlertActive}
                            setModalAlertActive={setModalAlertActive}
                            alertMessage={modalAlertMessage}
                />
                <ClientHintModal
                    modalHintActive={modalHintActive}
                    setModalHintActive={setModalHintActive}
                />
            </div>
        )
    }
}