import {Col, Nav, Row, Tabs, Tab} from "react-bootstrap";
import React, {useEffect} from "react";
import GeneralTab from "./GeneralTab";
import LogOfNewBatchesTab from "./LogOfNewBatchesTab";
import LanguageTab from "./LanguageTab";
import LinguistsTab from "./LinguistsTab";

export default function StyleGuideTabs(props) {
    // console.log("StyleGuideTabs", props)
    useEffect(() => {
        props.triggerOpenInModalAddedImage()
    }, []);

    return(
        <Tab.Container defaultActiveKey={"general"}>
            <Row>
                <Col sm={12}>
                    <Nav variant="pills" className="nav-custom-grey">
                        <Nav.Item>
                            <Nav.Link eventKey={"general"}>
                                General
                            </Nav.Link>
                        </Nav.Item>
                        {
                            props.logOfNewBatches &&
                            <Nav.Item>
                                <Nav.Link eventKey={"logOfNewBatches"}>
                                    Log Of New Batches
                                </Nav.Link>
                            </Nav.Item>
                        }
                        {
                            props.languages.map(language => {
                                if (language.value) {
                                    return(
                                        <Nav.Item key={language.code}>
                                            <Nav.Link eventKey={language.code}>
                                                {language.title}
                                            </Nav.Link>
                                        </Nav.Item>
                                    )
                                }
                            })
                        }
                        <Nav.Item>
                            <Nav.Link eventKey={"linguists"}>
                                For linguists
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey={"general"}>
                            <div className="tab-item-wrapper">
                                <br/>
                                <div className="row">
                                    <div className="col-sm-12 tableFixHead">
                                        <GeneralTab rows={props.rows} columns={props.columns}
                                            // setInputRegexModalValue={props.setInputRegexModalValue}
                                                    openModalToAddLinksAndImages={props.openModalToAddLinksAndImages}
                                                    modalAddLinksAndImagesActive={props.modalAddLinksAndImagesActive}
                                                    modalOpenAddedImageActive={props.modalOpenAddedImageActive}
                                                    generalTabSaveCellValue={props.generalTabSaveCellValue}
                                                    openInModalAddedImage={props.openInModalAddedImage}
                                                    triggerOpenInModalAddedImage={props.triggerOpenInModalAddedImage}
                                            /*triggerOpenInTooltipAddedImage={props.triggerOpenInTooltipAddedImage}*/ />
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        {
                        props.logOfNewBatches &&
                            <Tab.Pane eventKey={"logOfNewBatches"}>
                                <div className="tab-item-wrapper">
                                    <br/>
                                    <div className="row">
                                        <div className="col-sm-12 tableFixHead">
                                            <LogOfNewBatchesTab
                                                logOfNewBatchesHeaders={props.logOfNewBatchesHeaders}
                                                logOfNewBatchesRowsCount={props.logOfNewBatchesRowsCount}
                                                logOfNewBatchesRowsData={props.logOfNewBatchesRowsData}
                                                deleteLogOfNewBatchesRow={props.deleteLogOfNewBatchesRow}
                                                addLogOfNewBatchesRowToTheBeginning={props.addLogOfNewBatchesRowToTheBeginning}
                                                oninputLogOfNewBatchesCell={props.oninputLogOfNewBatchesCell}
                                                triggerOpenInModalAddedImage={props.triggerOpenInModalAddedImage}
                                                openModalToAddLinksAndImages={props.openModalToAddLinksAndImages}
                                                modalAddLinksAndImagesActive={props.modalAddLinksAndImagesActive}
                                                modalOpenAddedImageActive={props.modalOpenAddedImageActive}/>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                        }
                        {
                            props.languages.map(language => {
                                if (language.value) {
                                    return(
                                        <Tab.Pane key={language.code} eventKey={language.code}>
                                            <div className="tab-item-wrapper">
                                                <br/>
                                                <LanguageTab
                                                    language={language}
                                                    linguistLinks={props.linguistLinks}
                                                    addRowToStyleGuideLanguageBlock={props.addRowToStyleGuideLanguageBlock}
                                                    characterCommunications={props.characterCommunications}
                                                    projectManagementInfo={props.projectManagementInfo}
                                                    communicationBetweenCharacters={props.communicationBetweenCharacters}
                                                    oninputCharacterCommunicationCell={props.oninputCharacterCommunicationCell}
                                                    deleteRowFromStyleGuideLanguageBlock={props.deleteRowFromStyleGuideLanguageBlock}
                                                    modalAddLinksAndImagesActive={props.modalAddLinksAndImagesActive}
                                                    modalOpenAddedImageActive={props.modalOpenAddedImageActive}
                                                    triggerOpenInModalAddedImage={props.triggerOpenInModalAddedImage}
                                                    languageTabSavePreferencesCellValue={props.languageTabSavePreferencesCellValue}
                                                    openModalToAddLinksAndImages={props.openModalToAddLinksAndImages}
                                                    selectTemplateTextForLanguageTabPreferences={props.selectTemplateTextForLanguageTabPreferences}
                                                    employeeId={props.employeeId}
                                                    employeeName={props.employeeName}
                                                />
                                            </div>
                                        </Tab.Pane>
                                    )
                                }
                            })
                        }
                        <Tab.Pane eventKey={"linguists"}>
                            <div className="tab-item-wrapper">
                                <br/>
                                <LinguistsTab
                                    linguistLinks={props.linguistLinks}
                                    languages={props.languages}
                                    characterCommunications={props.characterCommunications}
                                    // returnLinguistLinkToWork={props.returnLinguistLinkToWork}
                                    resetAcceptedFromOtherLinguistCharactersValues={props.resetAcceptedFromOtherLinguistCharactersValues}
                                    setLinguistCharactersValues={props.setLinguistCharactersValues}
                                    getCharacterId={props.getCharacterId}
                                />
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
}